function getSelectedSuggestion(state) {
  return ({ residenceIndex }) => state.selectedSuggestions[residenceIndex];
}

const swapType = state => state.swapType;
const wishes = state => state.wishes;
const residences = state => state.residences;
const swapTimeFrame = state => state.swapTimeFrame;
const name = state => state.name;
const email = state => state.email;
const ssoProfile = state => state.ssoProfile;
const emailValid = state => state.emailValid;
const loading = state => state.loading;
const loginError = state => state.loginError;
const signupError = state => state.signupError;
const proposition = state => state.proposition;
const searchResultWishAreas = state => state.searchResultWishAreas;
const searchResults = state => state.searchAddressData.results;
const searchTerm = state => state.searchTerm;
const selectedAreas = state => state.selectedAreas;
const selectedSuggestions = state => state.selectedSuggestions;
const wishAreaOptions = state => state.wishAreaOptions;
const polygons = state => state.polygons;
const searchLoading = state => state.searchLoading;
const searchNoResultsFound = state => state.searchNoResultsFound;
const numberOfMatchingApartments = state => state.numberOfMatchingApartments;
const coordinates = state => state.coordinates;
const searchResultAddresses = state => state.searchAddressData.addresses;
const currentStep = state => state.currentStep;
const lastFinishedStep = state => state.lastFinishedStep;
const inSignup = state => state.inSignup;
const signupStarted = state => state.signupStarted;

export default {
  swapType,
  wishes,
  residences,
  swapTimeFrame,
  name,
  email,
  emailValid,
  loading,
  loginError,
  signupError,
  proposition,
  ssoProfile,
  searchResultWishAreas,
  searchResults,
  searchTerm,
  selectedAreas,
  selectedSuggestions,
  getSelectedSuggestion,
  wishAreaOptions,
  polygons,
  searchLoading,
  searchNoResultsFound,
  numberOfMatchingApartments,
  coordinates,
  searchResultAddresses,
  currentStep,
  lastFinishedStep,
  inSignup,
  signupStarted
};
