var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"swap-item block-pulse"},[_vm._m(0),(_vm.isMobile)?_c('div',{staticClass:"swap-item-header"},[_c('div',{staticClass:"profile-picture"}),_vm._m(1),_c('div',{staticClass:"icon-area"},[_c('div',{class:['favourite-icon block']}),_c('div',{class:['proposition-type block']},[_vm._v("label")])])]):_vm._e(),_c('div',{staticClass:"swap-item-body"},[(!_vm.isMobile)?_c('div',{staticClass:"swap-item-header"},[_vm._m(2),_c('div',{staticClass:"icon-area"})]):_vm._e(),(_vm.isMobile)?_c('div',[_vm._m(3),_vm._m(4)]):_c('div',{staticClass:"desktop-body-center"},[_vm._m(5),_vm._m(6)])])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"swap-item-slider"},[_c('div',{staticClass:"img-placeholder"})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"address-info"},[_c('div',{staticClass:"address block"},[_vm._v("address")]),_c('div',{staticClass:"postal-area block"},[_vm._v("postalarea")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"address-info"},[_c('div',{staticClass:"address block"},[_vm._v("address")]),_c('div',{staticClass:"postal-area block"},[_vm._v("postalarea")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"interest-bar"},[_c('div',{staticClass:"interest-area"},[_c('div',{staticClass:"interest-progress block"},[_vm._v("0%")])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"residence-info-bar block"},[_c('span',{staticClass:"extra-bold"},[_vm._v("0")]),_vm._v(" rum ∙ "),_c('span',{staticClass:"extra-bold"},[_vm._v("0")]),_vm._v(" kvm ∙ Våning "),_c('span',{staticClass:"extra-bold"},[_vm._v("0")]),_vm._v(" ∙ "),_c('span',{staticClass:"extra-bold"},[_vm._v("1000")]),_vm._v(" kr/månad ")])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"desktop-body-center-info block"},[_c('span',{staticClass:"extra-bold"},[_vm._v("0")]),_vm._v(" rum ∙ "),_c('span',{staticClass:"extra-bold"},[_vm._v("0")]),_vm._v(" kvm ∙ Våning "),_c('span',{staticClass:"extra-bold"},[_vm._v("{0")]),_c('br'),_c('span',{staticClass:"extra-bold"},[_vm._v("1000")]),_vm._v(" kr/månad ")])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"interest-bar"},[_c('div',{staticClass:"interest-area"},[_c('div',{staticClass:"interest-progress block"},[_vm._v("0%")])])])
}]

export { render, staticRenderFns }