<template>
  <div :key="filterUpdateKey" class="filters">
    <div v-if="isMobile" class="button-wrapper">
      <BaseButton
        type="button"
        class="full-width grey larger rounded"
        @click="$emit('apply-filters')"
      >
        {{ $t('swaplist_button_show_result') }}
      </BaseButton>
    </div>
    <FilterSection
      v-if="
        (swapFilterType !== SWAP_FILTER_TYPES.ANSWERED &&
          swapFilterType !== SWAP_FILTER_TYPES.REMOVED &&
          swapFilterType !== SWAP_FILTER_TYPES.LAST_PART_NEW &&
          !isMobile) ||
        swapListType === SWAP_LIST_TYPES.MAP
      "
      :collapsable="false"
      :title="$t('swaplist_filter_filters_show')"
    >
      <div slot="section-content">
        <FilterRadio
          :title="$t('swaplist_filter_filters_all')"
          :badge-value="preFilterCountUnmarked"
          :selected="swapFilterType === SWAP_FILTER_TYPES.UNANSWERED"
          :select="
            () =>
              handleSetSwapType({
                swapFilterType: SWAP_FILTER_TYPES.UNANSWERED
              })
          "
        />

        <FilterRadio
          :title="$t('swaplist_filter_filters_new')"
          :badge-value="newSwapsCount"
          badge-type="highlighted"
          :selected="swapFilterType === SWAP_FILTER_TYPES.NEW"
          :select="
            () => handleSetSwapType({ swapFilterType: SWAP_FILTER_TYPES.NEW })
          "
        />
      </div>
    </FilterSection>

    <FilterSection
      :collapsable="false"
      :title="$t('swaplist_filter_filters_swaptype')"
    >
      <div slot="section-content">
        <FilterCheckbox
          :toggle="() => handleToggleFilter({ key: 'isDirectSwap' })"
          :checked="isDirectSwap"
          :title="$t('swaplist_filter_filters_direct_swap')"
        />
        <FilterCheckbox
          :toggle="() => handleToggleFilter({ key: 'isTriangleSwap' })"
          :checked="isTriangleSwap"
          :title="$t('swaplist_filter_filters_triangle_swap')"
        />
      </div>
    </FilterSection>

    <FilterSection
      :title="$t('swaplist_filter_filters_other')"
      :collapsable="false"
    >
      <div slot="section-content">
        <template v-if="swapFilterType === SWAP_FILTER_TYPES.UNANSWERED">
          <FilterCheckbox
            :toggle="() => handleToggleFilter({ key: 'isFavourite' })"
            :checked="isFavourite"
            :title="$t('swaplist_filter_filters_favorited')"
          />
          <FilterCheckbox
            :toggle="() => handleToggleFilter({ key: 'showDenied' })"
            :checked="showDenied"
            :title="$t('swaplist_filter_filters_show_only_denied')"
          />
          <FilterCheckbox
            :toggle="() => handleToggleFilter({ key: 'isNotExtendedSwap' })"
            :checked="isNotExtendedSwap"
            :title="$t('swaplist_filter_filters_extended_swap')"
          />
        </template>
        <FilterCheckbox
          :toggle="() => handleToggleFilter({ key: 'hasImage' })"
          :checked="hasImage"
          :title="$t('swaplist_filter_filters_image')"
        />
      </div>
    </FilterSection>

    <FilterSection :title="$t('swaplist_filter_filters_amenities')">
      <div slot="section-content">
        <FilterCheckbox
          :toggle="() => handleToggleFilter({ key: 'elevator' })"
          :checked="elevator"
          :title="$t('swaplist_filter_filters_elevator')"
        />
        <FilterCheckbox
          :toggle="() => handleToggleFilter({ key: 'balcony' })"
          :checked="balcony"
          :title="$t('swaplist_filter_filters_balcony')"
        />
        <FilterCheckbox
          :toggle="() => handleToggleFilter({ key: 'bathtub' })"
          :checked="bathtub"
          :title="$t('swaplist_filter_filters_bathtub')"
        />
        <FilterCheckbox
          v-if="$country.isFeatureEnabled('FILTERS_COOP')"
          :toggle="() => handleToggleFilter({ key: 'isCoop' })"
          :checked="isCoop"
          :title="$t('swaplist_filter_filters_is_coop')"
        />
        <FilterCheckbox
          v-if="$country.isFeatureEnabled('FILTERS_COOP')"
          :toggle="() => handleToggleFilter({ key: 'isBecomingCoop' })"
          :checked="isBecomingCoop"
          :title="$t('swaplist_filter_filters_is_becoming_coop')"
        />
      </div>
    </FilterSection>

    <FilterSection :title="$t('swaplist_filter_filters_rent_size_etc')">
      <div slot="section-content">
        <div class="section">
          <div class="section-subtitle">
            {{ $t('swaplist_filter_filters_rent') }}
          </div>
          <VueSlider
            v-bind="sliderProps"
            v-model="rent"
            tooltip="off"
            :min="rentLimits.min"
            :max="rentLimits.max"
            :formatter="formatFloor"
          />

          <MaskedInput
            v-model="rent"
            :suffix="$t('swaplist_filter_filters_currency_short')"
            :min="rentLimits.min"
            :max="rentLimits.max"
            :special-case="rentFormatter"
          />
        </div>

        <div class="section">
          <div class="section-subtitle">
            {{ $t('swaplist_filter_filters_size') }}
          </div>
          <VueSlider
            v-bind="sliderProps"
            v-model="sqm"
            tooltip="off"
            :min="sqmLimits.min"
            :max="sqmLimits.max"
            :formatter="formatFloor"
          />

          <MaskedInput
            v-model="sqm"
            :suffix="$t('swaplist_filter_filters_sqm')"
            :min="sqmLimits.min"
            :max="sqmLimits.max"
            :special-case="sqmFormatter"
          />
        </div>

        <div class="section extra-margin-bottom">
          <div class="section-subtitle">
            {{ $t('swaplist_filter_filters_floor') }}
          </div>
          <VueSlider
            v-bind="sliderProps"
            v-model="floor"
            tooltip="off"
            :min="floorLimits.min"
            :max="floorLimits.max"
            :piecewise="true"
            :piecewise-label="true"
            :formatter="formatFloor"
          />
        </div>

        <div class="section extra-margin-bottom">
          <div class="section-subtitle">
            {{ $t('swaplist_filter_filters_room_amout') }}
          </div>
          <VueSlider
            v-bind="sliderProps"
            v-model="rooms"
            tooltip="off"
            :min="roomsLimits.min"
            :max="roomsLimits.max"
            :piecewise="true"
            :piecewise-label="true"
            :formatter="formatRooms"
          />
        </div>
      </div>
    </FilterSection>

    <FilterSection :title="$t('swaplist_filter_filters_areas')">
      <div slot="section-content">
        <FilterCheckbox
          v-for="area in sortedAreaGroups"
          :key="area.name"
          :toggle="() => handleToggleAreaFilter(area.name)"
          :checked="
            selectedFilterAreas && selectedFilterAreas.includes(area.name)
          "
          :title="`${area.name} (${area.count})`"
        />
      </div>
    </FilterSection>
  </div>
</template>

<script>
import { mapGetters, mapActions, mapMutations } from 'vuex';
import {
  SWAP_LIST_TYPES,
  SWAP_ITEM_TYPES,
  SWAP_FILTER_TYPES
} from '@/store/modules/swapList';
import VueSlider from 'vue-slider-component';
import throttle from 'lodash/throttle';
import MaskedInput from '../../MaskedInput/MaskedInput';
import FilterCheckbox from './FilterCheckbox';
import FilterRadio from './FilterRadio';
import FilterSection from './FilterSection';
import BaseButton from '../../BaseButton';

function rangeModel(filterKey) {
  return {
    get() {
      setTimeout(() => {
        this.hasDoneInitialSetup = true;
      }, 500);
      const filter = this.searchFilters[filterKey];
      const filterSetup = this.filterSetup[filterKey];

      if (filter) {
        return [filter.min, filter.max];
      } else {
        return [filterSetup.min, filterSetup.max];
      }
    },
    set: throttle(function (value) {
      // prevent from getting initial data
      if (!this.isInitialized.includes(filterKey)) {
        this.isInitialized.push(filterKey);
        window.Logger.log('INITIALIZED ', filterKey);
        return;
      }
      this.updateRangeFilter({ key: filterKey, min: value[0], max: value[1] });

      // prevent call moving between map and normal view
      if (!this.isMobile && this.hasDoneInitialSetup) {
        const filter = this.searchFilters[filterKey];
        const filterSetup = this.filterSetup[filterKey];

        // Prevent unesscessary searches
        if (filter.min === filterSetup.min && filter.max === filterSetup.max)
          return;

        this.desktopGetSwapsDebounced();
        this.sendMapEventDebounced();
      }
    }, 100)
  };
}

export default {
  name: 'Filters',

  components: {
    FilterSection,
    MaskedInput,
    VueSlider,
    FilterCheckbox,
    FilterRadio,
    BaseButton
  },

  props: {
    closeFilterModal: {
      type: Function,
      default: () => {}
    },
    readableSortType: {
      type: String,
      default: ''
    }
  },

  data() {
    return {
      swapTimeoutId: null,
      mapTimeoutId: null,
      SWAP_LIST_TYPES,
      SWAP_FILTER_TYPES,
      SWAP_ITEM_TYPES,
      sliderProps: {
        sliderStyle: {
          backgroundColor: '#409fff',
          border: '3px solid #fff',
          boxSizing: 'border-box',
          boxShadow: 'none'
        },
        processStyle: {
          backgroundColor: '#409fff'
        },
        bgStyle: {
          backgroundColor: '#e2eaef'
        },
        width: '100%',
        class: 'slider',
        dotSize: 20
      },
      hasDoneInitialSetup: false,
      isInitialized: []
    };
  },

  computed: {
    ...mapGetters({
      isMobile: 'screenSize/isMobile',
      searchFilters: 'swapList/searchFilters',
      filterSetup: 'swapList/filterSetup',
      swapListType: 'swapList/swapListType',
      swapFilterType: 'swapList/swapFilterType',
      primaryPropositionId: 'myPropositions/primaryPropositionId',
      swapItemMode: 'swapList/swapItemMode',
      preFilterCountTotal: 'swapList/preFilterCountTotal',
      filterCount: 'swapList/filterCount',
      fullInterestSwapsCount: 'swapList/fullInterestSwapsCount',
      preFilterCountUnmarked: 'swapList/preFilterCountUnmarked',
      newSwapsCount: 'swapList/newSwapsCount',
      areaGroups: 'swapList/areaGroups',
      selectedFilterAreas: 'swapList/selectedFilterAreas',
      selectedFilters: 'swapList/selectedFilters'
    }),

    sortedAreaGroups() {
      if (!this.areaGroups) return [];

      const sorted = [...this.areaGroups].sort((a, b) => {
        return a.name > b.name ? 1 : -1;
      });

      return sorted;
    },

    rent: rangeModel('rent'),
    rentLimits() {
      return this.filterSetup.rent;
    },
    rooms: rangeModel('rooms'),
    roomsLimits() {
      return this.filterSetup.rooms;
    },
    sqm: rangeModel('sqm'),
    sqmLimits() {
      return this.filterSetup.sqm;
    },
    floor: rangeModel('floor'),
    floorLimits() {
      return this.filterSetup.floor;
    },

    // amenitiees
    elevator() {
      return this.searchFilters.elevator;
    },
    balcony() {
      return this.searchFilters.balcony;
    },
    bathtub() {
      return this.searchFilters.bathtub;
    },
    isCoop() {
      return this.searchFilters.isCoop;
    },
    isBecomingCoop() {
      return this.searchFilters.isBecomingCoop;
    },

    //Swaptypes
    isDirectSwap() {
      return this.searchFilters.isDirectSwap;
    },
    isTriangleSwap() {
      return this.searchFilters.isTriangleSwap;
    },
    isNotExtendedSwap() {
      return this.searchFilters.isNotExtendedSwap;
    },

    //other
    isFavourite() {
      return this.searchFilters.isFavourite;
    },
    showDenied() {
      return this.searchFilters.showDenied;
    },
    hasImage() {
      return this.searchFilters.hasImage;
    },

    filterUpdateKey() {
      if (this.swapListType !== this.SWAP_LIST_TYPES.MAP) {
        return 'normal-filter';
      } else {
        return 'map-filter';
      }
    },

    currentPropositionId() {
      return (
        this.$route.params.otherUserPropositionId || this.primaryPropositionId
      );
    }
  },

  watch: {
    rent: {
      immediate: false,
      handler: function () {
        if (this.rent[0] > 1 || this.rent[1] < 30000) {
          this.setSelectedFilters('rent');
        } else if (this.rent[1] === 30000) {
          this.removeSelectedFilter('rent');
        }
        if (this.sqm[0] > 1 || this.sqm[1] < 300) {
          this.setSelectedFilters('sqm');
        } else if (this.sqm[1] === 300) {
          this.removeSelectedFilter('sqm');
        }
        if (this.floor[0] > 0 || this.floor[1] < 10) {
          this.setSelectedFilters('floor');
        } else if (this.floor[1] === 10) {
          this.removeSelectedFilter('floor');
        }
        if (this.rooms[0] > 1 || this.rooms[1] < 6) {
          this.setSelectedFilters('rooms');
        } else if (this.rooms[1] === 6) {
          this.removeSelectedFilter('rooms');
        }
      }
    }
  },
  methods: {
    ...mapMutations({
      setLoading: 'swapList/setLoading'
    }),

    ...mapActions({
      toggleCheckboxFilter: 'swapList/toggleCheckboxFilter',
      updateRangeFilter: 'swapList/updateRangeFilter',
      getSwaps: 'swapList/getSwaps',
      setSwapType: 'swapList/setSwapType',
      toggleSwapItemMode: 'swapList/toggleSwapItemMode',
      handleUpdateFilterAreas: 'swapList/handleUpdateFilterAreas',
      setSelectedFilters: 'swapList/setSelectedFilters',
      removeSelectedFilter: 'swapList/removeSelectedFilter'
    }),

    formatFloor(input) {
      if (input === 0) {
        return this.$t('general_ground_floor_short');
      }
      if (input === 10) {
        return '10+';
      }
      return input;
    },
    formatRooms(input) {
      if (input === 6) {
        return '6+';
      }
      return input;
    },

    rentFormatter(input) {
      if (input === 30000) {
        return '+';
      }
      return '';
    },

    sqmFormatter(input) {
      if (input === 300) {
        return '+';
      }
      return '';
    },

    handleToggleFilter(toggleObj) {
      const key = toggleObj.key;
      this.toggleCheckboxFilter(toggleObj);
      this.setSelectedFilters(toggleObj.key);

      const isChecked = this.searchFilters[toggleObj.key] ?? false;

      if (!isChecked) {
        this.removeSelectedFilter(key);
      }

      if (!this.isMobile) {
        this.desktopGetSwapsDebounced();
        this.sendMapEventDebounced();
      }
    },

    handleToggleAreaFilter(areaName) {
      this.handleUpdateFilterAreas({ areaName });
      this.setSelectedFilters(areaName);

      if (!this.isMobile) {
        this.desktopGetSwapsDebounced();
        this.sendMapEventDebounced();
      }
    },

    desktopGetSwapsDebounced(ms = 1000) {
      if (this.swapTimeoutId) {
        clearTimeout(this.swapTimeoutId);
      }

      this.swapTimeoutId = setTimeout(async () => {
        this.setLoading({ loading: true });
        await this.getSwaps({ propositionId: this.currentPropositionId });
      }, ms);
    },

    sendMapEventDebounced() {
      if (this.mapTimeoutId) {
        clearTimeout(this.mapTimeoutId);
      }

      this.mapTimeoutId = setTimeout(() => {
        this.$root.$emit('apply-filters-map');
      }, 1000);
    },

    handleApplyFilter() {
      this.closeFilterModal();
      this.getSwaps({ propositionId: this.currentPropositionId });

      this.$root.$emit('apply-filters-map');
    },

    handleSetSwapType({ swapFilterType }) {
      window.scrollTo({ top: 0, behavior: 'smooth' });
      this.setSwapType({ swapFilterType });
      this.sendMapEventDebounced();
      this.desktopGetSwapsDebounced(0);
    }
  }
};
</script>

<style lang="scss" scoped>
.filters {
  padding: 0 12px;
  padding-bottom: calc(60px + env(safe-area-inset-bottom));

  @media ($mobile) {
    margin-bottom: 10rem;
  }
}

.range-separator {
  font-size: 20px;
  margin-left: 10px;
  margin-right: 10px;
}

.input-wrapper {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.slider {
  margin-bottom: 10px;
}

.button-wrapper {
  padding: 1rem;
  border-top: 1px solid #ddd;
  position: fixed;
  display: flex;
  left: 0;
  bottom: 0;
  z-index: 1000;
  background-color: #fff;
  width: 100%;
  & button {
    font-size: 16px;
  }
}

.sumbit-btn {
  background-color: $dark-blue;
  -webkit-appearance: none;
  appearance: none;
  border-radius: 3px;
  border: none;
  box-sizing: border-box;
  color: #fff;
  cursor: pointer;
  display: inline-block;
  font-size: 14px;
  font-weight: 700;
  height: 50px;
  line-height: 50px;
  outline: none;
  padding: 0 30px;
  text-align: center;
  transition: all 0.2s ease-in-out;
  width: 100%;
}

.section {
  margin-bottom: 30px;

  &-subtitle {
    font-size: 0.7rem;
    font-weight: 600;
    text-transform: uppercase;
    margin-bottom: 10px;
    color: $text-secondary;
  }
  &.extra-margin-bottom {
    margin-bottom: 50px;
  }
}

// .toggle-mobile-wrapper {
//   width: 100%;
//   display: flex;
//   justify-content: center;
//   align-items: center;
//   padding: 12px 0 0 0;
// }

.sort-action {
  margin-top: 20px;
  margin-bottom: 4px;
  font-weight: 600;
  font-size: 0.8rem;
}

.sort-label {
  cursor: pointer;
  color: $main-blue;
  text-decoration: underline;
  position: relative;

  &::after {
    position: absolute;
    right: -14px;
    top: 4px;
    content: '';
    background-image: url('../../../assets/svg/arrow-blue.svg');
    background-position: center;
    background-size: contain;
    transform: rotate(90deg);
    height: 10px;
    width: 10px;
  }
}
</style>
