<template>
  <div
    class="menu-wrapper"
    :style="{
      'background-color': bgColor
    }"
  >
    <slot />
  </div>
</template>

<script>
export default {
  name: 'TabNavigation',

  props: {
    bgColor: {
      type: String,
      default: '#fff',
      required: false
    }
  }
};
</script>

<style lang="scss" scoped>
.menu-wrapper {
  width: 100%;
  padding: 0 10px;
  display: flex;
  overflow-x: scroll;
  margin-bottom: 6px;
  background-color: #fff;
  border-bottom: 1px solid #ebebeb;
  -ms-overflow-style: none;
  scrollbar-width: none;
  user-select: none;

  &::-webkit-scrollbar {
    display: none;
  }

  @media ($desktop) {
    background-color: #f7f7f9;
    padding: 0;
    margin: 24px 0;
  }
}
</style>
