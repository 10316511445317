import mixpanel from 'mixpanel-browser';
import { getValue } from '../plugins/country/module';

export function trackPropositionCreated({ type, userId, propositionId }) {
  try {
    const mixpanelToken = getValue('MIXPANEL');

    if (mixpanelToken) {
      mixpanel.init(mixpanelToken, {
        api_transport: 'sendBeacon',
        batch_requests: false
      });
      mixpanel.identify(userId);

      mixpanel.track('Proposition Created', {
        Type: type,
        'Proposition Id': propositionId,
        'User Id': userId
      });
    }
  } catch (error) {
    console.error('Error in mixpanel proposition created:', error);
  }
}
