<template>
  <div
    class="base-picker"
    :class="{
      invalid: !valid
    }"
  >
    <div :class="['title', { 'is-small': isSmallTitle }]">{{ title }}:</div>
    <div class="picker-wrapper">
      <div
        v-for="option in primaryOptions"
        :key="option"
        :class="['picker-option', { selected: value === option }]"
        @click="handlePrimaryOptionClick(option)"
      >
        <template v-if="customFirstOption && option === 0">
          {{ customFirstOption }}
        </template>
        <template v-else>
          {{ option.toLocaleString() }}
        </template>
      </div>

      <div
        v-if="secondaryOptions.length > 0"
        :class="['picker-option', { selected: value > primaryOptionsMaxValue }]"
      >
        <span
          v-if="value <= primaryOptionsMaxValue || value == null"
          class="custom-input-plus-sign"
          >+</span
        >
        <span v-else class="picker-option selected-custom-value">{{
          value.toLocaleString()
        }}</span>
        <select
          name="custom-input"
          :value="value"
          class="custom-input"
          @change="handleSecondaryOptionChange"
        >
          <option
            v-for="option in secondaryOptions"
            :key="option"
            :value="option"
          >
            {{ option.toLocaleString() }}
          </option>
        </select>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'BasePicker',

  props: {
    title: {
      type: String,
      default: ''
    },
    options: {
      type: Array,
      default: () => []
    },
    value: {
      type: [Number, null],
      default: null
    },
    primaryOptionsThreshold: {
      type: Number,
      default: 10
    },
    customFirstOption: {
      type: [String, Boolean],
      default: false
    },
    valid: {
      type: Boolean,
      default: false
    },
    isSmallTitle: {
      type: Boolean,
      default: false
    }
  },

  computed: {
    primaryOptions() {
      return this.options.slice(0, this.primaryOptionsThreshold);
    },

    // Values hidden under the '+' label
    secondaryOptions() {
      return this.options.slice(
        this.primaryOptionsThreshold,
        this.options.length
      );
    },

    primaryOptionsMaxValue() {
      return this.options[this.primaryOptionsThreshold - 1];
    }
  },

  methods: {
    handlePrimaryOptionClick(option) {
      this.$emit('selected', option);
    },

    handleSecondaryOptionChange({ target: { value: val } }) {
      this.$emit('selected', parseInt(val, 10));
    }
  }
};
</script>

<style lang="scss" scoped>
.base-picker {
  width: 100%;
  margin-bottom: 32px;
}

.title {
  margin-bottom: 10px;
  font-size: 1rem;
  line-height: 150%;
  letter-spacing: -0.176px;
  color: #0b182c;

  &.is-small {
    font-size: 14px;
    font-weight: 600;
    color: #0b182c;
  }

  .invalid & {
    color: #ff3843;
  }
}

.picker-wrapper {
  display: flex;
  overflow: hidden;
  width: 100%;
  gap: 8px;
}

.picker-option {
  position: relative;
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 8px;
  padding: 10px 8px;
  border-radius: 4px;
  border: 1px solid #dddcdb;
  background-color: #fff;
  font-size: 16px;
  font-weight: 600;
  line-height: 20px;
  cursor: pointer;
  user-select: none;
  white-space: nowrap;
  transition: all 0.2s ease-in-out;

  .invalid & {
    border-color: #ff3843;
  }

  &.selected {
    background-color: #0b182c;
    border-color: #0b182c;
    color: white;
  }
}

.custom-input-plus-sign {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  pointer-events: none;
  font-weight: 600;
}

.selected-custom-value {
  position: absolute;
  user-select: none;
  pointer-events: none;
  width: 100%;
  color: #fff;
  border-color: transparent !important;
  background-color: transparent;
  text-align: center;
  transition: none;
}

.custom-input {
  border: 0;
  background-color: transparent;
  width: 100%;
  height: 100%;
  cursor: pointer;
  text-align: center;
  text-align-last: center;
  appearance: none;
  color: transparent;
  outline: 0;
  padding: 0;

  option {
    background-color: #fff;
    color: #000;
  }
}
</style>
