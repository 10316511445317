import { render, staticRenderFns } from "./MyPropositions.vue?vue&type=template&id=a2c4fc94&scoped=true"
import script from "./MyPropositions.vue?vue&type=script&lang=js"
export * from "./MyPropositions.vue?vue&type=script&lang=js"
import style0 from "./MyPropositions.vue?vue&type=style&index=0&id=a2c4fc94&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "a2c4fc94",
  null
  
)

export default component.exports