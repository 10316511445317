var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{class:['indicator-wrapper']},[_c('div',{staticClass:"indicator-icons"},_vm._l((_vm.interests),function(interest,i){return _c('div',{key:i,class:[
        'indicator-icon',
        {
          interested: interest === true,
          'not-interested': interest === false
        }
      ]})}),0),_c('div',{staticClass:"interest-status"},[_vm._v(_vm._s(_vm.swapStatusText))])])
}
var staticRenderFns = []

export { render, staticRenderFns }