<template>
  <div class="main-content">
    <section class="full-width">
      <SubscriptionPickerNew
        v-if="!changePaymentMethod"
        :is-loading="loading"
      />
      <section
        v-if="
          (product || changePaymentMethod) && existingPaymentToken !== undefined
        "
      >
        <p v-if="!changePaymentMethod" class="total-sum-wrapper">
          {{ $t('klarna_button_discount_total', { totalSum: totalSum }) }}
        </p>

        <slot />

        <AToggleChoice
          v-if="isDev"
          class="mg"
          :active="modes.active"
          :inactive="modes.inactive"
          :is-active="modes.active.key === paymentEndpointType"
          :button="true"
          @change="setPaymentEndpointType($event)"
        />

        <template v-if="showKlarna">
          <KlarnaNew
            :proposition-id="propositionId"
            :product="product"
            :pay-text="payText"
            :meta="meta"
            :change-payment-method="changePaymentMethod"
            :additional-products="additionalProducts"
            @success="handleSuccess()"
            @error="handleError($event)"
            @loading="$emit('loading', $event)"
            @pending="handlePending($event)"
            @reset-payment-method="existingPaymentToken = null"
          />
        </template>
        <template v-else-if="!showKlarna">
          <Adyen
            :proposition-id="propositionId"
            :product="product"
            :pay-text="payText"
            :meta="meta"
            :change-payment-method="changePaymentMethod"
            :additional-products="additionalProducts"
            @success="handleSuccess()"
            @error="handleError($event)"
            @loading="$emit('loading', $event)"
            @pending="handlePending($event)"
          />
        </template>
      </section>
    </section>
  </div>
</template>

<script>
import SubscriptionPickerNew from '@/components/Payments/SubscriptionPickerNew';
import Adyen from '@/components/Payments/Adyen';
import KlarnaNew from '@/components/Payments/KlarnaNew';
import { mapActions, mapGetters, mapState } from 'vuex';
import { log } from '../../utils/logger';
import { isDebug } from '../../utils/debug';
import AToggleChoice from '@/components/atomic/atoms/a-toggle-choice';
import { getCookies } from '../../utils/helpers/cookie';

export default {
  name: 'BasePaymentNew',

  components: {
    SubscriptionPickerNew,
    Adyen,
    AToggleChoice,
    KlarnaNew
  },

  props: {
    changePaymentMethod: {
      type: Boolean,
      default: false
    },

    payText: {
      type: String,
      default: null
    },

    additionalProducts: {
      type: Array,
      default: () => []
    },

    product: {
      type: Object,
      default: null
    },

    propositionId: {
      type: [String, Number, null],
      default: null
    },

    meta: {
      type: Object,
      default: null
    },

    loading: {
      type: Boolean,
      default: false
    }
  },

  data() {
    return {
      isDev: isDebug(),
      paymentEndpointType: null,
      existingPaymentToken: undefined
    };
  },

  computed: {
    ...mapState('payment', ['activeOrders']),

    ...mapGetters({
      isMobile: 'screenSize/isMobile'
    }),

    totalSum() {
      let price = 0;

      if (!this.product) {
        return price;
      }

      price = this.product.priceInclVat;

      if (this.additionalProducts) {
        for (const p of this.additionalProducts) {
          price += p.priceInclVat;
        }
      }
      if (!this.$country.isCountry('SE')) {
        price = price.toFixed(2);
      }

      return price;
    },

    modes() {
      return {
        inactive: {
          key: 'dev',
          text: 'Test'
        },
        active: {
          key: 'prod',
          text: 'Prod'
        }
      };
    },

    showKlarna() {
      return (
        this.$country.isCountry('SE') &&
        this.existingPaymentToken &&
        this.existingPaymentToken.provider === 'klarna' &&
        !this.$route.query.q
      );
    }
  },

  async created() {
    if (this.isDev) {
      const cookies = getCookies();
      this.paymentEndpointType = cookies.paymentEndpointType;
    }

    this.existingPaymentToken = await this.getPaymentTokenProvider();

    document.addEventListener('success', this.handleSuccess);
    document.addEventListener('error', this.handleError);

    // TODO Remove when Adyen has fixed their bug
    var ival = setInterval(() => {
      document.querySelectorAll('#dropin-container ul li').forEach(el => {
        clearInterval(ival);
        el.addEventListener('click', () => {
          document
            .querySelectorAll('#dropin-container ul.test li')
            .forEach(el => {
              el.removeAttribute('data-disabled');
              console.log('Removed disabled attr');
            });
        });
      });
    }, 500);
  },

  async beforeDestroy() {
    document.removeEventListener('success', this.handleSuccess);
    document.removeEventListener('error', this.handleError);
  },

  methods: {
    ...mapActions({
      setToast: 'toast/setToast',
      getPaymentTokenProvider: 'payment/getPaymentTokenProvider'
    }),

    setPaymentEndpointType(e) {
      const expire = new Date(Math.floor(Date.now() + 26280000000));
      document.cookie = `paymentEndpointType=${e.key};expires=${expire};path=/;`;

      setTimeout(() => {
        window.location.reload();
      }, 200);
    },

    handleSuccess() {
      const payload = {
        product: this.product,
        propositionId: this.propositionId,
        meta: this.meta
      };

      this.$emit('success', payload);
    },

    handlePending() {
      this.$emit('pending');
      this.$router.replace(this.$routes.PAYMENT_PENDING);
    },

    handleError(error) {
      this.$emit('loading', false);

      if (error.response && error.response.status === 403 && this.isDev) {
        this.setToast({
          timer: 30,
          title:
            'Du är inte whitelistad. Fråga om cookie/header för att få tillgång till dev-betalningar.'
        });

        return;
      }

      this.$emit('error', error);
      this.setToast({
        timer: 30,
        title: this.$t('payment_error')
      });

      log(error.toString(), true, true);
    }
  }
};
</script>

<style lang="scss" scoped>
.total-sum-wrapper {
  text-align: center;
  font-weight: 600;
  margin-top: 0;
  margin-bottom: 40px;
}

.main-content {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
}

.full-width {
  width: 100%;
}

.debug-text {
  font-weight: bold;
  color: red;
}

.mg {
  margin-top: 30px;
  margin-bottom: 20px;
}
</style>
