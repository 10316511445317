<template>
  <div class="edit-proposition-container">
    <div v-if="proposition" class="edit-proposition">
      <BaseBackButton @click="goBack" class="back-button" />
      <div class="edit-prop-info-conatiner">
        <h1 class="page-heading">
          {{ $t('edit_prop_page_title') }}
        </h1>
        <span>{{ $t('edit_prop_page_subtitle') }}</span>
      </div>
      <div v-if="propositionSettings && propositionSettings.swapType !== 21">
        <div
          v-for="(residence, index) in proposition.residences"
          :key="index"
          class="sub-page-link-container"
        >
          <LinkSubListItem
            :path="
              $routes.EDIT_PROPOSITION_DETAILS_AND_DESCRIPTION +
              '/' +
              propositionId +
              '/' +
              residence.residenceIndex
            "
            class="link"
          >
            <p class="title">{{ $t('edit_prop_residence') }}</p>
            <p class="info">{{ $t('edit_prop_residence_desc') }}</p>
          </LinkSubListItem>
          <LinkSubListItem
            :path="
              $routes.EDIT_PROPOSITION_PHOTOS +
              '/' +
              propositionId +
              '/' +
              residence.residenceIndex
            "
            class="link"
          >
            <p class="title">{{ $t('edit_prop_edit_imgs') }}</p>
            <p class="info">{{ $t('edit_prop_edit_imgs_desc') }}</p>
          </LinkSubListItem>
          <LinkSubListItem
            :path="$routes.EDIT_PROPOSITION_WISHES + '/' + propositionId"
            class="link"
          >
            <p class="title">{{ $t('edit_prop_your_wishes') }}</p>
            <p class="info">{{ $t('edit_prop_your_wishes_desc') }}</p>
          </LinkSubListItem>
          <LinkSubListItem
            v-if="hasSecondWish"
            :path="$routes.EDIT_PROPOSITION_SECOND_WISHES + '/' + propositionId"
            class="link"
          >
            <p class="title">{{ $t('edit_prop_your_second_wishes') }}</p>
            <p class="info">{{ $t('edit_prop_your_wishes_desc') }}</p>
          </LinkSubListItem>
          <LinkSubListItem
            :path="
              $t('path_handle_subscription', $routeLocale) + '/' + propositionId
            "
            class="link"
          >
            <p class="title">{{ $t('edit_prop_subscription_type') }}</p>
            <p class="info">{{ $t('edit_prop_subscription_type_desc') }}</p>
          </LinkSubListItem>
          <LinkSubListItem
            :path="
              $t('path_edit_prop_settings', $routeLocale) + '/' + propositionId
            "
            class="link"
          >
            <p class="title">{{ $t('edit_prop_advanced') }}</p>
            <p class="info">{{ $t('edit_prop_advanced_desc') }}</p>
          </LinkSubListItem>
        </div>
      </div>
      <div v-else class="sub-page-link-container-two-one">
        <div class="links-container">
          <LinkSubListItem
            :path="
              $t('path_handle_subscription', $routeLocale) + '/' + propositionId
            "
            class="link"
          >
            <p class="title">{{ $t('edit_prop_subscription_type') }}</p>
            <p class="info">{{ $t('edit_prop_subscription_type_desc') }}</p>
          </LinkSubListItem>
          <LinkSubListItem
            :path="
              $t('path_edit_prop_settings', $routeLocale) + '/' + propositionId
            "
            class="link"
          >
            <p class="title">{{ $t('edit_prop_advanced') }}</p>
            <p class="info">{{ $t('edit_prop_advanced_desc') }}</p>
          </LinkSubListItem>
          <LinkSubListItem
            :path="$routes.EDIT_PROPOSITION_WISHES + '/' + propositionId"
            class="link"
          >
            <p class="title">{{ $t('edit_prop_your_wishes') }}</p>
            <p class="info">{{ $t('edit_prop_your_wishes_desc') }}</p>
          </LinkSubListItem>
          <LinkSubListItem
            v-if="hasSecondWish"
            :path="$routes.EDIT_PROPOSITION_SECOND_WISHES + '/' + propositionId"
            class="link"
          >
            <p class="title">{{ $t('edit_prop_your_second_wishes') }}</p>
            <p class="info">{{ $t('edit_prop_your_wishes_desc') }}</p>
          </LinkSubListItem>
        </div>

        <div
          v-for="(residence, index) in proposition.residences"
          :key="index"
          class="links-container proposition"
        >
          <h3 class="address">{{ residence.streetAddress }}</h3>
          <LinkSubListItem
            :path="
              $routes.EDIT_PROPOSITION_DETAILS_AND_DESCRIPTION +
              '/' +
              propositionId +
              '/' +
              residence.residenceIndex
            "
            class="link"
          >
            <p class="title">{{ $t('edit_prop_residence') }}</p>
            <p class="info">{{ $t('edit_prop_residence_desc') }}</p>
          </LinkSubListItem>
          <LinkSubListItem
            :path="
              $routes.EDIT_PROPOSITION_PHOTOS +
              '/' +
              propositionId +
              '/' +
              residence.residenceIndex
            "
            class="link"
          >
            <p class="title">{{ $t('edit_prop_edit_imgs') }}</p>
            <p class="info">{{ $t('edit_prop_edit_imgs_desc') }}</p>
          </LinkSubListItem>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import { lbApiBeta } from '../../utils/axiosConfig';
import BaseBackButton from '../../components/BaseBackButton.vue';
import LinkSubListItem from '../../components/Navigation/LinkSubList/LinkSubListItem.vue';

export default {
  name: 'EditProposition',

  components: {
    BaseBackButton,
    LinkSubListItem
  },

  data() {
    return {
      propositionId: null,
      proposition: null,
      propositionSettings: null
    };
  },

  head: {
    title() {
      return {
        inner:
          this.$t('edit_prop_meta') +
            this.proposition?.residences[0].streetAddress || ''
      };
    }
  },

  computed: {
    ...mapGetters({
      userPropositions: 'myPropositions/propositions'
    }),

    myPropositionsPath() {
      return this.$routes.MY_PROPOSITIONS;
    },

    hasSecondWish() {
      return this.proposition?.wishes.length > 1;
    }
  },

  async created() {
    this.propositionId = this.$route.params.propositionId;

    window.scrollTo(0, 0);

    this.getProposition();

    await this.getSettings();
  },

  methods: {
    ...mapActions({
      fetchPropositions: 'myPropositions/fetchPropositions'
    }),

    goBack() {
      this.$router.push(this.myPropositionsPath);
    },

    async getProposition() {
      await this.fetchPropositions();

      this.proposition = this.userPropositions.find(
        id => id.propositionId == this.propositionId
      );

      this.$emit('updateHead');
    },

    async getSettings() {
      try {
        const { data } = await lbApiBeta.get(
          `/api/v2/proposition/${this.propositionId}/swap-settings`
        );

        this.propositionSettings = data;
      } catch (e) {
        console.log(e);
      }
    }
  }
};
</script>

<style lang="scss" scoped>
.edit-proposition-container {
  max-width: none;
  width: 100%;
  background-color: #f8f8fa;
  min-height: 100vh;
}

.edit-proposition {
  padding: 0 16px;
  margin-bottom: 32px;

  @media ($small-desktop) {
    width: 96%;
  }

  @media ($desktop) {
    max-width: 1000px;
    width: 90%;
    margin: 0 auto;
  }
}

.edit-prop-info-conatiner {
  margin-bottom: 24px;
  line-height: 22px;

  .page-heading {
    margin-top: 20px;
    margin-bottom: 16px;
    font-size: 22px;

    @media ($desktop) {
      margin-top: 0;
      font-size: 28px;
    }
  }
}

.sub-page-link-container,
.sub-page-link-container-two-one {
  padding: 16px 0px;
  font-size: 15px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;

  .link {
    display: flex;
    justify-content: space-between;

    p {
      margin: 0;
    }

    .title {
      color: #0b182c;
    }

    .info {
      color: #999999;
    }
  }
}

.sub-page-link-container-two-one {
  .links-container {
    width: 100%;

    &.proposition {
      margin-top: 16px;

      .address {
        margin-bottom: 0;
      }
    }
  }
}

.page-sub-heading {
  margin-top: 0;
  font-size: 22px;
}

.proposition-card-container {
  margin-bottom: 30px;

  @media ($mobile) {
    margin-right: -5px;
    margin-left: -5px;
    padding: 1px 6px;
    background-color: #fff;
    border-radius: 4px;
  }
}

.link-groups {
  display: grid;
  gap: 16px;
  margin-bottom: 50px;
}

.link-group {
  padding: 16px;
  padding-bottom: 6px;
  border-radius: 4px;
  font-weight: 600;
  background-color: #fff;
  border: 1px solid rgba(28, 45, 65, 0.15);
  box-shadow: 0 2px 6px 0 rgb(0 0 0 / 5%);
}

.link-section {
  margin-bottom: 20px;
}

.link-group-heading {
  margin-top: 4px;
  margin-bottom: 14px;
  font-size: 22px;
}

.link-group-sub-heading {
  margin-top: 0;
  margin-bottom: 8px;
}

.link-group-link {
  display: flex;
  align-items: center;
  margin-bottom: 12px;

  &:hover,
  &:focus {
    text-decoration: underline;
  }
}

.link-group-icon {
  margin-right: 10px;
}

.link-label {
  margin: 0 38px;
  margin-top: -12px;
  margin-bottom: 10px;
  color: $text-secondary;
  font-size: 12px;
  line-height: 1.2;
}

.go-to-prop-page-container {
  text-align: center;
  margin-bottom: 40px;
}
</style>
