var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"swap-menu-wrapper"},[_c('button',{class:[
      'swap-menu-option',
      { selected: _vm.swapFilterType === _vm.SWAP_FILTER_TYPES.ONLY_FULL_INTEREST }
    ],on:{"click":() =>
        _vm.setSwapType({
          swapFilterType: _vm.SWAP_FILTER_TYPES.ONLY_FULL_INTEREST,
          update: true
        })}},[_c('BaseIcon',{staticClass:"swap-menu-icon",attrs:{"icon-file":_vm.swapFilterType === _vm.SWAP_FILTER_TYPES.ONLY_FULL_INTEREST
          ? 'menu-stars-active'
          : 'menu-stars'}}),_c('p',{staticClass:"swap-menu-option-text"},[_vm._v(" "+_vm._s(_vm.$t('swaps_list_toggle_full'))+" "),(_vm.swapInterestCounts)?_c('span',{staticClass:"swap-menu-option-count"},[_vm._v("("+_vm._s(_vm.swapInterestCounts.full.total)+")")]):_vm._e()]),(_vm.swapInterestCounts && _vm.swapInterestCounts.full.unseen > 0)?_c('div',{staticClass:"unseen-badge"},[_vm._v(" "+_vm._s(_vm.swapInterestCounts.full.unseen)+" ")]):_vm._e()],1),_c('button',{class:[
      'swap-menu-option',
      { selected: _vm.swapFilterType === _vm.SWAP_FILTER_TYPES.LAST_PART }
    ],on:{"click":() =>
        _vm.setSwapType({
          swapFilterType: _vm.SWAP_FILTER_TYPES.LAST_PART,
          update: true
        })}},[_c('BaseIcon',{staticClass:"swap-menu-icon",attrs:{"icon-file":_vm.swapFilterType === _vm.SWAP_FILTER_TYPES.LAST_PART
          ? 'menu-key-active'
          : 'menu-key'}}),_c('p',{staticClass:"swap-menu-option-text"},[_vm._v(" "+_vm._s(_vm.$t('swaps_list_toggle_me'))+" "),(_vm.swapInterestCounts)?_c('span',{staticClass:"swap-menu-option-count"},[_vm._v("("+_vm._s(_vm.swapInterestCounts.interestedInMine.total)+")")]):_vm._e()]),(
        _vm.swapInterestCounts && _vm.swapInterestCounts.interestedInMine.unseen > 0
      )?_c('div',{staticClass:"unseen-badge"},[_vm._v(" "+_vm._s(_vm.swapInterestCounts.interestedInMine.unseen)+" ")]):_vm._e()],1),_c('button',{class:[
      'swap-menu-option smaller',
      { selected: _vm.swapFilterType === _vm.SWAP_FILTER_TYPES.INTEREST_MARKED }
    ],on:{"click":() =>
        _vm.setSwapType({
          swapFilterType: _vm.SWAP_FILTER_TYPES.INTEREST_MARKED,
          update: true
        })}},[_c('BaseIcon',{staticClass:"swap-menu-icon",attrs:{"icon-file":_vm.swapFilterType === _vm.SWAP_FILTER_TYPES.INTEREST_MARKED
          ? 'menu-thumb-up-active'
          : 'menu-thumb-up',"width":_vm.isMobile ? 18 : 24,"height":_vm.isMobile ? 18 : 24}}),_c('p',{staticClass:"swap-menu-option-text"},[_vm._v(" "+_vm._s(_vm.$t('swaps_list_toggle_my_interests'))+" "),(_vm.swapInterestCounts)?_c('span',{staticClass:"swap-menu-option-count"},[_vm._v("("+_vm._s(_vm.swapInterestCounts.ownerInterestMarks.total)+")")]):_vm._e()])],1),_c('button',{class:[
      'swap-menu-option smaller',
      { selected: _vm.swapFilterType === _vm.SWAP_FILTER_TYPES.FAVOURITES }
    ],on:{"click":() =>
        _vm.setSwapType({
          swapFilterType: _vm.SWAP_FILTER_TYPES.FAVOURITES,
          update: true,
          isFavourites: true
        })}},[_c('BaseIcon',{staticClass:"swap-menu-icon",attrs:{"icon-file":_vm.swapFilterType === _vm.SWAP_FILTER_TYPES.FAVOURITES
          ? 'menu-heart-active'
          : 'menu-heart',"width":_vm.isMobile ? 18 : 24,"height":_vm.isMobile ? 18 : 24}}),_c('p',{staticClass:"swap-menu-option-text"},[_vm._v(" "+_vm._s(_vm.$t('swaps_list_toggle_my_favourites'))+" "),(_vm.swapInterestCounts)?_c('span',{staticClass:"swap-menu-option-count"},[_vm._v("("+_vm._s(_vm.swapInterestCounts.favourites.total)+")")]):_vm._e()])],1)])
}
var staticRenderFns = []

export { render, staticRenderFns }