var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"current-subscription"},[_c('div',{staticClass:"proposition-image",style:([
      _vm.currentProposition.residences[0].images.length > 0
        ? {
            backgroundImage: `url(${_vm.currentProposition.residences[0].images[0].fileName})`,
            opacity: 1,
            backgroundSize: 'cover'
          }
        : ''
    ])}),(_vm.currentProposition)?_c('div',{staticClass:"subscription-details"},[_c('div',{staticClass:"details-wrapper"},[_c('h2',{staticClass:"details-title"},[_vm._v(_vm._s(_vm.formattedAddress))]),(_vm.currentProposition.isFreemium)?_c('div',{staticClass:"subscription-info"},[_vm._v(" "+_vm._s(_vm.$t('current_subscription_subscription_info_freemium'))+" ")]):(_vm.currentProposition.isTrial)?_c('div',{staticClass:"subscription-info",domProps:{"innerHTML":_vm._s(
          _vm.$t('current_subscription_subscription_info', {
            nextPaymentDate: _vm.nextPaymentDate
          })
        )}}):(_vm.currentProposition.inFreeArea)?_c('div',[(_vm.currentProposition.active)?_c('span',[_vm._v(" "+_vm._s(_vm.$t('current_subscription_subscription_info_active'))+" ")]):_c('span',[_c('span',{domProps:{"innerHTML":_vm._s(_vm.$t('current_subscription_inactive'))}})])]):(
          !_vm.currentProposition.inFreeArea &&
          _vm.currentSubscription.paymentMethod === 'Complimentary'
        )?_c('div',[(_vm.currentProposition.active)?_c('span',[_vm._v(" "+_vm._s(_vm.$t('current_subscription_free_days'))+" "+_vm._s(_vm.nextPaymentDate)+". ")]):_c('span',[_c('span',{domProps:{"innerHTML":_vm._s(_vm.$t('current_subscription_inactive'))}})])]):_c('div',{staticClass:"subscription-info"},[(_vm.currentProposition.active)?_c('span',[_vm._v(" "+_vm._s(_vm.$t('current_subscription_your_subscription', { monthlyPrice: _vm.monthlyPrice }))+" "+_vm._s(_vm.currentSubscription.cancelledAt ? _vm.$t('current_subscription_is_being_canceled') : _vm.$t('current_subscription_is_being_extended'))+" "+_vm._s(_vm.$t('current_subscription_automatically'))+" "+_vm._s(_vm.nextPaymentDate)+". ")]):_c('span',[_c('span',{domProps:{"innerHTML":_vm._s(_vm.$t('current_subscription_inactive'))}})]),_c('br'),(!_vm.currentSubscription.cancelledAt)?_c('span',[_vm._v(" "+_vm._s(_vm.$t('current_subscription_extended_with', { days: _vm.currentSubscription.days }))+" "+_vm._s(_vm.swedishPaymentMethod === _vm.$t('current_subscription_for_free') ? '.' : ` ${_vm.$t('current_subscription_via')} ${_vm.swedishPaymentMethod}.`)+" ")]):_vm._e()])]),_c('div',{staticClass:"button-wrapper"},[(_vm.currentSubscription.cancelledAt)?_c('secondary-button',{style:(_vm.buttonStyle),attrs:{"full-width":_vm.isMobile},nativeOn:{"click":function($event){return _vm.handleRenewSubscription.apply(null, arguments)}}},[_vm._v(_vm._s(_vm.$t('current_subscription_automatic_renewal')))]):(_vm.currentProposition.isFreemium)?_c('secondary-button',{style:(_vm.buttonStyle),attrs:{"full-width":_vm.isMobile},nativeOn:{"click":function($event){return _vm.goToPaymentPage.apply(null, arguments)}}},[_vm._v(_vm._s(_vm.$t('freemium_banner_cta')))]):(_vm.currentSubscription.paymentMethod === 'Trial')?_c('secondary-button',{style:(_vm.buttonStyle),attrs:{"full-width":_vm.isMobile},nativeOn:{"click":function($event){return _vm.goToPaymentPage.apply(null, arguments)}}},[_vm._v(_vm._s(_vm.$t('current_subscription_upgrade')))]):(
          _vm.currentSubscription.paymentMethod === 'Complimentary' &&
          !_vm.currentProposition.inFreeArea
        )?_c('secondary-button',{style:(_vm.buttonStyle),attrs:{"full-width":_vm.isMobile},nativeOn:{"click":function($event){return _vm.goToPaymentPage.apply(null, arguments)}}},[_vm._v(_vm._s(_vm.$t('current_subscription_automatic_renewal')))]):(
          _vm.currentSubscription.paymentMethod !== 'Complimentary' &&
          _vm.showChangeSubscription &&
          _vm.hasPaymentToken
        )?_c('secondary-button',{style:(_vm.buttonStyle),attrs:{"full-width":_vm.isMobile},nativeOn:{"click":function($event){return (() => _vm.openCancelModal({ changeSubscription: true })).apply(null, arguments)}}},[_vm._v(_vm._s(_vm.$t('current_subscription_change_subscription')))]):(
          _vm.currentSubscription.paymentMethod === 'Complimentary' &&
          _vm.currentProposition.inFreeArea &&
          !_vm.currentProposition.active
        )?_c('secondary-button',{style:(_vm.buttonStyle),attrs:{"full-width":_vm.isMobile},nativeOn:{"click":function($event){return _vm.handleRenewSubscription.apply(null, arguments)}}},[_vm._v(_vm._s(_vm.$t('current_subscription_automatic_renewal')))]):_vm._e()],1)]):_vm._e()])
}
var staticRenderFns = []

export { render, staticRenderFns }