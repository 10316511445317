var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"modal-nav"},[_c('span',{class:[
      'nav-item',
      { 'is-active': _vm.pageModalActivePage === 'proposition' }
    ],on:{"click":function($event){return _vm.setPageModalActivePage('proposition')}}},[_vm._v(_vm._s(_vm.$t('modal_navigation_alt1')))]),_c('span',{class:[
      'nav-item',
      { 'is-active': _vm.pageModalActivePage === 'swapdetails' }
    ],on:{"click":function($event){return _vm.setPageModalActivePage('swapdetails')}}},[_vm._v(_vm._s(_vm.$t('modal_navigation_alt2')))])])
}
var staticRenderFns = []

export { render, staticRenderFns }