var render = function render(){var _vm=this,_c=_vm._self._c;return _c('label',{class:[
    'radio-container',
    {
      reverse: _vm.reverse
    }
  ],style:({ padding: _vm.paddingY > 0 && `${_vm.paddingY}px 0` })},[_c('input',{attrs:{"type":"radio","name":_vm.name,"disabled":_vm.disabled},domProps:{"value":_vm.value,"checked":_vm.checked},on:{"change":_vm.handleChange,"input":_vm.handleInput}}),_c('span',{class:{
      'is-disabled': _vm.disabled,
      'is-checked': _vm.checked
    }},[_vm._t("default")],2),_vm._t("checkmark",function(){return [_c('div',{ref:"radioCheckmark",staticClass:"radio-checkmark",class:{
        'is-active': _vm.checked,
        'is-disabled': _vm.disabled,
        animate: _vm.checked
      }})]},null,{ checked: _vm.checked, disabled: _vm.disabled })],2)
}
var staticRenderFns = []

export { render, staticRenderFns }