var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"user-card-container"},[(_vm.user && _vm.user.successfulSwapCount > 0)?_c('div',{staticClass:"success-swap-banner"},[_c('div',[_vm._v(" "+_vm._s(_vm.$t('user_card_swaped'))+" "),_c('br'),_c('span',{staticClass:"large"},[_vm._v(_vm._s(_vm.user && _vm.user.successfulSwapCount)+"x")])])]):_vm._e(),(_vm.isAuthenticated)?_c('div',{staticClass:"info-area"},[_c('div',{staticClass:"user-img",style:({
        backgroundImage: `url(${_vm.$t(
          'url_cdn',
          _vm.$routeLocale
        )}/profil-bilder/${_vm.roundedUserId}/${_vm.user.userId}.jpg)`,
        opacity: 1,
        backgroundSize: 'cover'
      })}),_c('div',{staticClass:"activity-indicator-area"},[(_vm.user && _vm.user.name)?_c('div',{staticClass:"user-name"},[_vm._v(_vm._s(_vm.user.name))]):_vm._e(),(_vm.user.verified)?_c('div',{staticClass:"verified-user"},[(_vm.user.verified)?_c('BaseIcon',{staticClass:"verified-badge",attrs:{"icon":"verified","width":12,"height":12}}):_vm._e(),_vm._v(" Verifierad användare ")],1):_vm._e()])]):_vm._e(),(_vm.isAuthenticated)?_c('user-activity-details',{staticClass:"activity-details",attrs:{"proposition-id":_vm.propositionId.toString()}}):_vm._e(),_c('div',{staticClass:"contact-area"},[_c('BaseButton',{class:[
        'full-width',
        {
          grey: _vm.showContactTriangleButton,
          outline: _vm.showContactTriangleButton
        }
      ],on:{"click":_vm.openContactModal}},[_vm._v(" "+_vm._s(_vm.showContactTriangleButton ? _vm.$t('user_card_contact') : _vm.$t('user_card_contact_swap'))+" ")]),(_vm.showContactTriangleButton)?_c('BaseButton',{staticClass:"full-width",staticStyle:{"margin-top":"12px"},on:{"click":_vm.handleContactTriangleSwap}},[_vm._v(" "+_vm._s(_vm.$t('user_card_contact_swap'))+" ")]):_vm._e()],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }