var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"radio",class:{
    'is-active': _vm.isChecked,
    'is-active-dark': _vm.isChecked && _vm.isButtonDark,
    'is-disabled': _vm.disabled
  }},[_c('label',{staticClass:"radio-container"},[_c('input',{attrs:{"type":"radio","name":_vm.name,"disabled":_vm.disabled},domProps:{"value":_vm.value,"checked":_vm.isChecked},on:{"change":_vm.changeHandler,"input":_vm.inputHandler}}),_vm._t("checkmark",function(){return [_c('div',{staticClass:"radio-checkmark",class:{
          'is-active': _vm.isChecked,
          'is-active-dark': _vm.isChecked && _vm.isButtonDark
        }})]},null,{ isChecked: _vm.isChecked, disabled: _vm.disabled }),_c('div',{staticClass:"radio-content"},[_vm._t("label",function(){return [(_vm.label)?_c('div',{staticClass:"radio-label"},[_vm._v(_vm._s(_vm.label))]):_vm._e()]},null,{ label: _vm.label, isChecked: _vm.isChecked, disabled: _vm.disabled })],2)],2)])
}
var staticRenderFns = []

export { render, staticRenderFns }