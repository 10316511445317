import { getCookies } from '@/utils/helpers/cookie';
import { isFeatureFlagEnabled } from '@/plugins/growthbook/growthbook';

const user = state => state.user;
const ready = state => state.ready;
const isImpersonated = state => state.isImpersonated;
const isAuthenticated = state => state.isAuthenticated;
const isAppRequest = () => {
  const cookies = getCookies();
  if (cookies['LGB-APP']) {
    return true;
  }

  return window.location.href
    .toLowerCase()
    .includes('isAppRequest=true'.toLowerCase());
};

function allowAccess(state) {
  return state.isAccessOverridable;
}

// Wait for app ready before checking test
function isTestEnabled(state) {
  return growthbookFeatureName => {
    if (!state.ready) {
      return false;
    }

    return isFeatureFlagEnabled(growthbookFeatureName);
  };
}

const propositions = state => state.propositions;

export default {
  user,
  ready,
  isImpersonated,
  isAuthenticated,
  isAppRequest,
  allowAccess,
  isTestEnabled,
  propositions
};
