<template>
  <div class="next-button-container">
    <slot />
  </div>
</template>

<script>
export default {};
</script>

<style lang="scss" scoped>
.next-button-container {
  position: fixed;
  bottom: env(safe-area-inset-bottom);
  left: 0;
  width: 100%;
  display: flex;
  flex-direction: column;
  padding: 20px;
  background-color: rgba(255, 255, 255, 0.8);
  backdrop-filter: blur(20px);
  z-index: 2;

  @media ($mobile) {
    box-shadow: 0px -12px 16px -4px rgba(16, 24, 40, 0.08),
      0px -4px 6px -2px rgba(16, 24, 40, 0.03);
  }

  @media ($desktop) {
    position: relative;
    padding: 40px 0;
    background-color: #faf9f8;
  }

  // Remove position fixed when keyboard is open
  // to prevent that too much screen space is covered
  @media (max-height: 500px) {
    position: absolute;
  }
}
</style>
