var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"swap-view"},[(_vm.swapViewLoading)?[_c('div',{staticClass:"title-wrapper"},[_c('div',{staticClass:"title"},[_vm._v(_vm._s(_vm.$t('swap_view_loading')))])]),(_vm.isUserList)?_c('SwapViewToggle'):_c('NonUserSwapViewToggle'),_c('div',{ref:"currentContainer",staticClass:"current-container",style:({ height: _vm.contentMaxHeight })},[_c('Spinner')],1)]:(_vm.swapDetails === null)?[_c('div',{staticClass:"panel"},[_c('h4',[_vm._v(_vm._s(_vm.$t('swap_view_not_available')))]),_c('BaseButton',{on:{"click":_vm.goToDirectSwap}},[_vm._v(_vm._s(_vm.$t('swap_view_not_available_button')))])],1)]:[(
        _vm.currentSwapViewTab !== _vm.SWAP_VIEW_TABS.SWAP_INFO &&
        _vm.currentSwapViewTab !== _vm.SWAP_VIEW_TABS.SWAP_ALTERNATIVES
      )?_c('div',{staticClass:"title-wrapper"},[_c('div',{staticClass:"title"},[_vm._v(" "+_vm._s(_vm.isTriangle ? _vm.$t('swap_view_triangle_swap') : _vm.isManual ? _vm.$t('swap_view_manual_swap') : _vm.$t('swap_view_direct_swap'))+" ")]),_c('div',{staticClass:"swap-info-btn",on:{"click":_vm.goToInfo}})]):_vm._e(),(
        _vm.currentSwapViewTab === _vm.SWAP_VIEW_TABS.SWAP_INFO ||
        _vm.currentSwapViewTab === _vm.SWAP_VIEW_TABS.SWAP_ALTERNATIVES
      )?_c('div',{staticClass:"modal-header",on:{"click":function($event){$event.stopPropagation();return _vm.closeModal.apply(null, arguments)}}},[_c('div',{staticClass:"close-btn"}),_c('div',{staticClass:"close-label"},[_vm._v(_vm._s(_vm.$t('swap_view_close')))])]):(_vm.isUserList)?_c('SwapViewToggle',{attrs:{"is-full-interest":_vm.isFullInterest,"has-active-chat":!!(_vm.swapDetails && _vm.swapDetails.chat),"chat-dot":_vm.swapDetails && _vm.swapDetails.chat && _vm.swapDetails.chat.unreadCount > 0}}):_c('NonUserSwapViewToggle'),_c('div',{staticClass:"current-container"},[(_vm.currentSwapViewTab === _vm.SWAP_VIEW_TABS.FULL_INTEREST)?_c('div',[_c('SwapFullInterest',{attrs:{"is-triangle":_vm.isTriangle,"full-since-date":_vm.swapDetails.fullSwapSince}})],1):_vm._e(),(_vm.currentSwapViewTab === _vm.SWAP_VIEW_TABS.SWAP)?_c('div',[_c('SwapOverview',{attrs:{"swap-details":_vm.swapDetails}})],1):_vm._e(),(_vm.currentSwapViewTab === _vm.SWAP_VIEW_TABS.SWAP_ALTERNATIVES)?_c('div',[_c('SwapAlternatives')],1):_vm._e(),(_vm.currentSwapViewTab === _vm.SWAP_VIEW_TABS.SWAP_INFO)?_c('div',[_c('SwapInfo',{attrs:{"swap-details":_vm.swapDetails}})],1):_vm._e(),(_vm.currentSwapViewTab === _vm.SWAP_VIEW_TABS.CHAT)?[(_vm.freemiumCanAnswerChat || _vm.premiumOrFreeLimitlessChat)?_c('Messages',{staticClass:"in-swap-view",attrs:{"standalone":true,"proposition-ids":_vm.messengerPropositionIds,"user-id":_vm.user.userId,"is-impersonated":_vm.user.isImpersonated,"is-mobile":_vm.isMobile,"where":"swapView","mode":"plain","scroll-elements":['current-container', 'conversations-wrapper']},on:{"isFreemiumLocked":_vm.handleFreemiumChatLocked}}):(_vm.isTrial)?_c('SwapViewChatPay',{attrs:{"payment-page-proposition-id":_vm.primaryPropositionId}}):_vm._e()]:_vm._e(),(_vm.currentSwapViewTab === _vm.SWAP_VIEW_TABS.MAP)?_c('SwapChainMap',{attrs:{"interests":_vm.interests,"swaps":_vm.swaps,"primary-proposition-id":_vm.primaryPropositionId}}):_vm._e()],2)]],2)
}
var staticRenderFns = []

export { render, staticRenderFns }