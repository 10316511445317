import { addDataLayer } from '@/utils/gtm';

export function getNumberOfSteps(swapType) {
  let steps = 5;
  if (swapType === 'oneTwo') steps = 7;
  else if (swapType === 'twoOne') steps = 6;

  return steps;
}

export function calculateProgress(step, swapType, index = 0) {
  const steps = getNumberOfSteps(swapType);
  if (swapType === 'oneTwo') {
    const doubleStep = 3;
    if (step >= doubleStep) {
      step += 2;
    } else if (index > 1) {
      step += parseInt(index, 10);
    }
  }

  if (swapType === 'twoOne') {
    if (step > 4 || index > 1) {
      step += 1;
    }
  }

  let progress = Math.round((step / steps) * 100);

  addDataLayer({
    category: 'proposition',
    what: 'progress',
    action: progress
  });
}
