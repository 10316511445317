<template>
  <div
    class="base-checkbox"
    :class="{
      'is-active': isChecked,
      'is-disabled': disabled,
      'has-error': !valid,
      'is-required': required
    }"
  >
    <label class="base-checkbox-container">
      <input
        type="checkbox"
        :name="name"
        :value="value"
        :checked="isChecked"
        :disabled="disabled"
        class="base-checkbox-input"
        @change="inputHandler"
      />
      <!-- @slot Custom check mark markup -->
      <slot name="checkmark" v-bind="{ isChecked, disabled }">
        <div
          class="base-checkbox-checkmark"
          :class="{
            'is-active': isChecked,
            'is-active-dark': isChecked && isCheckboxDark,
            secondary: secondary,
            sm: size === 'sm',
            md: size === 'md'
          }"
        ></div>
      </slot>
      <!-- @slot Custom label markup -->

      <slot name="label" v-bind="{ label, isChecked, disabled }">
        <div
          v-if="label"
          class="base-checkbox-label"
          :class="[
            {
              'variant-label-light': variant === 'label-light'
            },
            {
              'label-dark': isCheckboxDark
            }
          ]"
        >
          {{ label }}
        </div>
      </slot>
    </label>
    <div class="base-checkbox-message">
      <transition name="fade">
        <!-- @slot Custom message of form input -->
        <slot
          v-if="!disabled"
          :name="computedMessageSlotName"
          v-bind="{ computedMessage }"
        >
          <div :class="computedMessageClass">{{ computedMessage }}</div>
        </slot>
      </transition>
    </div>
  </div>
</template>

<script>
export default {
  name: 'BaseCheckbox',
  props: {
    name: {
      type: String,
      default: ''
    },
    value: {
      type: [String, Boolean],
      default: ''
    },
    label: {
      type: String,
      default: ''
    },
    /**
     *  Hint/Required message value of checkbox.
     */
    hintMessage: {
      type: String,
      default: 'Required.'
    },
    required: {
      type: Boolean,
      default: false
    },
    /**
     * Info/success message value of select.
     */
    infoMessage: {
      type: String,
      default: ''
    },
    /**
     * Error message value of select. It will be appeared if `valid` is `true`.
     */
    errorMessage: {
      type: String,
      default: ''
    },
    valid: {
      type: Boolean,
      default: true
    },
    disabled: {
      type: Boolean,
      default: false
    },
    selected: {
      type: [Array, Boolean],
      default: () => []
    },
    secondary: {
      type: Boolean,
      default: false
    },
    variant: {
      type: String,
      default: ''
    },
    size: {
      type: String,
      default: 'md'
    },
    isCheckboxDark: {
      type: Boolean,
      default: false
    },
    isLabelDark: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    isChecked() {
      if (typeof this.selected === 'boolean') {
        return this.selected;
      } else {
        return this.selected.includes(this.value);
      }
    },
    computedMessageSlotName() {
      return this.messagesHandler(
        'show-error-message',
        'show-info-message',
        this.required ? 'show-hint-message' : ''
      );
    },
    computedMessage() {
      return this.messagesHandler(
        this.errorMessage,
        this.infoMessage,
        this.required ? this.hintMessage : ''
      );
    },
    computedMessageClass() {
      return this.messagesHandler(
        'base-checkbox-message-error',
        'base-checkbox-message-info',
        this.required ? 'base-checkbox-message-hint' : ''
      );
    }
  },
  methods: {
    inputHandler() {
      if (typeof this.selected === 'boolean') {
        this.$emit('change', !this.selected);
      } else {
        let selected = [...this.selected];
        if (selected.includes(this.value)) {
          selected = selected.filter(value => value !== this.value);
        } else {
          selected.push(this.value);
        }
        this.$emit('change', selected);
      }
    },
    messagesHandler(error, info, hint) {
      if (this.errorMessage && !this.valid) {
        return error;
      } else if (this.infoMessage && this.valid) {
        return info;
      } else if (this.hintMessage) {
        return hint;
      } else {
        return '';
      }
    }
  }
};
</script>

<style lang="scss" scoped>
.base-checkbox {
  user-select: none;
}

.base-checkbox-container {
  position: relative;
  display: flex;
  align-items: center;
  padding: 0;
  cursor: pointer;

  .is-disabled & {
    cursor: not-allowed;
  }
}

.base-checkbox-checkmark {
  display: flex;
  align-items: center;
  justify-content: center;
  color: transparent;
  background-color: #fff;
  border: 1px solid #e8e8e8;
  border-radius: 5px;
  outline: none;
  transition: background-color 150ms linear, border-color 150ms linear;

  &.sm {
    width: 20px;
    height: 20px;
  }

  &.md {
    width: 24px;
    height: 24px;
  }

  .has-error & {
    border-color: red;
  }

  &.is-active {
    color: #fff;
    background-color: #2ec973;
    border-color: #2ec973;
    background-image: url('../assets/svg/checkbox-check-white.svg');
    background-size: 16px 12px;
    background-position: center;
    &.is-active-dark {
      background-color: #2c3e50;
      border-color: #2c3e50;
    }
  }

  .is-required & {
    // color: black;
  }

  .is-disabled & {
    color: #ccc;
    border-color: #ccc;
  }

  &:hover {
    // border-color: #2EC973;

    .has-error & {
      border-color: red;
    }

    .is-disabled & {
      border-color: #ccc;
    }
  }

  .is-disabled & {
    border-color: #ccc;

    &.is-active {
      opacity: 0.4;
    }
  }

  .has-error & {
    &.is-active {
      border-color: red;
      background-color: red;
    }
  }
}

// Secondary variant
.secondary.base-checkbox-checkmark {
  .is-active & {
    color: #2ec973;
    background-color: #fff;
    border-color: #e8e8e8;
    background-image: url('../assets/svg/checkbox-check-green.svg');
  }
}

.base-checkbox-label {
  flex: 1;
  margin: 0;
  margin-left: 10px;
  font-size: 14px;
  font-weight: 600;
  color: #2c3e50;
  transition: color 150ms linear;

  &.variant-label-light {
    font-weight: 400;
    line-height: 24px; /* 171.429% */
  }

  &.label-dark {
    color: #0b182c;
  }

  &:hover {
    .base-checkbox-is-disabled & {
      color: #ccc;
    }
  }
}

.base-checkbox-message {
  margin: 0;
}

.base-checkbox-message-error {
  color: red;
}

.base-checkbox-input {
  position: absolute;
  opacity: 0;
  left: -1000%;
  width: 1px;
  height: 1px;
  outline: none;
}
</style>
