import { lbPropositionApi, lbUserApi } from '../../../utils/axiosConfig';

async function fetchUser() {
  try {
    const response = await lbUserApi.get('/user/current');
    if (response.data && response.data.userId) {
      return response.data;
    }
  } catch (error) {
    // Empty catch block
  }

  return null;
}

export async function fetchUserPropositions() {
  try {
    const response = await lbPropositionApi.get('/propositions/user');

    if (response.data && response.data.length > 0) {
      return response.data;
    }
  } catch (error) {
    window.Logger.error(error);
  }

  return null;
}

export default {
  fetchUser,
  fetchUserPropositions
};
