var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[(_vm.loading)?_c('spinner'):_c('div',[_c('div',{ref:"scrollBox",staticClass:"swap-alternatives"},[_vm._l((_vm.filteredAlternatives),function(alternative){return _c('div',{key:`${alternative.swapParts[2].propositionId}-${alternative.swapParts[1].propositionId}`,staticClass:"alternative",on:{"click":() => _vm.handleSelectThirdParty(alternative)}},[_c('div',{class:[
            'alternative-img',
            {
              'placeholder-img':
                alternative.swapParts[2].residences[0].pictures.length < 1
            }
          ],style:(_vm.getImageStyle(alternative.swapParts))}),_c('div',{staticClass:"alternative-info"},[_c('div',{staticClass:"alternative-city"},[_vm._v(" "+_vm._s(_vm.getCity(alternative.swapParts))+" ")]),_c('div',{staticClass:"alternative-address"},[_vm._v(" "+_vm._s(_vm.getAddress(alternative.swapParts))+" ")]),(_vm.isImpersonated)?_c('div',{staticClass:"score-text"},[_vm._v(" Score: "+_vm._s(_vm.getScore(alternative.swapParts))+" ")]):_vm._e(),_c('div',{staticClass:"alternative-interests"},[_c('interest-indicators',{attrs:{"interests":_vm.getInterests(alternative.swapParts)}})],1)])])}),(_vm.totalPages > 1)?_c('pagination',{staticStyle:{"margin-top":"30px"},attrs:{"max-page":_vm.totalPages,"current-page":_vm.currentPage,"on-page-click":page => _vm.handleSelectPage(page)}}):_vm._e()],2)])],1)
}
var staticRenderFns = []

export { render, staticRenderFns }