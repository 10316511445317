var render = function render(){var _vm=this,_c=_vm._self._c;return _c('transition',{attrs:{"name":"fade-soft","mode":"out-in","appear":""}},[(_vm.show)?_c('div',{staticClass:"account-notification-modal"},[_c('div',{staticClass:"content"},[_c('img',{attrs:{"src":require("../../assets/svg/teamwork-alt.svg"),"alt":"Team effort, powerful statement"}}),_c('div',{staticClass:"text-wrapper"},[_c('div',{staticClass:"title"},[_vm._v(_vm._s(_vm.$t('matches_notification_modal_title')))]),_c('div',{staticClass:"body-text",domProps:{"innerHTML":_vm._s(
            _vm.count === 1
              ? _vm.$t('matches_notification_modal_body_singular', { count: _vm.count })
              : _vm.$t('matches_notification_modal_body', { count: _vm.count })
          )}}),_c('div',{staticClass:"cta-button"},[_c('BaseButton',{class:[
              'primary-action',
              'smaller',
              { rounded: _vm.isMobile, tiny: !_vm.isMobile }
            ],attrs:{"icon-file":_vm.isMobile ? 'icon-chat-white' : 'arrow-right-alt3-white',"icon-last":!_vm.isMobile,"icon-size":_vm.isMobile ? 20 : 14},on:{"click":_vm.handleCtaClick}},[_vm._v(_vm._s(_vm.$t('matches_notification_modal_cta')))])],1)]),_c('div',{staticClass:"close-button",on:{"click":_vm.handleClose}},[_c('BaseIcon',{staticClass:"base-input-icon",attrs:{"icon-file":"icon-close-black","height":_vm.isMobile ? 20 : 16,"width":20}})],1)])]):_vm._e()])
}
var staticRenderFns = []

export { render, staticRenderFns }