var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{class:['indicator-wrapper', { vertical: _vm.vertical }]},_vm._l((_vm.interests),function(interest,i){return _c('div',{key:i,class:[
      'indicator',
      {
        small: _vm.small,
        'list-size': _vm.listSize,
        interested: interest === true,
        'not-interested': interest === false
      }
    ]})}),0)
}
var staticRenderFns = []

export { render, staticRenderFns }