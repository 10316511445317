var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('div',{staticClass:"subscription-picker"},[(!_vm.displayCampaignInfo)?_c('div',{staticClass:"picker-title"},[_vm._v(" "+_vm._s(_vm.$t('payment_new_subscription_title'))+" ")]):_vm._e(),_c('div',{staticClass:"options-container"},[(
          _vm.selectedProduct && _vm.selectedProduct.category === 'annonsforlangning'
        )?_c('div',{staticClass:"subscription-options"},_vm._l((_vm.sortedProducts),function(product,index){return _c('div',{key:product.productId,class:[
            'option',
            {
              'option-is-selected':
                product.productId === _vm.selectedProduct.productId
            }
          ],on:{"click":function($event){return _vm.handleSelectProductId(product.productId)}}},[(index == 1)?_c('div',{staticClass:"option-label"},[_vm._v(" "+_vm._s(_vm.$t('subscription_picker_label'))+" ")]):_vm._e(),(!_vm.displayCampaignInfo)?_c('div',{staticClass:"subscription-period"},[_c('div',{staticClass:"period-length"},[_vm._v(" "+_vm._s(_vm.productTitle(product).length)+" ")]),_c('div',{staticClass:"period-label"},[_vm._v(_vm._s(_vm.productTitle(product).label))])]):_vm._e(),(_vm.displayCampaignInfo)?_c('div',{staticClass:"price-description"},[_c('div',{staticClass:"campaign-name"},[_vm._v(" "+_vm._s(product.name)+" ")]),_vm._v(" "+_vm._s(product.description)+" ")]):_vm._e(),_c('div',{staticClass:"price-per-month"},[(!product.campaign)?[_vm._v(" "+_vm._s(_vm.monthlyPrice(product))+" "+_vm._s(_vm.$t('subscription_picker_new_currency'))+"/"+_vm._s(_vm.$t('subscription_picker_month_short'))+" ")]:[_vm._v(" "+_vm._s(product.priceWithVAT)+" "+_vm._s(_vm.$t('subscription_picker_currency'))+" ")]],2),(_vm.monthlyPercentageOff(product))?_c('div',{staticClass:"price-discount"},[_vm._v(" "+_vm._s(_vm.$t('subscription_picker_save_perc_prefix'))+" "+_vm._s(_vm.monthlyPercentageOff(product))+"% ")]):_vm._e()])}),0):_vm._e()]),(_vm.displayCampaignInfo)?_c('div',{staticClass:"campaign-info"},[_c('strong',[_vm._v(_vm._s(_vm.$t('product_list_title')))]),_c('div',[_vm._v(" "+_vm._s(_vm.$t('product_list_info_1'))+" "+_vm._s(_vm.paymentPeriod)+" "+_vm._s(_vm.$t('product_list_info_2'))+" ("+_vm._s(_vm.selectedProduct.subscriptionProduct.priceInclVat)+" "+_vm._s(_vm.$t('product_list_info_3'))+"). ")])]):_vm._e()])])
}
var staticRenderFns = []

export { render, staticRenderFns }