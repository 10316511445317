import { lbApi } from './axiosConfig';
import { isDebug } from './debug';

const eventTypes = {
  PAGEVIEW: 0,
  CLICK: 1,
  SEE: 2,
  EXPERIMENT: 3
};

async function emitEvent(type, description, props) {
  if (type === eventTypes.PAGEVIEW) {
    const triggerBy = getTriggerBy();
    if (triggerBy) {
      props = {
        ...props,
        triggerBy
      };
    }
  }

  if (isDebug()) {
    return console.debug('Event emitted:', {
      t: type,
      d: description,
      p: props
    });
  }
  return lbApi.post(`/ev`, {
    t: type,
    d: description,
    p: props
  });
}

function setTriggerBy(id) {
  if (!localStorage) {
    return;
  }

  if (!id) {
    localStorage.removeItem('lb-ev-trigger');
    return;
  }

  localStorage.setItem('lb-ev-trigger', id);
}

function getTriggerBy() {
  if (!localStorage) {
    return null;
  }

  return localStorage.getItem('lb-ev-trigger');
}

function setEventQueue(evQueue) {
  if (!localStorage) {
    return;
  }

  if (!evQueue || evQueue.length === 0) {
    localStorage.removeItem('lb-ev-data');
    return;
  }

  localStorage.setItem('lb-ev-data', JSON.stringify(evQueue));
}

function getEventQueue() {
  if (!localStorage) {
    return [];
  }

  const storedEvents = localStorage.getItem('lb-ev-data');
  let evQueue = [];
  if (storedEvents) {
    evQueue = JSON.parse(storedEvents);
  }
  return evQueue;
}

function queueEvent(type, description, props) {
  const evQueue = getEventQueue();
  if (evQueue.length > 10) {
    return;
  }

  evQueue.push({
    t: type,
    d: description,
    p: props
  });

  if (type === eventTypes.CLICK) {
    setTriggerBy(props.id);
  }

  setEventQueue(evQueue);
}

function handleQueue() {
  let evQueue = getEventQueue();
  if (evQueue.length > 10) {
    setEventQueue(null);
    return;
  }

  if (evQueue.length === 0) {
    return;
  }

  let { t, d, p } = evQueue[0];

  emitEvent(t, d, p).finally(() => {
    evQueue.splice(0, 1);
    setEventQueue(evQueue);

    if (evQueue.length > 0) {
      setTimeout(handleQueue, 0);
    } else {
      setTriggerBy(null);
    }
  });
}

handleQueue();

export default {
  eventTypes,
  emitEvent,
  queueEvent
};
