<template>
  <component :is="tag" v-bind="urlTag" class="link-list-item" v-on="$listeners">
    <div class="icon-and-title">
      <BaseIcon :icon-file="iconFile" />
      <slot />
    </div>
    <slot name="icon-after">
      <BaseIcon
        :icon-file="'chevron-right-dark2'"
        :width="20"
        :height="20"
        class="chevron-icon"
      />
    </slot>
  </component>
</template>

<script>
export default {
  name: 'LinkListItem',

  props: {
    iconFile: {
      type: String,
      default: ''
    },

    path: {
      type: String,
      default: ''
    },

    isExternal: {
      type: Boolean,
      default: false
    }
  },

  computed: {
    tag() {
      return !this.path ? 'div' : this.isExternal ? 'a' : 'router-link';
    },
    urlTag() {
      return this.isExternal ? { href: this.path } : { to: this.path };
    }
  }
};
</script>

<style lang="scss" scoped>
.link-list-item {
  display: flex;
  align-items: center;
  padding: 16px 12px;
  border-radius: 6px;
  color: #0b182c;
  white-space: nowrap;
  cursor: pointer;
}

.icon-and-title {
  display: flex;
  align-items: center;
  gap: 12px;
}

.chevron-icon {
  margin-left: auto;
}
</style>
