<template>
  <div class="area-map-wrapper">
    <div ref="areaMap" class="map"></div>
  </div>
</template>

<script>
import { loadJs, loadCss } from '@/utils/maptiler';
import turf from 'turf';
import { mapGetters } from 'vuex';

export default {
  name: 'AreaPickerMap',

  props: {
    currentPolygon: {
      type: Object,
      required: true
    },
    delayInit: {
      type: Boolean,
      default: false
    },
    zoom: {
      type: Number,
      required: true
    },
    coordinates: {
      type: Array,
      required: true
    }
  },

  data: () => ({
    map: {}
  }),

  computed: {
    ...mapGetters({
      isMobile: 'screenSize/isMobile'
    })
  },

  watch: {
    currentPolygon() {
      this.updateOrClearAreas();
    },
    isMobile: function () {
      if (this.isMobile) {
        this.map.scrollZoom.disable();
        this.map.dragPan.disable();
      } else {
        this.map.scrollZoom.enable();
        this.map.dragPan.enable();
      }
    }
  },

  async mounted() {
    await Promise.all([loadJs(), loadCss()]);
    this.initializeMap();
  },

  methods: {
    initializeMap: async function () {
      const mapEl = this.$refs.areaMap;
      if (!mapEl) return;

      const { offsetWidth, offsetHeight } = mapEl;

      this.map = new maplibregl.Map({
        container: mapEl,
        width: offsetWidth,
        height: offsetHeight,
        center: this.coordinates,
        style:
          'https://api.maptiler.com/maps/streets/style.json?key=jTrvi1p07Kys0YeUMuBV',
        zoom: this.zoom,
        attributionControl: false,
        logoPosition: 'bottom-right'
      });

      if (!this.isMobile) {
        this.map.addControl(
          new maplibregl.NavigationControl({
            showCompass: false
          })
        );
      }

      if (this.isMobile) {
        this.map.scrollZoom.disable();
        this.map.dragPan.disable();
      }

      this.map.on('load', () => {
        this.mapLoaded = true;
        this.updateOrClearAreas();
      });
    },
    updateOrClearAreas() {
      if (this.map && this.map.getLayer) {
        if (Object.keys(this.currentPolygon).length === 0) {
          this.clearPolygons();
        } else {
          this.updateAreas();
        }
      }
    },

    clearPolygons() {
      if (this.map.getLayer('layer')) {
        this.map.removeLayer('layer');
      }

      if (this.map.getLayer('borderlayer')) {
        this.map.removeLayer('borderlayer');
      }

      if (this.map.getSource('layer')) {
        this.map.removeSource('layer');
      }
    },

    updateAreas() {
      try {
        this.clearPolygons();

        // Reset map to default coordinates if all polygons (areas) is removed
        if (
          this.currentPolygon.geometry &&
          this.currentPolygon.geometry.coordinates.length === 0
        ) {
          this.map.flyTo({
            center: this.coordinates,
            zoom: this.zoom
          });
        }

        this.map.addSource('layer', {
          type: 'geojson',
          data: this.currentPolygon
        });

        this.map.addLayer({
          id: 'layer',
          type: 'fill',
          source: 'layer',
          paint: {
            'fill-color': '#bc4ae4',
            'fill-opacity': 0.2
          }
        });

        this.map.addLayer({
          id: 'borderlayer',
          type: 'line',
          source: 'layer',
          paint: {
            'line-dasharray': [2, 2],

            'line-width': 1,
            'line-color': '#6742d2',
            'line-opacity': 1
          }
        });

        const bounds = turf.bbox(this.currentPolygon);

        this.map.fitBounds(bounds, { padding: 20 });
      } catch (e) {
        console.log({ error: e });
      }
    }
  }
};
</script>

<style lang="scss" scoped>
.area-map-wrapper {
  height: 100%;
  width: 100%;
  overflow: hidden;
}

.map {
  height: 100%;
  width: 100%;
  max-height: 100%;
  overflow: hidden;
  background-color: $bg-gray;
  outline: none;

  & > * {
    outline: none;
    -webkit-tap-highlight-color: rgba(255, 255, 255, 0); /* mobile webkit */
  }
}
</style>
<style>
.mapboxgl-ctrl-bottom-right {
  display: none;
}
</style>
