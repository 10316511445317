<template>
  <div class="page-steps">
    <div
      v-for="i in maxSteps"
      :key="i"
      :class="[i == step ? 'active' : '']"
      class="step"
    ></div>
  </div>
</template>

<script>
export default {
  name: 'PageStep',
  props: {
    step: {
      type: Number,
      default: null
    }
  },
  computed: {
    maxSteps() {
      return 5;
    }
  }
};
</script>

<style lang="scss" scoped>
.page-steps {
  display: flex;
  justify-content: center;
  margin-top: 20px;
  margin-left: 5px;

  @media ($desktop) {
    margin-top: 10px;
  }
}

.step {
  width: 5px;
  height: 5px;
  margin-right: 5px;
  background-color: #e8e8e8;
  border-radius: 50%;

  &.active {
    position: relative;
    width: 7px;
    height: 7px;
    top: -1px;
    background-color: #0b182c;
  }
}
</style>
