var render = function render(){var _vm=this,_c=_vm._self._c;return _c('section',[_c('div',{staticClass:"full-width"},[(_vm.hasPaymentToken && !_vm.changePaymentMethod)?_c('section',[_c('section',[(!_vm.changePaymentMethod)?_c('Agreement',{attrs:{"product":_vm.product,"checkbox-hidden":true},model:{value:(_vm.agreementChecked),callback:function ($$v) {_vm.agreementChecked=$$v},expression:"agreementChecked"}}):_vm._e(),_c('button',{class:[
            'order-button',
            { 'extra-visibility': _vm.product.category === 'extrasynlighet' }
          ],attrs:{"disabled":_vm.isLoading},on:{"click":function($event){return _vm.pay()}}},[(_vm.payText)?_c('span',[_vm._v(" "+_vm._s(_vm.payText)+" ")]):_c('span',[_vm._v(_vm._s(_vm.$t('klarna_button_action')))])]),(!_vm.isLoading)?_c('a',{staticClass:"change-payment-method",on:{"click":function($event){return _vm.restart()}}},[_vm._v(" "+_vm._s(_vm.$t('payment_new_method_action'))+" ")]):_vm._e()],1)]):_vm._e(),_c('section',{directives:[{name:"show",rawName:"v-show",value:(!_vm.isLoadingDropinComponent),expression:"!isLoadingDropinComponent"}]},[_c('form',{on:{"submit":function($event){$event.stopPropagation();$event.preventDefault();_vm.changePaymentMethod ? _vm.createToken() : _vm.createTokenAndpay()}}},[_c('div',{attrs:{"id":"klarna-container"}}),(!_vm.hasPaymentToken)?_c('div',[(!_vm.changePaymentMethod)?_c('Agreement',{attrs:{"product":_vm.product},model:{value:(_vm.agreementChecked),callback:function ($$v) {_vm.agreementChecked=$$v},expression:"agreementChecked"}}):_vm._e(),_c('button',{staticClass:"order-button",attrs:{"disabled":_vm.isLoading ||
              _vm.isLoadingDropinComponent ||
              _vm.isUpdatingSession ||
              (!_vm.agreementChecked && !_vm.changePaymentMethod),"type":"submit"}},[_vm._v(" "+_vm._s(_vm.$t('payment_continue_action'))+" ")])],1):_vm._e()])])])])
}
var staticRenderFns = []

export { render, staticRenderFns }