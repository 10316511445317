<template>
  <button class="back-button" @click="$emit('click')">
    <div class="base-button-container">
      <BaseIcon
        icon="chevron-left-dark"
        class="back-button-icon"
        :height="20"
        :width="20"
      />
      <slot>{{ $t('general_back') }}</slot>
    </div>
  </button>
</template>

<script>
export default {
  name: 'BaseBackButton'
};
</script>

<style lang="scss" scoped>
.back-button {
  border: none;
  outline: none;
  margin-top: 36px;
  cursor: pointer;
  background-color: transparent;

  &:hover,
  &:focus {
    color: #0b182c;
    text-decoration: underline;
  }
}

.base-button-container {
  display: flex;
  align-items: center;
  border: none;
  color: #0b182c;
  font-weight: 400;

  @media ($desktop) {
    margin-bottom: 30px;
  }
}

.back-button-icon {
  display: flex;
  justify-content: flex-start;
  margin-right: 3px;
}

::v-deep(.base-icon) {
  background-position: unset !important;
}
</style>
