<template>
  <component
    :is="tag"
    :is-external="isExternal"
    :target="target"
    class="button"
    v-bind="$attrs"
    :link="link"
    v-on="$listeners"
  >
    <span
      :class="icon || iconFile ? 'icon-and-slot-container' : ''"
      :style="{
        visibility: isLoading ? 'hidden' : 'visible',
        flexDirection: iconLast ? 'row-reverse' : ''
      }"
    >
      <BaseIcon
        v-if="icon || iconFile"
        :icon="icon"
        :icon-file="iconFile"
        :rotate-deg="rotateDeg"
        :height="iconSize"
        :width="iconSize"
        class="button-icon"
      />
      <slot />
    </span>

    <div v-if="isLoading" class="spinner-container">
      <BaseSpinner />
    </div>
  </component>
</template>

<script>
import BaseLink from './BaseLink';

export default {
  name: 'BaseButton',
  components: {
    BaseLink
  },
  props: {
    link: {
      type: [String, Object],
      default: ''
    },
    icon: {
      type: String,
      default: ''
    },
    iconFile: {
      type: String,
      default: ''
    },
    iconSize: {
      type: Number,
      default: 24,
      required: false
    },
    rotateDeg: {
      type: Number,
      default: null
    },
    isExternal: {
      type: Boolean,
      default: false
    },
    target: {
      type: String,
      default: ''
    },
    isLoading: {
      type: Boolean,
      default: false
    },
    iconLast: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    tag() {
      return this.link ? BaseLink : 'button';
    }
  }
};
</script>

<style lang="scss" scoped>
// Primary button and general button styling
.button,
.button:visited {
  position: relative;
  display: inline-flex;
  justify-content: center;
  padding: 16px 25px;
  font-size: 14px;
  line-height: 1;
  font-weight: 600;
  border-radius: 5px;
  color: #fff;
  text-align: center;
  background-color: $main-blue;
  border: 1px solid $main-blue;
  cursor: pointer;
  font-family: inherit;
  outline: none;
  user-select: none;
  appearance: none;
  -webkit-appearance: none;
  transition: all 0.2s ease-in-out;

  &:hover,
  &:focus {
    &:not([disabled]) {
      background-color: #2b8cee;
      border-color: #2b8cee;
      color: #fff;
    }
  }

  &:active {
    opacity: 0.7;
  }
}

// Primary button outline
.outline {
  background-color: transparent;
  color: $main-blue;

  &:hover,
  &:focus {
    &:not([disabled]) {
      background-color: transparent;
      color: #2b8cee;
    }
  }
}

// Secondary button
.secondary,
.secondary:visited {
  background-color: #2ec973;
  border-color: #2ec973;

  &:hover,
  &:focus {
    &:not([disabled]) {
      background-color: #25ac61;
      border-color: #25ac61;
      color: #fff;
    }
  }
}

// Secondary button outline
.secondary.outline {
  background-color: transparent;
  color: #2ec973;

  &:hover,
  &:focus {
    &:not([disabled]) {
      background-color: transparent;
      color: #25ac61;
    }
  }
}

// Warning button
.warning,
.warning:visited {
  background-color: #ff3843;
  border-color: #ff3843;

  &:hover,
  &:focus {
    &:not([disabled]) {
      background-color: #d92a33;
      border-color: #d92a33;
      color: #fff;
    }
  }
}

// Warning button outline
.warning.outline {
  background-color: transparent;
  color: #ff3843;

  &:hover,
  &:focus {
    &:not([disabled]) {
      background-color: transparent;
      color: #d92a33;
    }
  }
}

// Grey button
.grey,
.grey:visited {
  background-color: #0b182c;
  border-color: #0b182c;

  &:hover,
  &:focus {
    &:not([disabled]) {
      background-color: darken(#0b182c, 10%);
      border-color: darken(#0b182c, 10%);
      color: #fff;
    }
  }
}

// Grey button outline
.grey.outline {
  background-color: transparent;
  color: #0b182c;

  &:hover,
  &:focus {
    &:not([disabled]) {
      background-color: transparent;
      color: darken(#0b182c, 10%);
    }
  }
}

// Purple button
.purple,
.purple:visited {
  background-color: #9f41e9;
  border-color: #9f41e9;

  &:hover,
  &:focus {
    &:not([disabled]) {
      background-color: #7f27c3;
      border-color: #7f27c3;
      color: #fff;
    }
  }
}

// White button
.white,
.white:visited {
  background-color: #fff;
  border-color: #f6f2f1;
  color: #0b182c;

  &:hover,
  &:focus {
    &:not([disabled]) {
      background-color: #f7f7f7;
      border-color: #f6f2f1;
      color: #0b182c;
    }
  }

  &:active {
    opacity: 1;
  }
}

// Tiny variant,
// can be used in combination with all other variants
.tiny {
  padding: 8px 19px;
  border-radius: 5px;
  font-size: 12px;
}

// Smaller variant,
// can be used in combination with all other variants
.smaller {
  padding: 11px 21px;
  border-radius: 5px;
}

// Larger variant,
// can be used in combination with all other variants
.larger {
  padding: 18px 40px;
  border-radius: 5px;
}

// A variant with more border-radius,
// can be used in combination with all other variants
.rounded {
  border-radius: 50px;
}

// A variant for filter buttons with white background
.filter-button {
  height: 40px;
  padding: 0 16px;
  flex-grow: 1;
  font-weight: 300;
  font-size: 14px;
  background: #fff;
  color: #0b182c;
  border: 1px solid #f6f2f1;

  @media ($desktop) {
    flex: 0;
    min-width: 172px;
    border: 2px solid red;
  }

  &.button,
  &.button:visited {
    background-color: #fff;
    border: 1px solid #f6f2f1;
    cursor: pointer;
    font-family: inherit;
    outline: none;
    user-select: none;
    appearance: none;
    -webkit-appearance: none;
    transition: all 0.2s ease-in-out;

    span {
      display: flex;
      align-items: center;
      white-space: nowrap;
      height: 100%;
      div {
        &.button-icon {
          top: initial;
        }
      }
    }

    &:hover,
    &:focus {
      &:not([disabled]) {
        background-color: #fff;
        border-color: #f6f2f1;
        color: #0b182c;
      }
    }
  }
}

.full-width {
  width: 100%;
}

.button[disabled] {
  //pointer-events: none;
  opacity: 0.7;
  cursor: not-allowed;
}

.icon-and-slot-container {
  display: flex;
  gap: 7px;
  position: relative;
  align-items: center;
}

.button-icon {
  position: relative;
  left: 0;
}

.spinner-container {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  height: 20px;
  width: 20px;
}
</style>
