var render = function render(){var _vm=this,_c=_vm._self._c;return _c('section',[_c('div',[(_vm.hasPaymentToken && !_vm.changePaymentMethod)?_c('section',[(!_vm.isLoading)?_c('a',{staticClass:"change-payment-method",on:{"click":function($event){return _vm.restart()}}},[_vm._v(" "+_vm._s(_vm.$t('payment_new_method_action'))+" ")]):_vm._e(),_c('div',{staticClass:"payment-button-wrapper"},[_c('p',{staticClass:"terms"},[_vm._v(" "+_vm._s(_vm.$t('payment_new_terms1'))+" "),_c('a',{attrs:{"href":_vm.$t('path_general_terms', _vm.$routeLocale),"target":"_blank"}},[_vm._v(_vm._s(_vm.$t('payment_new_terms2')))]),_vm._v(" "+_vm._s(_vm.$t('payment_new_terms3'))+" ")]),_c('BaseButton',{class:[
            'order-button full-width',
            { purple: _vm.product.category === 'extrasynlighet' }
          ],attrs:{"disabled":_vm.isLoading},on:{"click":function($event){return _vm.pay()}}},[_vm._v(" "+_vm._s(_vm.$t('payment_new_continue'))+" — "+_vm._s(_vm.product.priceInclVat)+_vm._s(_vm.$t('payment_new_currency'))+" ")])],1)]):_vm._e(),_c('section',{directives:[{name:"show",rawName:"v-show",value:(!_vm.isLoadingDropinComponent),expression:"!isLoadingDropinComponent"}]},[_c('form',{on:{"submit":function($event){$event.stopPropagation();$event.preventDefault();_vm.changePaymentMethod ? _vm.createToken() : _vm.createTokenAndpay()}}},[_c('div',{staticClass:"klarna-container",attrs:{"id":"klarna-container"}}),(!_vm.hasPaymentToken)?_c('div',{staticClass:"payment-button-wrapper"},[_c('p',{staticClass:"terms"},[_vm._v(" "+_vm._s(_vm.$t('payment_new_terms1'))+" "),_c('a',{attrs:{"href":_vm.$t('path_general_terms', _vm.$routeLocale),"target":"_blank"}},[_vm._v(_vm._s(_vm.$t('payment_new_terms2')))]),_vm._v(" "+_vm._s(_vm.$t('payment_new_terms3'))+" ")]),_c('BaseButton',{class:[
              'order-button full-width',
              { purple: _vm.product.category === 'extrasynlighet' }
            ],attrs:{"disabled":_vm.isLoading || _vm.isLoadingDropinComponent || _vm.isUpdatingSession,"type":"submit"}},[_vm._v(" "+_vm._s(_vm.$t('payment_new_continue'))+" — "+_vm._s(_vm.product.priceInclVat)+_vm._s(_vm.$t('payment_new_currency'))+" ")])],1):_vm._e()])])])])
}
var staticRenderFns = []

export { render, staticRenderFns }