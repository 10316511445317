var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{ref:"container",class:[
    'sponsored-propositions',
    { flexible: _vm.flexible, 'has-boost': _vm.primaryProposition.extraVisibility }
  ]},[_c('div',{staticClass:"sponsored-propositions-inner"},[_c('div',{staticClass:"header"},[_c('div',{staticClass:"title"},[_vm._v(" "+_vm._s(_vm.title)+" ")])]),_c('div',{ref:"scrollBox",class:[
        'slider-items',
        {
          'not-flexible': !_vm.flexible,
          flexible: _vm.flexible
        }
      ]},_vm._l((_vm.propositions),function(proposition){return _c('SponsoredPropositionsItem',{key:proposition.propositionId + 'slideritem',ref:"item",refInFor:true,staticClass:"sponsored-propositions-item",attrs:{"proposition":proposition}})}),1),(_vm.containerWidth < _vm.scrollBoxWidth)?_c('div',{staticClass:"dot-container"},[(!_vm.isMobile)?_c('div',{staticClass:"arrow-container",on:{"click":_vm.scrollToLeft}},[_c('BaseIcon',{attrs:{"icon-file":"icon-chevron-left","width":8,"height":15}})],1):_vm._e(),(_vm.isMobile)?_c('div',{staticClass:"dots"},_vm._l((_vm.propositions),function(proposition,index){return _c('div',{key:proposition.propositionId + 'dot',class:['dot', { active: _vm.currentActive === index }]})}),0):_vm._e(),(!_vm.isMobile)?_c('div',{staticClass:"arrow-container",on:{"click":_vm.scrollToRight}},[_c('BaseIcon',{staticClass:"arrow-right",attrs:{"icon-file":"icon-chevron-left","width":8,"height":15}})],1):_vm._e()]):_vm._e()]),(_vm.isAuthenticated && !_vm.primaryProposition.extraVisibility)?_c('SponsoredPropositionsCta',{attrs:{"flexible":_vm.flexible}}):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }