var render = function render(){var _vm=this,_c=_vm._self._c;return _c('AImage',{attrs:{"url":_vm.url,"classes":[
    'image',
    {
      'rounded-all-left': !_vm.position && _vm.mode !== 'card-mobile',
      'rounded-up-left': _vm.position === 'upper' && _vm.mode !== 'card-mobile',
      'rounded-down-left': _vm.position === 'lower' && _vm.mode !== 'card-mobile',
      'rounded-all-upper': _vm.position === 'upper' && _vm.mode === 'card-mobile',
      'placeholder-img': _vm.isPlaceholderImg
    }
  ],"styles":_vm.styles,"loading":"lazy"},on:{"mouseenter":function($event){_vm.hovered = true},"mouseleave":function($event){_vm.hovered = false}}})
}
var staticRenderFns = []

export { render, staticRenderFns }