<template>
  <div ref="propositionSwitcher" class="proposition-switcher-wrapper">
    <div class="proposition-switcher" @click.exact.stop="toggleSwitcher">
      <Spinner v-if="loading" />
      <div class="show-all-label">
        <BaseIcon :icon-file="'switch'" :width="20" :height="20" />
        <div>
          {{ $t('proposition_switcher_uses_as') }}
          <span class="bold">{{
            primaryProposition.residences[0].streetAddress
          }}</span>
        </div>
      </div>
      <BaseIcon :icon-file="'chevron-down'" :width="20" :height="20" />
    </div>
    <PropositionList
      v-if="!isMobile && open && propositions"
      :close="closeList"
      :propositions="activePropositions"
      used-in="my-propositions-page"
    />
    <transition name="slide-up" mode="out-in">
      <StandardModal
        v-if="isMobile && open && propositions"
        standard-modal-name="PropositionSwitcherModal"
        :close-function="closeList"
        :title="$t('proposition_switcher_pick')"
      >
        <PropositionList
          slot="content"
          :close="closeList"
          :propositions="activePropositions"
        />
      </StandardModal>
    </transition>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import StandardModal from '@/components/Modals/Standard';
import PropositionList from './PropositionList';
import Spinner from '../Loading/Spinner';
import handleClickOutside from '../../utils/handleClickOutside';

export default {
  name: 'PropositionSwitcher',

  components: {
    StandardModal,
    PropositionList,
    Spinner
  },

  data() {
    return {
      open: false
    };
  },

  computed: {
    ...mapGetters({
      propositions: 'myPropositions/propositions',
      loading: 'propositionSwitcher/loading',
      isMobile: 'screenSize/isMobile',
      primaryProposition: 'myPropositions/primaryProposition'
    }),

    activePropositions: function () {
      if (!this.propositions) return [];
      const active = this.propositions.filter(p => p.active);

      return active;
    }
  },

  beforeDestroy() {
    !this.isMobile &&
      document.removeEventListener('mouseup', this.closeOnClickOutside);
  },

  methods: {
    toggleSwitcher() {
      this.open ? this.closeList() : this.openList();
    },

    openList() {
      this.open = true;
      !this.isMobile &&
        document.addEventListener('mouseup', this.closeOnClickOutside);
    },

    closeList() {
      this.open = false;
      !this.isMobile &&
        document.removeEventListener('mouseup', this.closeOnClickOutside);
    },

    closeOnClickOutside(e) {
      const { propositionSwitcher } = this.$refs;
      handleClickOutside({
        event: e,
        elements: [propositionSwitcher],
        action: this.closeList
      });
    }
  }
};
</script>

<style lang="scss" scoped>
.proposition-switcher-wrapper {
  position: relative;
}

.proposition-switcher {
  border-radius: 6px;
  border: 1px solid #dddcdb;
  background-color: #f6f2f1;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
  padding: 16px 12px;
  width: 100%;
  position: relative;
  cursor: pointer;
}

.show-all-label {
  display: flex;
  gap: 10px;
  align-items: center;
}
</style>
