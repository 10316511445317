<template>
  <div class="cancel-step-wrapper">
    <h2 v-if="isDeleteAccount" class="title">
      {{ $t('start_delete_account_title') }}
    </h2>
    <h2 v-else class="title">{{ $t('start_title') }}</h2>
    <div v-if="isDeleteAccount">
      <p class="subtitle" v-html="deleteAccountInfoText"></p>
      <p class="subtitle">
        {{ $t('start_delete_account_subtitle') }}
      </p>
    </div>
    <p v-else class="subtitle">
      {{ $t('start_subtitle') }}
    </p>
    <div class="options-wrapper">
      <div
        v-for="(reason, reasonIndex) in reasons"
        :key="reason.key"
        class="cancel-alternative"
        @click="handleCategoryClick(reason, reasonIndex + 1)"
      >
        {{ reason.title }}
      </div>
    </div>
    <link-button @click.native="closeFunction">{{
      $t('start_link_cancel')
    }}</link-button>
  </div>
</template>

<script>
import LinkButton from '../../Buttons/Link';

export default {
  name: 'CancelSubscriptionStart',

  components: {
    LinkButton
  },

  props: {
    closeFunction: {
      type: Function,
      required: true
    },
    goToStep: {
      type: Function,
      required: true
    },
    setCancelCategory: {
      type: Function,
      required: true
    },
    free: {
      type: Boolean,
      required: true
    },
    trial: {
      type: Boolean,
      required: true
    },
    isDeleteAccount: {
      type: Boolean,
      default: false
    }
  },
  data() {
    const reasons = [
      {
        title: this.$t('start_reason_1'),
        key: 'serviceUsedToFindSwap'
      }
    ];
    if (!this.free && !this.isDeleteAccount) {
      reasons.push({
        title: this.$t('start_reason_2'),
        key: 'feedback',
        feedbackType: 'COST'
      });
    }
    reasons.push({
      title: this.$t('start_reason_3'),
      key: 'reasons'
    });
    return {
      reasons
    };
  },

  computed: {
    deleteAccountInfoText() {
      const link = `<a href="${this.$routes.MY_PROPOSITIONS}"
      onmouseover="this.style.textDecoration='underline'" 
      onmouseout="this.style.textDecoration='none'"
      >${this.$t('menu_items_link_3')}</a>`;
      if (this.$country.isCountry('de')) {
        return `${this.$t(
          'edit_profile_delete_account_modal_copy'
        )} ${link} tun.`;
      }
      return `${this.$t('edit_profile_delete_account_modal_copy')} ${link}.`;
    }
  },

  methods: {
    handleCategoryClick({ key, feedbackType }, reasonIndex) {
      this.setCancelCategory({ category: key, categoryIndex: reasonIndex });
      this.goToStep({ step: key, feedbackType });
    }
  }
};
</script>

<style lang="scss" scoped>
.cancel-step-wrapper {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 0px 20px;

  @media ($mobile) {
    padding-bottom: 50px;
  }
}

.title {
  color: $text-primary;
  @media ($mobile) {
    text-align: center;
    font-size: 1.2rem;
    margin-top: 20px;
  }

  @media ($desktop) {
    margin-top: 40px;
    font-size: 1.8rem;
  }
}

.subtitle {
  color: $text-secondary;
  font-weight: 600;
  max-width: 490px;
  line-height: 24px;
  text-align: center;
}

.options-wrapper {
  margin: 30px 0;
  width: 100%;
  max-width: 500px;

  @media ($desktop) {
    margin: 40px 0;
  }
}

.cancel-alternative {
  cursor: pointer;
  width: 100%;
  display: flex;
  margin-bottom: 20px;
  justify-content: center;
  align-items: center;
  padding: 20px 16px;
  background-color: #fff;
  border: 1px solid #d8d8d8;
  border-radius: 8px;
  text-align: center;
  color: $text-primary;
  font-size: 1rem;
  font-weight: 600;

  transition: all 0.2s ease-in-out;

  @media ($desktop) {
    font-size: 1.2rem;
  }

  &:hover {
    background-color: $lighter-blue;
    color: white;
  }
}
</style>
