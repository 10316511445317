var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('div',{staticClass:"subscription-picker"},[(
        _vm.selectedProduct && _vm.selectedProduct.category === 'annonsforlangning'
      )?_c('div',{staticClass:"subscription-options"},_vm._l((_vm.sortedProducts),function(product,index){return _c('div',{key:product.productId,class:[
          'option',
          {
            'option-is-selected':
              product.productId === _vm.selectedProduct.productId
          }
        ],on:{"click":function($event){return _vm.handleSelectProductId(product.productId)}}},[(index == 1)?_c('div',{staticClass:"option-label"},[_vm._v(" "+_vm._s(_vm.$t('subscription_picker_label'))+" ")]):_vm._e(),_c('div',{staticClass:"subscription-period"},[_vm._v(_vm._s(_vm.productTitle(product)))]),_c('div',{staticClass:"price-description"},[_vm._v(_vm._s(_vm.productDescription(product)))]),_c('div',{staticClass:"price"},[(!product.campaign)?[_vm._v(" "+_vm._s(_vm.monthlyPrice(product))+" "+_vm._s(_vm.$t('subscription_picker_currency'))+" "),_c('span',{staticClass:"month"},[_vm._v("/"+_vm._s(_vm.$t('subscription_picker_month')))])]:[_vm._v(" "+_vm._s(product.priceWithVAT)+" "+_vm._s(_vm.$t('subscription_picker_currency'))+" ")]],2),(_vm.monthlyPercentageOff(product))?_c('div',{staticClass:"price-discount"},[_vm._v(" "+_vm._s(_vm.$t('subscription_picker_save_perc_prefix'))+" "+_vm._s(_vm.monthlyPercentageOff(product))+"% ")]):_vm._e()])}),0):(
        _vm.selectedProduct && _vm.selectedProduct.category === 'extrasynlighet'
      )?_c('div',{staticClass:"choice-wrapper"},_vm._l((_vm.products),function(product){return _c('button',{key:product.id,class:[
          'choice',
          {
            'choice-selected': _vm.selectedProduct.productId == product.productId
          }
        ],on:{"click":function($event){return _vm.handleSelectProductId(product.productId)}}},[(product.quantity == 14)?_c('div',{staticClass:"choice-label"},[_vm._v(" "+_vm._s(_vm.$t('subscription_picker_choice_label'))+" ")]):_vm._e(),_c('div',{staticClass:"choice-title"},[_c('div',[_vm._v(_vm._s(_vm.$t('subscription_picker_heading')))]),_vm._v(" "+_vm._s(product.quantity)+" "+_vm._s(_vm.$t('extra_visibility_days'))+" ")]),_c('div',{staticClass:"choice-price"},[_vm._v(" "+_vm._s(product.priceInclVat)+" "+_vm._s(_vm.$t('extra_visibility_currency'))+" ")]),_c('div',{staticClass:"choice-per-day"},[_vm._v(" "+_vm._s(_vm.$t('subscription_picker_per_day', { price: _vm.pricePerDay(product) }))+" ")])])}),0):_vm._e(),(
        !_vm.displayCampaignInfo &&
        _vm.selectedProduct &&
        _vm.selectedProduct.category === 'annonsforlangning'
      )?_c('div',{staticClass:"subscription-terms-info"},[_c('strong',[_vm._v(_vm._s(_vm.$t('subscription_picker_no_lock_period')))]),_vm._v(" – "+_vm._s(_vm.$t('subscription_picker_no_lock_period_postfix'))+" ")]):_vm._e(),(_vm.displayCampaignInfo)?_c('div',{staticClass:"campaign-info"},[_c('strong',[_vm._v(_vm._s(_vm.$t('product_list_title')))]),_c('div',[_vm._v(" "+_vm._s(_vm.$t('product_list_info_1'))+" "+_vm._s(_vm.paymentPeriod)+" "+_vm._s(_vm.$t('product_list_info_2'))+" ("+_vm._s(_vm.selectedProduct.subscriptionProduct.priceInclVat)+" "+_vm._s(_vm.$t('product_list_info_3'))+"). ")])]):_vm._e()])])
}
var staticRenderFns = []

export { render, staticRenderFns }