<template>
  <div class="wrapper">
    <div class="area-container">
      <div class="area-inner">
        <PageStep :step="currentStep" />

        <PageTitle>{{ $t('image_upload_title') }}</PageTitle>

        <div class="subtitle" v-html="$t('image_upload_subtitle')"></div>
        <div v-if="swapType === 'twoOne'" class="address-indicator">
          {{
            `${residences[residenceIndex].streetAddress} ${residences[residenceIndex].streetNumber}`
          }}
        </div>
        <EditImages
          :disabled="loading"
          :proposition-id="propositionId"
          :residence-index="`${residenceIndex + 1}`"
          @numberOfImages="n => (numberOfImages = n)"
        />

        <MobileTipBox :text="$t('image_upload_tooltip_text')" />

        <NextButtonContainer>
          <BaseButton
            v-if="numberOfImages > 0"
            class="button-go-to-next-page smaller rounded"
            :icon-file="'chevron-right-white'"
            :icon-last="true"
            :icon-size="20"
            :is-loading="loading"
            @click="handleNextClick()"
          >
            {{
              swapType !== 'twoOne' || residenceIndex === 1
                ? $t('image_upload_create')
                : $t('image_upload_continue')
            }}
          </BaseButton>
          <BaseButton
            v-else
            :disabled="loading"
            class="button-go-to-next-page smaller rounded"
            :icon-file="'chevron-right-white'"
            :icon-last="true"
            :icon-size="20"
            :is-loading="loading"
            @click="handleNextClick()"
          >
            {{ $t('image_upload_skip') }}
          </BaseButton>
        </NextButtonContainer>
      </div>
    </div>
    <div v-if="!isMobile" class="image-container"></div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import PageTitle from '@/components/Signup/PageTitle';
import MobileTipBox from '@/components/Signup/MobileTipBox.vue';
import PageStep from '@/components/Signup/PageStep.vue';
import NextButtonContainer from '@/components/Signup/NextButtonContainer.vue';
import { calculateProgress } from '@/utils/progress';
import { CURRENT_STEPS } from '@/store/modules/signup';
import events from '@/utils/events';
import EditImages from '@/components/Signup/ImageUpload/EditImages.vue';

export default {
  name: 'ImageUpload',

  components: {
    PageTitle,
    MobileTipBox,
    PageStep,
    EditImages,
    NextButtonContainer
  },

  data() {
    return {
      residenceIndex: 0,
      CURRENT_STEPS,
      showErrors: false,
      numberOfImages: 0
    };
  },

  computed: {
    ...mapGetters({
      isMobile: 'screenSize/isMobile',
      swapType: 'signup/swapType',
      residences: 'signup/residences',
      currentStep: 'signup/currentStep',
      loading: 'signup/loading',
      proposition: 'signup/proposition'
    }),

    propositionId() {
      return this.proposition ? `${this.proposition.propositionId}` : null;
    }
  },

  watch: {
    $route: function () {
      const { index } = this.$route.params;
      this.residenceIndex = parseInt(index, 10) - 1;
    }
  },

  async mounted() {
    const { index } = this.$route.params;
    this.residenceIndex = parseInt(index, 10) - 1;
    this.setCurrentStep({ step: CURRENT_STEPS.IMAGE_UPLOAD });
    this.scrollToTop();

    calculateProgress(this.currentStep, this.swapType, index);

    events.emitEvent(
      events.eventTypes.PAGEVIEW,
      `Signup Image Upload ${index}`
    );
  },

  methods: {
    ...mapActions({
      setCurrentStep: 'signup/setCurrentStep',
      finishSignup: 'signup/finishSignup'
    }),

    scrollToTop() {
      window.scrollTo(0, 0);
    },

    async handleNextClick() {
      if (this.swapType === 'twoOne' && this.residenceIndex === 0) {
        this.$router.push(
          `${this.$t('path_image_upload', this.$routeLocale)}/2`
        );
      } else {
        await this.finishSignup();
      }
    }
  }
};
</script>

<style lang="scss" scoped>
.wrapper {
  position: relative;
  max-width: 100vw;
  width: 100%;
  margin: 0;
  padding: 0 16px;
  display: flex;
  padding-bottom: calc(120px + env(safe-area-inset-bottom));
  background-color: #faf9f8;
  min-height: 100vh;

  @media ($desktop) {
    padding: 0;
  }
}

.area-container {
  flex: 4;
  flex-shrink: none;
  display: flex;
  justify-content: center;

  @media ($desktop) {
    overflow-y: auto;
    overflow-y: overlay;
  }
}

.area-inner {
  width: 100%;
  max-width: 450px;

  @media ($desktop) {
    padding: 20px;
  }
}

.image-container {
  flex: 6;
  height: calc(100vh - env(safe-area-inset-bottom));
  display: flex;
  justify-content: center;
  align-items: flex-end;
  background-position: center;
  background-size: cover;
  background-image: url('@/assets/img/1.jpg');
}

.subtitle {
  margin-top: -12px;
  margin-bottom: 24px;
}

.address-indicator {
  margin-top: -10px;
  margin-bottom: 20px;
  text-align: center;
  font-weight: 600;
  color: $text-primary;
  font-size: 1.2rem;
}

.input-wrapper {
  width: 100%;
  display: flex;

  @media ($desktop) {
    max-width: 600px;
    margin-left: auto;
    margin-right: auto;
  }
}

.form-group {
  @media (min-width: 1200px) {
    display: flex;

    .form-group-first-child {
      margin-right: 4px;
    }

    .form-group-last-child {
      margin-left: 4px;
    }
  }
}

.button-go-to-next-page {
  margin-bottom: env(safe-area-inset-bottom);
  margin-left: auto;
  padding: 12px 20px 12px 26px;
  font-size: 16px;
}
</style>
