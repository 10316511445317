var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"swap-list-item block-pulse"},[_c('div',{staticClass:"column"},[_c('div',{class:['favourite-icon block']})]),_vm._m(0),_vm._m(1),_vm._m(2),_vm._m(3),_vm._m(4),_vm._m(5),_vm._m(6),_vm._m(7)])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"column image-address-column"},[_c('div',{staticClass:"swap-list-item-image"}),_c('div',{staticClass:"address-info-wrapper"},[_c('div',{staticClass:"address-info"},[_c('div',{staticClass:"address block"},[_vm._v("address")]),_c('div',{staticClass:"action-button block"},[_vm._v("actionbtn")])])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"column"},[_c('div',{staticClass:"block"},[_vm._v("0")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"column"},[_c('div',{staticClass:"block"},[_vm._v("0")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"column"},[_c('div',{staticClass:"block"},[_vm._v("0")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"column rent-column"},[_c('div',{staticClass:"block"},[_vm._v("0kr")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"column postal-area-column"},[_c('div',{staticClass:"block"},[_vm._v("postalarea")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"column interest-column"},[_c('div',{staticClass:"interest-area"},[_c('div',{staticClass:"interest-progress block"},[_vm._v("0%")])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"column min-width-95"},[_c('div',{staticClass:"block"},[_vm._v("btns")])])
}]

export { render, staticRenderFns }