import actions from './actions';
import getters from './getters';
import mutations from './mutations';

export const CURRENT_STEPS = {
  WISH: 1,
  WISH_AREA: 2,
  SIGNUP: 3,
  PROPOSITION: 4,
  IMAGE_UPLOAD: 5
};

const initialState = () => ({
  swapType: 'oneOne',
  name: '',
  email: '',
  emailValid: false,
  loading: false,
  residences: [],
  wishes: [],
  swapTimeFrame: null,
  signupError: '',
  loginError: '',
  proposition: null,
  ssoProfile: null,
  searchLoading: false,
  searchNoResultsFound: false,
  searchResultWishAreas: [],
  searchTerm: '',
  wishAreaOptions: [],
  coordinates: [],
  selectedSuggestions: [],
  searchAddressData: {
    results: [],
    addresses: []
  },
  selectedAreas: [
    [], // wish 1
    [] // wish 2
  ],
  polygons: [
    {}, // wish 1
    {} //wish 2
  ],
  numberOfMatchingApartments: 0,
  currentStep: CURRENT_STEPS.WISH,
  lastFinishedStep: null,
  inSignup: false
});

const state = initialState;

export default {
  namespaced: true,
  initialState,
  state,
  actions,
  getters,
  mutations
};
