<template>
  <div class="cancel-step-wrapper">
    <spinner v-if="cancelSubscriptionLoading" />

    <standard-modal v-if="offerModalOpen" :close-function="closeOfferModal">
      <div slot="content" class="modal-content">
        <spinner v-if="cancelSubscriptionLoading" />
        <div class="offer-label">{{ $t('feedback_offer') }}</div>
        <div class="offer-sublabel">
          {{ $t('feedback_sublabel') }}
        </div>
        <div class="offer">
          <div class="cheapest-tag">{{ $t('feedback_tag') }}</div>
          <div class="offer-price-wrapper">
            <div class="offer-days">
              {{ $t('feedback_offer_days') }}
            </div>
            <div class="offer-price" style="font-weight: bold">
              {{ $t('feedback_price', { price: offerGroup }) }}
            </div>
          </div>

          <div class="offer-info">
            <span
              >{{ $t('feedback_save_text') }}
              <span style="color: red; font-weight: bold">{{
                $t('feedback_save_amount')
              }}</span></span
            >
          </div>

          <div class="offer-button-wrapper">
            <primary-button
              :disabled="cancelSubscriptionLoading"
              class="offer-button"
              :accent="true"
              @click.native="acceptCancelOffer"
              >{{
                cancelSubscriptionLoading
                  ? $t('feedback_cancel_loading')
                  : $t('feedback_cancel_yes')
              }}</primary-button
            >
          </div>

          <div class="terms">
            {{ $t('feedback_terms', { price: offerGroup }) }}
          </div>
        </div>

        <div class="cancel-button-wrapper">
          <link-button
            :disabled="cancelSubscriptionLoading"
            @click.native="cancelPropositionWithFeedback"
            >{{
              cancelSubscriptionLoading
                ? $t('feedback_cancel_loading')
                : $t('feedback_cancel_no')
            }}</link-button
          >
        </div>
      </div>
    </standard-modal>

    <h2 v-if="feedbackType === 'DETAILS'" class="title">
      {{ $t('feedback_title_details') }}
    </h2>
    <h2 v-else-if="feedbackType === 'OTHER_SERVICE'" class="title">
      {{ $t('feedback_other_service_title') }}
    </h2>
    <h2 v-else class="title">
      {{ $t('feedback_title') }}
    </h2>

    <textarea
      v-if="feedbackType === 'DETAILS'"
      rows="10"
      class="reason-area"
      :value="feedback"
      :placeholder="
        isDeleteAccount
          ? $t('feedback_delete_account_reason')
          : $t('feedback_reason')
      "
      @input="handleInput"
    ></textarea>

    <textarea
      v-else-if="feedbackType === 'OTHER_SERVICE'"
      rows="5"
      class="reason-area"
      :value="feedback"
      :placeholder="$t('feedback_other_service_placeholder')"
      @input="handleInput"
    ></textarea>

    <div v-else class="input-wrapper">
      <div class="input-box">
        <input
          :value="feedback"
          type="number"
          :placeholder="$t('feedback_example')"
          @input="handleInput"
        />
      </div>
      <span>{{ $t('feedback_currency_short') }}</span>
    </div>

    <div
      v-if="free || trial || feedbackType === 'OTHER_SERVICE'"
      class="button-wrapper"
    >
      <primary-button
        v-if="isDeleteAccount"
        :disabled="cancelSubscriptionLoading || !cancelButtonReady"
        style="margin-top: 20px"
        @click.native="handleDeleteAccountClick"
        >{{ $t('feedback_delete_account_action_button') }}</primary-button
      >
      <primary-button
        v-else
        :disabled="cancelSubscriptionLoading || !cancelButtonReady"
        style="margin-top: 20px"
        @click.native="handleCancelClick"
        >{{ $t('feedback_action_button') }}</primary-button
      >
      <link-button @click.native="closeFunction">{{
        $t('feedback_cancel_button')
      }}</link-button>
    </div>

    <div v-else class="button-wrapper">
      <MRadioButtons
        v-if="!isDeleteAccount"
        :values="[
          {
            key: 'later',
            active: !cancelImmediatelyChecked,
            value: renderCancel(
              $t('feedback_let_be', {
                date: currentProposition.paidUntil
              }),
              $t('feedback_subscribtion_ends')
            )
          },
          {
            key: 'immediately',
            active: cancelImmediatelyChecked,
            value: renderCancel(
              $t('feedback_cancel_immediately'),
              $t('feedback_both_ends')
            )
          }
        ]"
        :wrapper-classes="['container-around']"
        :classes="['large']"
        style="margin: 0 auto"
        @change="handleCheck"
      />

      <div v-if="cancelImmediatelyChecked" class="cancel-immediately-info">
        <div v-html="$t('feedback_cancel_immediately_info_1')"></div>
        <br />
        <br />
        <span>{{ $t('feedback_cancel_immediately_info_2') }}</span>
      </div>

      <primary-button
        v-if="isDeleteAccount"
        :disabled="cancelSubscriptionLoading || !cancelButtonReady"
        style="margin-top: 20px"
        @click.native="handleDeleteAccountClick"
        >{{ $t('feedback_delete_account_action_button') }}</primary-button
      >
      <primary-button
        v-else
        :disabled="cancelSubscriptionLoading || !cancelButtonReady"
        style="margin-top: 20px"
        @click.native="handleCancelClick"
        >{{ $t('feedback_action_button') }}</primary-button
      >
      <link-button @click.native="closeFunction">{{
        $t('feedback_cancel_button')
      }}</link-button>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import PrimaryButton from '../../Buttons/Primary';
import LinkButton from '../../Buttons/Link';
import MRadioButtons from '@/components/atomic/molecules/m-radio-buttons';
import Spinner from '../../Loading/Spinner';
import StandardModal from '@/components/Modals/Standard';

export default {
  name: 'CancelSubscriptionFeedback',

  components: {
    LinkButton,
    PrimaryButton,
    Spinner,
    StandardModal,
    MRadioButtons
  },

  props: {
    closeFunction: {
      type: Function,
      required: true
    },
    feedback: {
      type: String,
      required: true
    },
    setCancelFeedback: {
      type: Function,
      required: true
    },
    sendFeedback: {
      type: Function,
      required: true
    },
    cancelImmediatelyChecked: {
      type: Boolean,
      required: true
    },
    setCancelImmediatelyChecked: {
      type: Function,
      required: true
    },
    cancelSubscription: {
      type: Function,
      required: true
    },
    cancelSubscriptionLoading: {
      type: Boolean,
      required: true
    },
    goToStep: {
      type: Function,
      required: true
    },
    isDeleteAccount: {
      type: Boolean,
      default: false
    },
    feedbackType: {
      type: String,
      required: true
    },
    free: {
      type: Boolean,
      required: true
    },
    trial: {
      type: Boolean,
      required: true
    }
  },

  data() {
    return {
      offerModalOpen: false,
      offerGroup: '199'
    };
  },

  computed: {
    ...mapGetters({
      cancelButtonReady: 'handleSubscription/cancelButtonReady',
      currentProposition: 'handleSubscription/currentProposition',
      currentSubscription: 'handleSubscription/currentSubscription',
      user: 'app/user',
      showCancelOffer: 'handleSubscription/showCancelOffer'
    })
  },
  mounted() {
    this.offerGroup = '199';
  },
  methods: {
    ...mapActions({
      blockButton: 'handleSubscription/blockButton',
      acceptCancelOffer: 'handleSubscription/acceptCancelOffer',
      addAccountInGdprDeleteJobList: 'app/addAccountInGdprDeleteJobList'
    }),

    cancelPropositionWithFeedback: function () {
      if (!this.cancelButtonReady) return;
      this.blockButton();

      this.sendFeedback(false);

      this.cancelSubscription({
        cancelImmediately:
          this.isDeleteAccount ||
          this.free ||
          this.trial ||
          this.feedbackType === 'OTHER_SERVICE'
            ? true
            : this.cancelImmediatelyChecked
      });
    },

    handleInput: function (e) {
      this.setCancelFeedback({ feedback: e.target.value });
    },

    handleCheck: function ({ key }) {
      const cancelImmediately = key === 'immediately';
      this.setCancelImmediatelyChecked({ checked: cancelImmediately });
    },

    handleCancelClick() {
      if (
        this.showCancelOffer &&
        this.currentSubscription?.paymentMethod === 'Klarna'
      ) {
        this.openOfferModal();
      } else {
        this.cancelPropositionWithFeedback();
      }
    },

    async handleDeleteAccountClick() {
      if (!this.cancelButtonReady) return;
      this.blockButton();

      this.goToStep({ step: 'confirmationCancelAccount' });

      await this.sendFeedback(true);
      await this.addAccountInGdprDeleteJobList();

      setTimeout(() => {
        this.$router.replace(this.$routes.LOGOUT);
      }, 4000);
    },

    openOfferModal() {
      this.offerModalOpen = true;
    },

    closeOfferModal() {
      this.offerModalOpen = false;
    },

    renderCancel: function (text, subtitle) {
      return `<div><b>${text}</b></div><div style="color:#878b9d;margin-top:7px;">${subtitle}</div>`;
    }
  }
};
</script>

<style lang="scss" scoped>
.cancel-step-wrapper {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 0px 20px;

  @media ($mobile) {
    padding-bottom: 50px;
  }
}

.reason-area {
  margin-top: 20px;
  width: 100%;
  max-width: 500px;
  border: 1px solid $text-secondary;
  outline: none;
  resize: none;
  padding: 12px;
  border-radius: 5px;
  font-size: 1.1rem;
}

.button-wrapper {
  margin: 30px 0;
  display: flex;
  flex-direction: column;

  & > * {
    margin-bottom: 10px;
  }
}

.cancel-immediately-info {
  max-width: 500px;
  padding: 20px;
  background-color: $info-gray;
  border-radius: 8px;
  text-align: center;
  margin: 20px auto;
  line-height: 130%;
  font-weight: 600;
}

.input-wrapper {
  margin: 40px 0;
  display: flex;
  align-items: center;
  font-size: 1.3rem;

  & > *:not(:first-child) {
    margin-left: 5px;
  }
}

.input-box {
  width: 125px;
  height: 40px;
  border: 2px solid $text-secondary;
  border-radius: 5px;

  & > input {
    border: 0;
    height: 100%;
    width: 100%;
    padding: 3px 6px;
    border-radius: 5px;
  }
}

.title {
  color: $text-primary;
  @media ($mobile) {
    text-align: center;
    font-size: 1.2rem;
    margin-top: 20px;
  }

  @media ($desktop) {
    margin-top: 40px;
    font-size: 1.8rem;
  }
}

.modal-content {
  padding: 0 50px;

  @media ($mobile) {
    padding: 0 14px;
  }
}

.offer-title {
  font-size: 1.4rem;
  font-weight: 600;
}

.offer-text {
  color: white;
  z-index: 99;
  font-weight: 700;
  font-size: 1.1rem;
  transform: rotate(15deg);
}

.subscription-info {
  margin: 5px 0;
  font-size: 1.1rem;
}

.offer {
  display: flex;
  align-items: center;
  justify-content: space-between;
  border: 1px solid rgba(0, 0, 0, 0.17);
  border-radius: 8px;
  padding: 40px 20px;
  margin-top: 15px;
  flex-direction: column;
  width: 100%;
  max-width: 800px;
  position: relative;

  &:first-of-type {
    margin-top: 60px;
  }

  &-price-wrapper {
    min-width: 300px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    position: relative;
  }

  &-days {
    font-size: 1.5rem;
    font-weight: bold;
  }

  &-price {
    margin-top: 9px;
    font-weight: 600;
    color: red;
  }

  &-info {
    font-weight: 600;
    display: flex;
    width: 100%;
    flex-direction: column;
    margin: 0 48px 0 0;
    margin: 25px 0;
    text-align: center;
    justify-content: center;
    align-items: center;
    font-size: 0.9rem;
    line-height: 1.3rem;
  }

  &-button {
    min-width: 260px;
    padding: 0px 5px;
  }
}

.cheapest-tag {
  position: absolute;
  top: -31px;
  left: 50px;
  height: 30px;
  background-color: #f5fcf8;
  border: 1px solid $accent-green;
  border-bottom: none;
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: $accent-green;
  padding: 0 25px;
  font-weight: 700;
  font-size: 0.8rem;

  @media ($mobile) {
    left: calc(50% - 80px);
  }
}

.terms {
  font-size: 0.8rem;
  text-align: center;
  margin-top: 14px;
}

.cancel-button-wrapper {
  display: flex;
  justify-content: center;
  margin-top: 24px;
}

.offer-label {
  font-weight: 600;
  text-align: center;
  font-size: 2.2rem;
  margin-bottom: 12px;
}

.offer-sublabel {
  color: $text-primary;
  text-align: center;

  font-size: 1.2rem;
  margin-bottom: 54px;
}
</style>
