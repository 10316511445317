var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('BaseOverlay',{nativeOn:{"click":function($event){_vm.closeFunction();
      _vm.locked ? false : _vm.$emit('close');}}}),_c('transition',{attrs:{"name":_vm.isMobile ? 'slide-up' : 'fade',"mode":"out-in","appear":""}},[_c('div',{staticClass:"standard-modal"},[(_vm.showHeader)?_c('div',{class:[
          'header',
          { 'no-shadow': _vm.noShadow },
          { 'with-border': _vm.withBorder }
        ]},[(_vm.actionTitle)?_c('div',{staticClass:"action-button",on:{"click":_vm.actionFunction}},[_vm._v(" "+_vm._s(_vm.actionTitle)+" ")]):_vm._e(),_c('div',{staticClass:"title"},[_vm._v(_vm._s(_vm.title))]),_c('div',{staticClass:"close-btn",on:{"click":function($event){_vm.closeFunction();
            _vm.locked ? false : _vm.$emit('close');}}},[_c('menu-icon',{attrs:{"icon":"close"}})],1)]):_vm._e(),_c('div',{staticClass:"standard-content"},[_vm._t("content")],2)])])],1)
}
var staticRenderFns = []

export { render, staticRenderFns }