<template>
  <div class="swap-type-wrapper">
    <!-- <div v-if="collapsed" class="title">
      {{ $t("swap_type_selector_more_alternatives") }}:
    </div> -->
    <div class="label-and-button">
      <div class="label">{{ $t('swap_type_selector_more_alternatives') }}:</div>
      <div
        v-if="collapsed"
        :class="[
          'swaptype-dropdown-button',
          {
            'one-one': swapType === 'oneOne',
            'one-two': swapType === 'oneTwo',
            'two-one': swapType === 'twoOne',
            collapsed: !showMoreAlternatives,
            expanded: showMoreAlternatives
          }
        ]"
        @click="handleShowMoreAlternatives()"
      >
        <!-- <div v-if="showMoreAlternatives" class="title">
        {{ $t("swap_type_selector_more_alternatives") }}
      </div> -->
        <div class="selected-swaptype">
          {{ selectedType }}
        </div>
      </div>
    </div>
    <transition
      name="expand"
      @enter="enter"
      @after-enter="afterEnter"
      @leave="leave"
    >
      <div v-if="showMoreAlternatives" class="swap-type-selector">
        <div
          :class="['swap-type one-one', { selected: swapType === 'oneOne' }]"
          @click="handleSetSwapType('oneOne')"
        >
          <div class="swap-type-visual-radio-button">
            <span></span>
          </div>
          <!-- <div class="swap-type-icon icon-one-one"></div> -->
          <div class="swap-type-title">
            {{ $t('swap_type_type_title_1') }}
          </div>
        </div>

        <transition
          name="expand"
          @enter="enter"
          @after-enter="afterEnter"
          @leave="leave"
        >
          <div v-if="swapType === 'oneOne'" class="swap-type-text">
            {{ $t('swap_type_type_text_1') }}
          </div>
        </transition>

        <div
          :class="['swap-type one-two', { selected: swapType === 'oneTwo' }]"
          @click="handleSetSwapType('oneTwo')"
        >
          <div class="swap-type-visual-radio-button">
            <span></span>
          </div>
          <!-- <div class="swap-type-icon icon-one-two"></div> -->
          <div class="swap-type-title">
            {{ $t('swap_type_type_title_3') }}
          </div>
        </div>

        <transition
          name="expand"
          @enter="enter"
          @after-enter="afterEnter"
          @leave="leave"
        >
          <div v-if="swapType === 'oneTwo'" class="swap-type-text">
            {{ $t('swap_type_type_text_3') }}
          </div>
        </transition>

        <div
          :class="['swap-type two-one', { selected: swapType === 'twoOne' }]"
          @click="handleSetSwapType('twoOne')"
        >
          <div class="swap-type-visual-radio-button">
            <span></span>
          </div>
          <!-- <div class="swap-type-icon icon-two-one"></div> -->
          <div class="swap-type-title">
            {{ $t('swap_type_type_title_2') }}
          </div>
        </div>

        <transition
          name="expand"
          @enter="enter"
          @after-enter="afterEnter"
          @leave="leave"
        >
          <div
            v-if="swapType === 'twoOne'"
            :class="['swap-type-text', { 'text-open': swapType === 'twoOne' }]"
          >
            {{ $t('swap_type_type_text_2') }}
          </div>
        </transition>
      </div>
    </transition>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';

export default {
  name: 'SwapTypeSelector',

  props: {
    collapsed: {
      type: Boolean,
      default: false
    }
  },

  data() {
    return {
      showMoreAlternatives: true
    };
  },

  computed: {
    ...mapGetters({
      isMobile: 'screenSize/isMobile',
      swapType: 'signup/swapType',
      currentStep: 'signup/currentStep'
    }),

    selectedType() {
      switch (this.swapType) {
        default:
        case 'oneOne':
          return this.$t('swap_type_type_title_1');
        case 'twoOne':
          return this.$t('swap_type_type_title_2');
        case 'oneTwo':
          return this.$t('swap_type_type_title_3');
      }
    }
  },

  created() {
    this.showMoreAlternatives = !this.collapsed;
  },

  methods: {
    ...mapActions({
      setSwapType: 'signup/setSwapType'
    }),

    handleSetSwapType(swapType) {
      this.setSwapType({ swapType });
    },

    handleShowMoreAlternatives() {
      this.showMoreAlternatives = !this.showMoreAlternatives;
    },

    enter(element) {
      const width = getComputedStyle(element).width;

      element.style.width = width;
      element.style.position = 'absolute';
      element.style.visibility = 'hidden';
      element.style.height = 'auto';

      const height = getComputedStyle(element).height;

      element.style.width = null;
      element.style.position = null;
      element.style.visibility = null;
      element.style.height = 0;

      getComputedStyle(element).height;

      requestAnimationFrame(() => {
        element.style.height = height;
      });
    },

    afterEnter(element) {
      element.style.height = 'auto';
    },

    leave(element) {
      const height = getComputedStyle(element).height;

      element.style.height = height;

      getComputedStyle(element).height;

      requestAnimationFrame(() => {
        element.style.height = 0;
      });
    }
  }
};
</script>

<style lang="scss" scoped>
.wrapper {
  max-width: 100vw;
  width: 100%;
  margin: 0;
  display: flex;
  padding-bottom: calc(90px + env(safe-area-inset-bottom) * 2);

  @media ($desktop) {
    padding-top: $desktop-menu-height;
    padding-bottom: 0;
  }
}

.label-and-button {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
}

.label {
  line-height: 1.5;
  letter-spacing: -0.176px;
}

.area-container {
  flex: 4;
  flex-shrink: none;
  display: flex;
  justify-content: center;
}

.area-inner {
  width: 100%;
  max-width: 450px;
  display: flex;
  flex-direction: column;
  align-items: center;

  @media ($desktop) {
    padding: 20px;
  }
}

.image-container {
  flex: 6;
  position: sticky;
  top: $desktop-menu-height;
  height: calc(100vh - #{$desktop-menu-height});
  display: flex;
  justify-content: center;
  align-items: flex-end;
  background-position: center;
  background-size: cover;
  background-image: url('@/assets/img/1.jpg');
}

.swap-type {
  border-radius: 8px;
  cursor: pointer;
  user-select: none;

  // &:not(.selected) {
  //   border: 2px solid transparent;
  // }

  padding: 8px 0;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-start;

  &-icon {
    height: 50px;
    width: 80px;
    background-position: center;
    background-size: contain;
    margin-right: 20px;
  }

  &-title {
    font-weight: 600;
  }

  &-wrapper {
    display: flex;
    flex-direction: column;
    width: 100%;
  }
}

.swap-type-visual-radio-button {
  width: 24px;
  height: 24px;
  flex-shrink: 0;
  margin-right: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  background-color: #fff;
  border: 2px solid #0b182c;

  .selected & span {
    width: 12px;
    height: 12px;
    border-radius: 50%;
    background-color: #0b182c;
  }
}

// .selected {
//   border: 2px solid;
// }

// .one-one {
//   background-color: #e7f3ff;
//   color: #409fff;
//   border-color: #409fff;
// }

// .two-one {
//   background-color: #fce5f6;
//   color: #ed63d2;
//   border-color: #ed63d2;

//   .swap-type-visual-radio-button {
//     border-color: #ed63d2;

//     span {
//       background-color: #ed63d2;
//     }
//   }
// }

// .one-two {
//   background-color: #fff8e6;
//   color: #ffc212;
//   border-color: #ffc212;

//   .swap-type-visual-radio-button {
//     border-color: #ffc212;

//     span {
//       background-color: #ffc212;
//     }
//   }
// }

// .icon-one-one {
//   background-image: url("../assets/svg/swapIcons/one_to_one.svg");
// }
// .icon-two-one {
//   background-image: url("../assets/svg/swapIcons/two_to_one.svg");
// }
// .icon-one-two {
//   background-image: url("../assets/svg/swapIcons/one_to_two.svg");
// }

.how-it-works {
  margin-top: 40px;
  padding: 20px 40px;
  background-color: #fff;
  border-radius: 8px;
  border: thin solid rgba(0, 0, 0, 0.07);

  width: 100%;

  &-title {
    font-weight: 500;
    font-size: 1.4rem;
    margin-bottom: 12px;
  }
}

.step {
  font-weight: 600;
  font-size: 0.9rem;
}

.swap-type-text {
  font-size: 0.9rem;
  user-select: none;
  margin-bottom: 10px;
}

.expand-enter-active,
.expand-leave-active {
  transition: height 0.3s ease-in-out;
  overflow: hidden;
}

.expand-enter,
.expand-leave-to {
  height: 0;
}

// .button-go-to-next-page {
//   margin-bottom: env(safe-area-inset-bottom);
// }

.title {
  font-weight: 600;
  font-size: 1rem;
  margin-bottom: 0px;
  color: $text-secondary;

  .invalid & {
    color: #ff3843;
  }
}

.swaptype-dropdown-button {
  display: inline-flex;
  padding: 8px 12px;
  justify-content: center;
  align-items: center;
  gap: 6px;
  border-radius: 8px;
  border: 1px solid #dddcdb;
  background: #fff;
  color: #0b182c;
  cursor: pointer;
  user-select: none;

  .title {
    color: $text-secondary;
    margin-right: 5px;
  }

  .selected-swaptype {
    display: flex;
    align-items: center;
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: 20px;
  }

  &::after {
    width: 20px;
    height: 15px;
    margin-right: -5px;
    content: '';
    background: url('@/assets/svg/signup/chevron.svg') no-repeat;
    background-size: contain;
  }

  // &.collapsed::after {
  //   margin-left: auto;
  //   margin-right: 10px;
  //   width: 20px;
  //   height: 15px;
  //   content: "";
  //   background: url("../assets/svg/chevron.svg") no-repeat;
  //   background-size: contain;
  // }

  // &.expanded::after {
  //   margin-left: auto;
  //   margin-right: 12px;
  //   width: 20px;
  //   height: 15px;
  //   content: "";
  //   background: url("../assets/svg/arrow-up.svg") no-repeat;
  //   background-size: contain;
  // }
}

.swap-type-icon-small {
  height: 30px;
}
</style>
