var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{class:['full-width container', _vm.isLoading ? 'item-loading' : ''],on:{"mouseenter":function($event){return _vm.$emit('mouseenter')},"mouseleave":function($event){return _vm.$emit('mouseleave')}}},[_c('div',{staticClass:"item-wrapper",class:[
      { toned: _vm.toned, marked: _vm.marked, 'list-settings': _vm.isPartOfList },
      _vm.mode
    ]},[(_vm.residencePickerEnabled)?_c('router-link',{staticClass:"full-width",class:_vm.mode,attrs:{"to":_vm.link,"custom":""},scopedSlots:_vm._u([{key:"default",fn:function({ href, navigate }){return [_c('a',{attrs:{"href":href},on:{"click":function($event){return _vm.handleClick(navigate, $event)}}},[_c('transition',{attrs:{"name":"fade-text","mode":"out-in"}},[_c('MSearchListItemAd',{key:_vm.selectedResidence,attrs:{"index":0,"ad":_vm.item.residences[_vm.selectedResidence],"item":_vm.item,"toned":_vm.toned,"mode":_vm.mode},on:{"like":function($event){return _vm.$emit('like', $event)},"likeDone":function($event){return _vm.$emit('likeDone', $event)}}})],1)],1)]}}],null,false,1809571473)}):_c('router-link',{staticClass:"full-width",class:_vm.mode,attrs:{"to":_vm.link,"custom":""},scopedSlots:_vm._u([{key:"default",fn:function({ href, navigate }){return [_c('a',{attrs:{"href":href},on:{"click":function($event){return _vm.handleClick(navigate, $event)}}},[_c('transition',{attrs:{"name":"fade","mode":"out-in"}},_vm._l((_vm.item.residences),function(ad,index){return _c('MSearchListItemAd',{key:_vm.item.propositionId + index,attrs:{"index":index,"ad":ad,"item":_vm.item,"toned":_vm.toned,"mode":_vm.mode},on:{"like":function($event){return _vm.$emit('like', $event)}}})}),1)],1)]}}])})],1),(_vm.residencePickerEnabled)?_c('div',{staticClass:"proposition-picker-wrapper",class:[
      { 'list-settings': _vm.isPartOfList, 'none-list-settings': !_vm.isPartOfList },
      _vm.mode
    ]},[_c('span',{staticClass:"proposition-picker-text"},[_vm._v(_vm._s(_vm.$t('search_two_to_one_picker'))+" ")]),_c('AButton',{attrs:{"is-dark":_vm.selectedResidence === 0,"is-default":_vm.selectedResidence !== 0,"is-small":true},on:{"click":function($event){_vm.selectedResidence = 0}}},[_vm._v("1")]),_c('AButton',{attrs:{"is-dark":_vm.selectedResidence === 1,"is-default":_vm.selectedResidence !== 1,"is-small":true,"hover-effect":false},on:{"click":function($event){_vm.selectedResidence = 1}}},[_vm._v("2")])],1):_vm._e()])
}
var staticRenderFns = []

export { render, staticRenderFns }