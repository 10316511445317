var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"interest-buttons"},[_c('div',{staticClass:"buttons-wrapper"},[_c('div',{class:[
        'interest-button not-interested',
        { 'is-not-interested': _vm.interested === false }
      ],on:{"click":function($event){return _vm.$emit('interested', false)}}},[_vm._v(" "+_vm._s(_vm.$t('interest_button_hide'))+" ")]),_c('div',{class:[
        'interest-button interested',
        { 'is-interested': _vm.interested === true }
      ],on:{"click":function($event){return _vm.$emit('interested', true)}}},[_c('BaseIcon',{staticClass:"button-icon",attrs:{"icon-file":_vm.interested ? 'icon-check3-white' : 'icon-check3',"width":14,"height":14}}),_vm._v(" "+_vm._s(_vm.$t('interest_button_interested'))+" ")],1)])])
}
var staticRenderFns = []

export { render, staticRenderFns }