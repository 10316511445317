import { SWAP_VIEW_TABS } from './index';
import { cloneDeep } from 'lodash';

function setSwapListType(state, { type }) {
  state.swapListType = type;
}

function setSwapFilterType(state, { swapFilterType }) {
  state.swapFilterType = swapFilterType;
}

function setSortType(state, { type }) {
  state.sortType = type;
}

function setSwapInterestCounts(state, swapInterestCounts) {
  state.swapInterestCounts = swapInterestCounts;
}

function setNewSwapsCount(state, count) {
  state.newSwaps = count;
}

function setUnansweredCount(state, count) {
  state.preFilterCountUnmarked = count;
  state.unansweredSwaps = count;
}

function setSwapData(
  state,
  {
    address,
    swaps,
    currentPage,
    totalPages,
    fullInterestSwaps,
    newSwaps,
    undistributed,
    unmarkedSwaps,
    filterCount,
    listPropositionId,
    preFilterCountTotal,
    preFilterCountUnmarked,
    postFilterCountTotal,
    postFilterCountWithDiffs,
    postFilterCountUnmarked,
    areaGroups,
    queueCount,
    hasMore,
    unansweredSwaps,
    answeredSwaps,
    removedSwaps
  }
) {
  state.address = address;
  state.swaps = swaps;
  state.currentPage = currentPage;
  state.totalPages = totalPages;
  state.fullInterestSwaps = fullInterestSwaps;
  state.newSwaps = newSwaps;
  state.undistributed = undistributed;
  state.unmarkedSwaps = unmarkedSwaps;
  state.filterCount = filterCount;
  state.listPropositionId = listPropositionId;
  state.preFilterCountTotal = preFilterCountTotal;
  state.preFilterCountUnmarked = preFilterCountUnmarked;
  state.postFilterCountTotal = postFilterCountTotal;
  state.postFilterCountWithDiffs = postFilterCountWithDiffs;
  state.postFilterCountUnmarked = postFilterCountUnmarked;
  state.areaGroups = areaGroups;
  state.queueCount = queueCount;
  state.hasMore = hasMore;
  state.unansweredSwaps = unansweredSwaps;
  state.answeredSwaps = answeredSwaps;
  state.removedSwaps = removedSwaps;
}

function mergeSwapData(
  state,
  {
    address,
    swaps,
    currentPage,
    totalPages,
    fullInterestSwaps,
    newSwaps,
    undistributed,
    unmarkedSwaps,
    filterCount,
    listPropositionId,
    preFilterCountTotal,
    preFilterCountUnmarked,
    postFilterCountTotal,
    postFilterCountWithDiffs,
    postFilterCountUnmarked,
    areaGroups,
    queueCount,
    hasMore,
    unansweredSwaps,
    answeredSwaps,
    removedSwaps
  }
) {
  state.address = address;
  state.swaps = [...state.swaps, ...swaps];
  state.currentPage = currentPage;
  state.totalPages = totalPages;
  state.fullInterestSwaps = fullInterestSwaps;
  state.newSwaps = newSwaps;
  state.undistributed = undistributed;
  state.unmarkedSwaps = unmarkedSwaps;
  state.filterCount = filterCount;
  state.listPropositionId = listPropositionId;
  state.preFilterCountTotal = preFilterCountTotal;
  state.preFilterCountUnmarked = preFilterCountUnmarked;
  state.postFilterCountTotal = postFilterCountTotal;
  state.postFilterCountWithDiffs = postFilterCountWithDiffs;
  state.postFilterCountUnmarked = postFilterCountUnmarked;
  state.areaGroups = areaGroups;
  state.queueCount = queueCount;
  state.hasMore = hasMore;
  state.unansweredSwaps = unansweredSwaps;
  state.answeredSwaps = answeredSwaps;
  state.removedSwaps = removedSwaps;
}

function setLoading(state, { loading }) {
  state.loading = loading;
}

function setCurrentSwapListTab(state, { type }) {
  const swapView = { ...state.swapView };
  swapView.currentTab = type;
  state.swapView = swapView;
}

function setSwapDetails(state, { swapDetails }) {
  const swapView = { ...state.swapView };
  swapView.loading = false;
  swapView.swapDetails = swapDetails;
  state.swapView = swapView;
}

function replaceThirdParty(state, { alternative }) {
  const thirdParty = cloneDeep(alternative.swapParts[2]);
  const swapViewCopy = cloneDeep(state.swapView);
  const interests = alternative.swapParts.map(sp => sp.interested);

  swapViewCopy.swapDetails.swapParts[2] = thirdParty;
  swapViewCopy.swapDetails.swapParts.forEach((sp, index) => {
    sp.interested = interests[index];
  });

  state.swapView = swapViewCopy;
}

function removeThirdPartyFromDetails(state) {
  const swapViewCopy = cloneDeep(state.swapView);
  swapViewCopy.swapDetails.swapParts[2].isRemoved = true;
  state.swapView = swapViewCopy;
}

function closeSwapDetails(state) {
  state.swapView = {
    open: false,
    currentTab: SWAP_VIEW_TABS.SWAP,
    swapDetails: {},
    loading: false
  };
}

function setSwapAlternativesLoading(state) {
  state.swapView.swapAlternativesLoading = true;
}
function setSwapAlternatives(state, { currentPage, alternatives, totalPages }) {
  const swapViewCopy = cloneDeep(state.swapView);
  swapViewCopy.swapAlternatives = {
    currentPage,
    alternatives,
    totalPages
  };
  swapViewCopy.swapAlternativesLoading = false;
  state.swapView = swapViewCopy;
}

function updateFavouriteStatus(state, { targetPropositionId, favourite }) {
  const swapsCopy = cloneDeep(state.swaps);
  const swapTargets = swapsCopy.filter(
    s => s.propositionId == targetPropositionId
  );

  if (swapTargets.length > 0) {
    for (const swapTarget of swapTargets) {
      swapTarget.isFavourite = favourite;
    }
    state.swaps = swapsCopy;
    return;
  }
}

function updateFilter(state, { filter, clearSearchTerm }) {
  state.filter = { ...filter };

  if (clearSearchTerm) {
    state.searchTerm = '';
  }
}

function toggleCheckboxFilter(state, { key }) {
  if (state.filter[key]) {
    state.filter[key] = undefined;
  } else {
    state.filter[key] = true;
  }
}

function setSwapInterest(state, { interested, targetPropositionId }) {
  const swapsCopy = cloneDeep(state.swaps);
  const targets = swapsCopy.filter(
    swap => swap.propositionId.toString() === targetPropositionId.toString()
  );

  targets.forEach(target => (target.details.interests[0] = interested));

  state.swaps = swapsCopy;

  if (state.swapView.swapDetails && state.swapView.swapDetails.swapParts) {
    const swapDetailsCopy = cloneDeep(state.swapView.swapDetails);

    swapDetailsCopy.swapParts[0].interested = interested;

    state.swapView.swapDetails = swapDetailsCopy;
  }
}

function toggleSwapItemMode(state, { mode }) {
  state.swapItemMode = mode;
}

function setSortDirection(state, { sortDirection }) {
  state.sortDirection = sortDirection;
}

function setSearchTerm(state, { value }) {
  state.searchTerm = value;
}

function toggleFilterArea(state, { areaName }) {
  let selectedAreas = state.filter.postalAreas
    ? [...state.filter.postalAreas]
    : [];

  if (selectedAreas.includes(areaName)) {
    selectedAreas = selectedAreas.filter(sa => sa !== areaName);
  } else {
    selectedAreas.push(areaName);
  }

  state.filter.postalAreas = selectedAreas;
}

function removeSwapFromArray(state, targetPropositionId) {
  state.swaps = state.swaps.filter(
    swap => swap.propositionId != targetPropositionId
  );
}

function showSwapAddedBadge(state, val) {
  state.showSwapAddedBadge = !!val;
}
function markPropositionNoInterest(state, propositionId) {
  if (
    !state.noInterestMarkedIds.includes(propositionId) &&
    !state.reverseInterestMarkedIds.includes(propositionId)
  ) {
    state.noInterestMarkedIds = [
      ...state.noInterestMarkedIds,
      propositionId.toString()
    ];
  }
}

function unmarkPropositionNoInterest(state, propositionId) {
  state.noInterestMarkedIds = state.noInterestMarkedIds.filter(id => {
    return id != propositionId;
  });
}

function markPropositionReverseInterest(state, propositionId) {
  const alreadyMarked = state.reverseInterestMarkedIds.some(
    id => id == propositionId
  );

  if (!alreadyMarked) {
    state.reverseInterestMarkedIds.push(propositionId.toString());
  }
}

function unmarkPropositionReverseInterest(state, propositionId) {
  state.reverseInterestMarkedIds = state.reverseInterestMarkedIds.filter(id => {
    return id == propositionId;
  });
}

function setSelectedFilters(state, value) {
  if (!state.selectedFilters.includes(value)) {
    state.selectedFilters.push(value);
  }
}

function removeSelectedFilter(state, value) {
  state.selectedFilters = state.selectedFilters.filter(
    filter => filter !== value
  );
}

function clearSelectedFilters(state) {
  state.selectedFilters = [];
}

export default {
  setSwapListType,
  setSortType,
  setNewSwapsCount,
  setUnansweredCount,
  setSwapData,
  mergeSwapData,
  setLoading,
  setSwapDetails,
  closeSwapDetails,
  setCurrentSwapListTab,
  setSwapAlternatives,
  setSwapAlternativesLoading,
  replaceThirdParty,
  removeThirdPartyFromDetails,
  updateFavouriteStatus,
  updateFilter,
  toggleCheckboxFilter,
  toggleSwapItemMode,
  setSwapInterest,
  setSortDirection,
  setSearchTerm,
  setSwapFilterType,
  toggleFilterArea,
  removeSwapFromArray,
  showSwapAddedBadge,
  setSwapInterestCounts,
  markPropositionNoInterest,
  unmarkPropositionNoInterest,
  markPropositionReverseInterest,
  unmarkPropositionReverseInterest,
  setSelectedFilters,
  clearSelectedFilters,
  removeSelectedFilter
};
