var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"swap-item",style:([{ transform: _vm.position ? _vm.position : '' }]),on:{"click":function($event){$event.stopPropagation();return _vm.goToProposition.apply(null, arguments)}}},[_c('div',{staticClass:"content-wrapper"},[_c('div',{staticClass:"image-wrapper"},[_c('div',{staticClass:"image",style:([
          _vm.currentShownResidence.pictures &&
          _vm.currentShownResidence.pictures.length
            ? {
                backgroundImage: `url(${_vm.$t('url_residences')}/800x800/${
                  _vm.swap.propositionId
                }/${_vm.currentShownResidence.pictures[0]})`,
                opacity: 1,
                backgroundSize: 'cover'
              }
            : ''
        ])})]),_c('div',{staticClass:"swap-item-body"},[_c('div',[_c('div',{staticClass:"address-info"},[_c('div',{staticClass:"address"},[_vm._v(_vm._s(_vm.currentShownResidence.streetAddress))]),(_vm.is2to1)?_c('div',{staticClass:"address secondary"},[_vm._v(" &   "),_c('span',{staticClass:"underline",on:{"click":function($event){$event.stopPropagation();return _vm.switchCurrentShown.apply(null, arguments)}}},[_vm._v(_vm._s(_vm.currentSecondaryResidence.streetAddress))]),_c('div',{staticClass:"shift-icon"})]):_vm._e()]),_c('div',{staticClass:"center-info"},[_c('span',{staticClass:"extra-bold"},[_vm._v(_vm._s(_vm.currentShownResidence.rooms))]),_vm._v(" "+_vm._s(_vm.$t('map_swap_item_rooms'))+" ∙ "),_c('span',{staticClass:"extra-bold"},[_vm._v(_vm._s(_vm.currentShownResidence.sqm))]),_vm._v(" "+_vm._s(_vm.$t('map_swap_item_sqm'))+" ∙ "+_vm._s(_vm.$t('map_swap_item_floor'))+" "),_c('span',{staticClass:"extra-bold"},[_vm._v(_vm._s(_vm.currentShownResidence.floor))]),_c('br'),_c('span',{staticClass:"extra-bold"},[_vm._v(_vm._s(_vm.currentShownResidence.rent))]),_vm._v(" "+_vm._s(_vm.$t('map_swap_item_cost_per_month'))+" ")])]),_c('div',{staticClass:"interest-bar"},[_c('div',{staticClass:"interest-area"},[_c('InterestIndicators',{attrs:{"interests":_vm.interests}})],1),_c('InterestButtons',{attrs:{"interested":_vm.interests[0],"target-proposition-id":_vm.swap.propositionId.toString()}})],1)])])])
}
var staticRenderFns = []

export { render, staticRenderFns }