<template>
  <SwapOverview
    v-if="!isLoading && swapDetails"
    :swap-details="swapDetails"
    :show-action-buttons="false"
  ></SwapOverview>
  <div v-else-if="isLoading" class="spinner-container">
    <ASpinner />
  </div>
  <div v-else class="broken-swap">
    {{ $t('swap_overview_swap_not_available') }}
  </div>
</template>
<script>
import services from '@/store/modules/swapList/services';
import SwapOverview from '@/components/SwapList/SwapOverview';
import ASpinner from '@/components/atomic/atoms/a-spinner';

export default {
  components: {
    SwapOverview,
    ASpinner
  },

  props: {
    propositionIds: {
      type: Array,
      required: true
    }
  },

  data() {
    return {
      swapDetails: null,
      isLoading: false
    };
  },

  computed: {
    isDirect() {
      return this.propositionIds.length <= 2;
    }
  },

  watch: {
    async propositionId1(newVal, oldVal) {
      if (newVal !== oldVal) {
        this.swapDetails = null;
        await this.fetchSwapDetails();
      }
    }
  },

  created() {
    this.fetchSwapDetails();
  },

  methods: {
    async fetchSwapDetails() {
      this.isLoading = true;

      try {
        if (this.isDirect) {
          this.swapDetails = await services.getDirectSwapDetails({
            userPropositionId: this.propositionIds[0],
            propositionId: this.propositionIds[1]
          });
        } else {
          this.swapDetails = await services.getTriangleSwapDetails({
            userPropositionId: this.propositionIds[0],
            propositionId: this.propositionIds[1],
            propositionId2: this.propositionIds[2],
            ignoreFilter: true
          });
        }
      } catch {
        // Do nothing
      }

      this.isLoading = false;
    }
  }
};
</script>
<style lang="scss" scoped>
.container {
  max-width: 1200px;
  width: 90%;
  margin: 0 auto;
}

.spinner-container {
  height: 40px;
  width: 40px;
  margin: 0 auto;
  .spinner {
    border-top-color: $main_blue;
  }
}

.broken-swap {
  text-align: center;
  font-size: 1.2rem;
  border: 1px solid rgba(28, 45, 65, 0.15);
  box-shadow: 0 2px 6px 0 rgb(0 0 0 / 5%);
  background-color: #f8f8fa;
  border-radius: 5px;
  padding: 12px;
  color: #2c3e50;
  font-style: italic;
}
</style>
