export const constants = {
  DISABLED_FEATURES: {
    PAYMENT: { fr: true },
    SWAP_APPLICATION: { nl: true, fr: true, de: true, dk: true },
    FILTERS_COOP: { nl: true, fr: true, de: true, dk: true },
    EXTRA_VISIBILITY: { fr: true },
    PROPOSITIONS_CLOSE_BY: { fr: true },
    HOW_IT_WORKS: { nl: true, fr: true, de: true, dk: true },
    AMENITIES: { fr: true, dk: true },
    SITEGAINER: { nl: true, fr: true, de: true, dk: true },
    CONDO: { nl: true, de: true, dk: true },
    BANKID: { nl: true, de: true, dk: true },
    FREEMIUM: {},
    TRUSTPILOT: { nl: true, de: true, dk: true }
  },

  KEY_VALUES: {
    LANGUAGE: {
      se: 'sv',
      nl: 'nl',
      de: 'de',
      fr: 'fr',
      dk: 'da'
    },

    DOMAIN: {
      se: 'lagenhetsbyte.se',
      nl: 'woningruil24.nl',
      de: 'wohnungsswap.de',
      fr: 'echangeappartement.fr',
      dk: 'hjembytte.dk'
    },

    CURRENCY: {
      se: 'SEK',
      nl: 'EUR',
      fr: 'EUR',
      de: 'EUR',
      dk: 'DKK'
    },

    GOOGLE_TAG_MANAGER: {
      se: 'GTM-MT4HNZJ',
      nl: 'GTM-TSSB6WD',
      de: 'GTM-MSNFSK6',
      fr: 'GTM-KS7FCGJ',
      dk: 'GTM-T4P8J9ZS'
    },

    MIXPANEL: {
      se: 'de75e43464e7b16f2badc16098587171',
      nl: 'b8f3df59c65d266262e15ba62e774743',
      de: '',
      fr: ''
    },

    SENTRY: {
      se: 'https://14f0416558fa444cae5b626c3274c27d@o75495.ingest.sentry.io/1483693',
      nl: 'https://e54bbdbcf58343c7a2827cdf2556c211@o774700.ingest.sentry.io/5796561',
      fr: 'https://e54bbdbcf58343c7a2827cdf2556c211@o774700.ingest.sentry.io/5796561',
      de: 'https://e54bbdbcf58343c7a2827cdf2556c211@o774700.ingest.sentry.io/5796561'
    },

    TRUSTPILOT: {
      se: '567710d20000ff000586e10c',
      nl: '63fbe60353044ed29109e113',
      de: '63e36e342ba9106ee584074b',
      fr: ''
    },

    MIN_ROOMS: {
      se: [1, 2, 3, 4, 5],
      nl: [1, 2, 3, 4, 5],
      de: [1, 2, 3, 4, 5],
      fr: [1, 2, 3, 4, 5],
      dk: [1, 2, 3, 4, 5]
    },

    PROPOSITION_ROOMS: {
      se: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10],
      nl: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10],
      de: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10],
      fr: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10],
      dk: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10]
    },

    PROPOSITION_FLOOR: {
      se: [
        0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19,
        20, 21, 22, 23, 24, 25
      ],
      nl: [
        0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19,
        20, 21, 22, 23, 24, 25
      ],
      de: [
        0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19,
        20, 21, 22, 23, 24, 25
      ],
      fr: [
        0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19,
        20, 21, 22, 23, 24, 25
      ],
      dk: [
        0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19,
        20, 21, 22, 23, 24, 25
      ]
    },

    MAX_RENT: {
      se: 30000,
      dk: 30000,
      nl: 3000,
      fr: 3000,
      de: 3000
    },

    MAX_RENT_SIGNUP: {
      se: [
        5000, 5500, 6000, 6500, 7000, 7500, 8000, 8500, 9000, 9500, 10000,
        10500, 11000, 11500, 12000, 12500, 13000, 14000, 15000, 16000, 17000,
        18000, 19000, 20000, 21000, 22000, 23000, 24000, 25000, 27500, 30000
      ],
      nl: [
        500, 550, 600, 650, 700, 750, 800, 850, 900, 950, 1000, 1050, 1100,
        1150, 1200, 1250, 1300, 1400, 1500, 1600, 1700, 1800, 1900, 2000, 2100,
        2200, 2300, 2400, 2500, 2750, 3000
      ],
      de: [
        500, 550, 600, 650, 700, 750, 800, 850, 900, 950, 1000, 1050, 1100,
        1150, 1200, 1250, 1300, 1400, 1500, 1600, 1700, 1800, 1900, 2000, 2100,
        2200, 2300, 2400, 2500, 2750, 3000
      ],
      fr: [
        500, 550, 600, 650, 700, 750, 800, 850, 900, 950, 1000, 1050, 1100,
        1150, 1200, 1250, 1300, 1400, 1500, 1600, 1700, 1800, 1900, 2000, 2100,
        2200, 2300, 2400, 2500, 2750, 3000
      ],
      dk: [
        5000, 5500, 6000, 6500, 7000, 7500, 8000, 8500, 9000, 9500, 10000,
        10500, 11000, 11500, 12000, 12500, 13000, 14000, 15000, 16000, 17000,
        18000, 19000, 20000, 21000, 22000, 23000, 24000, 25000, 27500, 30000
      ]
    },

    MIN_SQM: {
      se: [10, 20, 30, 40, 50, 60, 70, 80, 90, 100],
      nl: [10, 20, 30, 40, 50, 60, 70, 80, 90, 100],
      de: [10, 20, 30, 40, 50, 60, 70, 80, 90, 100],
      fr: [10, 20, 30, 40, 50, 60, 70, 80, 90, 100],
      dk: [10, 20, 30, 40, 50, 60, 70, 80, 90, 100]
    },

    MAX_RENT_INITIAL_VALUE: {
      se: 12500,
      nl: 1250,
      de: 1250,
      fr: 1250,
      dk: 12500
    },

    MAP_INITIAL_COORDINATES: {
      se: [15, 63],
      nl: [5.3, 52.2],
      de: [10.69, 50.77],
      fr: [2.1, 47.1],
      dk: [10, 56.22]
    },

    MAP_ZOOM: {
      se: 4,
      nl: 6.8,
      de: 5.5,
      fr: 5.4,
      dk: 6.5
    },

    MAP_SETTINGS: {
      se: {
        center: [12.8, 63.11],
        zoom: 4
      },

      nl: {
        center: [5.2, 52.19],
        zoom: 7
      },

      fr: {
        center: [2.1, 47.1],
        zoom: 6.5
      },

      de: {
        center: [10.69, 50.77],
        zoom: 5.5
      },

      dk: {
        center: [10.29, 56.27],
        zoom: 6.9
      }
    }
  }
};

export const currentCountry = getCountry();

export function isFeatureDisabled(feature) {
  if (!feature) return false;
  feature = feature.toUpperCase();

  const featureObject = constants.DISABLED_FEATURES[feature];
  if (!featureObject) return null;

  const countrySpecificValue = featureObject[currentCountry];
  if (!countrySpecificValue) return false;

  if (typeof countrySpecificValue === 'function') {
    return countrySpecificValue();
  }

  return countrySpecificValue;
}

export function isFeatureEnabled(feature) {
  return !isFeatureDisabled(feature);
}

export function getValue(key) {
  key = key.toUpperCase();

  const values = constants.KEY_VALUES[key];
  if (!values) return null;

  if (values[currentCountry]) return values[currentCountry];
  if (values[currentCountry] === undefined && values['default'])
    return values['default'];

  return null;
}

export function isCountry(country) {
  return currentCountry === country.toLowerCase();
}

export function getCountry() {
  //Return country from env if exist
  const countryFromEnv = process.env.VUE_APP_COUNTRY;
  if (countryFromEnv) return countryFromEnv.toLowerCase();

  // Return top-domain.
  if (window.location.hostname.includes('.')) {
    const splittedHostname = window.location.hostname.split('.');
    const topLevelDomain = splittedHostname[splittedHostname.length - 1];
    if (constants.KEY_VALUES.LANGUAGE[topLevelDomain.toLowerCase()])
      return topLevelDomain.toLowerCase();
  }

  // Default to se (if localhost for example)
  return 'se';
}

export function getCountryUpperCase() {
  return getCountry().toLocaleUpperCase();
}

export function getLanguageFromCountry(country) {
  if (!country) country = currentCountry;
  return constants.KEY_VALUES.LANGUAGE[country.toLowerCase()];
}

export default {
  isFeatureDisabled,
  isFeatureEnabled,
  getValue,
  getCountry,
  getLanguageFromCountry,
  isCountry,
  constants,
  currentCountry
};
