<template>
  <div class="interest-buttons">
    <div :class="['buttons-wrapper', { 'blur-content': blurContent }]">
      <div
        :class="['interest-btn', { interested: interested === true }]"
        @click.prevent.stop="handleYesClick"
      >
        {{ $t('interest_button_yes') }}
      </div>
      <div
        :class="['interest-btn', { 'not-interested': interested === false }]"
        @click.prevent.stop="handleNoClick"
      >
        {{ $t('interest_button_no') }}
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import { SWAP_ITEM_TYPES } from '../../store/modules/swapList';
import {
  TUTORIAL_STATUS_TYPES,
  TUTORIAL_STEPS_TYPES
} from '../../store/modules/tutorial';
import { SWAP_FILTER_TYPES } from '../../store/modules/swapList';
import events from '@/utils/helpers/events';

export default {
  name: 'SwapListInterestButtons',

  props: {
    interested: {
      type: [Boolean, null],
      default: null
    },
    targetPropositionId: {
      type: String,
      required: true
    },
    blurContent: {
      type: Boolean,
      default: false
    }
  },

  data: () => ({
    tutHasMarkedInterest: false,
    TUTORIAL_STATUS_TYPES,
    TUTORIAL_STEPS_TYPES,
    SWAP_ITEM_TYPES,
    SWAP_FILTER_TYPES
  }),

  computed: {
    ...mapGetters({
      tutInterestMarks: 'tutorial/tutInterestMarks',
      tutStatus: 'tutorial/status',
      tutActiveStep: 'tutorial/tutActiveStep',
      swapItemMode: 'swapList/swapItemMode',
      primaryPropositionIsLimited: 'myPropositions/primaryPropositionIsLimited',
      swapFilterType: 'swapList/swapFilterType'
    }),
    isTutorialLike() {
      return (
        this.tutStatus === TUTORIAL_STATUS_TYPES.STARTED &&
        this.tutInterestMarks < 3
      );
    },
    isTutorialFirstLike() {
      return (
        this.tutStatus === TUTORIAL_STATUS_TYPES.STARTED &&
        this.tutActiveStep === TUTORIAL_STEPS_TYPES.HIGHLIGHT_SWAP &&
        this.tutInterestMarks === 1
      );
    }
  },

  methods: {
    ...mapActions({
      setToast: 'toast/setToast',
      markInterest: 'interests/markInterest',
      setGlobalModal: 'ui/setGlobalModal'
    }),

    handleYesClick() {
      if (this.isTutorialLike && !this.tutHasMarkedInterest) {
        this.incrementTutInterestMarks(1);
        this.tutHasMarkedInterest = true;
      }
      if (this.interested === false && this.primaryPropositionIsLimited) {
        this.setGlobalModal('ReverseInterestActionPaywall');
        events.emitEvent(events.eventTypes.SEE, 'Paywall', {
          trigger: 'reverse-no-interest-action',
          src: 'interest-mark'
        });
        return;
      }
      this.markInterest({
        targetPropositionId: this.targetPropositionId,
        interested: true,
        where: this.isTutorialFirstLike ? 'swap-list-tutorial' : 'swap-list'
      });
    },

    handleNoClick() {
      if (this.isTutorialLike && !this.tutHasMarkedInterest) {
        this.incrementTutInterestMarks(1);
        this.tutHasMarkedInterest = true;
      }
      this.$emit('no-interest', this.$event);

      if (
        this.swapFilterType === SWAP_FILTER_TYPES.REMOVED &&
        this.interested
      ) {
        this.markInterest({
          targetPropositionId: this.targetPropositionId,
          interested: false,
          where: this.isTutorialFirstLike ? 'swap-list-tutorial' : 'swap-list'
        });
      }

      this.markInterest({
        targetPropositionId: this.targetPropositionId,
        skipRequest:
          this.swapItemMode === SWAP_ITEM_TYPES.CARDS ||
          (this.swapItemMode === SWAP_ITEM_TYPES.EXCEL &&
            this.swapFilterType === SWAP_FILTER_TYPES.REMOVED)
            ? true
            : false,
        interested: false,

        where: this.isTutorialFirstLike ? 'swap-list-tutorial' : 'swap-list'
      });
    }
  }
};
</script>

<style lang="scss" scoped>
.interest-buttons {
  display: flex;
  align-items: center;
  margin-left: auto;
}

.interest-label {
  text-align: center;
  margin-bottom: 1px;
  font-size: 12px;
  font-weight: 600;
}

.buttons-wrapper {
  display: flex;
}

.interest-btn {
  cursor: pointer;
  width: 45px;
  padding: 4px 0;
  font-size: 0.75rem;
  font-weight: 600;
  text-transform: uppercase;
  border-radius: 3px;
  margin-left: 4px;
  border: 1px solid $transfer-blue;
  color: $transfer-blue;
  text-align: center;
}

.interested {
  background-color: $transfer-green;
  border: 1px solid $transfer-green;
  color: white;
}

.not-interested {
  background-color: $sweet-red;
  border: 1px solid $sweet-red;
  color: white;
}

.blur-content {
  filter: blur(4px);
  pointer-events: none;
  user-select: none;
}
</style>
