import localForage from 'localforage';
import { lbApiBeta, lbPropositionApi } from '../../../utils/axiosConfig';
import { log } from '../../../utils/logger';
import { subscribe } from '../../../utils/socket';
import i18n from '../../../i18n';

async function init({ dispatch, getters }) {
  subscribe('primary-proposition-updated', ({ propositionId }) => {
    const currentPrimaryPropositionId = getters['primaryPropositionId'];
    if (propositionId != currentPrimaryPropositionId) {
      dispatch('handleSubscription/refreshStates', propositionId, {
        root: true
      });
    }
  });
}

async function refreshPropositions(context) {
  log('Running refreshPropositions');
  const propositions = await context.dispatch('fetchPropositions', {
    skipLoading: true
  });

  if (!propositions) {
    return;
  }

  // Update primary proposition
  const primaryProposition = propositions.find(x => x.isPrimary == 1);
  if (primaryProposition) {
    context.commit('setPrimaryProposition', {
      skipSetRequest: true,
      primaryProposition
    });
  }

  // Update currently editing proposition
  const oldCurrentlyEditingProposition =
    context.state.currentlyEditingProposition;
  const currentlyEditingProposition = propositions.find(
    x => x.propositionId == oldCurrentlyEditingProposition.propositionId
  );
  if (currentlyEditingProposition) {
    context.commit('setCurrentlyEditingProposition', {
      currentlyEditingProposition
    });
  }
}

async function fetchPropositions(context, options = {}) {
  context.commit('setFetchingPropositions', true);

  if (options && !options.skipLoading)
    context.commit('setLoading', { loading: true });

  try {
    const data = await context.dispatch(
      'myPropositions/fetchUserPropositions',
      null,
      { root: true }
    );

    window.Logger.log(data);

    context.commit('setPropositions', { propositions: data });
    context.commit('setLoading', { loading: false });
    context.commit(
      'propositionSwitcher/setLoading',
      { loading: false },
      { root: true }
    );

    context.commit('setFetchingPropositions', false);

    return data;
  } catch (e) {
    log(`fetchPropositions: ${e}`);
  }

  context.commit('setFetchingPropositions', false);

  return null;
}

async function fetchUserPropositions() {
  const { data: myPropositions } = await lbPropositionApi.get(
    '/propositions/user'
  );

  return myPropositions;
}

async function deleteProposition(context, { propositionId }) {
  context.commit('setPropositionLoading', { propositionId, loading: true });

  try {
    const res = await lbApiBeta.post(
      '/api/object/removeproposition',
      {
        propositionId: propositionId
      },
      {
        headers: {
          'Content-Type': 'application/json'
        }
      }
    );

    window.Logger.log('[Remove proposition post]: ', res);
    context.commit('setPropositionLoading', { propositionId, loading: false });
    context.commit('deleteProposition', { propositionId });
  } catch (e) {
    window.Logger.log(e);
    context.commit('setPropositionLoading', { propositionId, loading: false });
  }
}

async function fetchPrimaryPropositionId(context) {
  const proposition = await context.dispatch('fetchPrimaryProposition');
  return proposition.propositionId;
}

async function fetchPrimaryProposition() {
  try {
    const { data } = await lbPropositionApi.get('/primaryProposition');

    if (data) {
      return data;
    }
  } catch (error) {
    window.Logger.log('No primary propositin');
  }

  return null;
}

async function setPrimaryProposition(
  context,
  { primaryPropositionId, skipSetRequest }
) {
  window.Logger.log('Setting primary proposition: ', primaryPropositionId);
  context.commit(
    'propositionSwitcher/setLoading',
    { loading: true },
    { root: true }
  );

  // remove legacy site's notification store
  localForage
    .removeItem('persist:primary')
    .then(() => {
      window.Logger.log('localForage cleared');
    })
    .catch(err => window.Logger.log(`Error clearing localForage, ${err}`));

  const propositions = context.state.propositions;
  const primaryProposition = propositions.find(
    proposition =>
      primaryPropositionId && proposition.propositionId == primaryPropositionId
  );

  const oldPrimaryProposition = context.getters['primaryProposition'];

  context.commit('setPrimaryProposition', {
    primaryProposition
  });

  if (!skipSetRequest && primaryPropositionId) {
    try {
      await context.dispatch(
        'setPrimaryPropositionRequest',
        primaryPropositionId
      );
    } catch (error) {
      context.commit('setPrimaryProposition', {
        primaryProposition: oldPrimaryProposition
      });

      context.commit(
        'propositionSwitcher/setLoading',
        { loading: false },
        { root: true }
      );

      context.dispatch(
        'toast/setToast',
        {
          title: i18n.t('toast_general_error_message'),
          timer: 10
        },
        { root: true }
      );

      throw error;
    }
  }

  context.dispatch(
    'trial/setTrialBanner',
    { primaryPropositionId, propositions },
    {
      root: true
    }
  );
  context.dispatch('trial/setOutdatedTrials', { propositions }, { root: true });

  context.commit(
    'propositionSwitcher/setLoading',
    { loading: false },
    { root: true }
  );

  context.dispatch('menu/getChatOwnerId', primaryPropositionId, { root: true });

  context.dispatch(
    'notificationCenter/fetchUnseenNotificationsCount',
    primaryPropositionId,
    { root: true }
  );
  context.dispatch('menu/fetchNotifications', primaryPropositionId, {
    root: true
  });

  context.dispatch('swapList/getSwapInterestCounts', primaryPropositionId, {
    root: true
  });
}

async function setPrimaryPropositionRequest(context, id) {
  await lbPropositionApi.post(`/primaryProposition`, { propositionId: id });
}

export default {
  fetchPropositions,
  deleteProposition,
  fetchUserPropositions,
  fetchPrimaryProposition,
  fetchPrimaryPropositionId,
  setPrimaryProposition,
  setPrimaryPropositionRequest,
  refreshPropositions,
  init
};
