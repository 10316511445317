<template>
  <button class="social-login-btn" @click="logInWithApple">
    <svg
      id="Social_Icon"
      enable-background="new 0 0 512 512"
      height="512px"
      version="1.1"
      viewBox="0 0 512 512"
      width="512px"
      xml:space="preserve"
      xmlns="http://www.w3.org/2000/svg"
      xmlns:xlink="http://www.w3.org/1999/xlink"
    >
      <g>
        <path
          clip-rule="evenodd"
          d="M368.003,117.456c-44.426,0-68.012,22.217-102.915,21.764   c-34.912,0.453-58.49-21.764-102.922-21.764c-35.364,0-80.257,21.764-100.657,53.511c-37.629,58.048-34.005,121.088-18.135,186.378   C60.603,426.744,121.358,512,169.421,512c39.441,0,57.131-21.325,95.667-21.325c38.526,0,55.756,21.325,94.76,21.325   c44.425,0,97.472-73.023,120.152-137.876c-43.534-17.232-73.01-60.306-73.01-106.113c0-45.346,23.133-80.264,58.498-100.215   C443.729,138.314,402.008,117.456,368.003,117.456L368.003,117.456z M356.209,0c-63.918,6.804-111.521,60.313-107.444,121.081   C312.691,114.731,359.848,60.767,356.209,0L356.209,0z"
          fill="#000"
          fill-rule="evenodd"
        />
      </g>
    </svg>
    <span class="btn-text">{{ label }}</span>
  </button>
</template>
<script>
import events from '@/utils/events';
import i18n, { routeLocale } from '@/i18n';

export default {
  name: 'AppleRegistrationButton',
  props: {
    label: {
      type: String,
      required: true
    },
    onSuccess: {
      type: Function,
      required: true
    }
  },
  mounted() {
    const loadAppleProfileFromIdToken = async idToken => {
      this.handleAppleIdentityResponse({
        authorization: {
          id_token: idToken
        }
      });
    };

    window.loadAppleProfileFromIdToken = loadAppleProfileFromIdToken.bind(this);
  },
  methods: {
    waitForScript() {
      return new Promise(resolve => {
        const readyCheckInterval = setInterval(() => {
          if (!window.AppleID || !window.AppleID.auth) {
            return;
          }

          clearInterval(readyCheckInterval);
          resolve();
        }, 100);
      });
    },
    initApple() {
      window.AppleID.auth.init({
        clientId: i18n.t('apple_login_client_id', routeLocale),
        scope: 'name email',
        redirectURI: window.location.origin,
        state: 'SignInUserAuthenticationRequest',
        usePopup: true,
        responseType: 'code',
        responseMode: 'query'
      });
    },
    async logInWithApple() {
      events.emitEvent(events.eventTypes.CLICK, `Begin Social Registration`, {
        type: 'APPLE'
      });

      if (window.ReactNativeWebView) {
        window.ReactNativeWebView.postMessage(
          JSON.stringify({
            type: 'SOCIAL_LOGIN',
            details: 'APPLE'
          })
        );
        return;
      }

      this.loadAppleIdSDK();
      await this.waitForScript();
      this.initApple();
      const response = await window.AppleID.auth.signIn();
      this.handleAppleIdentityResponse(response);
    },

    async handleAppleIdentityResponse(response) {
      if (!response.authorization) {
        return;
      }

      let name = '';
      if (response.user && response.user.name && response.user.name.firstName) {
        name = `${response.user.name.firstName} ${response.user.name.lastName}`;
      }

      events.emitEvent(
        events.eventTypes.CLICK,
        `Complete Social Registration`,
        {
          type: 'APPLE'
        }
      );

      this.onSuccess({
        accessToken: response.authorization.id_token,
        ssoType: 'APPLE',
        name,
        profilePicture:
          'https://appleid.cdn-apple.com/appleauth/static/bin/cb2194917758/dist/assets/appleaccount_icon_color-60-light@3x.png'
      });
    },
    loadAppleIdSDK() {
      const id = 'apple-id-jssdk';
      const fjs = document.getElementsByTagName('script')[0];
      if (document.getElementById(id)) {
        return;
      }
      const js = document.createElement('script');
      js.id = id;
      js.src =
        'https://appleid.cdn-apple.com/appleauth/static/jsapi/appleid/1/en_US/appleid.auth.js';

      fjs.parentNode.insertBefore(js, fjs);
    }
  }
};
</script>
