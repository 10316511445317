<template>
  <div class="tip-box">
    <div class="tip-icon"></div>
    <div class="tip-text">
      <span class="tip-title">{{ title }}&nbsp;</span>{{ text }}
    </div>
  </div>
</template>

<script>
export default {
  name: 'DesktopTipBox',

  props: {
    text: {
      type: String,
      default: ''
    },
    title: {
      type: String,
      default: function () {
        return this.$t('desktop_tip_box_title');
      }
    }
  }
};
</script>

<style lang="scss" scoped>
.tip-box {
  background-color: white;
  border-radius: 8px;
  padding: 12px;
  display: flex;
  line-height: 120%;
  align-items: center;
  width: 80%;
  max-width: 450px;
  box-shadow: 0px 0px 12px 2px rgba(0, 0, 0, 0.1);
}

.tip-icon {
  min-height: 36px;
  min-width: 36px;
  background-position: center;
  background-size: contain;
  background-image: url('@/assets/svg/signup/lightbulb.svg');
  margin-right: 12px;
  margin-top: -3px;
}

.tip-text {
  font-size: 0.9rem;
}

.tip-title {
  font-weight: 600;
}
</style>
