var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{class:[
    'upload-photos-area',
    _vm.disabled ? 'disabled' : '',
    _vm.filesIsDragged ? 'files-is-dragged' : '',
    _vm.isLoading ? 'files-is-loading' : ''
  ]},[(_vm.isLoading)?[_c('div',{staticClass:"upload-photos-area-content"},[_c('div',{staticStyle:{"width":"28px","height":"28px","margin-bottom":"20px"}},[_c('ASpinner',{staticClass:"spinner-alt"})],1),_c('div',[_vm._v(_vm._s(_vm.$t('edit_photos_uploading')))])])]:(_vm.disabled)?[_c('div',{staticClass:"upload-block"},[_c('div',{staticClass:"upload-photos-area-content"},[_c('BaseIcon',{staticClass:"upload-photos-icon",attrs:{"icon-file":'camera-black',"width":32,"height":32}}),(_vm.explainer || _vm.isMobile)?_c('div',{staticClass:"upload-text",domProps:{"innerHTML":_vm._s(
            _vm.isMobile
              ? _vm.$t('edit_photos_upload_description_mobile')
              : _vm.$t('edit_photos_upload_description_desktop')
          )}}):_vm._e()],1)])]:[(_vm.isMobile && _vm.isAndroid)?_c('div',{staticClass:"upload-block"},[_c('input',{ref:"uploadInput",staticClass:"upload-photos-input camera",attrs:{"type":"file","accept":"image/*","capture":"environment"},on:{"change":function($event){return _vm.handleChange($event.target.files)},"dragover":function($event){_vm.filesIsDragged = true},"dragleave":function($event){_vm.filesIsDragged = false},"drop":function($event){_vm.filesIsDragged = false}}}),_c('div',{staticClass:"upload-photos-area-content"},[_c('BaseIcon',{staticClass:"upload-photos-icon",attrs:{"icon-file":'camera-black',"width":32,"height":32}}),(_vm.explainer || _vm.isMobile)?_c('div',{staticClass:"upload-text",domProps:{"innerHTML":_vm._s(_vm.$t('edit_photos_camera_description_mobile'))}}):_vm._e()],1)]):_vm._e(),_c('div',{staticClass:"upload-block"},[_c('input',{ref:"uploadInput",staticClass:"upload-photos-input upload",attrs:{"type":"file","accept":"image/*","multiple":""},on:{"change":function($event){return _vm.handleChange($event.target.files)},"dragover":function($event){_vm.filesIsDragged = true},"dragleave":function($event){_vm.filesIsDragged = false},"drop":function($event){_vm.filesIsDragged = false}}}),_c('div',{staticClass:"upload-photos-area-content"},[_c('BaseIcon',{staticClass:"upload-photos-icon",attrs:{"icon-file":'camera-black',"width":32,"height":32}}),(_vm.explainer || _vm.isMobile)?_c('div',{staticClass:"upload-text",domProps:{"innerHTML":_vm._s(
            _vm.isMobile
              ? _vm.$t('edit_photos_upload_description_mobile')
              : _vm.$t('edit_photos_upload_description_desktop')
          )}}):_vm._e()],1)])]],2)
}
var staticRenderFns = []

export { render, staticRenderFns }