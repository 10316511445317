var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{class:['toggle-wrapper']},[_c('button',{class:['toggle-option', { 'selected-no': _vm.interested === false }],attrs:{"disabled":_vm.isLoading},on:{"click":function($event){$event.stopPropagation();return (() => _vm.attemptToggleInterest(false)).apply(null, arguments)}}},[_c('BaseIcon',{staticClass:"button-icon",attrs:{"icon-file":_vm.interested === false ? 'close-new-white' : 'close-new',"width":20,"height":20}}),_vm._v(" "+_vm._s(_vm.$t('interest_toggle_no'))+" ")],1),_c('button',{class:[
      'toggle-option',
      {
        'selected-yes': _vm.interested === true,
        'background-fill-rl':
          !_vm.noInterestMarksLeft &&
          _vm.shouldAnimateYesButton &&
          _vm.interested === true
      }
    ],attrs:{"disabled":_vm.isLoading},on:{"click":function($event){$event.stopPropagation();return (() => _vm.attemptToggleInterest(true)).apply(null, arguments)}}},[_c('div',{staticClass:"button-content"},[(
          !_vm.noInterestMarksLeft &&
          _vm.shouldAnimateYesButton &&
          _vm.interested === true
        )?_c('YesClickAnimation'):_vm._e(),_c('BaseIcon',{staticClass:"button-icon",attrs:{"icon-file":_vm.interested === true ? 'icon-check3-white' : 'icon-check3',"width":20,"height":20}}),_vm._v(" "+_vm._s(_vm.$t('interest_toggle_yes'))+" ")],1)])])
}
var staticRenderFns = []

export { render, staticRenderFns }