<template>
  <component :is="tag" :to="path" class="link-sub-list-item">
    <div class="title-and-desc">
      <slot />
    </div>
    <slot name="icon-after">
      <BaseIcon
        :icon-file="'chevron-right-dark2'"
        :width="20"
        :height="20"
        class="chevron-icon"
      />
    </slot>
  </component>
</template>

<script>
export default {
  name: 'LinkSubListItem',

  props: {
    path: {
      type: String,
      default: ''
    },

    isExternal: {
      type: Boolean,
      default: false
    }
  },

  computed: {
    tag() {
      return !this.path ? 'div' : this.isExternal ? 'a' : 'router-link';
    }
  }
};
</script>

<style lang="scss" scoped>
.link-sub-list-item {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  padding: 16px 0;
  color: #0b182c;
  white-space: nowrap;
  cursor: pointer;
  border-bottom: 1.9px solid #eaecf0;
}

.title-and-desc {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  gap: 10px;
}
</style>
