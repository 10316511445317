<template>
  <div
    v-if="isMobile ? isVisible : true"
    :class="['hero-wrapper', { visible: isVisible && isMobile }]"
  >
    <div class="button-container">
      <BaseButton type="button" class="save-button" @click="$emit('saveClick')">
        <div class="save-button-content-container">
          <BaseIcon :icon-file="iconFile" :width="20" :height="20" />
          <slot />
        </div>
      </BaseButton>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';

export default {
  name: 'FloatingSaveButton',

  props: {
    isVisible: {
      type: Boolean,
      default: false
    },
    iconFile: {
      type: String,
      default: ''
    }
  },

  computed: {
    ...mapGetters({
      isMobile: 'screenSize/isMobile'
    })
  }
};
</script>

<style lang="scss" scoped>
.hero-wrapper {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 8px;
  background-color: transparent;
  width: 100%;
  position: fixed;
  bottom: calc(58px + env(safe-area-inset-bottom));
  opacity: 0;
  transition: opacity 0.5s ease, visibility 0.5s ease;
  pointer-events: none;

  @media ($desktop) {
    bottom: 0;
    opacity: 1;
    pointer-events: all;
    gap: 0;
    position: relative;
  }

  &.visible {
    opacity: 1;
    pointer-events: all;
    z-index: 1;
  }

  .button-container {
    background-color: white;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 15px 0px;
    box-shadow: 0px 4px 8px -2px #1018281a;

    @media ($desktop) {
      background-color: transparent;
      box-shadow: none;
    }
  }

  .save-button {
    font-size: 16px;
  }

  .save-button-content-container {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    gap: 8px;
  }
}
</style>
