<template>
  <TabNavigation :bg-color="tabBgColor">
    <TabNavigationItem
      v-if="showTab('unanswered')"
      :active="activeTab === MATCH_STATUS_TYPES.UNANSWERED"
      :show-count="true"
      :count="matchStatusCounts.unanswered"
      :bg-color="tabBgColor"
      @click.native="
        setActiveTab({
          activeTab: MATCH_STATUS_TYPES.UNANSWERED,
          refetchStatusCounts: true
        })
      "
    >
      {{ $t('matches_nav_unanswered') }}
    </TabNavigationItem>
    <TabNavigationItem
      v-if="showTab('unread')"
      :active="activeTab === MATCH_STATUS_TYPES.UNREAD"
      :show-count="true"
      :count="matchStatusCounts.unread"
      :bg-color="tabBgColor"
      @click.native="
        setActiveTab({
          activeTab: MATCH_STATUS_TYPES.UNREAD,
          refetchStatusCounts: true
        })
      "
    >
      {{ $t('matches_nav_unread') }}
    </TabNavigationItem>
    <TabNavigationItem
      v-if="showTab('updated')"
      :active="activeTab === MATCH_STATUS_TYPES.UPDATED"
      :show-count="true"
      :count="matchStatusCounts.updated"
      :bg-color="tabBgColor"
      @click.native="
        setActiveTab({
          activeTab: MATCH_STATUS_TYPES.UPDATED,
          refetchStatusCounts: true
        })
      "
    >
      {{ $t('matches_nav_updated') }}
    </TabNavigationItem>
    <TabNavigationItem
      :active="activeTab === MATCH_STATUS_TYPES.ACTIVE"
      :show-count="true"
      :count="matchStatusCounts.active"
      :bg-color="tabBgColor"
      @click.native="
        setActiveTab({
          activeTab: MATCH_STATUS_TYPES.ACTIVE,
          refetchStatusCounts: true
        })
      "
    >
      {{ $t('matches_nav_active') }}
    </TabNavigationItem>
    <TabNavigationItem
      :active="activeTab === MATCH_STATUS_TYPES.INACTIVE"
      :show-count="false"
      :count="matchStatusCounts.inactive"
      :bg-color="tabBgColor"
      @click.native="
        setActiveTab({
          activeTab: MATCH_STATUS_TYPES.INACTIVE,
          refetchStatusCounts: true
        })
      "
    >
      {{ $t('matches_nav_inactive') }}
    </TabNavigationItem>
  </TabNavigation>
</template>

<script>
import { MATCH_STATUS_TYPES } from '@/store/modules/matches';
import { mapActions, mapGetters } from 'vuex';
import TabNavigation from '@/components/Navigation/TabNavigation/TabNavigation.vue';
import TabNavigationItem from '@/components/Navigation/TabNavigation/TabNavigationItem.vue';

export default {
  name: 'MatchesNavigation',

  components: {
    TabNavigation,
    TabNavigationItem
  },

  props: {
    tabBgColor: {
      type: String,
      default: '#fff',
      required: false
    }
  },

  data() {
    return {
      MATCH_STATUS_TYPES
    };
  },

  computed: {
    ...mapGetters({
      activeTab: 'matches/activeTab',
      initialMatchStatusCounts: 'matches/initialMatchStatusCounts',
      matchStatusCounts: 'matches/matchStatusCounts'
    })
  },

  methods: {
    ...mapActions({
      setActiveTab: 'matches/setActiveTab'
    }),

    showTab(tab) {
      return (
        this.initialMatchStatusCounts && this.initialMatchStatusCounts[tab] > 0
      );
    }
  }
};
</script>
