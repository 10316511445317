<template>
  <div :class="['residence-map-wrapper', visible ? 'map-visible' : '']">
    <div ref="residenceMap" class="map"></div>
  </div>
</template>

<script>
import { loadJs, loadCss } from '@/utils/maptiler';

export default {
  name: 'ResidenceMap',

  props: {
    coordinates: {
      type: Array,
      default: undefined
    },
    initialCoordinates: {
      type: Array,
      required: true
    },
    zoom: {
      type: Number,
      required: true
    },
    delayInit: {
      type: Boolean,
      default: false
    },
    interactive: {
      type: Boolean,
      default: true
    },
    visible: {
      type: Boolean,
      default: true
    }
  },

  data() {
    return {
      map: {}
    };
  },

  computed: {
    coordinatesWithFallback() {
      return this.coordinates ? this.coordinates : this.initialCoordinates;
    }
  },

  watch: {
    coordinates: function () {
      this.updateMap();
    },
    visible() {
      this.map.resize();
    }
  },

  async mounted() {
    await Promise.all([loadJs(), loadCss()]);
    this.initializeMap();
  },

  methods: {
    initializeMap() {
      const mapEl = this.$refs.residenceMap;
      if (!mapEl) return;

      const { offsetWidth, offsetHeight } = mapEl;

      const [lat, lng] = this.coordinatesWithFallback;
      this.map = new maplibregl.Map({
        container: mapEl,
        width: offsetWidth,
        height: offsetHeight,
        center: [lat, lng],
        style:
          'https://api.maptiler.com/maps/streets/style.json?key=jTrvi1p07Kys0YeUMuBV',
        zoom: this.coordinates ? 14 : this.zoom,
        attributionControl: false,
        logoPosition: 'bottom-right',
        interactive: this.interactive
      });

      this.map.scrollZoom.disable();

      if (this.coordinates) {
        this.marker = new maplibregl.Marker()
          .setLngLat([lat, lng])
          .addTo(this.map);
      }
    },

    updateMap: function () {
      if (!this.map.flyTo) return;

      if (this.marker && this.coordinates) {
        this.marker.setLngLat(this.coordinates);
        this.map.flyTo({
          center: this.coordinates,
          zoom: 14
        });
        this.map;
      } else if (this.coordinates) {
        this.marker = new maplibregl.Marker()
          .setLngLat(this.coordinates)
          .addTo(this.map);
        this.map.flyTo({
          center: this.coordinates,
          zoom: 14
        });
      } else if (this.coordinatesWithFallback) {
        if (this.marker) this.marker.remove();
        this.marker = false;
        this.map.flyTo({
          center: this.coordinatesWithFallback,
          zoom: this.zoom
        });
      }
    }
  }
};
</script>

<style lang="scss" scoped>
.residence-map-wrapper {
  height: 100%;
  width: 100%;
  overflow: hidden;
  opacity: 0;
  transition: 0.3s ease;
  &.map-visible {
    opacity: 1;
  }
  @media ($mobile) {
    border-radius: 8px;
  }
}

.map {
  height: 100%;
  width: 100%;
  max-height: 100%;

  overflow: hidden;
  background-color: $bg-gray;
  @media ($mobile) {
    border-radius: 8px;
  }

  & > * {
    outline: none;
    -webkit-tap-highlight-color: rgba(255, 255, 255, 0); /* mobile webkit */
  }
}
</style>
<style>
.mapboxgl-ctrl-bottom-right {
  display: none;
}
</style>
