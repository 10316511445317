import i18n, { routeLocale } from '@/i18n';

export default {
  ADD_PROPOSITION: i18n.t('path_add_proposition', routeLocale),
  EDIT_PROFILE: i18n.t('path_edit_profile', routeLocale),
  EDIT_USER_CREDENTIALS: i18n.t('path_edit_user_credentials', routeLocale),
  FAVOURITES: i18n.t('path_favourites', routeLocale),
  HELP: i18n.t('path_help', routeLocale),
  IMPRESSUM: i18n.t('path_impressum', routeLocale),
  HOME: '/',
  LOGOUT: i18n.t('path_logout', routeLocale),
  MESSAGES: i18n.t('path_messages', routeLocale),
  MY_PROPOSITIONS: i18n.t('path_my_propositions', routeLocale),
  EDIT_PROPOSITION: i18n.t('path_edit_proposition', routeLocale),
  EDIT_PROPOSITION_DESCRIPTION: i18n.t(
    'path_edit_proposition_description',
    routeLocale
  ),
  EDIT_PROPOSITION_DETAILS_AND_DESCRIPTION: i18n.t(
    'path_edit_proposition_details_and_description',
    routeLocale
  ),
  WISH: `${i18n.t('path_wish', routeLocale)}/:index`,
  WISH_AREA: `${i18n.t('path_wish', routeLocale)}/:index${i18n.t(
    'path_wish_area',
    routeLocale
  )}`,
  PROPOSITION_SIGNUP: `${i18n.t(
    'path_proposition_signup',
    routeLocale
  )}/:index`,
  SIGNUP: i18n.t('path_signup', routeLocale),
  IMAGE_UPLOAD: `${i18n.t('path_image_upload', routeLocale)}/:index`,
  SWAP_TYPE: i18n.t('path_swap_type', routeLocale),
  EDIT_PROPOSITION_WISHES: i18n.t('path_edit_proposition_wishes', routeLocale),
  EDIT_PROPOSITION_SECOND_WISHES: i18n.t(
    'path_edit_proposition_second_wishes',
    routeLocale
  ),
  EDIT_WISH_AREAS: i18n.t('path_edit_wish_areas', routeLocale),
  EDIT_PROP_SETTINGS: i18n.t('path_edit_prop_settings', routeLocale),
  EDIT_PROPOSITION_PHOTOS: i18n.t('path_edit_proposition_photos', routeLocale),
  CHANGE_TO_KLARNA: i18n.t('path_change_to_klarna', routeLocale),
  NOT_FOUND: i18n.t('path_not_found', routeLocale),
  ONBOARDING: i18n.t('path_onboarding', routeLocale),
  PAYMENT_PENDING: i18n.t('path_payment_pending', routeLocale),
  PAYMENT_HISTORY: i18n.t('path_payment_history', routeLocale),
  CHANGE_PAYMENT_METHOD: i18n.t('path_change_payment_method', routeLocale),
  PAYMENT_DONE_PROPOSITION: i18n.t(
    'path_payment_done_proposition',
    routeLocale
  ),
  PAYMENT_DONE_VISIBILITY: i18n.t('path_payment_done_visibility', routeLocale),
  SUBSCRIPTION_CANCELLED: i18n.t('path_subscription_cancelled', routeLocale),
  SUBSCRIPTION_OFFER_ACCEPTED: i18n.t(
    'path_subscription_offer_canceled',
    routeLocale
  ),
  UPDATE_TOKEN_DONE_KLARNA: i18n.t(
    'path_update_token_done_klarna',
    routeLocale
  ),
  CHANGE_SUBSCRIPTION_DONE: i18n.t(
    'path_change_subscription_done',
    routeLocale
  ),
  AUTO_SUBSCRIPTION_DONE: i18n.t('path_auto_subscription_done', routeLocale),
  PROPOSITIONS: i18n.t('path_propositions', routeLocale),
  SEARCH: i18n.t('path_search', routeLocale),
  PROPOSITION: i18n.t('path_proposition', routeLocale),
  PROPOSITION_GALLERY: i18n.t('path_proposition_gallery', routeLocale),
  HANDLE_SUBSCRIPTION: i18n.t('path_handle_subscription', routeLocale),
  PAYMENT_PROPOSITION: i18n.t('path_payment_proposition', routeLocale),
  SWAP_APPLICATION: i18n.t('path_swap_application', routeLocale),
  SWAP_SUGGESTIONS: i18n.t('path_swap_suggestions', routeLocale),
  SWAP_LIST: i18n.t('path_swap_list', routeLocale),
  SWAPS: i18n.t('path_swaps', routeLocale),
  APP_EXTRA_VISIBILITY_PAYMENT: i18n.t(
    'path_app_extra_visibility_payment',
    routeLocale
  ),
  HOW_IT_WORKS: i18n.t('path_how_it_works', routeLocale),
  GET_STARTED: i18n.t('path_get_started', routeLocale),
  LOGIN: i18n.t('path_login', routeLocale),
  LOGIN_RESET_PASSWORD: i18n.t('path_reset_password', routeLocale),
  GENERAL_TERMS: i18n.t('path_general_terms', routeLocale),
  COOKIE_POLICY: i18n.t('path_cookie_policy', routeLocale),
  BLOG_REDIRECT: i18n.t('path_blog_redirect', routeLocale),
  NEWS: i18n.t('path_news', routeLocale),
  TRIAL_INFO: i18n.t('path_trial_info', routeLocale),
  FREEMIUM_INFO: i18n.t('path_freemium_info', routeLocale),
  SWAP_DETAILS: i18n.t('path_swap_chain', routeLocale),
  MY_VISITORS: i18n.t('path_my_visitors', routeLocale),
  BLOCKED_PROPOSITIONS: i18n.t('path_removed_suggestions', routeLocale),
  OLD_SWAP_CHAIN_MANUAL: i18n.t('path_old_swap_chain_manual', routeLocale),
  OLD_SWAP_CHAIN_DIRECT: i18n.t('path_old_swap_chain_direct', routeLocale),
  OLD_SWAP_CHAIN_TRIANGLE: i18n.t('path_old_swap_chain_triangle', routeLocale),
  MATCHES: i18n.t('path_matches', routeLocale),
  SEARCH_COVERAGE_UNSUBSCRIBE: '/search-coverage/unsubscribe',
  BOOST_SUMMARY: '/boost-summary'
};
