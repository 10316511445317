<template>
  <router-link
    :is="isLink ? 'router-link' : 'div'"
    ref="swapItem"
    :class="['swap-item', { 'is-link': isLink }]"
    :to="`${$t('path_proposition', $routeLocale)}/${swap.propositionId}`"
    @click.native="handlePropositionCardClick"
    @click.right.native="handlePropositionCardRightClick"
  >
    <div class="swap-item-slider">
      <!-- <div class="swap-type-banner">
        {{
          isTriangle
            ? $t('swap_item_triangle')
            : isManual
            ? $t('swap_item_manual')
            : $t('swap_item_direct')
        }}
      </div> -->
      <div
        v-if="swap.inSwapProcess"
        :class="[
          'swap-type-banner swap-process',
          { 'has-manual-banner': swap.diff }
        ]"
      >
        {{ $t('swap_item_in_swap_process') }}
      </div>
      <SwapListSlider
        v-if="
          currentShownResidence.pictures &&
          currentShownResidence.pictures.length > 0
        "
        :residence-index="currentResidence"
        :images="currentShownResidence.pictures"
        :lock-scroll="lockScroll"
        :unlock-scroll="unlockScroll"
        :propositon="currentResidence"
      />
      <SwapListSlider
        v-else-if="propositionImagesArray && propositionImagesArray.length > 0"
        :primary-image="getImageName(propositionImagesArray[0])"
        :image-count="propositionImagesArray.length"
        :proposition-id="parseInt(swap.propositionId, 10)"
        :residence-index="currentResidence"
        :images="propositionImagesArray"
        :lock-scroll="lockScroll"
        :unlock-scroll="unlockScroll"
        :propositon="currentResidence"
        :currently-editing-proposition-id="swap.propositionId"
      />
      <img
        v-else-if="currentShownResidence.image"
        :src="currentShownResidence.image"
        alt=""
        class="img-placeholder"
      />
      <div v-else class="placeholder-container">
        <div class="img-placeholder no-image"></div>
        <p>{{ $t('proposition_missing_image_text') }}</p>
      </div>
    </div>

    <div v-if="isMobile" class="swap-item-header">
      <div class="address-info">
        <div class="address">
          {{ currentShownResidence.streetAddress }}
          <BaseIcon
            v-if="swap.verified"
            icon="verified"
            :width="18"
            :height="18"
            class="verified-badge"
          />
        </div>

        <div v-if="is2to1" class="address secondary">
          & &nbsp;
          <span class="underline" @click.prevent.stop="switchCurrentShown">{{
            currentSecondaryResidence.streetAddress
          }}</span>
          <div class="shift-icon"></div>
        </div>

        <div class="postal-area">
          {{ currentShownResidence.postalAddress }}{{ is2to1 ? ' & ' : ''
          }}{{ is2to1 ? currentSecondaryResidence.postalAddress : '' }}
        </div>
      </div>

      <div v-if="showFavouriteBtn" class="icon-area">
        <button
          type="button"
          :class="['favourite-icon', { marked: swap.isFavourite }]"
          @click="handleMarkFavourite"
        ></button>
      </div>
    </div>

    <div class="swap-item-body">
      <div v-if="!isMobile" class="swap-item-header">
        <div class="address-info">
          <div class="address">
            {{ currentShownResidence.streetAddress }}
            <BaseIcon
              v-if="swap.verified"
              icon="verified"
              :width="18"
              :height="18"
              class="verified-badge"
            />
          </div>
          <div v-if="is2to1" class="address secondary">
            & &nbsp;
            <span class="underline" @click.stop="switchCurrentShown">{{
              currentSecondaryResidence.streetAddress
            }}</span>
            <div class="shift-icon"></div>
          </div>

          <div class="postal-area">
            {{ currentShownResidence.postalAddress }}{{ is2to1 ? ' & ' : ''
            }}{{ is2to1 ? currentSecondaryResidence.postalAddress : '' }}
          </div>
        </div>

        <div v-if="showFavouriteBtn" class="icon-area">
          <button
            type="button"
            :class="['favourite-icon', { marked: swap.isFavourite }]"
            @click="handleMarkFavourite"
          ></button>
        </div>
      </div>

      <div v-if="isMobile">
        <div v-if="interests" class="interest-bar">
          <div v-if="showInterestsArea" class="interest-area">
            <div class="interest-progress">{{ swapPercentage }}%</div>
          </div>

          <BaseInterestButtonsFreemium
            :interested="interests[0]"
            :target-proposition-id="swap.propositionId.toString()"
            @interested="$emit('interested', $event)"
          />
        </div>

        <div class="residence-info-bar">
          <span class="extra-bold">{{ currentShownResidence.rooms }}</span>
          {{ $t('swap_item_rooms') }} &#8729;
          <span class="extra-bold">{{ currentShownResidence.sqm }}</span>
          {{ $t('swap_item_sqm') }}
          &#8729;
          {{
            currentShownResidence.floor !== 0 &&
            currentShownResidence.floor !== 999
              ? $t('swap_item_floor')
              : ''
          }}
          <span class="extra-bold">{{ formattedFloor }}</span>
          <br />
          <span class="extra-bold">{{ spacedRent }}</span>
          {{ $t('swap_item_cost_per_month') }}
        </div>

        <div class="slot-content">
          <slot />
        </div>
      </div>

      <div v-else class="desktop-body-center">
        <div class="desktop-body-center-info">
          <span v-if="isImpersonated" class="extra-bold red"
            >{{ $t('swap_item_ad_num') }} {{ swap.propositionNo }}</span
          >
          <br v-if="isImpersonated" />
          <span class="extra-bold">{{ currentShownResidence.rooms }}</span>
          {{ $t('swap_item_rooms') }} &#8729;
          <span class="extra-bold">{{ currentShownResidence.sqm }}</span>
          {{ $t('swap_item_sqm') }}
          &#8729;
          {{
            currentShownResidence.floor !== 0 &&
            currentShownResidence.floor !== 999
              ? $t('swap_item_floor')
              : ''
          }}
          <span class="extra-bold">{{ formattedFloor }}</span>
          <br />
          <span class="extra-bold">{{ spacedRent }}</span>
          {{ $t('swap_item_cost_per_month') }}
        </div>

        <div v-if="interests" class="interest-bar">
          <div v-if="showInterestsArea" class="interest-area">
            <div class="interest-progress">{{ swapPercentage }}%</div>
          </div>

          <BaseInterestButtonsFreemium
            :interested="interests[0]"
            :target-proposition-id="swap.propositionId.toString()"
            :interests="interests"
            @interested="$emit('interested', $event)"
          />
        </div>

        <div class="slot-content">
          <slot />
        </div>
      </div>

      <div class="amenities-bar">
        <AmenitiesLabels
          :conversation-open="swapChat !== null"
          :has-new-chat="swapChat && swapChat.unreadCount > 0"
          :residence="currentShownResidence"
        />

        <!-- <div class="action-button" @click.stop="handleShowSwapDetails">
          {{ $t('swap_item_action') }}
        </div> -->
      </div>
    </div>
  </router-link>
</template>

<script>
import SwapListSlider from '@/components/ImageSlider/SwapListSlider';
import BaseInterestButtons from '@/components/BaseInterestButtons';
import AmenitiesLabels from '@/components/SwapList/AmenitiesLabels';
import { mapGetters } from 'vuex';
import events from '@/utils/helpers/events';
import { addRightClickTracker } from '@/utils/helpers/rightClick';

export default {
  name: 'BasePropositionCard',

  components: {
    SwapListSlider,
    BaseInterestButtons,
    AmenitiesLabels
  },

  props: {
    isLink: {
      type: Boolean,
      default: true
    },
    swap: {
      type: Object,
      required: true
    },
    showFavouriteBtn: {
      type: Boolean,
      default: false
    },
    showInterestsArea: {
      type: Boolean,
      default: true
    },
    source: {
      type: String,
      default: null
    }
  },

  data() {
    return {
      currentResidence: 0,
      scrollLocked: false
    };
  },

  computed: {
    ...mapGetters({
      isMobile: 'screenSize/isMobile',
      isImpersonated: 'app/isImpersonated'
    }),

    residence1() {
      return this.swap.residences[0];
    },

    residence2() {
      return this.swap.residences[1];
    },

    interests() {
      return this.swap.details?.interests;
    },

    swapPercentage() {
      const { interests } = this;
      if (!interests) {
        return;
      }
      const interestedCount = interests.filter(interest => interest).length;

      const percentage = interestedCount / interests.length;
      if (percentage * 100 < 80) {
        return Math.floor(percentage * 100);
      }
      return Math.round(percentage * 100);
    },

    swapChat() {
      //return this.swap.chat;

      return null;
    },

    spacedRent() {
      return this.currentShownResidence.rent
        .toString()
        .replace(/\B(?=(\d{3})+(?!\d))/g, ' ');
    },

    currentShownResidence() {
      if (!this.is2to1) {
        return this.residence1;
      } else {
        return this.swap.residences[this.currentResidence];
      }
    },

    currentSecondaryResidence() {
      if (this.currentResidence === 0) {
        return this.residence2;
      } else {
        return this.residence1;
      }
    },

    swapLabel() {
      const { swapType } = this.swap;

      switch (swapType) {
        case 21:
          return this.$t('general_2_for_1');
        case 12:
          return this.$t('general_1_for_2');
        default:
          return this.$t('general_1_for_1');
      }
    },

    typeColor() {
      const { swapType } = this.swap;

      switch (swapType) {
        case 21:
          return 'pink';
        case 12:
          return 'yellow';
        default:
          return 'blue';
      }
    },

    is2to1() {
      return this.swap.residences.length > 1;
    },

    // isTriangle() {
    //   return this.swap.details.interests.length >= 3;
    // },

    // isManual() {
    //   return this.swap.details.type === 'manual';
    // },

    formattedFloor() {
      switch (this.currentShownResidence.floor) {
        case 0:
          return this.$t('general_ground_floor_short');
        case 999:
          return this.$t('general_attic_short');
        default:
          return this.currentShownResidence.floor;
      }
    },

    propositionImagesArray() {
      if (this.currentShownResidence.images) {
        return this.currentShownResidence.images.map(
          imageObject => imageObject.fileName
        );
      } else {
        return null;
      }
    }
  },

  methods: {
    getImageName(image) {
      const urlParts = image.split('/');
      return urlParts[urlParts.length - 1];
    },

    handleMarkFavourite() {
      if (this.swap.isFavourite) {
        this.$emit('clickedFavourite', false);
      } else {
        this.$emit('clickedFavourite', true);
      }
    },

    triggerPropositionClickedEvent() {
      events.emitEvent(events.eventTypes.CLICK, 'View Proposition', {
        src: this.source ? this.source : `swaplist`,
        propositionId: this.swap.propositionId
      });
    },

    handlePropositionCardRightClick() {
      addRightClickTracker(this.swap.propositionId, 'swaplist');
    },

    handlePropositionCardClick() {
      this.triggerPropositionClickedEvent();
    },

    switchCurrentShown() {
      if (this.currentResidence === 0) {
        this.currentResidence = 1;
      } else {
        this.currentResidence = 0;
      }
    },

    lockScroll() {
      this.scrollLocked = true;
    },

    unlockScroll() {
      this.scrollLocked = false;
    },

    initializeScrollLock() {
      const preventDefaultIfLocked = e => {
        if (!this.scrollLocked) {
          return;
        }

        e = e || window.event;
        if (e.preventDefault) {
          e.preventDefault();
        }
        e.returnValue = false;
      };

      window.addEventListener('touchmove', preventDefaultIfLocked, {
        passive: false
      });
    }
  }
};
</script>

<style lang="scss" scoped>
.swap-item {
  background-color: #fff;
  color: $text-primary;
  text-decoration: none;
  margin: 16px 0;

  @media ($mobile) {
    padding-bottom: 20px;
    border-bottom: 1px solid #f1f2f6;
    display: block;
  }

  @media ($desktop) {
    display: flex;
    border: 1px solid rgba(28, 45, 65, 0.15);
    border-radius: $small-box-radius;
    box-shadow: 0 2px 6px 0 rgba(0, 0, 0, 0.05);
    padding: 16px;
  }
}

.swap-item-body {
  @media ($desktop) {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }
}

.swap-item.is-link:hover {
  @media ($desktop) {
    border: 1px solid $transfer-blue;
    cursor: pointer;
  }
}

.desktop-body-center {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
}

.desktop-body-center-info {
  font-weight: 600;
  font-size: 0.85rem;
  margin-top: 10px;
  line-height: 160%;
}

.swap-item-header {
  display: flex;
  flex: 0 0 auto;
  -webkit-tap-highlight-color: transparent;

  @media ($mobile) {
    margin-top: 10px;
  }
}

.icon-area {
  margin-left: auto;
  display: flex;
  align-items: center;
}

.proposition-type {
  padding: 5px 8px;
  border-radius: 12px;
  font-weight: 700;
  white-space: nowrap;
  font-size: 0.6rem;

  &.pink {
    background-color: #fce5f6;
    color: #ed63d2;
  }

  &.blue {
    background-color: #e7f3ff;
    color: $transfer-blue;
  }

  &.yellow {
    color: #ffc212;
    background-color: #fff8e6;
  }
}

.underline {
  text-decoration: underline;
}

.favourite-icon {
  height: 20px;
  width: 20px;
  padding: 0;
  border: none;
  background-color: transparent;
  background-position: center;
  background-size: contain;
  background-image: url('@/assets/svg/heart-empty.svg');
  margin: 0 6px;
  cursor: pointer;
  &.marked {
    background-image: url('@/assets/svg/heart-full.svg');
  }
}

.swap-item-slider {
  position: relative;
  overflow: hidden;

  @media ($desktop) {
    height: 170px;
    margin: 0 16px 0 0;
    width: 35%;
    min-width: 35%;
    max-width: 220px;
  }
  @media ($mobile) {
    height: 210px;
    width: 100%;
  }
}

.swap-type-banner {
  position: absolute;
  top: 18px;
  left: 0;
  padding: 6px;
  padding-left: 12px;
  background-color: $dark-blue;
  color: #fff;
  z-index: 10;
  font-weight: 600;
  font-size: 0.8rem;
  border-top-right-radius: $small-box-radius;
  border-bottom-right-radius: $small-box-radius;

  &.swap-process {
    background-color: $transfer-blue;

    &.has-manual-banner {
      top: 18px;
    }
  }
}

.img-placeholder {
  width: 100%;
  object-fit: cover;
  background-color: #f7f7f9;
  display: block;
  background-image: url('../assets/svg/placeholderImage/placeholder-image-large.svg');

  @media ($desktop) {
    height: 170px;
    // margin: 0 16px 0 0;
  }
  @media ($mobile) {
    height: 210px;
    margin: 6px 0;
  }
}

.placeholder-container {
  position: relative;
  background-color: #f7f7f9;
  display: flex;
  flex-direction: column;
  align-items: center;

  .img-placeholder.no-image {
    background-color: #f7f7f9;
    background-position: 50%;
    background-size: 50%;
    aspect-ratio: 2 / 1.2;
    object-fit: cover;
    display: block;

    @media ($mobile) {
      aspect-ratio: 2 / 0.9;
    }
  }

  p {
    font-size: 12px;
    text-align: center;
    margin: 0;
    position: absolute;
    top: 70%;
    z-index: 1;
  }
}

.profile-picture {
  height: 40px;
  width: 40px;
  min-width: 40px;
  min-height: 40px;
  border-radius: 50%;
  background-size: cover;
  background-position: center;
  opacity: 0.8;
  background-image: url('../assets/img/nopic-square.png');
}

.address {
  display: flex;
  align-items: center;
  font-size: 20px;
  font-weight: 700;

  &.secondary {
    font-size: 0.8rem;
    display: flex;
    align-items: center;
  }
}

.verified-badge {
  margin-left: 6px;
  margin-top: -1px;
}

.shift-icon {
  height: 13px;
  width: 15px;
  margin-left: 6px;
  background-position: center;
  background-size: contain;
  background-image: url('../assets/svg/shift.svg');
}

.postal-area {
  margin-top: 3px;
  font-size: 0.8rem;
  color: $text-secondary;
  font-weight: 600;
}

.residence-info-bar {
  font-size: 0.85rem;
  line-height: 18px;
  padding-top: 14px;
  padding-bottom: 6px;

  @media ($desktop) {
    border-bottom: 1px solid #ddd;
  }
}

.interest-bar {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 3px 0 6px 0;
  padding-bottom: 8px;
}

.interest-area {
  display: flex;
  align-items: center;
}

.interest-progress {
  margin-left: 3px;
  font-weight: 600;
  color: $text-primary;
  font-size: 0.7rem;
}

.amenities-bar {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-top: 10px;
}

.action-button {
  cursor: pointer;
  font-size: 0.8rem;
  font-weight: 700;
  color: $transfer-blue;
  padding: 4px 0;
}

.extra-bold {
  font-weight: 600;
}

.red {
  color: red;
}

.slot-content:not(:empty) {
  font-size: 14px;
  font-weight: 600;
  padding-top: 10px;
  padding-bottom: 10px;
}
</style>
