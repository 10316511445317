<template>
  <div class="edit-proposition-wishes-container">
    <div class="edit-proposition-wishes">
      <BaseBackButton class="back-button" @click="goBack" />
      <div class="edit-prop-info-container">
        <h1 v-if="propositionWish" class="page-heading">
          {{ $t('edit_wish_title') }}
        </h1>

        <div class="sub-heading">{{ $t('edit_wish_subtitle') }}</div>
      </div>

      <LinkSubListItem
        :path="`${$routes.EDIT_WISH_AREAS}/${propositionId}/1`"
        class="link"
      >
        <p class="title">{{ $t('edit_prop_edit_areas_link') }}</p>
        <p class="info">{{ $t('edit_prop_edit_areas_title') }}</p>
      </LinkSubListItem>

      <template v-if="propositionWish">
        <div class="sub-heading-and-text-container">
          <h3 v-if="proposition" class="page-sub-heading">
            {{ $t('edit_description_title') }}
          </h3>
          <div class="wishes-subtitle">
            {{ $t('edit_description_subtitle') }}
          </div>
        </div>

        <div class="form-group">
          <BasePicker
            id="minRooms"
            :ref="!propositionWish.minRooms ? 'hasErrorMinRooms' : ''"
            key="min-rooms-picker"
            :title="$t('edit_wish_min_rooms_label')"
            name="minRooms"
            :options="$country.getValue('MIN_ROOMS')"
            :value="propositionWish.minRooms"
            :valid="!showErrors || !!propositionWish.minRooms"
            @selected="handleSetWishMinRooms($event, propositionWish)"
          />

          <BaseInput
            id="minSqm"
            v-model.number="propositionWish.minSqm"
            :valid="!showErrors || isNumeric(propositionWish.minSqm)"
            name="minSqm"
            type="number"
            :label="$t('edit_wish_min_sqm_label')"
            :placeholder="$t('edit_wish_min_sqm_label')"
            :suffix="$t('general_sqm_short')"
            class="form-group-item"
          />

          <BaseInput
            id="maxRent"
            v-model.number="propositionWish.maxRent"
            :valid="!showErrors || isNumeric(propositionWish.maxRent)"
            name="maxRent"
            type="number"
            :label="$t('edit_wish_max_rent')"
            :placeholder="$t('edit_wish_max_rent')"
            :suffix="$t('general_currency_per_month_short')"
            class="form-group-item"
          />
        </div>

        <div class="checkbox-list checkbox-list-first">
          <h4 class="checkbox-list-heading">
            {{ $t('edit_wish_require_title') }}
          </h4>
          <p class="checkbox-list-text">{{ $t('edit_wish_require_text') }}</p>

          <BaseCheckbox
            :label="$t('edit_wish_balcony')"
            :value="propositionWish.balcony"
            :selected="propositionWish.balcony"
            :is-checkbox-dark="true"
            class="checkbox"
            @change="propositionWish.balcony = !propositionWish.balcony"
          />

          <BaseCheckbox
            :label="$t('edit_wish_elevator')"
            :value="propositionWish.elevator"
            :selected="propositionWish.elevator"
            class="checkbox"
            :is-checkbox-dark="true"
            @change="propositionWish.elevator = !propositionWish.elevator"
          />

          <BaseCheckbox
            :label="$t('edit_wish_not_bottomfloor')"
            :value="propositionWish.noBottomFloor"
            :selected="propositionWish.noBottomFloor"
            class="checkbox"
            :is-checkbox-dark="true"
            @change="
              propositionWish.noBottomFloor = !propositionWish.noBottomFloor
            "
          />

          <template v-if="$country.isCountry('se')">
            <BaseCheckbox
              :label="$t('edit_wish_becoming_coop')"
              :value="propositionWish.becomingCooperative"
              :selected="propositionWish.becomingCooperative"
              class="checkbox"
              :is-checkbox-dark="true"
              @change="
                propositionWish.becomingCooperative =
                  !propositionWish.becomingCooperative
              "
            />
          </template>
        </div>

        <div class="checkbox-list">
          <h4 class="checkbox-list-heading">
            {{ $t('edit_wish_wish_title') }}
          </h4>
          <p class="checkbox-list-text" v-html="$t('edit_wish_wish_text')"></p>
          <BaseCheckbox
            :label="$t('edit_wish_fireplace')"
            :value="propositionWish.fireplace"
            :selected="propositionWish.fireplace"
            class="checkbox"
            :is-checkbox-dark="true"
            @change="propositionWish.fireplace = !propositionWish.fireplace"
          />

          <BaseCheckbox
            :label="$t('edit_wish_bathtub')"
            :value="propositionWish.bathtub"
            :selected="propositionWish.bathtub"
            :is-checkbox-dark="true"
            class="checkbox"
            @change="propositionWish.bathtub = !propositionWish.bathtub"
          />

          <template v-if="$country.isCountry('se')">
            <BaseCheckbox
              :label="$t('edit_wish_formed_coop')"
              :value="propositionWish.formedCooperative"
              :selected="propositionWish.formedCooperative"
              :is-checkbox-dark="true"
              class="checkbox"
              @change="
                propositionWish.formedCooperative =
                  !propositionWish.formedCooperative
              "
            />
          </template>
        </div>
      </template>

      <div class="button-container">
        <button
          v-if="
            proposition &&
            proposition.wishes &&
            proposition.wishes.length > 1 &&
            propositionSettings &&
            propositionSettings.swapType !== 12
          "
          class="action-button"
          @click="showRemoveModal = true"
        >
          <BaseIcon :icon-file="'trash-bin-blue'" class="icon" />
          {{ $t('edit_wish_remove_this_wish') }}
        </button>

        <button
          v-if="
            proposition && proposition.wishes && proposition.wishes.length === 1
          "
          class="action-button"
        >
          <a
            :href="`${$routes.EDIT_PROPOSITION_SECOND_WISHES}/${propositionId}`"
            class="action-link"
          >
            <BaseIcon :icon-file="'icon-circle-plus-blue'" class="icon" />
            {{ $t('edit_prop_add_additional') }}</a
          >
        </button>
      </div>
    </div>

    <FloatingSaveButton
      class="save-button"
      :is-visible="dataChanged"
      :icon-file="'check-mark-white'"
      @saveClick="handleSaveClick"
    >
      <template>
        <template v-if="isSaving">
          {{ $t('edit_user_credentials_loading') }}</template
        >
        <template v-else>
          {{ $t('edit_user_credentials_save') }}
        </template>
      </template>
    </FloatingSaveButton>

    <BaseModal
      v-if="showRemoveModal"
      :title="$t('edit_wish_remove_wish')"
      @close="showRemoveModal = false"
    >
      <div class="remove-modal-content">
        <BaseButton class="warning" @click="handleRemoveWishClick">
          {{ $t('edit_photos_modal_remove') }}
        </BaseButton>
        <BaseButton class="grey outline" @click="showRemoveModal = false">
          {{ $t('edit_photos_modal_close') }}
        </BaseButton>
      </div>
    </BaseModal>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import { lbApiBeta } from '../../utils/axiosConfig';
import FloatingSaveButton from '../../components/Buttons/FloatingSaveButton.vue';
import LinkSubListItem from '../../components/Navigation/LinkSubList/LinkSubListItem.vue';

export default {
  name: 'EditPropositionWish',

  components: {
    FloatingSaveButton,
    LinkSubListItem
  },

  data() {
    return {
      propositionId: null,
      wishIndex: null,
      proposition: null,
      showErrors: false,
      invalidFields: [],
      isSaving: false,
      propositionWish: null,
      propositionSettings: null,
      showRemoveModal: false,
      originalData: null
    };
  },

  head: {
    title() {
      return {
        inner: this.$t('edit_wish_meta_title') || ''
      };
    }
  },

  computed: {
    ...mapGetters({
      isMobile: 'screenSize/isMobile',
      userPropositions: 'myPropositions/propositions'
    }),

    editPropositionPath() {
      return this.$routes.EDIT_PROPOSITION + '/' + this.propositionId;
    },

    isValid() {
      return (
        this.isNumeric(this.propositionWish.maxRent) &&
        this.isNumeric(this.propositionWish.minRooms) &&
        this.isNumeric(this.propositionWish.minSqm)
      );
    },

    displayRooms(rooms) {
      return rooms.map(room => (room === 5 ? '5+' : room));
    },

    dataChanged() {
      return (
        JSON.stringify(this.originalData) !==
        JSON.stringify(this.propositionWish)
      );
    }
  },

  async created() {
    this.propositionId = this.$route.params.propositionId;
    this.wishIndex = this.$route.params.wishIndex;

    window.scrollTo(0, 0);

    await this.getProposition();

    await this.getWishes();
    await this.getSettings();
    this.saveOriginalWishesData();
  },

  methods: {
    ...mapActions({
      setToast: 'toast/setToast',
      fetchPropositions: 'myPropositions/fetchPropositions'
    }),

    goBack() {
      this.$router.go(-1);
    },

    async getProposition() {
      await this.fetchPropositions();

      this.proposition = this.userPropositions.find(
        id => id.propositionId == this.propositionId
      );
    },

    async getSettings() {
      try {
        const { data } = await lbApiBeta.get(
          `/api/v2/proposition/${this.propositionId}/swap-settings`
        );

        this.propositionSettings = data;
      } catch (e) {
        console.log(e);
      }
    },

    isNumeric(input) {
      return input - 0 == input && ('' + input).trim().length > 0;
    },

    validateForm() {
      this.invalidFields = [];

      if (!this.isNumeric(this.propositionWish.minSqm)) {
        this.invalidFields.push('minSqm');
      }
      if (!this.isNumeric(this.propositionWish.maxRent)) {
        this.invalidFields.push('maxRent');
      }

      return this.invalidFields.length > 0;
    },

    async handleSaveClick() {
      if (this.isValid) {
        try {
          this.isSaving = true;
          await this.updateWishes();
          this.saveOriginalWishesData();
          this.setToast({ title: this.$t('toast_changes_saved') });
          this.isSaving = false;
          setTimeout(() => {
            this.$router.push(this.editPropositionPath);
          }, 1500);
          await this.getWishes();
        } catch {
          this.isSaving = false;
          this.setToast({
            title: this.$t('toast_general_error_message'),
            timer: 5
          });
        }
      } else {
        this.showErrors = true;

        if (this.validateForm()) {
          const firstErrorField = this.invalidFields[0];
          this.scrollToError(firstErrorField);
        }
      }
    },

    async handleRemoveWishClick() {
      try {
        this.showRemoveModal = false;
        this.removeWish();
        this.setToast({ title: this.$t('edit_wish_toast_removed') });
        setTimeout(() => {
          this.$router.push(this.editPropositionPath);
        }, 1500);
        await this.getWishes();
      } catch {
        this.setToast({
          title: this.$t('toast_general_error_message'),
          timer: 5
        });
      }
    },

    async removeWish() {
      await lbApiBeta.delete(
        `/api/v2/proposition/${this.propositionId}/wish/1`,
        this.propositionWish
      );
    },

    async getWishes() {
      try {
        const responsePrimaryWish = await lbApiBeta.get(
          `/api/v2/proposition/${this.propositionId}/wish/1`
        );

        this.propositionWish = responsePrimaryWish.data;
      } catch (e) {
        console.log(e);
      }
    },

    async updateWishes() {
      await lbApiBeta.post(
        `/api/v2/proposition/${this.propositionId}/wish/1`,
        this.propositionWish
      );
    },

    saveOriginalWishesData() {
      this.originalData = JSON.parse(JSON.stringify(this.propositionWish));
    },

    handleSetWishMinRooms(selectedRooms, propositionWish) {
      propositionWish.minRooms = selectedRooms;
    },

    scrollToError(inputName) {
      const inputWithError = document.querySelector(`#${inputName}`);
      if (inputWithError) {
        inputWithError.scrollIntoView({ behavior: 'smooth', block: 'center' });
      }
    }
  }
};
</script>

<style lang="scss" scoped>
.edit-proposition-wishes-container {
  max-width: none;
  background-color: #f8f8fa;
  min-width: 100vw;
  min-height: 100vh;
  padding-bottom: 139px;

  @media ($desktop) {
    padding-bottom: 75px;
  }
}

.edit-proposition-wishes {
  padding: 0 16px;

  @media ($small-desktop) {
    width: 96%;
  }

  @media ($desktop) {
    max-width: 700px;
    margin: 0 auto;
  }
}

.save-button {
  width: 100%;
}

.edit-prop-info-container {
  margin-bottom: 24px;
  line-height: 22px;

  .page-heading {
    margin-top: 20px;
    margin-bottom: 16px;
    font-size: 22px;

    @media ($desktop) {
      margin-top: 0;
      font-size: 28px;
    }
  }
}

.link {
  p {
    margin: 0;
  }

  .title {
    color: #0b182c;
  }

  .info {
    color: #999999;
  }
}

.sub-heading-and-text-container {
  margin-bottom: 24px;

  .page-sub-heading {
    margin-bottom: 16px;
  }

  .wishes-subtitle {
    color: #485261;
  }
}

.sub-heading {
  color: #485261;
}

.form-group {
  display: grid;
  grid-template-columns: 1fr;
  gap: 10px;

  @media ($desktop) {
    display: flex;
    flex-direction: column;
  }
}

.checkbox-list {
  margin: 3rem 0;
}

.checkbox-list-first {
  margin-top: 0;
}

.checkbox-list-text {
  margin-top: -16px;
  margin-bottom: 20px;
  color: #485261;
}

.checkbox {
  margin-bottom: 1rem;
}

.button-container {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 20px;

  @media ($desktop) {
    margin-bottom: 50px;
  }
}

.action-button {
  display: flex;
  align-items: center;
  gap: 10px;
  background: none;
  border: 0;
  color: $main-blue;
  font-weight: 600;
  cursor: pointer;
  padding: 0;

  &:hover,
  &:focus {
    text-decoration: underline;
  }

  .action-link {
    display: flex;
    align-items: center;
    gap: 10px;
  }
}

.remove-modal-content {
  display: flex;
  justify-content: center;
  padding: 10px 0;

  button {
    margin: 0 10px;
    white-space: nowrap;
  }
}
</style>
