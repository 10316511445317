var render = function render(){var _vm=this,_c=_vm._self._c;return (
    _vm.infoBannerData &&
    _vm.showInfoBanner &&
    _vm.ABtestOnlyShowFor &&
    !_vm.ABtestHideFor &&
    !_vm.hideIfCreatedAfterDate &&
    _vm.showOnCurrentPage
  )?_c('div',{staticClass:"info-banner"},[_c(_vm.messageElement,{tag:"component",staticClass:"info-banner-content",attrs:{"to":_vm.infoBannerData.URL},on:{"click":_vm.handleInfoBannerClick},nativeOn:{"click":function($event){return _vm.handleInfoBannerClick.apply(null, arguments)}}},[_c('BaseIcon',{staticClass:"icon",attrs:{"icon-file":"icons/lightbulb-blue","width":_vm.isMobile ? 32 : 48,"height":_vm.isMobile ? 32 : 48}}),_c('div',{domProps:{"innerHTML":_vm._s(_vm.infoBannerData.BannerMessage)}})],1),_c('div',{staticClass:"close-button",on:{"click":_vm.handleCloseClick}},[_c('BaseIcon',{attrs:{"icon":"close-black"}})],1)],1):_vm._e()
}
var staticRenderFns = []

export { render, staticRenderFns }