<template>
  <VueSlider
    :value="value"
    :min="min"
    :max="max"
    :interval="interval"
    :data="customSteps"
    :contained="true"
    :class="{ 'far-left': dotFarLeft, 'far-right': dotFarRight }"
    v-bind="$attrs"
    @callback="handleInput"
  >
    <template #dot>
      <div class="custom-dot">
        <BaseIcon :icon-file="'handle-slider'" :width="10" :height="14" />
      </div>
    </template>
  </VueSlider>
</template>

<script>
import VueSlider from 'vue-slider-component';

export default {
  components: { VueSlider },

  props: {
    value: {
      type: Number,
      default: 0
    },
    min: {
      type: Number,
      default: 0
    },
    max: {
      type: Number,
      default: 100
    },
    interval: {
      type: Number,
      default: 1
    },
    customSteps: {
      type: Array,
      default: () => []
    }
  },

  computed: {
    dotFarLeft() {
      return (
        this.value === this.customSteps[0] ||
        this.value === this.customSteps[1] ||
        this.value === this.customSteps[2]
      );
    },

    dotFarRight() {
      return (
        this.value === this.customSteps[this.customSteps.length - 1] ||
        this.value === this.customSteps[this.customSteps.length - 2] ||
        this.value === this.customSteps[this.customSteps.length - 3]
      );
    }
  },

  methods: {
    handleInput(e) {
      this.$emit('input', e);
    }
  }
};
</script>

<style lang="scss">
.base-slider.vue-slider-component.vue-slider-horizontal {
  padding-top: 50px !important;
  padding-bottom: 20px !important;

  .vue-slider {
    background-color: #dddcdc;
    height: 4px !important;
  }

  .vue-slider-process {
    background-color: #0b182c;
  }

  .vue-slider-dot {
    top: -14px !important;
    left: -8px !important;
    width: 32px !important;
    height: 32px !important;
  }

  .custom-dot {
    display: grid;
    place-items: center;
    width: 32px;
    height: 32px;
    background-color: #0b182c;
    border-radius: 50%;
    box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
  }

  .vue-slider-tooltip {
    color: #0b182c;
    background-color: initial;
    border: none;
    padding: 0;
    font-size: 22px;
    font-weight: 600;
    line-height: 20px;
    letter-spacing: 0.44px;

    &::before {
      display: none;
    }
  }

  .vue-slider-tooltip-wrap.vue-slider-tooltip-top {
    top: -13px;

    .far-left & {
      left: 0;
      transform: translate(0, -100%);
    }

    .far-right & {
      left: auto;
      transform: translate(0, -100%);
      right: 0;
    }
  }
}

.far-left .vue-slider-tooltip-wrap.vue-slider-tooltip-top {
  left: 0 !important;
  transform: translate(0, -100%) !important;
}

.far-right .vue-slider-tooltip-wrap.vue-slider-tooltip-top {
  left: auto !important;
  transform: translate(0, -100%) !important;
  right: 0 !important;
}
</style>
