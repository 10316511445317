<template>
  <div
    v-if="
      infoBannerData &&
      showInfoBanner &&
      ABtestOnlyShowFor &&
      !ABtestHideFor &&
      !hideIfCreatedAfterDate &&
      showOnCurrentPage
    "
    class="info-banner"
  >
    <component
      :is="messageElement"
      :to="infoBannerData.URL"
      class="info-banner-content"
      @click="handleInfoBannerClick"
      @click.native="handleInfoBannerClick"
    >
      <BaseIcon
        icon-file="icons/lightbulb-blue"
        :width="isMobile ? 32 : 48"
        :height="isMobile ? 32 : 48"
        class="icon"
      />
      <div v-html="infoBannerData.BannerMessage"></div>
    </component>
    <div class="close-button" @click="handleCloseClick">
      <BaseIcon icon="close-black" />
    </div>
  </div>
</template>

<script>
import axios from 'axios';
import { getCookie, setCookie } from '@/utils/cookies';
import { mapGetters } from 'vuex';
import events from '@/utils/helpers/events';

export default {
  name: 'InfoBanner',

  data() {
    return {
      showInfoBanner: false,
      infoBannerData: null
    };
  },

  computed: {
    ...mapGetters({
      isMobile: 'screenSize/isMobile',
      user: 'app/user'
    }),

    messageElement() {
      return this.infoBannerData.URL ? 'router-link' : 'div';
    },

    ABtestOnlyShowFor() {
      if (!this.infoBannerData.ABtestOnlyShowFor) return true;

      return !!(
        this.infoBannerData.ABtestOnlyShowFor &&
        this.$growthbook.isFeatureFlagEnabled(
          `${this.infoBannerData.ABtestOnlyShowFor}`
        )
      );
    },

    ABtestHideFor() {
      return !!(
        this.infoBannerData.ABtestHideFor &&
        this.$growthbook.isFeatureFlagEnabled(
          `${this.infoBannerData.ABtestHideFor}`
        )
      );
    },

    hideIfCreatedAfterDate() {
      if (!this.infoBannerData.HideForUsersCreatedAfter) return false;

      const userRegisteredDate = new Date(this.user.registeredAt);
      const HideForUsersCreatedAfter = new Date(
        this.infoBannerData.HideForUsersCreatedAfter
      );
      return userRegisteredDate > HideForUsersCreatedAfter;
    },

    showOnCurrentPage() {
      return (
        (this.$routes.SWAP_LIST === this.$router.currentRoute.path &&
          this.infoBannerData.ShowInSwapList) ||
        (this.$routes.MATCHES === this.$router.currentRoute.path &&
          this.infoBannerData.ShowInMatchesView)
      );
    }
  },

  created() {
    this.getInfoBannerNotification();
  },

  methods: {
    async getInfoBannerNotification() {
      try {
        const response = await axios.get(
          `https://cms.lagenhetsbyte.se/banner-notification?_locale=${this.$routeLocale}`
        );
        this.infoBannerData = response.data;
        this.infoBannerVisibility();
      } catch (error) {
        this.error = error;
      }
    },

    infoBannerVisibility() {
      const hideInfoBanner = getCookie(
        'hide-info-banner-' + this.infoBannerData.publishedAt
      );
      if (hideInfoBanner) {
        this.showInfoBanner = false;
      } else {
        this.showInfoBanner = true;

        events.emitEvent(events.eventTypes.SEE, 'InfoBanner', {
          src: this.$router.currentRoute.path,
          title: this.infoBannerData.BannerMessage
        });
      }
    },

    handleInfoBannerClick() {
      events.emitEvent(events.eventTypes.CLICK, 'InfoBanner', {
        action: 'cta-clicked',
        src: this.$router.currentRoute.path,
        title: this.infoBannerData.BannerMessage,
        target: this.infoBannerData.URL
      });
    },

    handleCloseClick() {
      setCookie(
        'hide-info-banner-' + this.infoBannerData.publishedAt,
        true,
        180
      );
      this.showInfoBanner = false;

      events.emitEvent(events.eventTypes.CLICK, 'InfoBanner', {
        action: 'closed',
        src: this.$router.currentRoute.path,
        title: this.infoBannerData.BannerMessage
      });
    }
  }
};
</script>

<style lang="scss" scoped>
.info-banner {
  position: relative;
  display: grid;
  grid-template-columns: 1fr 54px;
  background: #ffffff;
  border: 1px solid #e8e8e8;
  box-shadow: 0px 0px 15px rgba(0, 0, 0, 0.1);
  border-radius: 10px;
}

.icon {
  display: grid;
  place-items: center;
  margin-right: 15px;
  flex-shrink: 0;
}

.info-banner-content {
  display: flex;
  align-items: center;
  padding: 15px;
  padding-right: 0;
  font-size: 14px;
  line-height: 1.5;
  color: $text-primary;
}

.info-banner-content ::v-deep strong,
.info-banner-content ::v-deep a {
  font-weight: 600;
}

.info-banner-content ::v-deep p {
  margin: 0;
}

.close-button {
  display: grid;
  place-items: center;
  cursor: pointer;
}
</style>
