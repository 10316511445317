<template>
  <div class="page-title"><slot></slot></div>
</template>

<script>
export default {
  name: 'PageTitle'
};
</script>

<style lang="scss" scoped>
.page-title {
  font-size: 24px;
  line-height: 1.5;
  font-weight: 700;
  letter-spacing: -0.264px;
  margin: 16px 0 24px 0;
  margin-top: 24px;
}
</style>
