<template>
  <div class="wrapper">
    <div class="area-container">
      <div class="area-inner">
        <PageStep :step="currentStep" />

        <div v-if="isAuthenticated">
          <PageTitle>{{ $t('signup_title') }}</PageTitle>

          <div class="subtitle">
            {{ $t('signup_subtitle') }}
          </div>
        </div>
        <div v-if="!isAuthenticated">
          <PageTitle v-if="!isLogin">{{
            $t('signup_title_not_logged_in')
          }}</PageTitle>

          <PageTitle v-else>{{ $t('signup_title_logged_in') }}</PageTitle>

          <template v-if="!isLogin">
            <div
              v-if="numberOfMatchingApartments >= 50"
              class="subtitle"
              v-html="
                $t('signup_subtitle_not_logged_in_apartments', {
                  apartments: numberOfMatchingApartments
                })
              "
            ></div>
            <div
              v-else
              class="subtitle"
              v-html="$t('signup_subtitle_not_logged_in')"
            ></div>
          </template>
          <div v-else class="subtitle">
            {{ $t('signup_subtitle_logged_in') }}
          </div>

          <div v-if="ssoProfile" class="sso-profile-card">
            <img
              v-if="ssoProfile.profilePicture"
              :src="ssoProfile.profilePicture"
              class="sso-profile-picture"
            />
            <span class="sso-profile-name">{{ ssoProfile.name }}</span>
            <a @click="deselectSSOProfile()">
              <BaseIcon class="sso-clear" icon="close-dark" />
            </a>
            <span v-if="showErrors" class="sso-error-message">{{
              signupError || loginError
            }}</span>
          </div>

          <div v-if="!ssoProfile && !isLogin" class="input-wrapper">
            <BaseInput
              id="name"
              :ref="!name ? 'hasErrorName' : ''"
              name="name"
              type="text"
              :value="name"
              :label="$t('signup_name_label')"
              :placeholder="$t('signup_name_placeholder')"
              :valid="!showErrors || !!name"
              :disabled="loading"
              @input="val => setName({ name: val })"
            />
            <BaseInput
              id="email"
              :ref="!emailValid ? 'hasErrorEmail' : ''"
              name="email"
              type="email"
              :value="email"
              :label="$t('signup_email_address_label')"
              :placeholder="$t('signup_email_address_placeholder')"
              :valid="!showErrors || (emailValid && !signupError)"
              :error-message="signupError"
              :disabled="loading"
              @input="val => setEmail({ email: val })"
            />
            <div class="or-delimiter">
              <span>{{ $t('login_or_delimiter') }}</span>
            </div>
            <div>
              <!-- <FacebookRegistrationButton
                :label="$t('register_with_facebook_label')"
                :on-success="setSSOProfile"
              /> -->
              <GoogleRegistrationButton
                :label="$t('register_with_google_label')"
                :on-success="setSSOProfile"
              />
              <AppleRegistrationButton
                :label="$t('register_with_apple_label')"
                :on-success="setSSOProfile"
              />
            </div>
          </div>

          <div
            v-if="!isLogin"
            :class="[
              'accept-terms',
              { 'has-contract': showHasContractCheckbox }
            ]"
          >
            <BaseCheckbox
              v-if="showHasContractCheckbox"
              id="contract"
              :ref="!hasContract ? 'hasErrorHasContract' : ''"
              :value="hasContract"
              :selected="hasContract"
              :valid="!showErrors || hasContract"
              :is-checkbox-dark="true"
              :error-message="'Um Mitglied bei Wohnungsswap.de zu sein, musst du einen Mietvertrag direkt vom Vermieter haben.'"
              :disabled="loading"
              class="has-contract-checkbox"
              @change="() => (hasContract = !hasContract)"
            >
              <template #label>
                <div class="base-checkbox-label" style="margin-left: 6px">
                  Ich habe einen Mietvertrag direkt vom Vermieter
                </div>
              </template>
            </BaseCheckbox>
            <BaseCheckbox
              id="terms"
              :ref="!termsAccepted ? 'hasErrorTerms' : ''"
              :value="termsAccepted"
              :selected="termsAccepted"
              :valid="!showErrors || termsAccepted"
              :is-checkbox-dark="true"
              :error-message="$t('signup_terms_error_message')"
              :disabled="loading"
              @change="handleTermsAccept"
            >
              <template #label>
                <div class="base-checkbox-label" style="margin-left: 6px">
                  {{ $t('signup_terms_1') }}
                  <a :href="$t('path_terms')" target="_blank">{{
                    $t('signup_terms_2')
                  }}</a>
                </div>
              </template>
            </BaseCheckbox>
          </div>

          <div v-if="!ssoProfile && isLogin" class="input-wrapper">
            <BaseInput
              id="email"
              name="name"
              type="email"
              :value="email"
              :label="$t('signup_email_address_label')"
              :placeholder="$t('signup_email_address_placeholder')"
              :valid="!showErrors || emailValid"
              :disabled="loading"
              @input="val => setEmail({ email: val })"
            />
            <BaseInput
              id="password"
              v-model="password"
              name="password"
              type="password"
              :label="$t('signup_password_label')"
              :placeholder="$t('signup_password_placeholder')"
              :valid="!showErrors || (!!password && !loginError)"
              :error-message="loginError"
              :disabled="loading"
            />

            <div class="login-text forgot-pw">
              <a
                class="login-link"
                :href="$t('path_reset_password', $routeLocale)"
              >
                {{ $t('signup_forgot_pw') }}
              </a>
            </div>

            <div class="or-delimiter">
              <span>{{ $t('login_or_delimiter') }}</span>
            </div>
            <div id="social-registration-wrapper">
              <div>
                <FacebookRegistrationButton
                  :label="$t('login_with_facebook_label')"
                  :on-success="setSSOProfile"
                />
                <GoogleRegistrationButton
                  :label="$t('login_with_google_label')"
                  :on-success="setSSOProfile"
                />
                <AppleRegistrationButton
                  :label="$t('login_with_apple_label')"
                  :on-success="setSSOProfile"
                />
              </div>
            </div>
          </div>

          <div v-if="!isLogin" class="login-text">
            {{ $t('signup_login_text_1') }}<br />
            <span class="login-link" @click="setLogin">
              {{ $t('signup_login_action_1') }}
            </span>
          </div>

          <div v-else class="login-text">
            {{ $t('signup_login_text_2') }}<br />
            <span class="login-link" @click="setSignup">
              {{ $t('signup_login_action_2') }}
            </span>
          </div>

          <NextButtonContainer>
            <BaseButton
              v-if="!isLogin"
              :loading="loading"
              :disabled="loading"
              class="button-go-to-next-page smaller rounded"
              :icon-file="'chevron-right-white'"
              :icon-last="true"
              :icon-size="20"
              @click="handleRegisterClick"
            >
              {{ $t('signup_primary_action') }}
            </BaseButton>
            <BaseButton
              v-else
              :loading="loading"
              :disabled="loading"
              class="button-go-to-next-page smaller rounded"
              :icon-file="'chevron-right-white'"
              :icon-last="true"
              @click="handleLoginClick"
            >
              {{ $t('signup_primary_action_2') }}
            </BaseButton>
          </NextButtonContainer>
        </div>
      </div>
    </div>

    <div v-if="!isMobile" class="image-container"></div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import PageTitle from '@/components/Signup/PageTitle';
import FacebookRegistrationButton from '@/components/SSOButtons/FacebookRegistrationButton';
import AppleRegistrationButton from '@/components//SSOButtons/AppleRegistrationButton';
import GoogleRegistrationButton from '@/components//SSOButtons/GoogleRegistrationButton';
import PageStep from '@/components/Signup/PageStep.vue';
import NextButtonContainer from '@/components/Signup/NextButtonContainer.vue';
import { calculateProgress } from '@/utils/progress';
import { CURRENT_STEPS } from '@/store/modules/signup';
import events from '@/utils/events';

export default {
  name: 'Signup',

  components: {
    PageTitle,
    PageStep,
    FacebookRegistrationButton,
    AppleRegistrationButton,
    GoogleRegistrationButton,
    NextButtonContainer
  },

  data() {
    return {
      isLogin: false,
      hasContract: false,
      termsAccepted: false,
      password: '',
      showErrors: false,
      isApp: !!window.ReactNativeWebView
    };
  },

  computed: {
    ...mapGetters({
      isAuthenticated: 'app/isAuthenticated',
      isMobile: 'screenSize/isMobile',
      ssoProfile: 'signup/ssoProfile',
      name: 'signup/name',
      email: 'signup/email',
      emailValid: 'signup/emailValid',
      loading: 'signup/loading',
      signupError: 'signup/signupError',
      loginError: 'signup/loginError',
      currentStep: 'signup/currentStep',
      swapType: 'signup/swapType',
      numberOfMatchingApartments: 'signup/numberOfMatchingApartments'
    }),

    canContinue() {
      if (this.isLogin) {
        if (this.ssoProfile && this.ssoProfile.accessToken) {
          return true;
        }

        if (this.emailValid && this.password) {
          return true;
        }
      } else if (!this.isLogin) {
        if (
          this.ssoProfile &&
          this.ssoProfile.accessToken &&
          this.termsAccepted
        ) {
          return true;
        }

        if (
          this.name &&
          this.emailValid &&
          this.termsAccepted &&
          (this.showHasContractCheckbox ? this.hasContract : true)
        ) {
          return true;
        }
      }

      return false;
    },

    showHasContractCheckbox() {
      return this.$country.isCountry('de');
    }
  },

  mounted() {
    this.scrollToTop();
    this.setCurrentStep({ step: CURRENT_STEPS.SIGNUP });
    calculateProgress(this.currentStep, this.swapType);

    events.emitEvent(events.eventTypes.PAGEVIEW, `Signup Register`);

    // TODO: Remove temporary hack so that the backend registration don't crash
    // since we still expect timeframe in the creation of a proposition
    this.setSwapTimeFrame(3);

    if (this.isAuthenticated) {
      this.$router.push(
        `${this.$t('path_proposition_signup', this.$routeLocale)}/1`
      );
    }
  },

  methods: {
    ...mapActions({
      setName: 'signup/setName',
      setEmail: 'signup/setEmail',
      register: 'signup/register',
      setSSOProfile: 'signup/setSSOProfile',
      login: 'signup/login',
      thirdPartyLogin: 'signup/thirdPartyLogin',
      setCurrentStep: 'signup/setCurrentStep',
      setSwapTimeFrame: 'signup/setSwapTimeFrame'
    }),

    scrollToFirstError() {
      this.$nextTick(() => {
        const errorRefs = Object.keys(this.$refs)
          .filter(ref => ref.startsWith('hasError'))
          .map(ref => this.$refs[ref])
          .filter(ref => ref !== undefined);

        if (this.showErrors && errorRefs.length > 0) {
          const firstErrorRef = errorRefs[0];
          if (firstErrorRef && firstErrorRef.$el) {
            firstErrorRef.$el.scrollIntoView({
              behavior: 'smooth',
              block: 'center'
            });
          } else if (firstErrorRef) {
            firstErrorRef.scrollIntoView({
              behavior: 'smooth',
              block: 'center'
            });
          }
        }
      });
    },

    scrollToTop() {
      window.scrollTo(0, 0);
    },

    setLogin() {
      this.setSSOProfile(null);
      this.isLogin = true;
      this.showErrors = false;
    },

    setSignup() {
      this.isLogin = false;
      this.showErrors = false;
    },

    handleTermsAccept() {
      this.termsAccepted = !this.termsAccepted;
    },

    async handleRegisterClick() {
      if (this.canContinue) {
        const success = await this.register();
        if (success) {
          this.$router.push(
            `${this.$t('path_proposition_signup', this.$routeLocale)}/1`
          );
        }
      }

      this.showErrors = true;
      this.scrollToFirstError();
    },

    async handleLoginClick() {
      if (this.canContinue) {
        let success = false;

        if (this.ssoProfile) {
          success = await this.thirdPartyLogin(this.ssoProfile);
        } else {
          success = await this.login(this.password);
        }

        if (success) {
          this.$router.push(
            `${this.$t('path_proposition_signup', this.$routeLocale)}/1`
          );
        }
      }

      this.showErrors = true;
    },

    deselectSSOProfile() {
      this.showErrors = false;
      this.setSSOProfile(null);
    }
  }
};
</script>

<style lang="scss" scoped>
.wrapper {
  max-width: 100vw;
  width: 100%;
  margin: 0;
  display: flex;
  background-color: #faf9f8;
  min-height: 100vh;

  &.content {
    padding: 0;
  }

  @media ($desktop) {
    padding: 0;
  }
}

.area-container {
  flex: 4;
  flex-shrink: none;
  display: flex;
  justify-content: center;
}

.area-inner {
  position: relative;
  padding: 0 16px;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-bottom: calc(160px + env(safe-area-inset-bottom));

  @media ($desktop) {
    padding: 20px;
    max-width: 450px;
  }
}

.image-container {
  flex: 6;
  height: calc(100vh - env(safe-area-inset-bottom));
  background-position: center;
  background-size: cover;
  background-image: url('@/assets/img/4.jpg');
}

.subtitle {
  margin-top: -12px;
  line-height: 150%;
  letter-spacing: -0.176px;

  strong {
    font-weight: 600;
  }
}

.input-wrapper {
  margin-top: 32px;
  margin-bottom: 32px;
  width: 100%;

  @media ($desktop) {
    max-width: 600px;
    margin-left: auto;
    margin-right: auto;
  }
}

.input-error {
  position: relative;
  top: -10px;
  font-weight: 600;
  text-align: center;
  font-size: 0.8rem;
  color: #ff0000;
}

.accept-terms {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  font-weight: 600;
  font-size: 0.8rem;
  margin-bottom: 32px;
  user-select: none;

  &.has-contract {
    align-items: flex-start;
  }

  a {
    color: #409fff;
  }
}

.has-contract-checkbox {
  margin-bottom: 16px;
}

.login-text {
  text-align: center;
  font-size: 0.8rem;

  &.forgot-pw {
    text-align: right;
    margin-top: -10px;
    font-weight: 600;
  }
}

.login-link {
  cursor: pointer;
  color: $transfer-blue;
  text-decoration: none;

  &:hover {
    text-decoration: underline;
  }
}

.button-go-to-next-page {
  margin-bottom: env(safe-area-inset-bottom);
  margin-left: auto;
  padding: 12px 20px 12px 26px;
  font-size: 16px;
}

.or-delimiter {
  position: relative;
  display: block;
  text-align: center;
  border-top: 1px solid #dddcdb;
  margin: 40px 0;
}

.or-delimiter span {
  position: absolute;
  top: -14px;
  padding: 4px 12px;
  background: #faf9f8;
  left: 50%;
  transform: translateX(-50%);
  color: #999;
  font-size: 16px;
  line-height: 1;
  letter-spacing: -0.176px;
}

.sso-profile-card {
  margin-top: 10px;
  width: 100%;
  padding: 20px 20px;
  text-align: center;
}

.sso-profile-picture {
  border-radius: 100%;
  height: 50px;
  width: 50px;
}

.sso-profile-name {
  vertical-align: middle;
  margin-left: 10px;
}

.sso-clear {
  vertical-align: middle;
  margin-left: 20px;
  display: inline-block;
}

.sso-clear:hover {
  cursor: pointer;
}

.sso-error-message {
  margin-top: 20px;
  text-align: center;
  display: block;
  font-size: 12px;
  color: red;
}
</style>
