<template>
  <div
    class="base-input"
    :class="{
      'has-text': !!value,
      invalid: !valid
    }"
  >
    <label
      :class="['base-input-label', { 'is-dark': isLabelDark }]"
      :for="name"
    >
      <!-- @slot Custom input label -->
      <slot name="label" v-bind="{ label }">{{ label }}</slot>
    </label>
    <div class="base-input-wrapper" @click="handleInputClick">
      <BaseIcon
        v-if="icon"
        :icon="icon"
        class="base-input-icon"
        @click="() => $emit('input-icon-clicked')"
      />
      <input
        v-bind="$attrs"
        :id="name"
        :ref="name"
        :value="value"
        :placeholder="placeholder"
        :required="required"
        :disabled="disabled"
        :name="name"
        :class="{ 'base-input-is-password': isPassword }"
        :type="inputType"
        @keyup="onChange($event)"
        v-on="listeners"
      />
      <div v-if="suffix" class="base-input-suffix">{{ suffix }}</div>
      <slot
        v-if="isPassword"
        v-bind="{
          isPasswordVisible,
          switchVisibilityPassword
        }"
        name="show-password"
      >
        <div
          class="pw-visibility-toggle"
          @click.stop.prevent="switchVisibilityPassword"
        >
          <BaseIcon :icon="isPasswordVisible ? 'eye' : 'eye-hidden'" />
        </div>
      </slot>
    </div>
    <transition name="sf-fade">
      <!-- @slot Custom error message of form input -->
      <slot
        v-if="!valid && errorMessage"
        name="error-message"
        v-bind="{ errorMessage }"
      >
        <div class="base-input-error-message">{{ errorMessage }}</div>
      </slot>
    </transition>
  </div>
</template>

<script>
export default {
  name: 'BaseInput',
  props: {
    value: {
      type: [String, Number],
      default: ''
    },
    label: {
      type: String,
      default: ''
    },
    placeholder: {
      type: String,
      default: ''
    },
    suffix: {
      type: String,
      default: ''
    },
    name: {
      type: String,
      default: ''
    },
    type: {
      type: String,
      default: 'text'
    },
    icon: {
      type: String,
      default: ''
    },
    // Validate value of form input
    valid: {
      type: Boolean,
      default: true
    },
    // Error message value of form input. It will be appeared if `valid` is `true`.
    errorMessage: {
      type: String,
      default: ''
    },
    required: {
      type: Boolean,
      default: false,
      description: 'Native input required attribute'
    },
    disabled: {
      type: Boolean,
      default: false,
      description: 'Native input disabled attribute'
    },
    // Status of show password icon display
    hasShowPassword: {
      type: Boolean,
      default: false
    },
    isLabelDark: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      isPasswordVisible: false,
      inputType: ''
    };
  },
  computed: {
    listeners() {
      return {
        ...this.$listeners,
        input: event => this.$emit('input', event.target.value)
      };
    },
    isPassword() {
      return this.type === 'password' && this.hasShowPassword;
    }
  },

  created() {
    this.inputType = this.type;
  },
  // watch: {
  //   value: {
  //     immediate: true,
  //     handler: function (value) {
  //       if (isNaN(value)) {
  //         this.$emit("input");
  //       }
  //     },
  //   },
  // },
  methods: {
    switchVisibilityPassword() {
      this.isPasswordVisible = !this.isPasswordVisible;
      this.inputType = this.isPasswordVisible ? 'text' : 'password';
    },
    handleInputClick() {
      try {
        this.$refs[this.name].focus();
      } catch (error) {
        // Do noting
      }
    },
    onChange: function () {
      this.$emit('onChange');
    }
  }
};
</script>

<style lang="scss" scoped>
.base-input {
  position: relative;
  width: 100%;
  margin-bottom: 20px;
}

.base-input-wrapper {
  position: relative;
  display: flex;
  align-items: center;
  width: 100%;
  background-color: #fff;
  border: 1px solid #e8e8e8;
  border-radius: 10px;
  overflow: hidden;

  .base-input-alt & {
    border-width: 1px;
    border-radius: 5px;
  }

  .invalid & {
    border-color: #ff3843;
  }
}

.base-input-label {
  display: block;
  font-weight: 600;
  font-size: 14px;
  margin: 0;
  margin-bottom: 5px;
  color: #878b9d;

  &.is-dark {
    color: #0b182c;
  }

  .base-input-alt & {
    font-size: 12px;
  }

  .invalid & {
    color: #ff3843;
  }
}

.base-input-icon {
  position: relative;
  height: 24px;
  flex: 0 0 24px;
  margin: 0 16px;
  margin-top: -2px;
  background-position: center;
  background-size: contain;

  .base-input-alt & {
    flex: 0 0 20px;
    height: 20px;
  }

  &::after {
    content: '';
    display: block;
    position: absolute;
    right: -16px;
    top: 2px;
    width: 1px;
    height: 20px;
    background-color: #e8e8e8;
  }
}

input {
  padding: 20px 16px;
  margin: 0;
  border: 0;
  background-color: transparent;
  font-family: inherit;
  outline: 0;
  font-weight: 600;
  width: 100%;
  color: #000;
  font-size: 1rem;
  -moz-appearance: textfield;
  -webkit-appearance: none;

  .base-input-alt & {
    padding: 12px;
    font-size: 12px;
    line-height: 16px;
  }

  &::-webkit-inner-spin-button,
  &::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
}

input[type='number'] {
  -moz-appearance: textfield;
}

input::-webkit-input-placeholder,
input::placeholder {
  color: #878b9d;
}

.base-input-suffix {
  padding-right: 20px;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  white-space: nowrap;
  background-color: #fff;
  font-size: 14px;
  font-weight: 600;
  color: #2c3e50;
  border-radius: 10px;
}

.base-input-error-message {
  position: relative;
  margin: 3px 0;
  font-weight: 600;
  font-size: 0.8rem;
  color: #ff3843;
}

.pw-visibility-toggle {
  background-color: transparent;
  border-width: 0;
  padding-right: 12px;
  cursor: pointer;

  .base-icon {
    width: 20px;
  }
}
</style>
