var render = function render(){var _vm=this,_c=_vm._self._c;return (_vm.ready && !_vm.primaryPropositionActive)?_c('NoActiveModal',{attrs:{"payment-page-proposition-id":_vm.primaryPropositionId,"in-free-area":_vm.primaryPropositionFreeArea,"source":"swap-list"}}):_c('div',{staticClass:"swaps-page-container"},[_c('div',{staticClass:"swaps-page"},[_c('div',{staticClass:"swaps-page-header"},[(_vm.isMobile)?_c('InfoBanner',{staticClass:"info-banner"}):_vm._e(),(!_vm.isMobile)?_c('div',{staticClass:"my-swaps-title"},[_vm._v(" "+_vm._s(_vm.$t('swaps_title'))+" ")]):_vm._e(),_c('SwapMenu',{staticClass:"swap-menu"}),(_vm.isMobile)?_c('div',{staticClass:"results-and-toggle"},[_c('div',{staticClass:"results-info"},[_c('div',{staticClass:"results-title"},[_vm._v(_vm._s(_vm.pageTitleAndDescription.title))]),_c('div',{staticClass:"results-text"},[_vm._v(" "+_vm._s(_vm.pageTitleAndDescription.description)+" ")])])]):_vm._e()],1),_c('div',{staticClass:"main-content"},[(!_vm.isMobile)?_c('InfoBanner',{staticClass:"info-banner"}):_vm._e(),(!_vm.isMobile && _vm.swapListType === _vm.SWAP_LIST_TYPES.LIST)?_c('SwapHeader',{staticStyle:{"margin-top":"-20px"},attrs:{"show-filters":_vm.swapListType === _vm.SWAP_LIST_TYPES.LIST,"is-swap-list":false,"page-title":_vm.pageTitleAndDescription.title}}):_vm._e(),(_vm.shouldShowLoadingContainer)?_c('LoaderContainer',{attrs:{"progress":_vm.progress,"is-mobile":_vm.isMobile,"swap-item-mode":_vm.swapItemMode,"current-page-size":25}}):(_vm.swaps && _vm.swaps.length === 0 && _vm.queueCount > 0)?[_c('div',{staticClass:"no-suggestions"},[_c('BaseIcon',{attrs:{"icon-file":"hands-clapping-blue","width":117,"height":117}}),_c('h1',{staticClass:"heading"},[_vm._v(" "+_vm._s(_vm.$t('swaps_no_left_title'))+" ")]),_c('p',{staticClass:"text-body"},[_vm._v(" "+_vm._s(_vm.$t('swaps_no_left_text'))+" ")])],1)]:(_vm.swaps && _vm.swaps.length === 0)?_c('NoResult'):(
          _vm.isMobile ||
          _vm.swapFilterType === _vm.SWAP_FILTER_TYPES.UNANSWERED_NEW ||
          (!_vm.isMobile && _vm.swapItemMode === _vm.SWAP_ITEM_TYPES.CARDS)
        )?_c('SwapContainer',{attrs:{"used-in":"swaps-page"}}):_c('SwapListContainer',{attrs:{"blur-content":(_vm.primaryProposition.isFreemium || _vm.primaryProposition.isTrial) &&
          _vm.swapFilterType == 'LAST_PART',"is-swaps-page":true}}),(
          _vm.swapFilterType !== _vm.SWAP_FILTER_TYPES.UNANSWERED_NEW &&
          _vm.totalPages > 1
        )?_c('FlowPagination',{attrs:{"current-page":_vm.currentPage,"max-page":_vm.totalPages,"on-page-click":_vm.handlePageClick,"item-count":_vm.postFilterCountWithDiffs,"current-page-size":_vm.currentPageSize,"on-select-page-size":_vm.handlePageSizeSelected,"is-loading":_vm.loading}}):_vm._e(),(_vm.swapFilterType === _vm.SWAP_FILTER_TYPES.UNANSWERED_NEW && _vm.hasMore)?_c('InfiniteScrollPagination',{attrs:{"on-page-click":_vm.handlePageClick,"is-loading":_vm.loading}}):_vm._e()],2)])])
}
var staticRenderFns = []

export { render, staticRenderFns }