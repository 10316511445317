<template>
  <button class="social-login-btn" @click="logInWithGoogle">
    <svg
      id="Social_Icon"
      enable-background="new 0 0 128 128"
      version="1.1"
      viewBox="0 0 128 128"
      xml:space="preserve"
      xmlns="http://www.w3.org/2000/svg"
      xmlns:xlink="http://www.w3.org/1999/xlink"
    >
      <g id="_x31__stroke">
        <g id="Google">
          <rect
            clip-rule="evenodd"
            fill="none"
            fill-rule="evenodd"
            height="128"
            width="128"
          />
          <path
            clip-rule="evenodd"
            d="M27.585,64c0-4.157,0.69-8.143,1.923-11.881L7.938,35.648    C3.734,44.183,1.366,53.801,1.366,64c0,10.191,2.366,19.802,6.563,28.332l21.558-16.503C28.266,72.108,27.585,68.137,27.585,64"
            fill="#FBBC05"
            fill-rule="evenodd"
          />
          <path
            clip-rule="evenodd"
            d="M65.457,26.182c9.031,0,17.188,3.2,23.597,8.436L107.698,16    C96.337,6.109,81.771,0,65.457,0C40.129,0,18.361,14.484,7.938,35.648l21.569,16.471C34.477,37.033,48.644,26.182,65.457,26.182"
            fill="#EA4335"
            fill-rule="evenodd"
          />
          <path
            clip-rule="evenodd"
            d="M65.457,101.818c-16.812,0-30.979-10.851-35.949-25.937    L7.938,92.349C18.361,113.516,40.129,128,65.457,128c15.632,0,30.557-5.551,41.758-15.951L86.741,96.221    C80.964,99.86,73.689,101.818,65.457,101.818"
            fill="#34A853"
            fill-rule="evenodd"
          />
          <path
            clip-rule="evenodd"
            d="M126.634,64c0-3.782-0.583-7.855-1.457-11.636H65.457v24.727    h34.376c-1.719,8.431-6.397,14.912-13.092,19.13l20.474,15.828C118.981,101.129,126.634,84.861,126.634,64"
            fill="#4285F4"
            fill-rule="evenodd"
          />
        </g>
      </g>
    </svg>
    <span class="btn-text">{{ label }}</span>
  </button>
</template>
<script>
import events from '@/utils/events';
import i18n, { routeLocale } from '@/i18n';
import axios from 'axios';

export default {
  name: 'GoogleRegistrationButton',
  props: {
    label: {
      type: String,
      required: true
    },
    onSuccess: {
      type: Function,
      required: true
    }
  },
  mounted() {
    this.loadGoogleIdentitySDK();
    this.waitForScript().then(this.initGoogle);

    const loadGoogleProfileFromCredential = async credential => {
      this.handleGoogleIdentityResponse({
        credential
      });
    };

    window.loadGoogleProfileFromCredential =
      loadGoogleProfileFromCredential.bind(this);
  },
  methods: {
    logInWithGoogle() {
      events.emitEvent(events.eventTypes.CLICK, `Begin Social Registration`, {
        type: 'GOOGLE'
      });

      if (window.ReactNativeWebView) {
        window.ReactNativeWebView.postMessage(
          JSON.stringify({
            type: 'SOCIAL_LOGIN',
            details: 'GOOGLE'
          })
        );
        return;
      }

      const wrapper = document.createElement('div');
      wrapper.style.display = 'none';
      wrapper.classList.add('custom-google-button');
      document.body.appendChild(wrapper);

      window.google.accounts.id.renderButton(wrapper, {});

      wrapper.querySelector('div[role=button]').click();
    },
    waitForScript() {
      return new Promise(resolve => {
        const readyCheckInterval = setInterval(() => {
          if (!window.google || !window.google.accounts) {
            return;
          }

          clearInterval(readyCheckInterval);
          resolve();
        }, 100);
      });
    },
    initGoogle() {
      window.google.accounts.id.initialize({
        client_id: i18n.t('google_login_client_id', routeLocale),
        callback: this.handleGoogleIdentityResponse
      });
    },
    async handleGoogleIdentityResponse(response) {
      if (!response.credential) {
        return;
      }

      const userResponse = await axios.get(
        `https://oauth2.googleapis.com/tokeninfo?id_token=${response.credential}`
      );
      if (!userResponse.data) {
        return;
      }

      events.emitEvent(
        events.eventTypes.CLICK,
        `Complete Social Registration`,
        {
          type: 'GOOGLE'
        }
      );

      this.onSuccess({
        accessToken: response.credential,
        ssoType: 'GOOGLE',
        name: userResponse.data.name,
        profilePicture: userResponse.data.picture
      });
    },
    loadGoogleIdentitySDK() {
      const id = 'google-identity-script';
      const fjs = document.getElementsByTagName('script')[0];
      if (document.getElementById(id)) {
        return;
      }
      const js = document.createElement('script');
      js.id = id;
      js.src = 'https://accounts.google.com/gsi/client';
      fjs.parentNode.insertBefore(js, fjs);
    }
  }
};
</script>
