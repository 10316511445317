var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{class:['proposition-list', _vm.usedIn]},_vm._l((_vm.filteredPropositions),function(proposition){return _c('div',{key:proposition.propositionId,staticClass:"proposition-alternative",on:{"click":e => _vm.selectProposition(e, proposition)}},_vm._l((proposition.residences),function(residence,index){return _c('div',{key:residence.streetAddress + proposition.propositionId,staticClass:"alternative-wrapper"},[_c('div',{staticClass:"proposition-image",style:([
          residence.images && residence.images.length > 0
            ? {
                backgroundImage: `url(${residence.images[0].fileName})`,
                opacity: 1
              }
            : ''
        ])}),_c('div',{staticClass:"inner"},[_c('p',{staticClass:"text"},[_c('span',{staticClass:"bold dark"},[_vm._v(_vm._s(residence.streetAddress))])]),(index === 0)?_c('p',{staticClass:"text"},[_vm._v(" "+_vm._s(_vm.$t('proposition_list_ad_num'))+" "),_c('span',[_vm._v(_vm._s(proposition.propositionNo))])]):_vm._e()])])}),0)}),0)
}
var staticRenderFns = []

export { render, staticRenderFns }