<template>
  <div>
    <div v-if="isLoading" class="uploaded-photo-is-loading">
      <div
        class="spinner-container"
        style="width: 28px; height: 28px; margin-bottom: 20px"
      >
        <ASpinner class="spinner-alt" />
      </div>
    </div>
    <div class="image-container">
      <div v-if="index === 0" class="image-badge">
        {{ $t('edit_photos_primary_pic') }}
      </div>
      <BaseImage
        :url="getImageThumbnail(image.filename)"
        alt=""
        draggable="false"
        class="uploaded-image"
        @done="isLoading = false"
      />
      <div ref="dropdown" class="dropdown" @click="openCloseImageMenu()">
        <div v-if="dropdownOpen" class="dropdown-content">
          <div
            v-if="index != 0"
            class="image-options-row"
            @click="handleMoveClick(-1)"
          >
            {{ $t('edit_photos_move_up') }}
          </div>
          <div
            v-if="index < maxIndex"
            class="image-options-row"
            @click="handleMoveClick(1)"
          >
            {{ $t('edit_photos_move_down') }}
          </div>
          <div class="image-options-row" @click="handleRotate(-90)">
            {{ $t('edit_photos_rotate_left') }}
          </div>
          <div class="image-options-row" @click="handleRotate(90)">
            {{ $t('edit_photos_rotate_right') }}
          </div>
          <div
            class="image-options-row delete-image"
            @click="handleRemoveClick()"
          >
            {{ $t('edit_photos_remove') }}
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { lbApiBeta } from '@/utils/axiosConfig';
import ASpinner from '@/components/atomic/atoms/a-spinner';
import { resizeImage } from '@/utils/helpers/image';

export default {
  name: 'ImageHandler',

  components: {
    ASpinner
  },

  props: {
    propositionId: {
      type: String,
      required: true
    },

    image: {
      type: Object,
      required: true
    },

    index: {
      type: Number,
      required: true
    },

    maxIndex: {
      type: Number,
      required: true
    }
  },

  data() {
    return {
      dropdownOpen: false,
      isLoading: false
    };
  },

  watch: {
    image(newValue, oldValue) {
      if (newValue !== oldValue) {
        this.isLoading = newValue.isLoading;
      }
    }
  },

  async created() {
    let self = this;
    window.addEventListener('click', function (e) {
      // close dropdown when clicked outside
      if (!self.$el.contains(e.target)) {
        self.dropdownOpen = false;
      } else {
        // debugger;
      }
    });
  },

  methods: {
    openCloseImageMenu() {
      this.dropdownOpen = !this.dropdownOpen;
    },

    getImageThumbnail(image) {
      return resizeImage(image, '800x800');
    },

    handleRemoveClick() {
      this.$emit('removeImage', this.image.picture_id);
    },

    handleConfirmRemoveImage() {
      this.$emit('removeImage', this.image.picture_id);
    },

    handleMoveClick(direction) {
      this.$emit('handleMoveClick', { index: this.index, direction });
    },

    async handleRotate(degrees) {
      this.isLoading = true;

      const response = await lbApiBeta.post('/api/object/RotatePicture', {
        picId: this.image.picture_id,
        adId: this.propositionId,
        degrees
      });

      const thumbnailUrl = resizeImage(response.data.Object, '800x800');

      this.isLoading = false;
      this.$emit('updateImage', {
        picture_id: this.image.picture_id,
        thumbnail: thumbnailUrl,
        filename: response.data.Object
      });
    }
  }
};
</script>

<style lang="scss" scoped>
/* Dropdown button on hover & focus */
.dropdown:hover,
.dropdown:focus {
  background-color: $info-gray;
}

/* The container <div> - needed to position the dropdown content */
.dropdown {
  position: absolute;
  top: 10px;
  right: 10px;
  display: flex;
  justify-content: center;
  height: 32px;
  width: 32px;
  background-color: white;
  color: black;
  font-size: 16px;
  border: 1px solid rgba(0, 0, 0, 0.2);
  border-radius: 50%;
  box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
  cursor: pointer;
}

.dropdown::before {
  content: '...';
  padding: 0;
  font-weight: 1000;
}

.dropdown-content {
  position: absolute;
  right: 0;
  top: 35px;
  background-color: #f9f9f9;
  min-width: 150px;
  box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
  border-radius: 8px;
  z-index: 5;
}

.dropdown-content div {
  color: black;
  padding: 12px 16px;
  text-decoration: none;
  display: block;
}

.image-options-row:hover {
  background-color: #f1f1f1;
}

.image-options-row:first-child:hover {
  border-radius: 8px 8px 0 0;
}

.image-options-row:last-child:hover {
  border-radius: 0 0 8px 8px;
}

.uploaded-photo-is-loading {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  background-color: rgba(227, 229, 233, 0.5);
  z-index: 1;

  .spinner-container {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 2;
  }
}

.image-container {
  flex-grow: 1;
  padding-bottom: 75%;
  overflow: hidden;
}

.image-badge {
  position: absolute;
  top: 10px;
  left: 10px;
  background-color: #2c3e50;
  color: #fff;
  padding: 5px 10px;
  border-radius: 8px;
  font-size: 14px;
  font-weight: 600;
  z-index: 3;
}

.uploaded-image {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
  background-color: #e8e8e8;
}
</style>
