<template>
  <div class="wrapper">
    <div class="area-container">
      <div class="area-inner">
        <PageStep :step="currentStep" />

        <PageTitle>{{ $t('proposition_title') }}</PageTitle>

        <div v-if="swapType === 'twoOne'" class="subtitle">
          {{
            $t('proposition_subtitle', { residenceIndex: residenceIndex + 1 })
          }}
        </div>

        <AddressInputField
          :ref="!address || !streetNumberValidation ? 'hasErrorAddress' : ''"
          :residence-index="residenceIndex"
          :search-loading="searchLoading"
          :show-errors="showErrors"
          :street-number-valid="streetNumberValidation"
        />

        <BasePicker
          key="rooms-picker"
          :ref="!rooms ? 'hasErrorRooms' : ''"
          :title="$t('proposition_amount_of_rooms')"
          :options="$country.getValue('PROPOSITION_ROOMS')"
          :primary-options-threshold="5"
          :value="rooms"
          :valid="!showErrors || !!rooms"
          @selected="handleSetResidenceRooms"
        />

        <BasePicker
          key="floor-picker"
          :ref="!floor ? 'hasErrorFloor' : ''"
          :title="$t('floor_picker_title')"
          :options="$country.getValue('PROPOSITION_FLOOR')"
          :primary-options-threshold="6"
          :value="floor"
          :custom-first-option="$t('floor_picker_bottom_floor')"
          :valid="!showErrors || !!hasValue(floor)"
          class="floor-picker"
          @selected="handleSetResidenceFloor"
        />

        <div class="form-group">
          <BaseInput
            :ref="!sqm ? 'hasErrorSqm' : 'noError'"
            name="sqm"
            type="number"
            :value="sqm"
            :label="$t('proposition_living_space')"
            :placeholder="$t('proposition_living_space_placeholder')"
            :suffix="$t('proposition_sqm')"
            :valid="!showErrors || !!sqm"
            class="form-group-first-child input-field"
            @input="handleSetResidenceSqm"
          />
          <BaseInput
            :ref="!rent ? 'hasErrorRent' : 'noError'"
            name="rent"
            type="number"
            :value="rent"
            :label="$t('proposition_monthly_rent')"
            :placeholder="$t('proposition_monthly_rent')"
            :suffix="$t('proposition_currency_short')"
            :valid="!showErrors || !!rent"
            class="form-group-last-child input-field"
            @input="handleSetResidenceRent"
          />
        </div>

        <MobileTipBox
          :title="$t('proposition_tooltip_title')"
          :text="$t('proposition_tooltip_text')"
        />

        <NextButtonContainer>
          <BaseButton
            class="button-go-to-next-page smaller rounded"
            :icon-file="'chevron-right-white'"
            :icon-last="true"
            :icon-size="20"
            @click="handleNextClick()"
          >
            {{ $t('proposition_continue') }}
          </BaseButton>
        </NextButtonContainer>
      </div>
    </div>
    <div v-if="!isMobile" class="map-container">
      <ResidenceMap
        :coordinates="coordinates[residenceIndex]"
        :initial-coordinates="$country.getValue('MAP_INITIAL_COORDINATES')"
        :zoom="$country.getValue('MAP_ZOOM')"
        :interactive="false"
      />
    </div>
    <BaseModal
      v-if="showDuplicateAdModal"
      :title="$t('proposition_duplicate_title')"
      @close="showDuplicateAdModal = false"
    >
      <div class="duplicate-ad-content">
        <p
          v-html="
            $t('proposition_duplicate_dialog', {
              adress: `${currentResidence.streetAddress} ${currentResidence.streetNumber}`
            })
          "
        ></p>
        <div class="duplicate-ad-buttons">
          <BaseButton class="grey outline" @click="handleNextClick(true)">
            {{ $t('proposition_duplicate_continue') }}
          </BaseButton>
          <BaseButton class="primary" @click="goToEditPage()">
            {{ $t('proposition_duplicate_edit') }}
          </BaseButton>
        </div>
      </div>
    </BaseModal>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import PageTitle from '@/components/Signup/PageTitle';
import AddressInputField from '@/components/FormElements/InputField/AddressInputField';
import ResidenceMap from '@/components/Maps/ResidenceMap';
import MobileTipBox from '@/components/Signup/MobileTipBox';
import PageStep from '@/components/Signup/PageStep';
import NextButtonContainer from '@/components/Signup/NextButtonContainer.vue';
import { calculateProgress } from '@/utils/progress';
import { CURRENT_STEPS } from '@/store/modules/signup';
import events from '@/utils/events';
import routes from '@/router/routes.constants';

export default {
  name: 'Proposition',

  components: {
    PageTitle,
    AddressInputField,
    ResidenceMap,
    MobileTipBox,
    PageStep,
    NextButtonContainer
  },

  data() {
    return {
      residenceIndex: 1,
      CURRENT_STEPS,
      showErrors: false,
      showDuplicateAdModal: false
    };
  },

  computed: {
    ...mapGetters({
      isMobile: 'screenSize/isMobile',
      swapType: 'signup/swapType',
      residences: 'signup/residences',
      selectedSuggestions: 'signup/selectedSuggestions',
      coordinates: 'signup/coordinates',
      searchLoading: 'signup/searchLoading',
      currentStep: 'signup/currentStep',
      loading: 'signup/loading',
      propositions: 'myPropositions/propositions',
      isAppRequest: 'app/isAppRequest'
    }),

    streetNumberValue() {
      if (!this.residences[this.residenceIndex]) return '';
      return this.residences[this.residenceIndex]['streetNumber'];
    },

    streetNumberValidation() {
      const regex = /^[1-9]\d*,?(?:[ -]?(?:[a-zA-Z]+|\d+))*$/;
      const trimmedStreetNumberValue = this.streetNumberValue
        ? this.streetNumberValue.trim()
        : '';
      return (
        regex.test(trimmedStreetNumberValue) &&
        this.streetNumberValue.length > 0 &&
        this.streetNumberValue.length <= 11
      );
    },

    currentResidence() {
      return this.residences[this.residenceIndex];
    },

    canContinue() {
      const {
        hasValue,
        rooms,
        selectedSuggestions,
        sqm,
        rent,
        floor,
        streetNumberValidation
      } = this;

      return (
        hasValue(rooms) &&
        hasValue(selectedSuggestions[this.residenceIndex]) &&
        hasValue(sqm) &&
        hasValue(rent) &&
        hasValue(floor) &&
        streetNumberValidation
      );
    },

    rooms() {
      if (!this.currentResidence) return null;
      return this.currentResidence.rooms;
    },

    address() {
      if (!this.currentResidence) return null;
      return this.currentResidence.streetAddress;
    },

    sqm() {
      if (!this.currentResidence) return null;
      return this.currentResidence.sqm;
    },

    rent() {
      if (!this.currentResidence) return null;
      return this.currentResidence.rent;
    },

    floor() {
      if (!this.currentResidence) return null;
      return this.currentResidence.floor;
    },

    nextLink() {
      if (this.swapType === 'twoOne' && this.residenceIndex === 0) {
        return `${this.$t('path_proposition', this.$routeLocale)}/2`;
      } else {
        return this.$t('path_signup', this.$routeLocale);
      }
    },

    addressCoordinates() {
      return this.coordinates[this.residenceIndex];
    }
  },

  watch: {
    $route: function () {
      const { index } = this.$route.params;
      this.residenceIndex = parseInt(index, 10) - 1;
    },

    canContinue() {
      this.showErrors = false;
    }
  },

  mounted() {
    const { index } = this.$route.params;
    this.residenceIndex = parseInt(index, 10) - 1;
    this.setCurrentStep({ step: CURRENT_STEPS.PROPOSITION });
    this.scrollToTop();

    calculateProgress(this.currentStep, this.swapType, index);

    events.emitEvent(events.eventTypes.PAGEVIEW, `Signup Residence ${index}`);
  },

  methods: {
    ...mapActions({
      setResidenceRooms: 'signup/setResidenceRooms',
      setResidenceFloor: 'signup/setResidenceFloor',
      setResidenceSqm: 'signup/setResidenceSqm',
      setResidenceRent: 'signup/setResidenceRent',
      setCurrentStep: 'signup/setCurrentStep',
      fetchPropositions: 'myPropositions/fetchPropositions',
      loadSuggestionAddressNumber: 'signup/loadSuggestionAddressNumber',
      loadSuggestionLocalityDetails: 'signup/loadSuggestionLocalityDetails',
      createProposition: 'signup/createProposition'
    }),

    scrollToFirstError() {
      this.$nextTick(() => {
        const errorRefs = Object.keys(this.$refs)
          .filter(ref => ref.startsWith('hasError'))
          .map(ref => this.$refs[ref])
          .filter(ref => ref !== undefined);

        if (this.showErrors && errorRefs.length > 0) {
          const firstErrorRef = errorRefs[0];
          if (firstErrorRef && firstErrorRef.$el) {
            firstErrorRef.$el.scrollIntoView({
              behavior: 'smooth',
              block: 'center'
            });
          } else if (firstErrorRef) {
            firstErrorRef.scrollIntoView({
              behavior: 'smooth',
              block: 'center'
            });
          }
        }
      });
    },

    scrollToTop() {
      window.scrollTo(0, 0);
    },

    hasValue(val) {
      return (
        val !== undefined &&
        val !== null &&
        !Number.isNaN(val) &&
        val.length !== 0
      );
    },

    handleSetResidenceRooms(rooms) {
      this.setResidenceRooms({
        rooms,
        residenceIndex: this.residenceIndex
      });
    },

    handleSetResidenceFloor(floor) {
      this.setResidenceFloor({
        floor,
        residenceIndex: this.residenceIndex
      });
    },

    handleSetResidenceRent(rent) {
      const parsed = parseInt(rent);
      this.setResidenceRent({
        rent: parsed,
        residenceIndex: this.residenceIndex
      });
    },

    handleSetResidenceSqm(sqm) {
      const parsed = parseInt(sqm);
      this.setResidenceSqm({
        sqm: parsed,
        residenceIndex: this.residenceIndex
      });
    },

    isDuplicate() {
      return this.propositions?.some(p =>
        p.residences?.some(
          r =>
            r.streetAddress ===
            `${this.currentResidence.streetAddress} ${this.currentResidence.streetNumber}`
        )
      );
    },

    goToEditPage() {
      const duplicatedProps = this.propositions.filter(p =>
        p.residences?.some(
          r =>
            r.streetAddress ===
            `${this.currentResidence.streetAddress} ${this.currentResidence.streetNumber}`
        )
      );

      let queryParam = this.isAppRequest ? '?isAppRequest=true' : '';

      if (duplicatedProps.length === 1) {
        // Direct edit of single proposition
        window.location.replace(
          `${routes.EDIT_PROPOSITION}/${duplicatedProps[0].propositionId}${queryParam}`
        );
      } else {
        // Multiple propositions with the same adress, go to list
        window.location.replace(`${routes.MY_PROPOSITIONS}${queryParam}`);
      }
    },

    async handleNextClick(forceContinue = false) {
      if (!this.canContinue) {
        this.showErrors = true;
        this.scrollToFirstError();
        return;
      }

      if (this.isDuplicate() && !forceContinue) {
        this.showDuplicateAdModal = true;
        return;
      }

      if (this.swapType === 'twoOne' && this.residenceIndex === 0) {
        this.scrollToTop();
        this.$router.push(
          `${this.$t('path_proposition_signup', this.$routeLocale)}/2`
        );
      } else {
        // create proposition now before proceeding to image upload
        await this.createProposition(true);
        this.fetchPropositions();

        this.$router.push(
          `${this.$t('path_image_upload', this.$routeLocale)}/1`
        );
      }
    }
  }
};
</script>

<style lang="scss" scoped>
.wrapper {
  position: relative;
  max-width: 100vw;
  width: 100%;
  margin: 0;
  padding: 0 16px;
  display: flex;
  padding-bottom: calc(120px + env(safe-area-inset-bottom));
  background-color: #faf9f8;
  min-height: 100vh;

  @media ($desktop) {
    padding: 0;
  }
}

.area-container {
  flex: 4;
  flex-shrink: none;
  display: flex;
  justify-content: center;

  @media ($desktop) {
    overflow-y: auto;
    overflow-y: overlay;
  }
}

.area-inner {
  width: 100%;
  max-width: 450px;

  @media ($desktop) {
    padding: 20px;
  }
}

.map-container {
  flex: 6;
  height: calc(100vh - env(safe-area-inset-bottom));
}

.subtitle {
  margin-top: -12px;
  margin-bottom: 24px;
}

.input-wrapper {
  width: 100%;
  display: flex;

  @media ($desktop) {
    max-width: 600px;
    margin-left: auto;
    margin-right: auto;
  }
}

.form-group {
  @media (min-width: 1200px) {
    display: flex;

    .form-group-first-child {
      margin-right: 4px;
    }

    .form-group-last-child {
      margin-left: 4px;
    }
  }
}

.button-go-to-next-page {
  margin-bottom: env(safe-area-inset-bottom);
  margin-left: auto;
  padding: 12px 20px 12px 26px;
  font-size: 16px;
}

.duplicate-ad-content {
  padding: 10px 0;

  .duplicate-ad-buttons {
    display: flex;
    flex-direction: column;
    align-items: stretch;

    button:last-child {
      margin-top: 10px;
      white-space: nowrap;
    }
  }

  p {
    margin-top: 0;
  }
}

.floor-picker {
  margin-bottom: 50px !important;
}

.input-field {
  margin-bottom: 40px !important;
}
</style>
