import { lbApiBeta } from '../../../utils/axiosConfig';
import { subscribe } from '../../../utils/socket';
import { router } from '@/main';

const toggleMenu = context => {
  context.commit('toggleMenu');
};

const closeMenu = context => {
  context.commit('closeMenu');
};

const toggleNotificationCenter = context => {
  if (context.state.notificationCenterOpen === false) {
    context.dispatch('openNotificationCenter');
  } else {
    context.dispatch('closeNotificationCenter');
  }

  if (context.rootGetters['screenSize/isMobile']) {
    if (context.state.notificationCenterOpen === false) {
      context.commit('ui/removeDisableScroll', 'notification-center', {
        root: true
      });
    } else {
      context.commit('ui/addDisableScroll', 'notification-center', {
        root: true
      });
    }
  }
};

const openNotificationCenter = context => {
  if (context.state.notificationCenterOpen === false) {
    context.dispatch('notificationCenter/fetchNotifications', null, {
      root: true
    });
  }

  context.commit('openNotificationCenter');
};

const closeNotificationCenter = context => {
  context.commit('closeNotificationCenter');
};

async function getChatOwnerId(context, primaryPropositionId) {
  try {
    const chatParticipantIdRes = await lbApiBeta.get(
      `/api/messenger/primary-participant/${primaryPropositionId}`
    );

    if (!chatParticipantIdRes.data) {
      return context.commit('setChatOwnerId', null);
    }

    return context.commit('setChatOwnerId', chatParticipantIdRes.data);
  } catch (error) {
    console.error(error);
  }

  context.commit('setChatOwnerId', null);
}

async function init(context, propositionId) {
  try {
    context.dispatch('getChatOwnerId', propositionId);
    await context.dispatch('fetchNotifications', propositionId);

    let timerId = null;
    const delayFetch = data => {
      if (
        !context.state.chatOwnerId ||
        (data && data.senderId == context.state.chatOwnerId)
      ) {
        return;
      }

      let delay = 2000;

      if (
        router.currentRoute.params.roomId &&
        context.state.chatOwnerId == data.participantId
      ) {
        delay = 0;
      }

      if (timerId) {
        clearTimeout(timerId);
      }

      timerId = setTimeout(() => {
        context.dispatch('fetchNotifications', propositionId);
      }, delay);
    };

    subscribe('reconnect', delayFetch);
    subscribe('chat-message-sent', delayFetch);
    subscribe('chat-read', delayFetch);
  } catch (error) {
    console.error(error);
  }
}

async function fetchNotifications(context, propositionId) {
  try {
    const response = await lbApiBeta.get(
      `/api/user/menunotifications${
        propositionId ? `?primaryPropositionId=${propositionId}` : ''
      }`
    );
    const notifications = response.data;

    const translatedNotifications = {
      onboarding: notifications.onboarding || 0,
      newSwaps: notifications.newSwaps || 0,
      unreadMessages: notifications.unreadMessages || 0,
      unansweredFullSwaps: notifications.unansweredFullSwaps || 0,
      unreadAndUpdatedMatches: notifications.unreadAndUpdatedMatches || 0,
      unansweredSwaps: notifications.unansweredSwaps || 0
    };

    context.commit('setMenuNotifications', translatedNotifications);

    if (translatedNotifications.onboarding > 0) {
      context.commit('setAttentionDot', {
        show: true
      });
    } else {
      context.commit('setAttentionDot', {
        show: false
      });
    }
    return translatedNotifications;
  } catch (error) {
    console.error('Could not fetch notifications', error);
  }
}

function showMobileTabBar(context) {
  context.commit('showMobileTabBar');
}

function hideMobileTabBar(context) {
  context.commit('hideMobileTabBar');
}

function showStickyTabBar(context) {
  context.commit('setStickyTabBar', true);
}

function removeStickyTabBar(context) {
  context.commit('setStickyTabBar', false);
}

async function waitForState({ state }, property) {
  while (state[property] === undefined) {
    await new Promise(resolve => setTimeout(resolve, 100));
  }

  return true;
}

export default {
  toggleMenu,
  closeMenu,
  toggleNotificationCenter,
  openNotificationCenter,
  closeNotificationCenter,
  fetchNotifications,
  showMobileTabBar,
  hideMobileTabBar,
  showStickyTabBar,
  removeStickyTabBar,
  getChatOwnerId,
  init,
  waitForState
};
