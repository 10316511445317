var render = function render(){var _vm=this,_c=_vm._self._c;return (_vm.ready && !_vm.primaryPropositionActive)?_c('NoActiveModal',{attrs:{"payment-page-proposition-id":_vm.primaryPropositionId,"in-free-area":_vm.primaryPropositionFreeArea,"source":"swap-list"}}):_c('div',{staticClass:"matches-page-container"},[_c('div',{staticClass:"matches-page"},[(_vm.isLoadingStatusCounts)?[_c('div',{staticClass:"loading-container-page"},[_c('BaseSpinnerAndText')],1)]:[(!(_vm.isMobile && _vm.selectedMatch))?[_c('div',{staticClass:"banner-and-tabs-container"},[_c('InfoBanner',{staticClass:"info-banner"}),_c('MatchesNavigation',{attrs:{"tab-bg-color":"transparent"}})],1),(!_vm.isLoadingMatches)?_c('div',{ref:"matchesContainer",class:[
            'matches-container',
            { 'matches-container--no-matches': !_vm.tabHasMatches }
          ]},[(!_vm.tabHasMatches)?_c('NoResult'):[(
                _vm.activeTab === 'unanswered' && _vm.matchesInActiveTab.length >= 3
              )?_c('div',{staticClass:"blocked-banner"},[_c('BaseIcon',{staticClass:"icon",attrs:{"icon-file":"chat-unlock-blue","width":_vm.isMobile ? 24 : 32,"height":_vm.isMobile ? 24 : 32}}),_c('div',[_c('strong',[_vm._v(_vm._s(_vm.$t('blocked_banner_title')))]),_c('p',[_vm._v(" "+_vm._s(_vm.$t('blocked_banner_text'))+" ")])])],1):_vm._e(),_vm._l((_vm.matchesInActiveTab),function(match,index){return _c('MatchCard',{key:index,attrs:{"match":match,"selected-match":_vm.selectedMatch},nativeOn:{"click":function($event){return _vm.handleMatchSelected(match)}}})})]],2):_c('div',{class:['loading-container', { panel: !_vm.isMobile }]},[_c('BaseSpinnerAndText')],1)]:_vm._e(),(
          (_vm.tabHasMatches && _vm.selectedMatch) ||
          _vm.isLoadingMatches ||
          (_vm.primaryPropositionIsLimited && !_vm.isMobile && _vm.tabHasMatches)
        )?_c('div',{staticClass:"chat-rooms-container"},[(_vm.selectedMatch && _vm.selectedMatch.chatRoom)?_c('Messages',{key:_vm.selectedMatch.id,attrs:{"in-room":_vm.selectedMatch.chatRoom,"user-id":_vm.user.userId,"is-impersonated":_vm.user.isImpersonated,"is-mobile":_vm.isMobile,"standalone":false,"used-in-matches":true,"match-is-inactive":_vm.swapIsInactive,"where":"matches"},on:{"room-created":_vm.chatRoomCreated,"chat-read":function($event){return _vm.resetReadCount(_vm.selectedMatch)},"close-chat-clicked":_vm.closeChat}}):_vm._e()],1):_vm._e()]],2)])
}
var staticRenderFns = []

export { render, staticRenderFns }