<template>
  <div :class="['link-list', { compact }]">
    <slot />
  </div>
</template>

<script>
export default {
  name: 'LinkList',

  props: {
    compact: {
      type: Boolean,
      default: false
    }
  }
};
</script>

<style lang="scss" scoped>
.link-list {
  width: 100%;
  user-select: none;
  margin-top: 24px;
  margin-bottom: 32px;
}

.compact ::v-deep .link-list-item {
  padding-top: 10px;
  padding-bottom: 10px;
}
</style>
