var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"mobile-swap-header"},[_c('div',{class:_vm.tutStatus === _vm.TUTORIAL_STATUS_TYPES.STARTED &&
      _vm.tutActiveStep === _vm.TUTORIAL_STEPS_TYPES.FIRST_INTEREST_MODAL
        ? 'animate-slide-down-height'
        : ''},[_c('InfoBanner',{staticClass:"info-banner"}),(_vm.isUserList && _vm.showPropositionSwitcher)?_c('PropositionSwitcher'):_vm._e(),(
        _vm.shouldShowOnboardingBanner &&
        _vm.tutStatus !== _vm.TUTORIAL_STATUS_TYPES.STARTED &&
        _vm.accountType !== 'trial'
      )?_c('div',{staticClass:"onboarding-wrapper",class:{
        'onboarding-fade-grow-animation':
          _vm.tutStatus === _vm.TUTORIAL_STATUS_TYPES.COMPLETED
      }},[_c('OnboardingBanner')],1):_vm._e(),_c('div',{staticClass:"action-wrapper"},[_c('div',{staticClass:"swap-count-wrapper"},[(_vm.loading)?_c('span',[_vm._v(" "+_vm._s(_vm.$t('mobile_header_loading_swaps'))+" ")]):(_vm.isLastPartFilterType && _vm.totalFilterCount > 0)?_c('span',[_vm._v(" "+_vm._s(_vm.$t('mobile_header_showing_swap_interest', { total: _vm.postFilterCountTotal }))+" ")]):(_vm.undistributedCount)?_c('span',[_vm._v(" "+_vm._s(_vm.$t('mobile_header_showing_undistributed', { total: _vm.postFilterCountTotal }))+" ")]):_c('span',[_vm._v(" "+_vm._s(_vm.$t('mobile_header_showing'))+" "+_vm._s(_vm.postFilterCountTotal)+" "+_vm._s(_vm.postFilterCountTotal < _vm.totalFilterCount && _vm.totalFilterCount > 0 ? ` ${_vm.$t('swaplist_header_of')} ${_vm.totalFilterCount}` : '')+" "+_vm._s(_vm.$t('mobile_header_swaps'))+" ")])])])],1)])
}
var staticRenderFns = []

export { render, staticRenderFns }