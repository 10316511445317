import { cloneDeep } from 'lodash';
import index from './index';

const setSwapType = (state, { swapType }) => {
  state.swapType = swapType;
};

const setWishMinRooms = (state, { rooms, wishIndex }) => {
  const wishesCopy = cloneDeep(state.wishes);
  let wish = wishesCopy[wishIndex] || {};

  wish.minRooms = rooms;

  wishesCopy[wishIndex] = wish;

  state.wishes = wishesCopy;
};

const setWishMinSize = (state, { size, wishIndex }) => {
  const wishesCopy = cloneDeep(state.wishes);
  let wish = wishesCopy[wishIndex] || {};

  wish.minSize = size;

  wishesCopy[wishIndex] = wish;

  state.wishes = wishesCopy;
};
const setWishMaxRent = (state, { rent, wishIndex }) => {
  const wishesCopy = cloneDeep(state.wishes);
  let wish = wishesCopy[wishIndex] || {};

  wish.maxRent = rent;

  wishesCopy[wishIndex] = wish;

  state.wishes = wishesCopy;
};

const setResidenceRooms = (state, { rooms, residenceIndex }) => {
  const residencesCopy = cloneDeep(state.residences);
  let residence = residencesCopy[residenceIndex] || {};

  residence.rooms = rooms;

  residencesCopy[residenceIndex] = residence;

  state.residences = residencesCopy;
};

const setResidenceCoordinates = (state, { coordinates, residenceIndex }) => {
  const residencesCopy = cloneDeep(state.residences);
  let residence = residencesCopy[residenceIndex] || {};

  residence.coordinates = coordinates;

  residencesCopy[residenceIndex] = residence;

  state.residences = residencesCopy;
};

const setResidenceAddress = (state, { address, residenceIndex }) => {
  const residencesCopy = cloneDeep(state.residences);
  let residence = residencesCopy[residenceIndex] || {};

  residence.streetAddress = address;

  residencesCopy[residenceIndex] = residence;

  state.residences = residencesCopy;
};

const setResidenceZipCode = (state, { zipCode, residenceIndex }) => {
  const residencesCopy = cloneDeep(state.residences);
  let residence = residencesCopy[residenceIndex] || {};

  residence.zipCode = zipCode;

  residencesCopy[residenceIndex] = residence;

  state.residences = residencesCopy;
};

const setResidenceNumber = (state, { number, residenceIndex }) => {
  const residencesCopy = cloneDeep(state.residences);
  let residence = residencesCopy[residenceIndex] || {};

  residence.streetNumber = number;

  residencesCopy[residenceIndex] = residence;

  state.residences = residencesCopy;
};

const setResidenceArea = (state, { area, residenceIndex }) => {
  const residencesCopy = cloneDeep(state.residences);
  let residence = residencesCopy[residenceIndex] || {};

  residence.area = area;

  residencesCopy[residenceIndex] = residence;

  state.residences = residencesCopy;
};

const setResidenceRent = (state, { rent, residenceIndex }) => {
  const residencesCopy = cloneDeep(state.residences);
  let residence = residencesCopy[residenceIndex] || {};

  residence.rent = rent;

  residencesCopy[residenceIndex] = residence;

  state.residences = residencesCopy;
};

const setResidenceSqm = (state, { sqm, residenceIndex }) => {
  const residencesCopy = cloneDeep(state.residences);
  let residence = residencesCopy[residenceIndex] || {};

  residence.sqm = sqm;

  residencesCopy[residenceIndex] = residence;

  state.residences = residencesCopy;
};

const setResidenceFloor = (state, { floor, residenceIndex }) => {
  const residencesCopy = cloneDeep(state.residences);
  let residence = residencesCopy[residenceIndex] || {};

  residence.floor = floor;

  residencesCopy[residenceIndex] = residence;

  state.residences = residencesCopy;
};

const setSwapTimeFrame = (state, timeFrame) => {
  state.swapTimeFrame = timeFrame;
};

const setName = (state, { name }) => {
  state.name = name;
};

const setSSOProfile = (state, ssoProfile) => {
  state.loginError = null;
  state.signupError = null;
  state.ssoProfile = ssoProfile;
};

const setEmail = (state, { email, isValid }) => {
  state.email = email;
  state.emailValid = isValid;
};

const setLoading = (state, { loading }) => (state.loading = loading);

const setLoginError = (state, { error }) => {
  state.loginError = error;
};

const setSignupError = (state, { error }) => {
  state.signupError = error;
};

const setPropositionSignup = (state, { proposition }) =>
  (state.proposition = proposition);

const setSearchTerm = (state, { inputValue }) =>
  (state.searchTerm = inputValue);

const setWishAreasSearchResult = (state, { val }) =>
  (state.searchResultWishAreas = val);

const setWishAreaOptions = (state, { val }) => (state.wishAreaOptions = val);

const addWishArea = (state, { area, wishIndex }) => {
  const wishes = cloneDeep(state.selectedAreas);

  wishes[wishIndex].push(area);

  state.selectedAreas = wishes;
};

const removeWishArea = (state, { area, wishIndex }) => {
  const wishes = cloneDeep(state.selectedAreas);

  wishes[wishIndex] = wishes[wishIndex].filter(a => a.id !== area.id);

  state.selectedAreas = wishes;
};

const setPolygonSignup = (state, { val, wishIndex }) => {
  const polygons = cloneDeep(state.polygons);
  polygons[wishIndex] = val;

  state.polygons = polygons;
};

function setSearchLoading(state, { loading }) {
  state.searchLoading = loading;
}

function setSearchNoResultsFound(state, { noResults }) {
  state.searchNoResultsFound = noResults;
}

function setAddressSearchResults(state, { addresses, results }) {
  state.searchAddressData.addresses = addresses;
  state.searchAddressData.results = results;
}

function setSelectedSuggestion(state, { selectedSuggestion, residenceIndex }) {
  const copy = cloneDeep(state.selectedSuggestions);
  copy[residenceIndex] = selectedSuggestion;
  state.selectedSuggestions = copy;
}

function setSelectedAreas(state, { selectedAreas }) {
  state.selectedAreas = selectedAreas;
}

function setCoordinates(state, { coordinates, residenceIndex }) {
  const copy = cloneDeep(state.coordinates);
  copy[residenceIndex] = coordinates;
  state.coordinates = copy;
}

function setNumberOfMatchingApartments(state, number) {
  state.numberOfMatchingApartments = number;
}

function setCurrentStep(state, { step }) {
  state.currentStep = step;
}

function setInSignup(state, { inSignup }) {
  state.inSignup = inSignup;
}

const resetState = (state, overrides) => {
  const initial = index.initialState();
  Object.keys(initial).forEach(key => {
    state[key] = overrides[key] || initial[key];
  });
};

export default {
  setSwapType,
  setWishMinRooms,
  setWishMinSize,
  setWishMaxRent,
  setResidenceRooms,
  setResidenceAddress,
  setResidenceZipCode,
  setResidenceNumber,
  setResidenceArea,
  setResidenceCoordinates,
  setResidenceFloor,
  setResidenceRent,
  setResidenceSqm,
  setSwapTimeFrame,
  setSSOProfile,
  setName,
  setEmail,
  setLoading,
  setLoginError,
  setSignupError,
  setPropositionSignup,
  setWishAreasSearchResult,
  setSearchTerm,
  setWishAreaOptions,
  addWishArea,
  removeWishArea,
  setPolygonSignup,
  setSearchLoading,
  setSearchNoResultsFound,
  setAddressSearchResults,
  setSelectedSuggestion,
  setSelectedAreas,
  setCoordinates,
  setNumberOfMatchingApartments,
  setCurrentStep,
  setInSignup,
  resetState
};
