<template>
  <div :class="['proposition-list', usedIn]">
    <div
      v-for="proposition in filteredPropositions"
      :key="proposition.propositionId"
      class="proposition-alternative"
      @click="e => selectProposition(e, proposition.propositionId)"
    >
      <div
        v-for="(residence, index) in proposition.residences"
        :key="residence.streetAddress + proposition.propositionId"
        class="alternative-wrapper"
      >
        <div
          :class="[
            'proposition-image',
            {
              'placeholder-img':
                !residence.images ||
                (residence.images && residence.images.length < 1)
            }
          ]"
          :style="[
            residence.images && residence.images.length > 0
              ? {
                  backgroundImage: `url(${residence.images[0].fileName})`,
                  opacity: 1
                }
              : ''
          ]"
        />
        <div class="inner">
          <p class="text">
            <span class="bold dark">{{ residence.streetAddress }}</span>
          </p>
          <p v-if="index === 0" class="text">
            {{ $t('proposition_list_ad_num') }}
            <span>{{ proposition.propositionNo }}</span>
          </p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';

export default {
  name: 'PropositionList',

  props: {
    close: {
      type: Function,
      required: true
    },
    propositions: {
      type: Array,
      required: true
    },
    usedIn: {
      type: String,
      default: ''
    }
  },

  computed: {
    ...mapGetters({
      isMobile: 'screenSize/isMobile',
      primaryPropositionId: 'myPropositions/primaryPropositionId'
    }),

    filteredPropositions() {
      const filtered = this.propositions.filter(
        proposition => proposition.propositionId !== this.primaryPropositionId
      );

      window.Logger.log('[Propositions in propositionlist]: ', filtered);
      return filtered;
    }
  },

  methods: {
    ...mapActions({
      setPrimaryProposition: 'myPropositions/setPrimaryProposition'
    }),

    selectProposition(event, id) {
      event.stopPropagation();
      this.setPrimaryProposition({ primaryPropositionId: id });
      this.close();
    }
  }
};
</script>

<style lang="scss" scoped>
.proposition-list {
  background-color: white;
  border: 1px solid #e4e4e4;
  box-shadow: 0 4px 17px -3px rgba(0, 0, 0, 0.15);
  left: 0px;
  overflow-y: scroll;
  position: absolute;
  top: 35px;
  width: 100%;
  z-index: 500;

  @media ($desktop) {
    max-height: 400px;

    &.my-propositions-page {
      top: 75px;
    }
  }

  @media ($mobile) {
    height: 100%;
    padding: 0;
    position: relative;
    top: 0;
    width: 100%;
  }
}

.proposition-alternative {
  cursor: pointer;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 14px 14px;
  width: 100%;

  &:hover {
    background-color: rgba(0, 0, 0, 0.05);
  }

  &:not(:last-child) {
    border-bottom: 1px solid rgba(0, 0, 0, 0.1);
  }
}

.alternative-wrapper {
  align-items: center;
  display: flex;
  width: 100%;

  &:nth-child(2) {
    margin-top: 5px;
  }
}

.inner {
  color: $text-secondary;
  display: flex;
  flex-direction: column;
  font-size: 14px;
  justify-content: center;
  padding-left: 10px;
  width: 100%; //IE
}

.text {
  margin: 0;
  padding: 2px;
}

.bold {
  font-weight: 600;
}

.dark {
  color: $text-primary;
}

.proposition-image {
  background-color: #f7f7f9;
  background-image: url('../../assets/svg/placeholderImage/placeholder-image-medium.svg');
  background-position: center;
  background-size: cover;
  border-radius: 5px;
  height: 50px;
  width: 50px;
  min-width: 50px;

  &.placeholder-img {
    background-size: 80%;
  }
}
</style>
