<template>
  <div class="edit-proposition-settings-container">
    <div class="edit-proposition-settings">
      <BaseBackButton @click="goBack" class="back-button" />
      <h1 v-if="proposition" class="page-heading">
        {{ proposition.residences[0].streetAddress }}
        <template v-if="proposition.residences[1]">
          & {{ proposition.residences[1].streetAddress }}
        </template>
      </h1>

      <template v-if="propositionSettings">
        <div class="checkbox-list">
          <h4 class="checkbox-list-heading">
            {{ $t('edit_prop_settings_swaptype') }}
          </h4>
          <BaseCheckbox
            :label="$t('edit_wish_show_triangle_in_swap_list')"
            :value="propositionSettings.enableTriangle"
            :selected="propositionSettings.enableTriangle"
            :is-checkbox-dark="true"
            class="checkbox"
            @change="
              propositionSettings.enableTriangle =
                !propositionSettings.enableTriangle
            "
          />

          <BaseCheckbox
            :label="$t('edit_wish_show_manual')"
            :value="propositionSettings.enableManual"
            :selected="propositionSettings.enableManual"
            :is-checkbox-dark="true"
            class="checkbox"
            @change="
              propositionSettings.enableManual =
                !propositionSettings.enableManual
            "
          />
        </div>

        <div v-if="proposition.swapType !== 21" class="checkbox-list">
          <h4 class="checkbox-list-heading">
            {{ $t('edit_prop_settings_adtype') }}
          </h4>
          <BaseRadioButton
            key="11"
            v-model="propositionSettings.swapType"
            :value="11"
            :selected="propositionSettings.swapType"
            :label="$t('edit_prop_settings_label_11')"
            :is-button-dark="true"
            class=""
          />
          <BaseRadioButton
            key="12"
            v-model="propositionSettings.swapType"
            :value="12"
            :selected="propositionSettings.swapType"
            :label="$t('edit_prop_settings_label_12')"
            :is-button-dark="true"
            class=""
          />
        </div>

        <div class="button-container">
          <BaseButton @click="handleSaveClick">
            <template v-if="isSaving">{{
              $t('edit_user_credentials_loading')
            }}</template>
            <template v-else>{{
              $t('edit_user_credentials_save')
            }}</template></BaseButton
          >
        </div>
      </template>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import { lbApiBeta } from '../../utils/axiosConfig';

export default {
  name: 'EditPropositionSettings',

  data() {
    return {
      propositionId: null,
      proposition: null,
      isSaving: false,
      propositionSettings: null
    };
  },

  head: {
    title() {
      return {
        inner: this.$t('edit_prop_settings') || ''
      };
    }
  },

  computed: {
    ...mapGetters({
      isMobile: 'screenSize/isMobile',
      userPropositions: 'myPropositions/propositions'
    }),

    editPropositionPath() {
      return this.$routes.EDIT_PROPOSITION + '/' + this.propositionId;
    }
  },

  async created() {
    this.propositionId = this.$route.params.propositionId;

    window.scrollTo(0, 0);

    await this.getProposition();

    this.getSettings();
  },

  methods: {
    ...mapActions({
      setToast: 'toast/setToast',
      fetchPropositions: 'myPropositions/fetchPropositions'
    }),

    async getProposition() {
      await this.fetchPropositions();

      this.proposition = this.userPropositions.find(
        id => id.propositionId == this.propositionId
      );
    },

    handleSwapTypeChange() {},

    goBack() {
      this.$router.push(this.editPropositionPath);
    },

    handleSaveClick() {
      this.updateSettings();
      this.isSaving = true;
    },

    async getSettings() {
      try {
        const { data } = await lbApiBeta.get(
          `/api/v2/proposition/${this.propositionId}/swap-settings`
        );

        this.propositionSettings = data;
      } catch (e) {
        console.log(e);
      }
    },

    async updateSettings() {
      await lbApiBeta
        .post(
          `/api/v2/proposition/${this.propositionId}/swap-settings`,
          this.propositionSettings
        )
        .then(() => {
          this.setToast({ title: this.$t('toast_changes_saved') });

          this.$router.push(this.editPropositionPath);
        })
        .catch(() => {
          this.setToast({
            title: this.$t('toast_general_error_message'),
            timer: 5
          });
        })
        .then(() => {
          this.isSaving = false;
        });
    }
  }
};
</script>

<style lang="scss" scoped>
.edit-proposition-settings-container {
  max-width: none;
  width: 100%;
  background-color: #f8f8fa;
  min-height: 100vh;
}

.edit-proposition-settings {
  padding: 0 10px;

  @media ($mobile) {
    padding: 0 16px;
  }

  @media ($small-desktop) {
    width: 96%;
  }

  @media ($desktop) {
    max-width: 700px;
    width: 90%;
    margin: 0 auto;
  }
}

.edit-proposition-settings .back-button {
  @media ($desktop) {
    margin-left: 0;
  }
}

.page-heading {
  margin-top: 0;
  margin-bottom: 24px;
  font-size: 28px;

  @media ($mobile) {
    margin-top: 20px;
    font-size: 26px;
  }
}

.checkbox-list {
  margin: 3rem 0;
}

.checkbox {
  margin-bottom: 1rem;
}

.button-container {
  text-align: center;
  margin-top: 20px;
  margin-bottom: 200px;

  @media ($desktop) {
    margin-bottom: 50px;
  }
}
</style>
