var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"cancel-step-wrapper"},[(_vm.changeSubscriptionLoading)?_c('spinner'):_vm._e(),(_vm.offerType === 'SAVE')?_c('h2',{staticClass:"title"},[_vm._v(" "+_vm._s(_vm.$t('offer_title_save'))+" ")]):_c('h2',{staticClass:"title"},[_vm._v(_vm._s(_vm.$t('offer_title')))]),(_vm.offer1)?_c('p',{staticClass:"subtitle"},[_vm._v(_vm._s(_vm.offer1.subtitle))]):_vm._e(),(_vm.offer1)?_c('div',{staticClass:"offer"},[(_vm.offer1.isCheapest)?_c('div',{staticClass:"cheapest-tag"},[_vm._v(" "+_vm._s(_vm.$t('offer_tag'))+" ")]):_vm._e(),_c('div',{staticClass:"offer-price-wrapper"},[_c('div',{staticClass:"offer-days"},[_vm._v(_vm._s(_vm.offer1.offerTitle))]),_c('div',{staticClass:"offer-price"},[_vm._v(_vm._s(_vm.offer1.subscriptionPrice))])]),_c('div',{staticClass:"offer-info"},[(_vm.offer1.savings)?_c('span',[_vm._v(" "+_vm._s(_vm.$t('offer_savings', { savings: _vm.offer1.savings }))+" ")]):_vm._e(),_c('span',[_vm._v(" "+_vm._s(_vm.$t('offer_payment_period', { offerDays: _vm.offer1.offerDays }))+" ")])]),_c('div',{staticClass:"offer-button-wrapper"},[_c('primary-button',{staticClass:"offer-button",attrs:{"accent":true},nativeOn:{"click":function($event){return (() =>
            _vm.changeSubscription({
              newPrice: _vm.offer1.subscriptionPrice,
              productId: _vm.offer1.productId,
              currentProductId: _vm.currentProductId,
              changeContext: _vm.changeContext
            })).apply(null, arguments)}}},[_vm._v(_vm._s(_vm.$t('offer_action')))])],1)]):_vm._e(),(_vm.offer2)?_c('div',{staticClass:"offer"},[_c('div',{staticClass:"offer-price-wrapper"},[_c('div',{staticClass:"offer-days"},[_vm._v(_vm._s(_vm.offer2.offerTitle))]),_c('div',{staticClass:"offer-price"},[_vm._v(_vm._s(_vm.offer2.subscriptionPrice))])]),_c('div',{staticClass:"offer-info"},[(_vm.offer2.savings)?_c('span',[_vm._v(" "+_vm._s(_vm.$t('offer_savings', { savings: _vm.offer2.savings }))+" ")]):_vm._e(),_c('span',[_vm._v(_vm._s(_vm.$t('offer_payment_period', { offerDays: _vm.offer2.offerDays })))])]),_c('div',{staticClass:"offer-button-wrapper"},[_c('primary-button',{staticClass:"offer-button",attrs:{"accent":true},nativeOn:{"click":function($event){return (() =>
            _vm.changeSubscription({
              newPrice: _vm.offer2.subscriptionPrice,
              productId: _vm.offer2.productId,
              currentProductId: _vm.currentProductId,
              changeContext: _vm.changeContext
            })).apply(null, arguments)}}},[_vm._v(_vm._s(_vm.$t('offer_action')))])],1)]):_vm._e(),(_vm.offerType === 'SAVE')?_c('div',{staticClass:"button-wrapper"},[_c('link-button',{nativeOn:{"click":function($event){return _vm.handleRejectOffer.apply(null, arguments)}}},[_vm._v(_vm._s(_vm.$t('offer_reject')))]),_c('link-button',{nativeOn:{"click":function($event){return _vm.closeFunction.apply(null, arguments)}}},[_vm._v(_vm._s(_vm.$t('offer_cancel_and_back')))])],1):_c('div',{staticClass:"button-wrapper"},[_c('link-button',{nativeOn:{"click":function($event){return _vm.closeFunction.apply(null, arguments)}}},[_vm._v(_vm._s(_vm.$t('offer_cancel')))])],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }