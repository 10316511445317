<template>
  <div class="tip-box">
    <div class="tip-icon"></div>
    <div class="tip-text">
      <span class="tip-title">{{ title }}&nbsp;</span>{{ text }}
    </div>
  </div>
</template>

<script>
export default {
  name: 'MobileTipBox',

  props: {
    title: {
      type: String,
      default: function () {
        return this.$t('mobile_tip_box_title');
      }
    },
    text: {
      type: String,
      default: ''
    }
  }
};
</script>

<style lang="scss" scoped>
.tip-box {
  border: 1px solid #dddcdb;
  border-radius: 8px;
  padding: 12px 10px 12px 8px;
  line-height: 1.4;
  margin-top: 24px;
  margin-bottom: 24px;
  position: relative;
  overflow: hidden;
  width: 100%;
  background-color: #fff;

  @media ($desktop) {
    margin-bottom: 4px;
  }
}

.tip-icon {
  position: absolute;
  top: 12px;
  left: 10px;
  height: 26px;
  width: 26px;
  background-position: center;
  background-size: contain;
  background-image: url('@/assets/svg/signup/lightbulb.svg');
  margin-right: 8px;
}

.tip-text {
  padding-left: 32px;
  padding-right: 0;
  font-size: 0.9rem;
}

.tip-title {
  font-weight: 600;
}
</style>
