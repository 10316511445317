/* eslint-disable prettier/prettier */
import axios from 'axios';
import { lbApiBeta, authenticationApi } from '../../../utils/axiosConfig';
import i18n, { routeLocale } from '@/i18n';

import { currentCountry } from '@/plugins/country/module';
import { searchApi } from '@/utils/axiosConfig';

async function search(searchTerm) {
  try {
    const res = await axios.get(
      `${i18n.t(
        'url_geolocation2',
        routeLocale
      )}/search/${currentCountry}/${encodeURI(searchTerm)}`
    );

    return { val: res.data, error: false };
  } catch (e) {
    return { val: e, error: true };
  }
}

async function getMultiPolygon(areas) {
  try {
    const res = await axios.post(
      `${i18n.t(
        'url_geolocation2',
        routeLocale
      )}/location/${currentCountry}/geoJson`,
      {
        locationIds: areas
      }
    );

    return { val: res.data, error: false };
  } catch (e) {
    return { val: e, error: true };
  }
}

async function getWishAreaButtons() {
  try {
    const res = await axios.get(
      `${i18n.t('url_geolocation2', routeLocale)}/wisharea/${currentCountry}`,
      {}
    );

    return { val: res.data, error: false };
  } catch (e) {
    return { val: e, error: true };
  }
}

async function getMatchingApartments(areas) {
  try {
    const res = await searchApi.post('search', {
      page: 1,
      pageSize: 1,
      swapTypes: [11],
      residence: {
        rooms: { min: 1 },
        sqm: { min: 0 },
        rent: { min: 0 },
        geolocations: areas
      }
    });

    return { val: res.data, error: false };
  } catch (e) {
    return { val: e, error: true };
  }
}

async function registerUser(email, name, ssoProfile) {
  try {
    const { data } = await lbApiBeta.post(
      i18n.t('path_email_registration', routeLocale),
      {
        email,
        name,
        ...ssoProfile
      },
      {
        headers: {
          'Content-Type': 'application/json'
        }
      }
    );

    // registration success
    return data;
  } catch (e) {
    return { status: e.response.status, message: 'Could not create user' };
  }
}

async function registerProposition({
  wishes,
  residences,
  swapTimeFrame,
  selectedAreas
}) {
  const postObj = {
    wishes: wishes.map((wish, index) => ({
      rooms: parseInt(wish.minRooms),
      sqm: parseInt(wish.minSize),
      rent: parseInt(wish.maxRent),
      geolocations: selectedAreas[index].map(a => a.id)
    })),
    residences: residences.map(residence => ({
      rooms: parseInt(residence.rooms),
      floor: parseInt(residence.floor),
      sqm: parseInt(residence.sqm),
      rent: parseInt(residence.rent),
      streetAddress: `${residence.streetAddress} ${
        residence.streetNumber ? residence.streetNumber : ''
      }`,
      coordinates: residence.coordinates,
      zipCode: residence.zipCode.replace(' ', '')
    })),
    swapTimeFrame
  };

  try {
    const headers = {
      'Content-Type': 'application/json'
    };

    if (window._token) {
      headers['JWAUTH'] = window._token;
    }

    const data = await lbApiBeta.post(
      i18n.t('path_proposition_registration', routeLocale),
      postObj,
      {
        headers
      }
    );

    if (data.status !== 200) {
      console.error('Error', data);
      throw new Error('Couldnt create proposition 1');
    }

    return data;
  } catch (e) {
    console.error('Error', e);
    throw new Error('Couldnt create proposition 2');
  }
}

async function login({ email, password }) {
  try {
    const result = await authenticationApi.post('/auth', {
      username: email,
      password: password
    });

    if (!result.status === 200) throw Error('Something went wrong');

    return true;
  } catch (e) {
    return false;
  }
}

async function facebookLogin({ accessToken }) {
  try {
    const result = await authenticationApi.post(
      `facebook/login?accessToken=${accessToken}`
    );
    if (!result.status === 200) throw Error('Something went wrong');

    return true;
  } catch (e) {
    return false;
  }
}

async function googleLogin({ accessToken }) {
  try {
    const result = await authenticationApi.post(
      `google/login?accessToken=${accessToken}`
    );
    if (!result.status === 200) throw Error('Something went wrong');

    return true;
  } catch (e) {
    return false;
  }
}

function validateEmail(value) {
  let valid = true;

  const re =
    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

  if (!re.test(value)) {
    valid = false;
  }

  return valid;
}

export default {
  search,
  getMultiPolygon,
  getWishAreaButtons,
  getMatchingApartments,
  registerUser,
  registerProposition,
  validateEmail,
  login,
  facebookLogin,
  googleLogin
};
