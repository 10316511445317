import { SORT_TYPES } from '.';
import store from '@/store/store';
import { router } from '../../../main';

function swapListType(state) {
  return state.swapListType;
}
function swapFilterType(state) {
  return state.swapFilterType;
}
function sortType(state) {
  return state.sortType;
}

function swaps(state) {
  return state.swaps;
}

function preFilterCountTotal(state) {
  return state.preFilterCountTotal;
}
function preFilterCountUnmarked(state) {
  return state.preFilterCountUnmarked;
}
function postFilterCountTotal(state) {
  return state.postFilterCountTotal;
}
function postFilterCountWithDiffs(state) {
  return state.postFilterCountWithDiffs;
}
function postFilterCountUnmarked(state) {
  return state.postFilterCountUnmarked;
}

function pendingSwapCount(state) {
  return state.unmarkedSwaps;
}

function loading(state) {
  return state.loading;
}

function swapView(state) {
  return state.swapView;
}
function swapDetails(state) {
  return state.swapView.swapDetails;
}
function swapViewLoading(state) {
  return state.swapView.loading;
}
function currentSwapViewTab(state) {
  return state.swapView.currentTab;
}

function currentPage(state) {
  return state.currentPage;
}

function totalPages(state) {
  return state.totalPages;
}

function hasMore(state) {
  return state.hasMore;
}

function swapAlternatives(state) {
  return state.swapView.swapAlternatives.alternatives;
}
function swapAlternativesCurrentPage(state) {
  return state.swapView.swapAlternatives.currentPage;
}
function swapAlternativesTotalPages(state) {
  return state.swapView.swapAlternatives.totalPages;
}
function swapAlternativesLoading(state) {
  return state.swapView.swapAlternativesLoading;
}

function searchFilters(state) {
  return state.filter;
}
function filterSetup(state) {
  return state.filterSetup;
}

function filterCount(state) {
  return state.filterCount;
}

function fullInterestSwapsCount(state) {
  return state.fullInterestSwaps;
}

function hasNewSwaps(state) {
  return state.newSwaps > 0;
}

function newSwapsCount(state) {
  return state.newSwaps;
}

function undistributedCount(state) {
  return state.undistributed;
}

function swapItemMode(state) {
  return state.swapItemMode;
}

function sortDirection(state) {
  return state.sortDirection;
}

function currentPropositionId(state) {
  const primaryPid = store.getters['myPropositions/primaryPropositionId'];
  return (
    router.currentRoute.otherUserPropositionId ||
    state.listPropositionId ||
    primaryPid
  );
}

function isUserList(state) {
  return (
    !state.listPropositionId || // Without this, isUserList is not reactive (Why?)
    !router.currentRoute.params.otherUserPropositionId ||
    state.listPropositionId ==
      store.getters['myPropositions/primaryPropositionId'] ||
    store.getters['myPropositions/propositions'].some(
      x => x.propositionId == state.listPropositionId
    )
  );
}

function listPropositionId(state) {
  return state.listPropositionId;
}

function address(state) {
  return state.address;
}

function searchTerm(state) {
  return state.searchTerm;
}

function areaGroups(state) {
  return state.areaGroups;
}

function selectedFilterAreas(state) {
  return state.filter.postalAreas;
}

function swapInterestCounts(state) {
  return state.swapInterestCounts;
}

function swapInterestCountsUnseenTotal(state) {
  if (!state.swapInterestCounts) {
    return null;
  }

  return (
    state.swapInterestCounts.full.unseen +
    state.swapInterestCounts.interestedInMine.unseen
  );
}

function defaultSort() {
  return SORT_TYPES.HIGHEST_SCORE;
}

function showSwapAddedBadge(state) {
  return state.showSwapAddedBadge;
}

function queueCount(state) {
  return state.queueCount;
}

function unansweredSwaps(state) {
  return state.unansweredSwaps;
}

function answeredSwaps(state) {
  return state.answeredSwaps;
}

function removedSwaps(state) {
  return state.removedSwaps;
}

function noInterestMarkedIds(state) {
  return state.noInterestMarkedIds;
}

function reverseInterestMarkedIds(state) {
  return state.reverseInterestMarkedIds;
}

function selectedFilters(state) {
  return state.selectedFilters;
}

export default {
  defaultSort,
  swapListType,
  sortType,
  isUserList,
  swaps,
  pendingSwapCount,
  loading,
  swapView,
  currentSwapViewTab,
  swapDetails,
  swapViewLoading,
  currentPage,
  totalPages,
  swapAlternativesCurrentPage,
  swapAlternatives,
  swapAlternativesTotalPages,
  swapAlternativesLoading,
  searchFilters,
  filterSetup,
  filterCount,
  fullInterestSwapsCount,
  hasNewSwaps,
  newSwapsCount,
  undistributedCount,
  swapItemMode,
  sortDirection,
  address,
  searchTerm,
  preFilterCountTotal,
  preFilterCountUnmarked,
  postFilterCountTotal,
  postFilterCountWithDiffs,
  postFilterCountUnmarked,
  swapFilterType,
  areaGroups,
  selectedFilterAreas,
  swapInterestCounts,
  showSwapAddedBadge,
  swapInterestCountsUnseenTotal,
  currentPropositionId,
  listPropositionId,
  queueCount,
  hasMore,
  unansweredSwaps,
  answeredSwaps,
  removedSwaps,
  noInterestMarkedIds,
  reverseInterestMarkedIds,
  selectedFilters
};
