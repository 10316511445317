<template>
  <section
    id="conversation-content-wrapper"
    ref="conversationContentWrapper"
    class="conversation-content-wrapper"
    :class="[
      mode,
      {
        'used-on-page': usedOnPage,
        'used-in-matches': usedInMatches,
        'height-adjustment': !isIos
      }
    ]"
  >
    <section
      v-if="isLoading || !interestsInfo.isDoneLoading"
      class="message-loader"
    >
      <div class="spinner-wrapper">
        <ASpinner :classes="['loading-spinner']"></ASpinner>
      </div>
    </section>

    <section v-if="room && !standalone" class="back-wrapper">
      <AAccordion
        :hide-borders="true"
        :close-on-outside-click="true"
        :active-on-init="detailsVisible"
        :watch-active="true"
        :hide-arrow="true"
        @change="detailsVisible = $event"
      >
        <template slot="title">
          <router-link
            v-if="!usedInMatches"
            :to="$routes.MESSAGES"
            class="back-link"
            :class="{ hidden: !isMobile }"
          >
            <span class="icon-wrapper">
              <BaseIcon :icon-file="'arrow-back'" />
            </span>
          </router-link>
          <div
            v-else
            class="back-link"
            :class="{ hidden: !isMobile }"
            @click="backArrowClicked"
          >
            <span class="icon-wrapper">
              <BaseIcon :icon-file="'arrow-back'" />
            </span>
          </div>

          <p class="text-center back-title title">
            <span v-html="titleHtml"></span>
          </p>

          <span
            :class="[
              'icon-wrapper',
              'swap-icon-wrapper',
              { 'is-open': detailsVisible }
            ]"
          >
            <BaseIcon :icon-file="'chevron-down'" class="icon" />
          </span>
        </template>

        <SwapDetails :proposition-ids="currentPropositionIds"></SwapDetails>
      </AAccordion>
    </section>
    <div
      v-if="usedInMatches && inRoom.id == 0 && !matchIsInactive"
      class="new-match-message empty-area"
    >
      <BaseIcon
        :icon-file="'chat-icon'"
        :width="88"
        :height="88"
        class="chat-icon"
      />
      <h2 class="title">{{ $t('matches_emptychat_title') }}</h2>
      <p class="paragraph">
        {{ $t('matches_emptychat_body') }}
      </p>
    </div>

    <section
      v-if="!room && !isLoading && standalone && interestsInfo.isDoneLoading"
      class="empty-area"
    >
      <template v-if="isFullInterestSwap">
        <h2 class="title">{{ $t('chat_conversation_not_started_title') }}</h2>
        <p
          class="paragraph"
          v-html="
            $t('chat_conversation_not_started_text', {
              names: propositionsText
            })
          "
        ></p>
      </template>
      <template v-else-if="!isFullInterestSwap && interestsInfo.isDoneLoading">
        <BaseIcon
          :icon-file="'chat-icon'"
          :width="88"
          :height="88"
          class="chat-icon"
        />
        <p
          class="paragraph"
          v-html="
            $t('chat_conversation_not_started_text_not_all_intresested', {
              names: propositionsText
            })
          "
        ></p>
      </template>
    </section>

    <section
      v-if="!isLoading && room && room.id && data && owners"
      id="conversations-wrapper"
      class="conversations-wrapper"
      :class="[
        {
          iphone: isIos,
          'interest-hidden': inactivatedPropositionExists,
          'no-interest-level-toggle': !showInterestLevelToggle,
          'is-freemium-chat-unlocked':
            primaryProposition.isFreemium && !room.isFreemiumLocked
        },
        [mode]
      ]"
    >
      <p
        v-if="room.initiatorId || orderedMessages.length > 0"
        class="text-small text-center text-started"
      >
        <span>
          {{
            $t('chat_conversation_started_text', {
              date: roomCreatedText,
              name: owners[room.initiatorId]
                ? owners[room.initiatorId].displayName
                : owners[orderedMessages[0].senderId].displayName
            })
          }}
        </span>
      </p>

      <p
        v-if="data.hasMore && !isLoadingMore && interestsInfo.isDoneLoading"
        class="load-more text-link"
        @click="loadMore()"
      >
        {{ $t('chat_conversation_show_more') }}
      </p>

      <div v-else-if="isLoadingMore" class="load-more-spinner">
        <div class="spinner-small-wrapper">
          <ASpinner :classes="['loading-spinner']"></ASpinner>
        </div>
      </div>

      <div v-if="interestsInfo.isDoneLoading">
        <Message
          v-for="(message, index2) of orderedMessages"
          :key="index2 + message.id"
          :message="message"
          :owners="owners"
          :user-id="userId"
          :is-mobile="isMobile"
          :owner-interests="ownerInterests"
          :animate="index2 > initialMessagesLength - 1"
          :used-in-matches="usedInMatches"
        ></Message>

        <div
          v-if="
            !isFullInterestSwap && interestsInfo.isDoneLoading && !usedInMatches
          "
          class="missing-interest-banner not-standalone"
          v-html="$t('chat_conversation_start_conversation')"
        ></div>
      </div>
    </section>

    <section
      v-if="!isLoading"
      class="control-wrapper"
      :class="[
        mode,
        {
          iphone: isIos
        },
        { 'no-control': !showInterestLevelToggle }
      ]"
    >
      <div v-if="showInterestLevelToggle" class="interest-wrapper">
        <AAccordion
          :hide-borders="true"
          :active-on-init="interestAccordionActive"
          :arrow-reverse="true"
          :close-on-outside-click="true"
          :locked="false"
          :watch-active="true"
          @change="handleAccordionToggle"
        >
          <div slot="title">
            <span
              v-if="
                interestsInfo.interestLevel === null || interestAccordionActive
              "
              class="interest-unselected-title"
            >
              {{
                usedInMatches
                  ? $t('chat_conversation_interest_title_variant')
                  : $t('chat_conversation_interest_title')
              }}</span
            >

            <ChatInterestSelect
              v-else
              :color="currentInterestColor"
              :class="{ 'interest-unselected-title': interestAccordionActive }"
            >
              {{ currentInterestText }}
            </ChatInterestSelect>
          </div>

          <div
            class="interest-level-selector-wrapper"
            :class="{ desktop: !isMobile }"
          >
            <ChatInterestSelect
              color="green"
              :selected="
                interestsInfo.interested === true &&
                interestsInfo.interestLevel === 0
              "
              @click="handleInterestLevelClick(0)"
            >
              {{ $t('chat_interest_select_0') }}
            </ChatInterestSelect>

            <ChatInterestSelect
              color="blue"
              :selected="
                interestsInfo.interested === true &&
                interestsInfo.interestLevel === 1
              "
              @click="handleInterestLevelClick(1)"
            >
              {{ $t('chat_interest_select_1') }}
            </ChatInterestSelect>

            <ChatInterestSelect
              color="orange"
              :selected="
                interestsInfo.interested === true &&
                interestsInfo.interestLevel === 2
              "
              @click="handleInterestLevelClick(2)"
            >
              {{ $t('chat_interest_select_2') }}
            </ChatInterestSelect>

            <ChatInterestSelect
              color="red"
              :selected="interestsInfo.interested === false"
              @click="handleInterestLevelClick(3)"
            >
              {{ $t('chat_interest_select_3') }}
            </ChatInterestSelect>
          </div>
        </AAccordion>
      </div>

      <div
        v-if="!isLoading && interestsInfo.isDoneLoading"
        class="new-message-wrapper"
        :class="[mode]"
      >
        <p v-if="showChatIsLockedErrorMsg" class="chat-error-msg">
          {{ $t('freemium_chat_locked_error_msg') }}
        </p>
        <div class="input-wrapper">
          <div class="area-wrapper">
            <AArea
              id="input-area"
              v-model="newMessage"
              :disabled="isSending || isLoading || matchIsInactive"
              :rows="areaRows"
              class="input-area"
              @focus="handleAreaFocus()"
              @outside="handleAreaBlur($event)"
            ></AArea>
          </div>
          <div class="send-wrapper">
            <AButton
              :is-loading="isSending"
              :is-disabled="!isNewMessageValid || isSending || isLoading"
              :is-small="isMobile"
              class="is-rounded is-secondary"
              @click="sendMessage()"
              >{{ $t('chat_conversation_send') }}</AButton
            >
          </div>
        </div>
      </div>
    </section>
  </section>
</template>

<script>
import { lbApiBeta } from '../../utils/axiosConfig';
import icons from '@/utils/icon-constants';
import { mapGetters, mapActions } from 'vuex';

import ASpinner from '@/components/atomic/atoms/a-spinner';
import AArea from '@/components/atomic/atoms/a-area';
import AButton from '@/components/atomic/atoms/a-button';
import AAccordion from '@/components/atomic/atoms/a-accordion';
import Message from './Message';
import SwapDetails from '@/components/SwapDetails/SwapDetails';
import ChatInterestSelect from './ChatInterestSelect.vue';

import { subscribe, unsubscribe } from '../../utils/socket';
import { randomUuid } from '@/utils/helpers/string';
import interestService from '@/store/modules/interests/services';
import events from '@/utils/helpers/events';
import { isDebug } from '../../utils/debug';
import { log } from '../../utils/logger';

export default {
  name: 'Messages',

  components: {
    ASpinner,
    AArea,
    AButton,
    AAccordion,
    Message,
    SwapDetails,
    ChatInterestSelect
  },

  props: {
    inRoom: {
      type: [Object],
      default: () => {}
    },

    userId: {
      type: [Number, String],
      default: null
    },

    isImpersonated: {
      type: Boolean,
      default: false
    },

    isMobile: {
      type: [Boolean],
      default: false
    },

    standalone: {
      type: [Boolean],
      default: false
    },

    propositionIds: {
      type: Array,
      required: false,
      default: () => []
    },

    mode: {
      type: [String, null],
      default: ''
    },

    scrollElements: {
      type: [Array, null],
      default: null
    },

    where: {
      type: [String, null],
      default: ''
    },

    usedOnPage: {
      type: Boolean,
      default: false
    },

    usedInMatches: {
      type: Boolean,
      default: false
    },

    matchIsInactive: {
      type: Boolean,
      default: false
    },

    isFreemiumLocked: {
      type: Boolean,
      default: false
    }
  },

  data() {
    return {
      initialMessagesLength: 0,
      interestAccordionActive: false,
      icons: icons,
      data: null,
      owners: {},
      isLoading: false,
      isSending: false,
      newMessage: '',
      detailsVisible: false,
      isLoadingMore: false,
      interestsInfo: {
        interested: undefined,
        interestLevel: null,
        isDoneLoading: false
      },
      room: null,
      propositions: null,
      deviceId: randomUuid(),
      areaRows: 3, //this.isMobile ? 1 : 3,
      defaultAreaRows: 3,
      preventInputRowChange: false,
      ownerInterests: null,
      othersInterested: false,
      isFullInterestSwap: true,
      showChatIsLockedErrorMsg: false
    };
  },

  head: {
    title() {
      return {
        inner: `${
          this.usedInMatches
            ? this.$t('meta_title_matches')
            : this.$t('meta_title_chat')
        } - ${this.titleText}`
      };
    }
  },

  computed: {
    ...mapGetters({
      primaryProposition: 'myPropositions/primaryProposition'
    }),
    matchesViewABTest() {
      return this.$growthbook.isFeatureFlagEnabled('matches-view');
    },

    showInterestLevelToggle() {
      return (
        !this.inactivatedPropositionExists &&
        this.interestsInfo.isDoneLoading &&
        (this.isFullInterestSwap || this.usedInMatches)
      );
    },

    currentInterestColor() {
      if (!this.interestsInfo) {
        return null;
      }

      if (
        this.interestsInfo.interested === false ||
        this.interestsInfo.interestLevel === 3
      ) {
        return 'red';
      } else if (this.interestsInfo.interestLevel === 0) {
        return 'green';
      } else if (this.interestsInfo.interestLevel === 1) {
        return 'blue';
      } else if (this.interestsInfo.interestLevel === 2) {
        return 'orange';
      } else {
        return null;
      }
    },

    currentInterestText() {
      if (!this.interestsInfo) {
        return '';
      }

      if (
        this.interestsInfo.interested === false ||
        this.interestsInfo.interestLevel === 3
      ) {
        return this.$t('chat_interest_select_3');
      } else if (this.interestsInfo.interestLevel === 0) {
        return this.$t('chat_interest_select_0');
      } else if (this.interestsInfo.interestLevel === 1) {
        return this.$t('chat_interest_select_1');
      } else if (this.interestsInfo.interestLevel === 2) {
        return this.$t('chat_interest_select_2');
      } else {
        if (this.usedInMatches) {
          return this.$t('chat_conversation_interest_title_variant');
        }
        return this.$t('chat_conversation_interest_title', {
          streetName: this.bPartAddress
        });
      }
    },

    isIos() {
      return /iPad|iPhone|iPod/.test(navigator.userAgent);
    },

    chatOwnerId() {
      return this.$store.state.menu.chatOwnerId;
    },

    orderedMessages() {
      if (!this.data) return null;

      return this.data.messages
        .slice()
        .sort((a, b) => a.createdAt - b.createdAt);
    },

    roomCreatedText() {
      if (!this.room) return '';

      if (this.$dayjs.tz(this.room.createdAt) > this.$dayjs.tz()) {
        return this.$dayjs.tz().fromNow();
      } else return this.$dayjs.tz(this.room.createdAt).fromNow();
    },

    propositionsText() {
      if (!this.propositions) return '';

      return this.propositions
        .filter(x => x.userId != this.userId)
        .map(x => `<b>${x.address}</b>`)
        .join(` ${this.$t('chat_conversation_general_and')} `);
    },

    titleText() {
      if (!this.room) return '';

      return this.room.conversations
        .filter(x => x.owner.userId != this.userId)
        .map(x => `${x.owner.displayName.trim()}, ${x.owner.address}`)
        .join(' & ');
    },

    titleHtml() {
      if (!this.room) return '';

      return this.room.conversations
        .filter(x => x.owner.userId != this.userId)
        .map(x => `<b>${x.owner.displayName.trim()}</b>, ${x.owner.address}`)
        .join('<br/>');
    },

    bPartAddress() {
      if (this.propositions && this.propositions[1]) {
        return this.propositions[1].address;
      }

      if (!this.room) {
        return '';
      }

      return this.room.conversations[1].owner.address;
    },

    roomId() {
      if (!this.room) return -1;
      return this.room.id;
    },

    isNewMessageValid() {
      return this.newMessage && this.newMessage.length > 0;
    },

    userConversationId() {
      if (!this.owners) return null;

      return this.owners[this.chatOwnerId].conversationId;
    },

    currentPropositionIds() {
      return this.room.conversations.map(x => Number(x.owner.propositionId));
    },

    inactivatedPropositionExists() {
      if (!this.owners) {
        return true;
      }

      return Object.keys(this.owners).some(
        key => this.owners[key] && this.owners[key].inactivatedAt
      );
    }
  },

  watch: {
    room() {
      if (this.isIos) {
        setTimeout(() => {
          this.$refs.conversationContentWrapper.classList.add(
            'height-adjustment'
          );
          this.scrollToBottom();
        }, 200);
      }
    },
    async inRoom(newRoom) {
      this.isLoading = true;

      await this.initNewRoom(newRoom);

      this.isLoading = false;
      this.scrollToBottom();
    },

    async propositionIds(newVal, oldVal) {
      if (
        newVal &&
        oldVal &&
        JSON.stringify(newVal) === JSON.stringify(oldVal)
      ) {
        return;
      }

      await this.init();
    }
  },

  async created() {
    if (this.isMobile && this.primaryProposition.isFreemium) {
      this.setFreemiumBannerTopMargin('60px');
    }

    try {
      subscribe('chat-read', this.handleMarkAsRead);
      subscribe('chat-message-sent', this.handleNewMessage);

      this.setLayout();
      document.addEventListener('new-size', this.setLayout);
      document.addEventListener('keydown', this.sendKeyboardSendCombination);

      if (!this.isIos) {
        document.body.onresize = this.handleResize;
      }

      await this.init();
    } catch (error) {
      console.error('Messages create error:', error);
    }
  },

  beforeDestroy() {
    document.removeEventListener('keydown', this.sendKeyboardSendCombination);
    document.addEventListener('new-size', this.setLayout);
    unsubscribe('chat-message-sent', this.handleNewMessage);
    unsubscribe('chat-read', this.handleMarkAsRead);
    document.body.onresize = null;

    this.showMobileTabBar();
    this.showMobileHeader(true);

    if (this.isMobile && this.primaryProposition.isFreemium) {
      this.setFreemiumBannerTopMargin('0px');
    }
  },

  methods: {
    ...mapActions({
      setFreemiumBannerTopMargin: 'ui/setFreemiumBannerTopMargin'
    }),
    async init() {
      this.isLoading = true;
      if (this.standalone) {
        await this.getPropositionData(this.propositionIds);
        const room = await this.getRoom(this.propositionIds);

        this.$emit('isFreemiumLocked', room.isFreemiumLocked);

        if (!room || !room.conversations || room.conversations.length === 0) {
          this.room = null;
          this.isLoading = false;

          await this.getInterests();
          return;
        }

        this.room = room;
        this.$emit('title-changed', this.titleText);
      } else {
        this.room = this.inRoom;
      }

      this.getOwners(this.room.conversations);
      await this.getMessages();
      await this.getInterests();
      this.checkExpanded();

      this.isLoading = false;
      this.scrollToBottom();
    },
    handleAccordionToggle(ev) {
      if (
        this.isFullInterestSwap &&
        this.interestsInfo.interestLevel === null
      ) {
        this.handleInterestLevelClick(-1);
      }

      this.interestAccordionActive = ev;
    },
    handleAreaFocus() {
      this.$nextTick(() => {
        if (this.isMobile) {
          this.scrollToBottom();
        }
      });
    },

    handleAreaBlur(e) {
      this.$nextTick(() => {
        try {
          if (
            e &&
            e.target &&
            e.target.className &&
            e.target.className.includes('accordion')
          ) {
            this.preventInputRowChange = false;
            return;
          }
        } catch (error) {
          // Do nothing
        }

        if (!this.isMobile) {
          // this.areaRows = this.defaultAreaRows;
          return;
        }

        if (!this.newMessage) {
          // this.areaRows = 1;
        }
      });
    },

    handleResize() {
      if (
        !this.isMobile ||
        !this.isInputFocused() ||
        window.pageYOffset === 0
      ) {
        return;
      }

      this.scrollToBottom();
    },

    ...mapActions({
      sentMessage: 'gtm/sentMessage',
      showMobileHeader: 'ui/showMobileHeader',
      showMobileTabBar: 'menu/showMobileTabBar',
      hideMobileTabBar: 'menu/hideMobileTabBar',
      setToast: 'toast/setToast'
    }),

    handleMarkAsRead(data) {
      setTimeout(() => {
        if (!this.data) return;

        if (this.roomId != data.chatRoomId) {
          return;
        }

        for (const message of this.data.messages) {
          if (!message.readBy) {
            message.readBy = [data.participantId];
          } else if (!message.readBy.includes(data.participantId)) {
            message.readBy.push(data.participantId);
          }
        }

        this.$emit('chat-read');
      }, 1000);
    },

    addUnread() {
      for (let i = 0; i < this.room.conversations.length; i++) {
        if (this.room.conversations[i].ownerId == this.chatOwnerId) {
          continue;
        }

        this.room.conversations[i] = {
          ...this.room.conversations[i],
          unreadCount: this.room.conversations[i].unreadCount + 1
        };
      }
    },

    handleNewMessage(data) {
      if (!this.data) return;

      this.$nextTick(() => {
        if (
          this.chatOwnerId == data.senderId ||
          !this.room ||
          this.room.id != data.chatRoomId ||
          this.data.messages.some(x => x.id == data.id)
        ) {
          return;
        }

        data.createdAt = this.$dayjs.tz();
        this.data.messages.push(data);
        this.scrollToBottom();
        this.markAsRead();
      });
    },

    async initNewRoom(room) {
      if (!room) {
        this.room = null;
        return false;
      }

      this.showChatIsLockedErrorMsg = false;

      this.interestsInfo.interested = undefined;
      this.preventInputRowChange = false;
      this.interestAccordionActive = false;
      this.interestsInfo.isDoneLoading = false;
      this.isFullInterestSwap = true;
      this.areaRows = this.defaultAreaRows; //this.isMobile ? 1 : this.defaultAreaRows;
      this.room = room;
      this.getOwners(room.conversations);
      await this.getMessages(true);
      this.checkExpanded();
      await this.getInterests();
      this.scrollToBottom();
    },

    async getPropositionData(propositionIds) {
      this.propositions = null;

      const { data } = await lbApiBeta.post(
        '/api/messenger/participant/propositions',
        {
          propositionIds: propositionIds.map(p => parseInt(p))
        }
      );

      this.propositions = data;

      if (!this.room) {
        const title = this.propositions
          .filter(x => x.userId != this.userId)
          .map(x => `${x.address}`)
          .join(` ${this.$t('chat_conversation_general_and')} `);

        this.$emit('title-changed', title);
      }
    },

    async getRoom(propositionIds) {
      try {
        const { data } = await lbApiBeta.post(
          '/api/messenger/chat-room/find-or-mock',
          {
            propositionIds: propositionIds
          }
        );

        return data;
      } catch (error) {
        this.handleGeneralError(error);
      }
    },

    async markAsRead() {
      try {
        const { data } = await lbApiBeta.post(
          `/api/messenger/chat-room/read/${this.room.id}/${this.chatOwnerId}`
        );
        return data;
      } catch (error) {
        this.handleGeneralError(error);
      }
    },

    setLayout() {
      this.$nextTick(() => {
        if (this.isMobile && !this.usedOnPage) {
          this.areaRows = this.defaultAreaRows; // 1;
          this.showMobileHeader(false);
          this.hideMobileTabBar();
        } else {
          this.areaRows = this.defaultAreaRows;
          this.showMobileHeader(true);
        }
      });
    },

    checkExpanded() {
      if (!this.room || this.detailsVisible) {
        return false;
      }

      this.detailsVisible = this.room.conversations.some(
        x => x.owner.inactivatedAt && x.owner.id !== this.chatOwnerId
      );
    },

    sendKeyboardSendCombination(event) {
      if (event.ctrlKey && event.key === 'Enter') {
        this.sendMessage();
      }
    },

    async loadMore() {
      const { id } = this.data.messages[0];
      if (!id) return;

      this.isLoadingMore = true;

      try {
        const { data } = await lbApiBeta.get(
          `/api/messenger/message/previous/${this.chatOwnerId}/room/${this.roomId}/before/${id}?ver=2`
        );

        this.data.hasMore = data.hasMore;
        data.messages.reverse().map(x => {
          this.data.messages.unshift(x);
        });
      } catch (error) {
        this.handleGeneralError(error);
      }

      this.isLoadingMore = false;
    },

    getOwners(roomConversations) {
      this.owners = {};
      roomConversations.map((x, idx) => {
        this.owners[x.owner.id] = x.owner;
        this.owners[x.owner.id].conversationId = x.id;
        this.owners[x.owner.id].index = idx;
      });
    },

    getOwnerByIndex(index) {
      let ownerKey = Object.keys(this.owners)[index];
      if (!ownerKey) {
        return null;
      }

      return this.owners[ownerKey];
    },

    setOwnerInterests(propositionId, props) {
      if (!this.ownerInterests) {
        this.ownerInterests = {};
      }

      this.ownerInterests[propositionId] = props;
    },

    async createRoom(propositionIds) {
      try {
        const { data } = await lbApiBeta.post(
          `/api/messenger/chat-room/find-or-create`,
          {
            propositionIds: propositionIds.map(p => parseInt(p))
          }
        );

        return data;
      } catch (error) {
        this.handleGeneralError(error);
      }
    },

    async getMessages(skipLoading) {
      if ((!this.roomId || !this.chatOwnerId) && !skipLoading) {
        this.isLoading = false;
        return;
      }

      if (!skipLoading) this.isLoading = true;

      try {
        const { data } = await lbApiBeta.get(
          `/api/messenger/message/chat-room/${this.chatOwnerId}/${this.roomId}?ver=2`
        );

        this.data = data;

        this.initialMessagesLength = data.messages.length;

        events.emitEvent(events.eventTypes.CLICK, 'Chat Room', {
          unreadCount: this.room.conversations[0].unreadCount,
          sent: data.messages.filter(m => m.senderId === this.chatOwnerId)
            .length,
          received: data.messages.filter(m => m.senderId !== this.chatOwnerId)
            .length,
          hasMore: data.hasMore
        });
      } catch (error) {
        this.handleGeneralError(error);
      }

      if (!skipLoading) {
        this.isLoading = false;
      }
    },

    async sendMessage() {
      if (!this.isNewMessageValid || this.isSending) {
        return;
      }

      this.isSending = true;

      let roomCreated = false;

      if (!this.room) {
        const room = await this.createRoom(this.propositionIds);

        room.createdAt = this.$dayjs.tz();
        await this.initNewRoom(room);
        roomCreated = true;
      } else if (!this.room.id) {
        let ids = this.room.conversations.map(x => x.owner.propositionId);
        const room = await this.createRoom(ids);
        room.createdAt = this.$dayjs.tz();
        await this.initNewRoom(room);
        roomCreated = true;
      }

      try {
        const { data } = await lbApiBeta.post('/api/messenger/message', {
          chatRoomId: this.room.id,
          content: this.newMessage,
          senderId: this.chatOwnerId
        });

        if (this.primaryProposition.isFreemium && data.isFreemiumLocked) {
          this.showChatIsLockedErrorMsg = true;
          this.isSending = false;
          this.newMessage = '';

          return;
        }
        data.createdAt = this.$dayjs.tz();

        this.sentMessage({
          from: this.currentPropositionIds[0],
          to: this.currentPropositionIds.slice(1),
          where: this.where
        });

        this.newMessage = '';

        this.data.messages.push(data);

        this.addUnread();
        this.scrollToBottom();
        if (roomCreated) {
          this.$emit('room-created');
        }
      } catch (error) {
        this.handleGeneralError(error);
      }

      this.isSending = false;
      // IOS HACK
      if (this.isIos) {
        this.$nextTick(() => {
          const cwrap = this.$refs.conversationContentWrapper;
          if (cwrap) {
            cwrap.classList.remove('height-adjustment');
            setTimeout(() => {
              cwrap.classList.add('height-adjustment');
            }, 200);
          }
        });
      }
    },

    isInputFocused() {
      const element = document.getElementById('input-area');
      if (!element) return false;
      return element === document.activeElement;
    },

    async scrollToBottom() {
      this.$nextTick(() => {
        for (const className of this.scrollElements || [
          'conversations-wrapper',
          'conversation-content-wrapper'
        ]) {
          try {
            const elements = document.getElementsByClassName(className);
            if (!elements) {
              return;
            }

            [...elements].forEach(el => {
              el.scrollTo(0, el.scrollHeight);
              el.scrollTop = el.scrollHeight;
              if (!this.standalone || !this.usedInMatches) {
                window.scrollBy({
                  top: el.scrollHeight,
                  left: 0
                });
              }
            });
          } catch (error) {
            console.error(error);
          }
        }
      });
    },

    focusInput() {
      this.$nextTick(() => {
        const element = document.getElementById('input-area');
        if (element) element.focus();
      });
    },

    async getInterests() {
      try {
        let ids = [];
        this.ownerInterests = {};

        if (this.standalone) {
          ids = this.propositionIds.map(x => x).reverse();
        } else {
          ids = this.room.conversations
            .map(x => x.owner.propositionId)
            .reverse();
        }
        if (ids.length === 0) {
          return;
        }
        const aPartPropositionId = ids[ids.length - 1];
        const bPartPropositionId = ids.length > 2 ? ids[1] : ids[0];
        const lastPartPropositionId = ids[0];

        const aInterestedInBInfo = await this.isInterested(
          aPartPropositionId,
          bPartPropositionId,
          ids.length > 2 ? lastPartPropositionId : 0
        );

        this.otherInterested = false;
        this.setOwnerInterests(aPartPropositionId, aInterestedInBInfo);
        this.interestsInfo.interested = aInterestedInBInfo.interested;
        this.interestsInfo.interestLevel = aInterestedInBInfo.interestLevel;

        const lastPartInterestedInAInfo = await this.isInterested(
          lastPartPropositionId,
          aPartPropositionId,
          ids.length > 2 ? bPartPropositionId : 0
        );

        this.setOwnerInterests(
          lastPartPropositionId,
          lastPartInterestedInAInfo
        );

        this.othersInterested = lastPartInterestedInAInfo.interested == true;

        if (ids.length > 2) {
          const bInterestedInCInfo = await this.isInterested(
            bPartPropositionId,
            lastPartPropositionId,
            aPartPropositionId
          );
          this.setOwnerInterests(bPartPropositionId, bInterestedInCInfo);

          if (this.othersInterested) {
            this.othersInterested = bInterestedInCInfo.interested == true;
          }
        }

        this.isFullInterestSwap = !Object.values(this.ownerInterests).some(
          p => p.interested !== true
        );

        this.interestsInfo.isDoneLoading = true;
      } catch (error) {
        this.handleGeneralError(error);
      }
    },

    async isInterested(id1, id2, id3) {
      const returnObj = { interested: false, interestLevel: null };

      try {
        const { data } = await lbApiBeta.get(
          `/api/v2/interest/${id1}/${id2}?propositionId3=${id3}&includeLevel=true`
        );

        if (data && data.interest === null) {
          returnObj.interested = null;
        } else if (data && data.interest && data.interest > 0) {
          returnObj.interested = true;
          returnObj.interestLevel = data.interestLevel;
        }
      } catch (error) {
        this.handleGeneralError(error);
      }

      return returnObj;
    },

    async handleInterestLevelClick(interestLevel) {
      this.interestAccordionActive = false;

      if (this.interestsInfo.interestLevel === interestLevel) {
        return;
      }

      try {
        // Create room if it doesn't exist, to be able to send system message
        if (!this.room && this.standalone && interestLevel !== -1) {
          const room = await this.createRoom(this.propositionIds);
          room.createdAt = this.$dayjs.tz();
          await this.initNewRoom(room);
        }

        this.interestsInfo.interestLevel = interestLevel;
        this.interestAccordionActive = false;
        this.interestsInfo.interested = interestLevel !== 3;

        let ids = [];

        if (this.room) {
          ids = this.room.conversations.map(x => x.owner.propositionId);
        } else {
          ids = this.propositionIds;
        }

        const response = await interestService.setInterestLevel({
          propositionId: ids[0],
          propositionId2: ids[1],
          propositionId3: ids.length === 3 ? ids[2] : 0,
          interestLevel
        });
        if (response.success === false && interestLevel !== -1) {
          throw new Error('Interest level changed failed');
        }

        if (this.room && this.room.id === 0 && interestLevel !== -1) {
          ids = this.room.conversations.map(x => x.owner.propositionId);
          this.createRoom(ids);
          this.$emit('room-created');
        }

        events.emitEvent(events.eventTypes.CLICK, 'InterestLevel', {
          src: 'chat',
          interestLevel
        });
      } catch (error) {
        this.handleGeneralError(error);
      }
    },

    handleGeneralError(error) {
      if (isDebug()) {
        console.error(error);
      }

      log(error.toString(), true);
      this.setToast({
        timer: 10,
        title: this.$t('toast_general_error_message')
      });
    },

    backArrowClicked() {
      this.$emit('close-chat-clicked');
    }
  }
};
</script>

<style lang="scss" scoped>
h2,
p {
  padding: 0;
  margin: 0;
}

a {
  text-decoration: none;
  color: $main-blue;
}

.conversations-wrapper {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 100%;
  padding: 20px;
  padding-top: 80px;
  transition: all 0.1s ease-in-out;

  height: calc(
    100vh - 212px - env(safe-area-inset-bottom) - env(safe-area-inset-top)
  );

  height: calc(
    100dvh - 212px - env(safe-area-inset-bottom) - env(safe-area-inset-top)
  );

  overflow: auto;

  &.no-interest-level-toggle {
    height: calc(
      100vh - 179px - env(safe-area-inset-bottom) - env(safe-area-inset-top)
    );

    height: calc(
      100dvh - 179px - env(safe-area-inset-bottom) - env(safe-area-inset-top)
    );
  }

  .used-on-page & {
    max-height: 500px;
  }

  .used-in-matches & {
    height: 100%;
    padding-bottom: 45px;

    @media ($desktop) {
      padding-bottom: 145px;
    }
  }

  @media ($mobile) {
    margin-bottom: 40px;
    padding: 0 20px;
    margin-top: 75px;
    min-height: calc(
      100vh - 135px - 140px - env(safe-area-inset-bottom) -
        env(safe-area-inset-top)
    );

    min-height: calc(
      100dvh - 135px - 140px - env(safe-area-inset-bottom) -
        env(safe-area-inset-top)
    );

    .used-in-matches & {
      min-height: calc(
        100vh - 170px - env(safe-area-inset-bottom) - env(safe-area-inset-top)
      );

      min-height: calc(
        100dvh - 170px - env(safe-area-inset-bottom) - env(safe-area-inset-top)
      );
    }
  }

  &.is-freemium-chat-unlocked {
    @media ($mobile) {
      padding: 10px 20px;
      margin-top: 0;
      min-height: calc(
        100dvh - 188px - 140px - env(safe-area-inset-bottom) -
          env(safe-area-inset-top)
      );
      height: calc(
        100dvh - 340px - env(safe-area-inset-bottom) - env(safe-area-inset-top)
      );
    }
    @media ($desktop) {
      height: calc(
        100dvh - 270px - env(safe-area-inset-bottom) - env(safe-area-inset-top)
      );
    }
  }
}

.control-wrapper.interest-hidden {
  border-top: none !important;
}

.conversations-wrapper.interest-hidden {
  @media ($mobile) {
    height: calc(
      100vh - 165px - env(safe-area-inset-bottom) - env(safe-area-inset-top)
    );

    height: calc(
      100dvh - 165px - env(safe-area-inset-bottom) - env(safe-area-inset-top)
    );

    margin-bottom: calc(90px + env(safe-area-inset-bottom));
  }
}

.conversation-content-wrapper {
  height: 100vh;
  position: relative;
  width: 100%;
  overflow: hidden;

  &.in-modal,
  &.in-swap-view {
    overflow: visible;
  }

  @media ($mobile) {
    overflow: auto;
  }
}

.conversation-content-wrapper.height-adjustment {
  height: 100%;
}

.missing-interest-banner {
  padding: 24px 45px;
  font-weight: 400;
  font-size: 14px;
  line-height: 150%;
  letter-spacing: -0.01em;
  color: #2c3e50;
  background-color: #ffc212;

  .in-swap-view & {
    position: relative;
    width: calc(100% + 30px);
    left: -15px;

    @media ($desktop) {
      width: calc(100% + 48px);
      left: -24px;
      top: -10px;
    }
  }

  &.not-standalone {
    margin-left: -20px;
    width: calc(100% + 40px);
    margin-top: 15px;
  }

  b {
    font-weight: 600;
  }
}

.title {
  font-style: normal;
  font-weight: 700;
  font-size: 24px;
  line-height: 120%;
  text-align: center;
  color: #2c3e50;
}

.paragraph {
  font-size: 16px;
  line-height: 150%;
  letter-spacing: -0.01em;
  margin-top: 20px !important;
  max-width: 407px;
}

.spinner-wrapper {
  display: flex;
  width: 40px;
  height: 40px;
}

.spinner-small-wrapper {
  display: flex;
  width: 20px;
  height: 20px;
}

.loading-spinner {
  border-top-color: $main_blue !important;
}

.message-loader {
  display: flex;
  align-content: center;
  -webkit-box-pack: center;
  justify-content: center;
  width: 100%;
  -webkit-box-align: center;
  position: absolute;
  top: 40%;
  align-items: center;

  @media ($mobile) {
    top: 40%;
    position: fixed;
  }
}

.control-wrapper {
  border-top: 1px solid lightgray;
  display: flex;
  align-items: center;
  flex-direction: column;
  padding: 0 20px 20px;
  position: absolute;
  bottom: 0px;
  width: 100%;
  background-color: white;
  z-index: 1;

  @media ($mobile) {
    padding: 0px 20px 10px 20px;
    position: fixed;
    bottom: 0;
  }
}

.no-control {
  padding-top: 10px;

  @media ($desktop) {
    padding-top: 20px;
  }
}

.new-message-wrapper {
  display: flex;
  flex-direction: column;
  width: 100%;

  .chat-error-msg {
    font-size: 14px;
    color: red;
    margin-bottom: 5px;
  }
}

.interest-wrapper {
  display: flex;
  flex-direction: column;
  width: 100%;
}

.input-wrapper {
  display: flex;
  align-items: center;
  position: relative;
  width: 100%;

  .area-wrapper {
    display: flex;
    width: 100%;
  }

  .input-area {
    border: 1px solid lightgray;
    border-radius: 5px;
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
    resize: none;
    width: 100%;
    height: auto;
    padding: 8px;
  }

  .send-wrapper {
    display: flex;
    margin-left: 20px;

    @media ($mobile) {
      margin-left: 12px;
    }
  }
}

.accordion-wrapper {
  box-shadow: 0px 4px 10px 0px rgba(0, 0, 0, 0.1);
  background-color: white;
}

.acc-wrapper {
  padding: 10px 25px 0 25px;
  position: absolute;
  width: 100%;
  z-index: 2;

  @media ($mobile) {
    padding: 5px 15px 0 15px;
    top: 66px;
    position: fixed;
  }
}

.text-started {
  margin-bottom: 10px;
}

.text-small {
  font-size: 12px;
}

.text-center {
  text-align: center;
}

.load-more {
  text-align: center;
  margin: 20px 0;
  cursor: pointer;
}

.load-more-spinner {
  display: flex;
  justify-content: center;
  padding: 20px 0;
}

.back-wrapper {
  z-index: 5;
  padding: 10px 10px;
  border-bottom: 1px solid lightgray;
  background-color: white;
  position: absolute;
  width: 100%;

  display: flex;
  justify-content: space-between;

  @media ($mobile) {
    position: fixed;
    top: 0;
  }
}

.icon {
  display: inline-flex;
  height: 18px;
  width: 18px;
  background-position: center;
  background-size: contain;
}

.icon-wrapper {
  margin-left: 4px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
}

.missing-interest {
  padding: 20px;
  font-size: 14px;

  padding: 20px 20px 0 20px;

  @media ($mobile) {
    padding: 20px;
  }
}

.conversation-content-wrapper.plain {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  height: 100%;
}

.conversations-wrapper.plain {
  margin-top: 0;
  padding-top: 0;

  @media ($mobile) {
    padding-top: 20px;

    height: calc(
      100vh - 180px - env(safe-area-inset-bottom) - env(safe-area-inset-top)
    );

    min-height: calc(
      100dvh - 180px - env(safe-area-inset-bottom) - env(safe-area-inset-top)
    );

    .used-on-page & {
      margin-bottom: 0;
    }
  }
}

.in-swap-view .conversations-wrapper.plain {
  @media ($mobile) {
    height: calc(
      100vh - 280px - env(safe-area-inset-bottom) - env(safe-area-inset-top)
    );

    min-height: calc(
      100dvh - 280px - env(safe-area-inset-bottom) - env(safe-area-inset-top)
    );
  }
}

.control-wrapper.plain {
  bottom: 0;
  left: 0;
  position: relative;
  // margin-top: 10px;

  @media ($mobile) {
    margin-top: 0;
    position: fixed;

    .used-on-page & {
      position: static;
    }
  }
}

.empty-area {
  display: flex;
  flex-direction: column;
  align-content: center;
  justify-content: flex-start;
  width: 100%;
  height: 100%;
  align-items: center;
  padding: 25px;
}

.empty-area > *:not(:first-child) {
  margin-top: 25px;
}

.mgr-20 {
  margin-right: 20px;
}

.text-link {
  color: $main-blue;
}

.back-title {
  font-size: 14px;
  font-weight: 400;
  line-height: 120%;

  ::v-deep b {
    font-weight: 600;
  }
}

.interest-wrapper ::v-deep .accordion-body {
  padding-top: 8px;
  padding-bottom: 20px;
}

.interest-level-selector-wrapper > *:not(:first-child) {
  margin-top: 20px;
}

.interest-level-selector-wrapper.desktop {
  padding: 0 14px;
}

.interest-unselected-title {
  color: $text-info;
  font-size: 15px;
}

.back-link {
  height: 100%;
}

.swap-icon-wrapper {
  margin-right: 4px;

  @media ($desktop) {
    margin-right: 8px;
  }

  .icon {
    transition: 0.2s transform ease-in-out;
  }

  &.is-open .icon {
    transform: rotate(180deg);
  }
}

.hidden {
  visibility: hidden;
}

::v-deep .accordion-wrapper {
  width: 100%;
}

::v-deep .accordion-title-left {
  width: 100%;
  justify-content: space-between;
}

::v-deep .accordion-title-right {
  margin: 0;
}

.new-match-message {
  text-align: center;
  padding-top: 130px;
}
</style>
