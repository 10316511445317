var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"dots"},[(_vm.dots > 5)?_vm._l((_vm.isVisible),function(dot,index){return _c('div',{key:index,class:[
        'dot',
        {
          active: dot === _vm.active,
          smaller:
            (_vm.isVisible[_vm.isVisible.length - 2] === dot && _vm.active < _vm.dots - 3) ||
            (_vm.isVisible[1] === dot && _vm.active > 3),
          smallest:
            (_vm.isVisible[_vm.isVisible.length - 1] === dot && _vm.active < _vm.dots - 2) ||
            (_vm.isVisible[0] === dot && _vm.active > 2)
        }
      ]})}):_vm._l((_vm.dots),function(dot,index){return _c('div',{key:index,class:[
        'dot',
        {
          active: dot === _vm.active
        }
      ]})})],2)
}
var staticRenderFns = []

export { render, staticRenderFns }