<template>
  <div id="payments" class="payments">
    <BaseBackButton @click="goBack" class="back-button" />

    <div class="payments-container">
      <div v-if="!isLoading" class="payment-category">
        <h2>{{ $t('payment_history_title') }}</h2>

        <h4 v-if="!paymentHistory.length">
          {{ $t('payment_history_no_found') }}
        </h4>

        <PaymentCard
          v-for="payment in paymentHistory"
          :key="payment.paymentId"
          :friendly-product-name="payment.friendlyProductName"
          :friendly-payment-type="payment.friendlyPaymentType"
          :proposition-id="payment.propositionId"
          :days="payment.paymentDays"
          :type="payment.paymentType"
          :sum="payment.amount"
          :product-id="payment.productId"
          :pay-date="new Date(payment.paymentDate)"
          :paid="payment.paid"
          :repaid="payment.repaid"
          :reference="payment.orderId"
          :invoice-url="payment.invoiceUrl"
        />
      </div>

      <div v-else class="payment-category">
        <h2>{{ $t('payment_history_loading') }}</h2>
        <payment-card-loader />
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import PaymentCard from '@/components/PaymentCard';
import PaymentCardLoader from '@/components/PropositionCards/Loaders/PaymentCard';
import BaseBackButton from '../../components/BaseBackButton.vue';

export default {
  name: 'PaymentHistory',

  components: {
    PaymentCard,
    PaymentCardLoader,
    BaseBackButton
  },

  data() {
    return {
      products: null
    };
  },

  head: {
    title() {
      return {
        inner: this.$t('meta_title_payment_history')
      };
    }
  },

  computed: {
    ...mapGetters({
      isMobile: 'screenSize/isMobile',
      paymentHistory: 'paymentHistory/paymentHistory',
      isLoading: 'paymentHistory/isLoading',
      user: 'app/user',
      primaryProposition: 'myPropositions/primaryProposition'
    }),

    handleSubscriptionPath() {
      return (
        this.$routes.HANDLE_SUBSCRIPTION +
        '/' +
        this.primaryProposition.propositionId
      );
    }
  },

  created() {
    this.fetchPaymentHistory();
  },

  methods: {
    ...mapActions({
      fetchPaymentHistory: 'paymentHistory/fetchPaymentHistory'
    }),

    goBack() {
      this.$router.push(this.handleSubscriptionPath);
    }
  }
};
</script>

<style lang="scss" scoped>
.payments {
  display: flex;
  justify-content: space-around;
  flex-direction: column;

  @media ($mobile) {
    padding: 0 16px;
  }
}

.back-button {
  @media ($desktop) {
    padding-left: 60px;
  }
}

.payments-container {
  padding-left: 60px;
  width: calc(75% - 30px); // IE

  @media ($mobile) {
    width: 90%;
    padding: 0;
  }

  h2 {
    font-size: 24px;
    font-weight: 700;
    margin-top: 0;

    @media ($mobile) {
      font-size: 18px;
      margin-bottom: -10px;
      margin-top: 20px;
    }
  }
}
</style>
