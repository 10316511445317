<template>
  <div class="c-wrapper">
    <div class="address-picker-wrapper">
      <transition name="map" mode="out-in" @after-enter="onAfterEnter">
        <div v-if="addressCoordinates && isMobile" class="map-wrapper">
          <ResidenceMap
            :visible="residenceMapTransitionComplete"
            :initial-coordinates="$country.getValue('MAP_INITIAL_COORDINATES')"
            :zoom="$country.getValue('MAP_ZOOM')"
            :coordinates="addressCoordinates"
            :delay-init="true"
            :interactive="false"
          />
        </div>
      </transition>

      <transition name="fade" mode="out-in">
        <div v-if="componentMounted" class="centered">
          <div class="form-wrapper">
            <div class="form-group">
              <MapInputField
                id="streetAddress"
                ref="addressInput"
                :placeholder="$t('address_picker_label_gatuaddress')"
                :no-results-found="searchNoResultsFound"
                :no-results-message="$t('map_suggestions_no_results_address')"
                :value="streetAddressValue"
                :auto-focus="
                  streetAddressValue && streetAddressValue.length == 0
                "
                :handle-update="addressInputHandler"
                :suggestions="searchResultAddresses"
                :suggestion-callback="suggestionClickHandler"
                :has-focus="addressFieldFocused"
                :set-focus="handleFocus"
                :set-blur="handleBlur"
                :loading="searchLoading"
                :input-label="$t('address_picker_label_your_address')"
                input-icon="map-pin"
                :is-valid="
                  selectedAddressValue && selectedAddressValue.length != 0
                "
                :show-errors="showErrors"
                autocomplete="off"
                @keydown="handleStreetAddressKeydown"
              />
              <BaseInput
                v-show="showNumberInput"
                ref="numberInput"
                name="streetNumber"
                type="text"
                :value="streetNumberValue"
                :label="$t('address_picker_label_number_short')"
                :auto-focus="
                  streetAddressValue &&
                  streetAddressValue.length > 0 &&
                  streetNumberValue == 0
                "
                :valid="!showErrors || streetNumberValid"
                autocomplete="off"
                class="street-number-input"
                @input="numberInputHandler"
              />
            </div>
            <div class="selected-address">
              <span class="label">{{
                $t('address_picker_selected_address_label')
              }}</span>
              <template
                v-if="selectedAddressValue && selectedAddressValue.length != 0"
              >
                {{ streetAddressValue
                }}<template v-if="streetNumberValue">
                  {{ streetNumberValue }}</template
                >, {{ streetAreaValue }}
              </template>
              <template v-else>
                {{ $t('address_picker_selected_address_none') }}
              </template>
            </div>
          </div>
        </div>
      </transition>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import { debounce } from 'lodash';
import MapInputField from './MapInputField';
import ResidenceMap from '@/components/Maps/ResidenceMap';

export default {
  name: 'AddressInputField',

  components: {
    MapInputField,
    ResidenceMap
  },

  props: {
    residenceIndex: {
      type: [String, Number],
      required: true
    },
    searchLoading: {
      type: Boolean,
      default: false
    },
    showErrors: {
      type: Boolean,
      default: false
    },
    streetNumberValid: {
      type: Boolean,
      default: false
    }
  },

  data() {
    return {
      addressPickerOpen: false,
      showNumberInput: false,
      addressFieldFocused: false,
      componentMounted: false,
      residenceMapTransitionComplete: false
    };
  },

  computed: {
    ...mapGetters({
      searchResults: 'signup/searchResults',
      searchNoResultsFound: 'signup/searchNoResultsFound',
      getSelectedSuggestion: 'signup/getSelectedSuggestion',
      searchResultAddresses: 'signup/searchResultAddresses',
      coordinates: 'signup/coordinates',
      isMobile: 'screenSize/isMobile',
      residences: 'signup/residences'
    }),

    selectedAddressValue() {
      return this.getSelectedSuggestion({
        residenceIndex: this.residenceIndex
      });
    },

    streetAddressValue() {
      if (!this.residences[this.residenceIndex]) return '';
      return this.residences[this.residenceIndex]['streetAddress'];
    },

    streetNumberValue() {
      if (!this.residences[this.residenceIndex]) return '';
      return this.residences[this.residenceIndex]['streetNumber'];
    },

    streetAreaValue() {
      if (!this.residences[this.residenceIndex]) return '';
      return this.residences[this.residenceIndex]['area'];
    },

    addressCoordinates() {
      return this.coordinates[this.residenceIndex];
    }
  },

  watch: {
    showNumberInput() {
      this.$nextTick(() => {
        if (
          !this.$refs.numberInput ||
          !this.$refs.numberInput.$refs.streetNumber
        )
          return;

        this.$refs.numberInput.$refs.streetNumber.focus();
      });
    },

    coordinates() {
      this.setResidenceCoordinates({
        coordinates: this.coordinates[this.residenceIndex],
        residenceIndex: this.residenceIndex
      });
    }
  },

  mounted() {
    setTimeout(() => {
      if (
        this.streetNumberValue ||
        (this.selectedAddressValue && this.selectedAddressValue.length != 0)
      ) {
        this.showNumberInput = true;
      }
      this.setMounted();
    }, 200);
  },

  methods: {
    ...mapActions({
      searchAddress: 'signup/searchAddress',
      loadSuggestionAddressNumber: 'signup/loadSuggestionAddressNumber',
      loadSuggestionLocalityDetails: 'signup/loadSuggestionLocalityDetails',
      setSelectedSuggestion: 'signup/setSelectedSuggestion',
      setResidenceAddress: 'signup/setResidenceAddress',
      setResidenceNumber: 'signup/setResidenceNumber',
      setResidenceArea: 'signup/setResidenceArea',
      setResidenceCoordinates: 'signup/setResidenceCoordinates'
    }),

    onAfterEnter() {
      this.residenceMapTransitionComplete = true;
    },

    handleSelectAddress() {
      if (this.chatState === this.$chatStates.RESIDENCE_ONE_ADDRESS) {
        this.selectResidence1Address();
      } else {
        this.selectResidence2Address();
      }
    },

    handleStreetAddressKeydown() {
      if (this.addressCoordinates && this.addressCoordinates.length > 0) {
        this.setSelectedSuggestion({
          selectedSuggestion: [],
          residenceIndex: this.residenceIndex
        });
      }
      this.showNumberInput = false;
    },

    openAddressPicker() {
      this.addressPickerOpen = true;
    },

    closeAddressPicker() {
      this.addressPickerOpen = false;
    },

    addressInputHandler(value) {
      this.setResidenceAddress({
        address: value,
        residenceIndex: this.residenceIndex
      });

      this.addressSearchHandler(value);
    },

    addressSearchHandler: debounce(function (value) {
      this.searchAddress({ searchTerm: value });
    }, 200),

    numberInputHandler(value) {
      this.setResidenceNumber({
        residenceIndex: this.residenceIndex,
        number: value
      });

      this.numberSearchHandler(value);
    },

    numberSearchHandler: debounce(function (value) {
      this.loadSuggestionAddressNumber({
        number: value,
        residenceIndex: this.residenceIndex
      });
    }, 300),

    suggestionClickHandler: function (data) {
      const { suggestion } = data;

      this.setSelectedSuggestion({
        selectedSuggestion: suggestion,
        residenceIndex: this.residenceIndex
      });

      this.loadSuggestionLocalityDetails({
        placeId: suggestion.id,
        residenceIndex: this.residenceIndex
      });

      const addressParts = suggestion.name.split(/(\d+)/);
      const address = addressParts[0].trim();
      const number = addressParts.splice(1, addressParts.length).join('');

      this.setResidenceAddress({
        address: address,
        residenceIndex: this.residenceIndex
      });

      this.setResidenceNumber({
        residenceIndex: this.residenceIndex,
        number
      });

      const descriptionParts = suggestion.description.split(',');

      this.setResidenceArea({
        residenceIndex: this.residenceIndex,
        area: descriptionParts[0]
      });

      this.addressFieldFocused = false;
      this.showNumberInput = true;
    },

    handleFocus() {
      this.addressFieldFocused = true;
    },

    handleBlur() {
      this.addressFieldFocused = false;
    },

    setMounted() {
      this.componentMounted = true;
    }
  }
};
</script>

<style lang="scss" scoped>
.c-wrapper {
  width: 100%;

  @media ($desktop) {
    max-width: 600px;
    margin-left: auto;
    margin-right: auto;
  }
  display: flex;
  flex-direction: column;
  align-items: flex-end;
}

.address-picker-wrapper {
  width: 100%;

  &.keyboard-padding {
    padding-bottom: 200px;
  }
}

.btn {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: $dark-blue;
  color: white;
  font-weight: 700;
  font-size: 0.9rem;
  margin: 20px 0;
  padding: 12px;
  border-radius: 8px;
}

.title {
  font-weight: 600;
  font-size: 0.9rem;
  text-align: center;
  margin-bottom: 10px;
}

.options-wrapper {
  display: flex;
  flex-wrap: wrap;
}

.option {
  padding: 8px 12px;
  border-radius: 8px;
  color: white;
  background-color: $main-blue;
  margin: 5px 8px 0 0;
  font-weight: 600;
  font-size: 0.9rem;
  cursor: pointer;
}

.map-wrapper {
  width: 100%;
  max-width: 800px;
  margin: 0 auto;
  height: 240px;

  @media ($mobile) {
    height: 150px;
    margin-bottom: 32px;
  }
}

.form-group {
  width: 100%;
  max-width: 100vw;
  border-radius: 5px;
  display: flex;
  align-items: flex-end;

  & > * {
    &:first-child {
      width: 100%;
    }
  }
}

.field-wrapper {
  display: flex;
  width: 100%;
  & > * {
    &:first-child {
      width: 100%;
    }
  }
}

.street-number-input {
  margin-left: 8px;
  max-width: 70px;
}

.selected-address {
  margin-top: -12px;
  margin-bottom: 32px;
  font-size: 14px;
  font-weight: 600;

  .label {
    color: $text-secondary;
  }
}

.show {
  display: inline-block;
}

.map-enter-active,
.map-leave-active {
  transition: height 0.6s ease-in-out;
  overflow: hidden;
}

.map-enter,
.map-leave-to {
  height: 0px;
}
</style>
