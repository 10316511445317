var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"toggle-wrapper"},[_c('div',{class:[
      'indicator',
      { 'offset-middle': _vm.currentSwapViewTab === _vm.SWAP_VIEW_TABS.CHAT },
      { 'offset-right': _vm.currentSwapViewTab === _vm.SWAP_VIEW_TABS.MAP }
    ]}),_c('div',{class:[
      'toggle-option',
      {
        selected:
          _vm.currentSwapViewTab === _vm.SWAP_VIEW_TABS.SWAP ||
          _vm.currentSwapViewTab === _vm.SWAP_VIEW_TABS.SWAP_ALTERNATIVES
      }
    ],on:{"click":() => _vm.setCurrentSwapViewTab({ type: _vm.SWAP_VIEW_TABS.SWAP })}},[_vm._v(" "+_vm._s(_vm.$t('swap_view_toggle_the_swap'))+" ")]),_c('div',{class:[
      'toggle-option',
      { selected: _vm.currentSwapViewTab === _vm.SWAP_VIEW_TABS.CHAT }
    ],on:{"click":() => _vm.setCurrentSwapViewTab({ type: _vm.SWAP_VIEW_TABS.CHAT })}},[_vm._v(" "+_vm._s(_vm.$t('swap_view_toggle_chat'))+" "),(_vm.chatDot)?_c('div',{staticClass:"unread-dot"}):_vm._e()]),_c('div',{class:[
      'toggle-option',
      { selected: _vm.currentSwapViewTab === _vm.SWAP_VIEW_TABS.MAP }
    ],on:{"click":() => _vm.setCurrentSwapViewTab({ type: _vm.SWAP_VIEW_TABS.MAP })}},[_vm._v(" "+_vm._s(_vm.$t('swap_view_toggle_map'))+" ")])])
}
var staticRenderFns = []

export { render, staticRenderFns }