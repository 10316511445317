<template>
  <div class="swap-tab-navigation-wrapper">
    <div class="swap-tab-nav">
      <TabNavigation :bg-color="tabBgColor">
        <div class="swap-tab-item-wrapper">
          <TabNavigationItem
            :count="unansweredSwaps"
            :active="
              swapFilterType === SWAP_FILTER_TYPES.UNANSWERED ||
              swapFilterType === SWAP_FILTER_TYPES.NEW
            "
            :show-count="unansweredSwaps !== 0"
            :should-animate="animateUnAnsweredTab"
            :bg-color="tabBgColor"
            @click.native="
              () => {
                resetFilters({ onPageLoad: true });

                setSwapType({
                  swapFilterType: SWAP_FILTER_TYPES.UNANSWERED,
                  update: true
                });
              }
            "
            >{{ $t('swap_list_tabs_unanswered') }}
          </TabNavigationItem>
        </div>

        <div
          v-if="lastPartABTest"
          :class="['swap-tab-item-wrapper', { disabled: lastPartCount === 0 }]"
        >
          <TabNavigationItem
            :count="lastPartCount"
            :active="swapFilterType === SWAP_FILTER_TYPES.LAST_PART_NEW"
            :show-count="lastPartCount !== 0"
            :should-animate="false"
            :bg-color="tabBgColor"
            @click.native="
              () => {
                resetFilters({ onPageLoad: true });

                setSwapType({
                  swapFilterType: SWAP_FILTER_TYPES.LAST_PART_NEW,
                  update: true
                });
              }
            "
            >{{ $t('swaps_list_toggle_me') }}
          </TabNavigationItem>
        </div>

        <div
          :class="['swap-tab-item-wrapper', { disabled: answeredSwaps === 0 }]"
        >
          <TabNavigationItem
            :count="answeredSwaps ? answeredSwaps : 0"
            :active="swapFilterType === SWAP_FILTER_TYPES.ANSWERED"
            :show-count="answeredSwaps !== 0"
            :should-animate="animateAnsweredTab"
            :bg-color="tabBgColor"
            @click.native="
              () => {
                if (answeredSwaps === 0) {
                  return;
                }
                // preventing modals in DK and if user has premium
                if (!$country.isCountry('dk') && primaryPropositionIsLimited) {
                  trackAnsweredTabClicks();
                }
                resetFilters({ onPageLoad: true });

                setSwapType({
                  swapFilterType: SWAP_FILTER_TYPES.ANSWERED,
                  update: true
                });
              }
            "
            >{{ $t('swap_list_tabs_answered') }}
          </TabNavigationItem>
        </div>
        <div
          :class="['swap-tab-item-wrapper', { disabled: removedSwaps === 0 }]"
        >
          <TabNavigationItem
            :count="removedSwaps ? removedSwaps : 0"
            :active="swapFilterType === SWAP_FILTER_TYPES.REMOVED"
            :show-count="removedSwaps !== 0"
            :should-animate="animateRemovedTab"
            :bg-color="tabBgColor"
            @click.native="
              () => {
                if (removedSwaps === 0) {
                  return;
                }
                resetFilters({ onPageLoad: true });

                setSwapType({
                  swapFilterType: SWAP_FILTER_TYPES.REMOVED,
                  update: true
                });
              }
            "
          >
            {{ $t('swap_list_tabs_removed') }}
          </TabNavigationItem>
        </div>
      </TabNavigation>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import TabNavigation from '@/components/Navigation/TabNavigation/TabNavigation.vue';
import TabNavigationItem from '@/components/Navigation/TabNavigation/TabNavigationItem.vue';
import { SWAP_FILTER_TYPES } from '../../store/modules/swapList';
import { subscribe, unsubscribe } from '../../utils/socket';

export default {
  name: 'SwapTabNavigation',

  components: {
    TabNavigation,
    TabNavigationItem
  },

  props: {
    tabBgColor: {
      type: String,
      default: '#fff',
      required: false
    }
  },

  data() {
    return {
      SWAP_FILTER_TYPES: SWAP_FILTER_TYPES,
      animateAnsweredTab: false,
      animateUnAnsweredTab: false,
      animateRemovedTab: false,
      interestCookieName: 'lb-interests',
      interestCookieExp: 30
    };
  },

  computed: {
    ...mapGetters({
      swapFilterType: 'swapList/swapFilterType',
      unansweredSwaps: 'swapList/unansweredSwaps',
      swapInterestCounts: 'swapList/swapInterestCounts',
      showGlobalModal: 'ui/showGlobalModal',
      tabAnsweredClicks: 'ui/tabAnsweredClicks',
      primaryPropositionIsLimited: 'myPropositions/primaryPropositionIsLimited'
    }),

    lastPartABTest() {
      return this.$growthbook.isFeatureFlagEnabled('last-part-tab');
    },

    currentPropositionId() {
      return (
        this.$route.params.otherUserPropositionId || this.primaryPropositionId
      );
    },

    lastPartCount() {
      return this.swapInterestCounts?.interestedInMine?.total ?? 0;
    },

    answeredSwaps() {
      return this.swapInterestCounts?.answered?.total ?? 0;
    },

    removedSwaps() {
      return this.swapInterestCounts?.removed?.total ?? 0;
    },

    totalInterestMarks() {
      return this.swapInterestCounts?.ownerInterestMarks.total;
    }
  },

  created() {
    // subscribe to swap interest events
    subscribe('interest', this.onInterest);
  },

  beforeDestroy() {
    // unsubscribe from swap interest events
    unsubscribe('interest', this.onInterest);
  },

  methods: {
    ...mapActions({
      setSwapType: 'swapList/setSwapType',
      getSwaps: 'swapList/getSwaps',
      setGlobalModal: 'ui/setGlobalModal',
      setTabAnsweredClicks: 'ui/setTabAnsweredClicks',
      resetFilters: 'swapList/resetFilters'
    }),

    onInterest(event) {
      if (event.interested === true) {
        this.animateAnsweredTab = !this.animateAnsweredTab;
      }
      if (event.interested === false) {
        this.animateRemovedTab = !this.animateRemovedTab;
      }
      if (event.interested === null) {
        this.animateUnAnsweredTab = !this.animateUnAnsweredTab;
        // get swaps again when unanswered tab animates
        this.getSwaps(this.currentPropositionId);
      }
    },

    trackAnsweredTabClicks() {
      this.setTabAnsweredClicks(this.tabAnsweredClicks + 1);
      const hasInterestMarks = this.totalInterestMarks > 0;
      const likelyhood = 0.8;

      switch (this.tabAnsweredClicks) {
        case 1:
          if (Math.random() < likelyhood) {
            // 1 answered tab click and interstmarks are zero
            if (!hasInterestMarks) {
              this.setGlobalModal('NoInterestMarksActionModal');
              // 1 answered tab click and has interestmarks
            } else {
              this.setGlobalModal('InterestMarksActionModal');
            }
          }
          break;
        case 10:
          // 10 answered tab clicks and has interestmarks
          if (hasInterestMarks && Math.random() < likelyhood) {
            this.setGlobalModal('InterestMarksActionModal');
          }
          break;
        case 20:
          // 20 answered tab clicks and has interestmarks
          if (hasInterestMarks && Math.random() < likelyhood) {
            this.setGlobalModal('InterestMarksActionModal');
          }
          break;
        case 30:
          // reset answered tab clicks
          this.setTabAnsweredClicks(0);
          break;
      }
    }
  }
};
</script>

<style lang="scss" scoped>
.swap-tab-navigation-wrapper {
  margin-left: auto;
  margin-right: auto;
  max-width: 1400px;

  .menu-wrapper {
    margin-bottom: 0;
    background-color: transparent;
  }

  .swap-tab-item-wrapper {
    &.disabled {
      pointer-events: none;
      opacity: 0.5;
    }
  }
}
</style>
