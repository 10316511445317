<template>
  <div class="handle-subscription">
    <div class="handle-subscription-container">
      <spinner v-if="autoSubscriptionLoading" />
      <h1 v-if="!isMobile" class="page-title">
        {{
          isLoading
            ? $t('handle_subscription_title_loading')
            : $t('handle_subscription_title')
        }}
      </h1>

      <div class="wrapper">
        <div class="top-container">
          <BaseBackButton @click="goBack" class="back-button" />

          <LinkSubListItem :path="$routes.PAYMENT_HISTORY" class="link">
            <p class="title">{{ $t('handle_subscription_payment_history') }}</p>
            <p class="info">
              {{ $t('handle_subscription_payment_history_subtext') }}
            </p>
          </LinkSubListItem>
        </div>

        <current-subscription
          v-if="!isLoading"
          class="current-subscription"
          :current-subscription="currentSubscription"
          :current-proposition="currentProposition"
          :open-cancel-modal="openCancelModal"
          :handle-renew-subscription="handleRenewSubscription"
          :go-to-payment-page="goToPaymentPage"
        />

        <current-subscription-loader v-else />
      </div>
      <div v-if="!isLoading" class="cancel-wrapper">
        <div
          v-if="currentSubscription.cancelledAt && currentProposition.active"
          class="cancel"
        >
          <h3>{{ $t('handle_subscription_cancel_title') }}</h3>
          <p>
            {{ $t('handle_subscription_cancel_info') }}
          </p>
          <button
            class="cancel-button"
            @click="() => cancelSubscription({ cancelImmediately: true })"
          >
            {{ $t('handle_subscription_cancel_action') }}
          </button>
        </div>

        <div
          v-else-if="
            (currentSubscription.paymentMethod === 'Complimentary' ||
              currentSubscription.paymentMethod === 'Trial') &&
            currentProposition.active
          "
          class="cancel"
        >
          <h3>{{ $t('handle_subscription_confirm_title') }}</h3>

          <p>
            {{ $t('handle_subscription_cancel_info') }}
          </p>
          <button class="cancel-button" @click="openCancelModal">
            {{ $t('handle_subscription_confirm_action') }}
          </button>
        </div>

        <div v-else-if="currentProposition.active" class="cancel">
          <h3>{{ $t('handle_subscription_confirm_title') }}</h3>

          <p>
            {{
              $t('handle_subscription_confirm_text_1', {
                nextPaymentDate
              })
            }}
          </p>
          <p>
            {{ $t('handle_subscription_confirm_text_2') }}
          </p>
          <button class="cancel-button" @click="openCancelModal">
            {{ $t('handle_subscription_confirm_action') }}
          </button>
        </div>
      </div>
    </div>

    <!-- Cancel modal -->
    <stepper-modal
      v-if="cancelModalOpen"
      :close-function="closeCancelModal"
      :no-shadow="true"
    >
      <cancel-subscription slot="content" :close-function="closeCancelModal" />
    </stepper-modal>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import CancelSubscription from '../../components/HandleSubscription/CancelSubscription';

import StepperModal from '../../components/Modals/StepperModal';
import CurrentSubscription from '../../components/HandleSubscription/CurrentSubscription';
import CurrentSubscriptionLoader from '../../components/PropositionCards/Loaders/CurrentSubscription';
import Spinner from '../../components/Loading/Spinner';
import events from '@/utils/helpers/events';
import LinkSubListItem from '../../components/Navigation/LinkSubList/LinkSubListItem.vue';

export default {
  name: 'HandleSubscription',

  components: {
    CancelSubscription,
    StepperModal,
    CurrentSubscription,
    CurrentSubscriptionLoader,
    Spinner,
    LinkSubListItem
  },

  data() {
    return {
      working: false
    };
  },

  head: {
    title() {
      return {
        inner: this.$t('handle_subscription_title')
      };
    }
  },

  computed: {
    ...mapGetters({
      isLoading: 'handleSubscription/isLoading',
      currentProposition: 'handleSubscription/currentProposition',
      currentSubscription: 'handleSubscription/currentSubscription',
      cancelModalOpen: 'handleSubscription/cancelModalOpen',
      isMobile: 'screenSize/isMobile',
      autoSubscriptionLoading: 'handleSubscription/autoSubscriptionLoading',
      user: 'app/user'
    }),

    editPropositionPath() {
      return (
        this.$routes.EDIT_PROPOSITION +
        '/' +
        this.currentProposition.propositionId
      );
    },

    myPropositionsPath() {
      return this.$routes.MY_PROPOSITIONS;
    },

    nextPaymentDate() {
      if (this.currentSubscription.paymentMethod === 'Complimentary') {
        return this.$t('handle_subscription_at_login');
      }

      return this.currentProposition.paidUntil;
    }
  },

  mounted() {
    try {
      this.$nextTick(() => {
        document.body.scrollTop = document.documentElement.scrollTop = 0;
      });
    } catch (error) {
      //  Empty catch
    }

    this.$l.log('Get current proposition');
    this.getCurrentProposition({ propositionId: this.$route.params.id });
  },

  destroyed() {
    this.resetInitialState();
  },

  methods: {
    ...mapActions({
      getCurrentProposition: 'handleSubscription/getCurrentProposition',
      openCancelModal: 'handleSubscription/openCancelModal',
      closeCancelModal: 'handleSubscription/closeCancelModal',
      cancelSubscription: 'handleSubscription/cancelSubscription',
      activateAutoSubscription: 'handleSubscription/activateAutoSubscription',
      activateFreeSubscription: 'handleSubscription/activateFreeSubscription',
      resetInitialState: 'handleSubscription/resetInitialState'
    }),

    goBack() {
      if (this.$router.history.length > 1) {
        this.$router.go(-1);
      } else {
        this.$router.push(this.myPropositionsPath);
      }
    },

    async handleRenewSubscription() {
      if (!this.working) {
        this.working = true;

        console.log(this.currentSubscription);

        if (this.currentSubscription.paymentMethod === 'Klarna') {
          await this.activateAutoSubscription();
        } else if (this.currentSubscription.paymentMethod === 'Adyen') {
          this.$router.push(
            `${this.$t('path_pay', this.$routeLocale)}/${this.propositionId}`
          );
        } else if (this.currentSubscription.paymentMethod === 'Complimentary') {
          await this.activateFreeSubscription();
        } else {
          this.$router.push(
            this.$t('path_change_to_klarna', this.$routeLocale)
          );
        }

        this.working = false;
      }
    },

    goToPaymentPage() {
      events.emitEvent(events.eventTypes.CLICK, 'Payment', {
        id: 'handle-subscription'
      });

      this.$router.push(
        `${this.$routes.PAYMENT_PROPOSITION}/${this.currentProposition.propositionId}`
      );
    }
  }
};
</script>

<style lang="scss" scoped>
.handle-subscription {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.handle-subscription-container {
  @media ($mobile) {
    width: 100%;
  }
}

.top-container {
  @media ($mobile) {
    width: 100%;
    padding: 0 16px;
  }
}

.page-title {
  font-size: 1.9rem;
  font-weight: 600;
}

.link {
  margin-top: 4px;

  @media ($desktop) {
    margin-top: 0;
  }

  p {
    margin: 0;
  }

  .title {
    color: #0b182c;
  }

  .info {
    color: #999999;
  }
}

.current-subscription {
  @media ($mobile) {
    padding: 48px 16px;
    width: 100%;
  }

  @media ($desktop) {
    margin: 48px 0;
  }
}

.cancel-wrapper {
  @media ($mobile) {
    display: flex;
    justify-content: flex-start;
    width: 100%;
    border-top: 3px solid #dddcdb;
    padding: 0 16px 140px 16px;
  }
}

.cancel {
  padding: 20px 0;
  max-width: 550px;

  & > p {
    margin: 3px 0;
    font-weight: 600;
    color: $text-primary;
    font-size: 0.85rem;
    line-height: 1.3rem;
  }
}

.cancel-button {
  cursor: pointer;
  text-decoration: none;
  border: none;
  background-color: rgba(0, 0, 0, 0);
  padding: 0;
  font-weight: 600;
  color: $text-primary;
  font-size: 0.85rem;
  line-height: 0.9rem;
  margin-top: 15px;
  border-bottom: 1px solid $text-primary;
}
</style>
