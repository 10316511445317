<template>
  <div
    class="base-icon"
    :class="icon"
    :width="width"
    :height="height"
    :style="{ ...cssProps, ...styleIcon, ...styleRotation }"
    v-on="$listeners"
  ></div>
</template>

<script>
export default {
  name: 'BaseIcon',
  props: {
    icon: {
      type: String,
      default: ''
    },
    iconFile: {
      type: String,
      default: ''
    },
    rotateDeg: {
      type: Number,
      default: null
    },
    width: {
      type: Number,
      default: 24
    },
    height: {
      type: Number,
      default: 24
    }
  },
  computed: {
    cssProps() {
      return {
        '--icon-width': this.width + 'px',
        '--icon-height': this.height + 'px'
      };
    },

    styleIcon() {
      if (this.iconFile) {
        try {
          const dir = require(`@/assets/svg/${this.iconFile}.svg`);

          return {
            backgroundImage: `url(${dir})`
          };
        } catch (error) {
          console.error('Icon not found:', error.message);
        }
      }

      return {};
    },

    styleRotation() {
      if (!this.rotateDeg) return {};

      return { transform: `rotate(${this.rotateDeg}deg)` };
    }
  }
};
</script>

<style lang="scss" scoped>
.base-icon {
  background-position: center;
  background-size: contain;
  width: var(--icon-width);
  height: var(--icon-height);

  &.close {
    background-image: url('../assets/svg/icon-close.svg');
  }

  &.close-black {
    background-image: url('../assets/svg/close.svg');
  }

  &.check-circle {
    background-image: url('../assets/svg/icon-check-circle.svg');
  }

  &.envelope {
    background-image: url('../assets/svg/icon-envelope.svg');
  }

  &.map-pin {
    background-image: url('../assets/svg/icon-map-pin-alt.svg');
  }

  &.pen {
    background-image: url('../assets/svg/icon-pen.svg');
  }

  &.ruler-combined {
    background-image: url('../assets/svg/icon-ruler-combined.svg');
  }

  &.wallet {
    background-image: url('../assets/svg/icon-wallet.svg');
  }

  &.lock {
    background-image: url('../assets/svg/icon-lock.svg');
  }

  &.magnifying-glass {
    background-image: url('../assets/svg/icon-magnifying-glass.svg');
  }

  &.arrow-left {
    background-image: url('../assets/svg/icon-arrow-left.svg');
  }

  &.chevron-right {
    background-image: url('../assets/svg/icon-chevron-right.svg');
  }

  &.chevron-left-dark {
    background-image: url('../assets/svg/chevron-left-dark.svg');
  }

  &.camera {
    background-image: url('../assets/svg/icon-camera.svg');
  }

  &.camera-white {
    background-image: url('../assets/svg/icon-camera-white.svg');
  }

  &.angle-up {
    background-image: url('../assets/svg/icon-angle-up.svg');
  }

  &.angle-down {
    background-image: url('../assets/svg/icon-angle-down.svg');
  }

  &.rotate-left {
    background-image: url('../assets/svg/icon-rotate-left.svg');
  }

  &.rotate-right {
    background-image: url('../assets/svg/icon-rotate-right.svg');
  }

  &.trash-alt {
    background-image: url('../assets/svg/icon-trash-alt.svg');
  }

  &.thumb-up {
    background-image: url('../assets/svg/icon-thumb-up.svg');
  }

  &.eye {
    background-image: url('../assets/svg/icon-eye.svg');
  }

  &.eye-hidden {
    background-image: url('../assets/svg/icon-eye-hidden.svg');
  }

  &.clock {
    background-image: url('../assets/svg/icon-clock.svg');
  }

  &.smile {
    background-image: url('../assets/svg/icon-smile.svg');
  }

  &.padlock {
    background-image: url('../assets/svg/icon-padlock.svg');
  }

  &.bell {
    background-image: url('../assets/svg/icon-bell.svg');
  }

  &.bell-badge {
    background-image: url('../assets/svg/icon-bell-badge.svg');
  }

  &.bell-large {
    background-image: url('../assets/svg/icon-bell-large.svg');
  }

  &.bell-large-blue {
    background-image: url('../assets/svg/icon-bell-large-blue.svg');
  }

  &.bell-with-badge-large {
    background-image: url('../assets/svg/icon-bell-with-badge-large.svg');
  }

  &.watch {
    background-image: url('../assets/svg/icon-watch.svg');
  }

  &.eye-white {
    background-image: url('../assets/svg/icon-eye-white.svg');
  }

  &.eye-clock-ten {
    background-image: url('../assets/svg/icon-clock-ten.svg');
  }

  &.comment-alt-check {
    background-image: url('../assets/svg/icon-comment-alt-check.svg');
  }

  &.comment-info {
    background-image: url('../assets/svg/icon-comment-info.svg');
  }

  &.comment-question {
    background-image: url('../assets/svg/icon-comment-question.svg');
  }

  &.link-broken {
    background-image: url('../assets/svg/icon-link-broken.svg');
  }

  &.megaphone {
    background-image: url('../assets/svg/icon-megaphone.svg');
  }

  &.trophy {
    background-image: url('../assets/svg/icon-trophy.svg');
  }

  &.swap {
    background-image: url('../assets/svg/icon-swap.svg');
  }

  &.swap-active {
    background-image: url('../assets/svg/icon-swap-active.svg');
  }

  &.search {
    background-image: url('../assets/svg/icon-search.svg');
  }

  &.search-active {
    background-image: url('../assets/svg/icon-search-active.svg');
  }

  &.chat2 {
    background-image: url('../assets/svg/icon-chat2.svg');
  }

  &.chat2-active {
    background-image: url('../assets/svg/icon-chat2-active.svg');
  }

  &.my-prop {
    background-image: url('../assets/svg/icon-my-prop.svg');
  }

  &.my-prop-active {
    background-image: url('../assets/svg/icon-my-prop-active.svg');
  }

  &.notifications {
    background-image: url('../assets/svg/icon-notifications.svg');
  }

  &.notifications-active {
    background-image: url('../assets/svg/icon-notifications-active.svg');
  }

  &.focus-add {
    background-image: url('../assets/svg/icon-focus-add.svg');
  }

  &.search-header {
    background-image: url('../assets/svg/icon-search-header.svg');
  }

  &.menu {
    background-image: url('../assets/svg/icon-menu.svg');
  }

  &.bankid {
    background-image: url('../assets/svg/bankid-input.svg');
  }

  &.bankid-white {
    background-image: url('../assets/svg/bankid-white.svg');
  }

  &.verified {
    background-image: url('../assets/svg/icon-verified-badge.svg');
  }
}
</style>
