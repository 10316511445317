var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{ref:"reversePropositionCard",staticClass:"reverse-action-wrapper"},[_c('div',{class:['reverse-action-container', { 'is-boosted': _vm.swap.boosted }]},[(
        (_vm.isMobile &&
          !_vm.swap.boosted &&
          _vm.currentShownResidence &&
          _vm.currentShownResidence.pictures &&
          _vm.currentShownResidence.pictures.length > 0) ||
        (!_vm.isMobile &&
          _vm.currentShownResidence &&
          _vm.currentShownResidence.pictures &&
          _vm.currentShownResidence.pictures.length > 0)
      )?_c('img',{staticClass:"primary-image",attrs:{"src":_vm.currentShownResidence &&
        _vm.currentShownResidence.pictures &&
        _vm.currentShownResidence.pictures.length !== 0
          ? _vm.getImageThumbnail(_vm.currentShownResidence.pictures[0])
          : _vm.currentShownResidence.image || _vm.fallbackImage}}):(
        _vm.isMobile &&
        _vm.swap.boosted &&
        _vm.currentShownResidence.pictures &&
        _vm.currentShownResidence.pictures.length > 0
      )?_c('div',{class:['primary-image-container']},[_c('img',{class:['primary-image'],attrs:{"src":_vm.currentShownResidence.pictures &&
          _vm.currentShownResidence.pictures.length !== 0
            ? _vm.getImageThumbnail(_vm.currentShownResidence.pictures[0])
            : _vm.currentShownResidence.image || _vm.fallbackImage}})]):_c('div',{staticClass:"primary-image img-placeholder"}),(
        _vm.isMobile &&
        _vm.swap.boosted &&
        _vm.currentShownResidence.pictures &&
        _vm.currentShownResidence.pictures.length >= 3
      )?_c('div',{staticClass:"secondary-images-container"},[_c('img',{attrs:{"src":_vm.getImageThumbnail(_vm.currentShownResidence.pictures[1])}}),_c('img',{attrs:{"src":_vm.getImageThumbnail(_vm.currentShownResidence.pictures[2])}})]):_vm._e(),_c('div',{class:[
        'reverse-action-text-and-btn-container',
        { 'is-boosted': _vm.swap.boosted }
      ]},[_c('p',{staticClass:"address"},[_vm._v(" "+_vm._s(_vm.currentShownResidence ? _vm.currentShownResidence.streetAddress : '-')+" ")]),_c('p',{staticClass:"info-text"},[_vm._v(_vm._s(_vm.$t('reverse_interest_action_card_text')))]),_c('BaseButton',{class:[
          _vm.isMobile
            ? 'grey outline rounded smaller full-width'
            : 'grey outline rounded smaller',
          'reverse-interest-btn'
        ],on:{"click":_vm.handleAttemptReverseInterestAction}},[_vm._v(" "+_vm._s(_vm.$t('reverse_interest_action_btn_text'))+" ")])],1)])])
}
var staticRenderFns = []

export { render, staticRenderFns }