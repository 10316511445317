var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"interest-buttons"},[_c('div',{class:['buttons-wrapper', { 'blur-content': _vm.blurContent }]},[_c('div',{class:[
        'interest-btn not-interested',
        {
          'is-not-interested': _vm.interested === false
        },
        { 'reverse-is-shown': _vm.reverseIsShown }
      ],on:{"click":function($event){$event.preventDefault();$event.stopPropagation();return _vm.handleNoClick.apply(null, arguments)}}},[_c('BaseIcon',{staticClass:"button-icon",attrs:{"icon-file":_vm.interested === false ? 'close-new-white' : 'close-new',"width":14,"height":14}}),_vm._v(" "+_vm._s(_vm.$t('interest_button_hide'))+" ")],1),_c('div',{class:[
        'interest-btn interested',
        {
          'is-interested': _vm.interested === true,
          'background-fill-rl':
            !_vm.noInterestMarksLeft &&
            _vm.interested === true &&
            _vm.shouldAnimateYesButton
        }
      ],on:{"click":function($event){$event.preventDefault();$event.stopPropagation();return _vm.handleYesClick.apply(null, arguments)}}},[_c('div',{staticClass:"button-content"},[(!_vm.noInterestMarksLeft && _vm.interested && _vm.shouldAnimateYesButton)?_c('YesClickAnimation'):_vm._e(),_c('BaseIcon',{staticClass:"button-icon",attrs:{"icon-file":_vm.interested ? 'icon-check3-white' : 'icon-check3',"width":14,"height":14}}),_vm._v(" "+_vm._s(_vm.$t('interest_button_interested'))+" ")],1)])])])
}
var staticRenderFns = []

export { render, staticRenderFns }