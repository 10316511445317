var render = function render(){var _vm=this,_c=_vm._self._c;return _c('button',_vm._g(_vm._b({class:[
    'button',
    {
      'is-fullwidth': _vm.isFullwidth,
      'is-disabled': _vm.isDisabled,
      'is-rounded': _vm.isRounded,
      'is-pill': _vm.isPill,
      'is-small': _vm.isSmall,
      'is-small': _vm.isSmall,
      'is-large': _vm.isLarge,
      'is-transparent': _vm.isTransparent,
      'is-default': _vm.isDefault,
      'is-dark': _vm.isDark,
      hover: _vm.hoverEffect,
      capitalize: _vm.capitalize,
      loading: _vm.isLoading
    },
    ..._vm.classes
  ],attrs:{"disabled":_vm.isDisabled}},'button',_vm.$attrs,false),_vm.$listeners),[(_vm.isLoading)?_c('div',{staticClass:"inner-wrapper"},[_c('div',{staticClass:"spinner"},[_c('ASpinner')],1)]):_vm._e(),_c('div',{staticClass:"inner-wrapper",class:{ hidden: _vm.isLoading || _vm.animating }},[_vm._t("default"),(_vm.isPill)?_c('div',{staticClass:"right",class:{ hidden: !_vm.isPill || _vm.isLoading || _vm.animating }},[_c('div',{staticClass:"icon-wrapper"},[_c('AImage',{attrs:{"url":_vm.icons.GENERAL.CLOSE_WHITE,"classes":['icon-close']}})],1)]):_vm._e()],2)])
}
var staticRenderFns = []

export { render, staticRenderFns }