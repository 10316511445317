var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{class:['card', { isSelected: _vm.isSelected }]},[_c('div',{staticClass:"body"},[_c('div',{staticClass:"photos",class:{
        'one-photo': _vm.match.propositions.length === 2,
        'two-photos': _vm.match.propositions.length === 3
      }},[(_vm.match.propositions.length === 3)?_c('BaseImage',{key:_vm.match.propositions.propositionId,attrs:{"url":_vm.match.propositions[1].image,"is-placeholder-small":true}}):_vm._e(),_c('BaseImage',{key:_vm.match.propositions[0].propositionId,attrs:{"url":_vm.match.propositions[0].image,"is-placeholder-small":true}})],1),_c('div',{staticClass:"adress-and-message-container"},[_c('div',{class:[
          'address',
          { 'card-has-badge': _vm.match.unreadCount > 0 || _vm.match.isNew }
        ]},[_c('div',{staticClass:"part-b"},[_vm._v(_vm._s(_vm.match.propositions[0].streetAddress))]),(_vm.match.propositions.length === 3)?_c('div',{staticClass:"part-c"},[_vm._v(" & "+_vm._s(_vm.match.propositions[1].streetAddress)+" ")]):_vm._e()]),(_vm.match.lastMessage)?_c('div',{class:[
          'last-message',
          { 'last-message--inactive-card': _vm.swapIsInactive }
        ]},[(_vm.match.lastMessage.senderName === 'SYSTEM')?[_c('div',{staticClass:"name-and-date"},[_vm._v(" "+_vm._s(JSON.parse(_vm.match.lastMessage.message).displayName)+" – "+_vm._s(_vm.customDate(_vm.match.lastMessage.sentAt))+" ")]),_c('div',{staticClass:"message message-system",domProps:{"innerHTML":_vm._s(
              _vm.truncateText(
                _vm.convertSystemChatMessageWithoutAddress(
                  _vm.match.lastMessage.message
                ),
                40
              )
            )}})]:[_c('div',{staticClass:"name-and-date"},[_vm._v(" "+_vm._s(_vm.match.lastMessage.senderName)+" – "+_vm._s(_vm.customDate(_vm.match.lastMessage.sentAt))+" ")]),_c('div',{staticClass:"message",domProps:{"innerHTML":_vm._s(
              _vm.match.lastMessage.message
                ? _vm.truncateText(_vm.match.lastMessage.message, 40)
                : ''
            )}})]],2):_c('div',{staticClass:"last-message"},[_c('div',{staticClass:"name-and-date"},[_vm._v(" "+_vm._s(_vm.customDate(_vm.match.createdAt))+" ")]),_c('div',{staticClass:"message"},[_vm._v(_vm._s(_vm.$t('matches_card_new_match_msg')))])])]),(_vm.match.unreadCount > 0)?_c('div',{staticClass:"new-badge"},[_c('BaseIcon',{attrs:{"icon-file":"unread-mail","width":12,"height":12}}),_vm._v(" "+_vm._s(_vm.match.unreadCount)+" ")],1):(_vm.match.isNew)?_c('div',{staticClass:"new-badge new-badge-match"},[_c('div',{staticClass:"dot"}),_vm._v(" "+_vm._s(_vm.$t('matches_card_new_badge'))+" ")]):_vm._e(),(_vm.primaryPropositionIsLimited && _vm.match.chatRoom.id !== 0)?_c('div',{staticClass:"chat-badge chat-started-badge"},[_vm._v(" "+_vm._s(_vm.$t('matches_card_conversation_started'))+" ")]):_vm._e()]),_c('div',{staticClass:"footer"},[(_vm.partBPropositionActive)?_c('RouterLink',{staticClass:"swap-link",attrs:{"to":{
        query: {
          propositionPageId: _vm.partBPropositionId
        }
      }},nativeOn:{"click":function($event){$event.stopPropagation();}}},[_vm._v(_vm._s(_vm.$t('matches_card_show_prop'))+" "),_c('BaseIcon',{staticClass:"icon",attrs:{"icon-file":'chevron-right-grey',"width":12,"height":12}})],1):_vm._e()],1)])
}
var staticRenderFns = []

export { render, staticRenderFns }