var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"base-input",class:{
    'has-text': !!_vm.value,
    invalid: !_vm.valid
  }},[_c('label',{class:['base-input-label', { 'is-dark': _vm.isLabelDark }],attrs:{"for":_vm.name}},[_vm._t("label",function(){return [_vm._v(_vm._s(_vm.label))]},null,{ label: _vm.label })],2),_c('div',{staticClass:"base-input-wrapper",on:{"click":_vm.handleInputClick}},[(_vm.icon)?_c('BaseIcon',{staticClass:"base-input-icon",attrs:{"icon":_vm.icon},on:{"click":() => _vm.$emit('input-icon-clicked')}}):_vm._e(),_c('input',_vm._g(_vm._b({ref:_vm.name,class:{ 'base-input-is-password': _vm.isPassword },attrs:{"id":_vm.name,"placeholder":_vm.placeholder,"required":_vm.required,"disabled":_vm.disabled,"name":_vm.name,"type":_vm.inputType},domProps:{"value":_vm.value},on:{"keyup":function($event){return _vm.onChange($event)}}},'input',_vm.$attrs,false),_vm.listeners)),(_vm.suffix)?_c('div',{staticClass:"base-input-suffix"},[_vm._v(_vm._s(_vm.suffix))]):_vm._e(),(_vm.isPassword)?_vm._t("show-password",function(){return [_c('div',{staticClass:"pw-visibility-toggle",on:{"click":function($event){$event.stopPropagation();$event.preventDefault();return _vm.switchVisibilityPassword.apply(null, arguments)}}},[_c('BaseIcon',{attrs:{"icon":_vm.isPasswordVisible ? 'eye' : 'eye-hidden'}})],1)]},null,{
        isPasswordVisible: _vm.isPasswordVisible,
        switchVisibilityPassword: _vm.switchVisibilityPassword
      }):_vm._e()],2),_c('transition',{attrs:{"name":"sf-fade"}},[(!_vm.valid && _vm.errorMessage)?_vm._t("error-message",function(){return [_c('div',{staticClass:"base-input-error-message"},[_vm._v(_vm._s(_vm.errorMessage))])]},null,{ errorMessage: _vm.errorMessage }):_vm._e()],2)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }